import React from "react";

import LoadingSpinner from "../../../../../components/LoadingSpinner/LoadingSpinner";
import bomFromApiPropType from "../../../../../propTypes/bomSummaryPropType";
import useQualifiedBills from "./useQualifiedBills";

function QualifiedBill({ bom }) {
  return (
    <div style={{ height: "max-content" }} className="d-flex flex-column justify-content-between gap-3 bg-light-2 p-3 rounded">
      <h4 className="m-0">{bom.BOMID}</h4>
      <p className="m-0">{bom.Desc}</p>
    </div>
  );
}

QualifiedBill.propTypes = {
  bom: bomFromApiPropType.isRequired,
};

function QualifiedBillsBodyPart() {
  const { selectedBoms, loading } = useQualifiedBills();

  let body;
  if (loading) {
    body = (
      <div className="d-flex justify-content-center align-items-center">
        <LoadingSpinner />
      </div>
    );
  } else {
    body = selectedBoms.map((bom) => (
      <div className="mb-3" key={bom.BOMID}>
        <QualifiedBill bom={bom} />
      </div>
    ));
  }

  return <div>{body}</div>;
}

export default QualifiedBillsBodyPart;
