import * as Sentry from "@sentry/react";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { useGenerateQualificationPdfMutation, useGetQualificationPdfQuery } from "../features/api/qualificationPDFs";
import { addErrors } from "../features/app/appStateSlice";
import DebugLogger from "../utils/logging";
import useBom from "./useBom";

const logger = new DebugLogger("useQualificationPdf.js");

export default function useQualificationPdf(bomId) {
  const [canPoll, setCanPoll] = useState(false);
  const [generationInProgress, setGenerationInProgress] = useState(false);
  const { data: pdf, isError, error } = useGetQualificationPdfQuery({ bomId }, { pollingInterval: 5000, skip: !canPoll });
  const { bom } = useBom(bomId);
  const generatePdf = useGenerateQualificationPdfMutation()[0];
  const dispatch = useDispatch();

  useEffect(() => {
    if (isError) {
      if (error.status !== 404) {
        dispatch(addErrors([JSON.stringify(error.data || `Failed to retrieve PDF for ${bomId}`)]));
      } else if (error.data?.inProgress) {
        setGenerationInProgress(true);
      } else {
        setGenerationInProgress(false);
      }
    } else if (pdf && pdf.url) {
      setGenerationInProgress(false);
    } else {
      setGenerationInProgress(true);
    }

    return () => {
      setGenerationInProgress(false);
    };
  }, [isError, error, dispatch, bomId, pdf]);

  useEffect(() => {
    if (bom && bom.BOM_State === "CLOSED") {
      if (!generationInProgress) {
        generatePdf({ bomId })
          .then(() => {
            logger.log("PDF generation triggered.");
          })
          .catch((err) => {
            Sentry.captureException(err);
            dispatch(addErrors([JSON.stringify(err.data || "Unable to generate PDF.")]));
          });
      }
    }
  }, [generationInProgress, generatePdf, dispatch, bomId, bom]);

  useEffect(() => {
    if (bom && bom.BOM_State === "CLOSED") {
      if (!generationInProgress && pdf && pdf.url) {
        setCanPoll(false);
      } else {
        setCanPoll(true);
      }
    } else {
      setCanPoll(false);
    }

    return () => {
      setCanPoll(false);
    };
  }, [pdf, generationInProgress, bom]);

  return useMemo(
    () => ({
      pdf,
    }),
    [pdf],
  );
}
