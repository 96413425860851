import React from "react";
import { PiDownloadSimpleBold } from "react-icons/pi";

import sampleFile from "../../../../data/Template_Sample_File.xlsx";

function DownloadSampleFile() {
  return (
    <a href={sampleFile} rel="noreferrer" target="_blank" className="btn p-0 fw-bold text-secondary text-uppercase d-flex align-items-center gap-1">
      <PiDownloadSimpleBold />
      Sample File
    </a>
  );
}

export default DownloadSampleFile;
