import React from "react";

import useViewAs from "../../../../hooks/useViewAs";
import BOMCards from "./BOMCards/BOMCards";
import BOMTable from "./BOMTable/BOMTable";
import { HistoryBodyContextProvider } from "./HistoryBodyContext";
import Toolbar from "./Toolbar/Toolbar";
import TopActions from "./TopActions";

function Content() {
  const { viewAs } = useViewAs();

  if (viewAs === "card") {
    return <BOMCards />;
  }

  return <BOMTable />;
}

export default function HistoryBody() {
  return (
    <HistoryBodyContextProvider>
      <div>
        <TopActions />
        <Toolbar />
        <Content />
      </div>
    </HistoryBodyContextProvider>
  );
}
