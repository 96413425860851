import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import Progress from "../../../../components/Progress/Progress";
import { currentBOMImportIndexSelector, unparsedBOMsSelector } from "../../../../features/fileImport/fileImportSlice";
import { toOrdinal } from "../../../../utils/text";
import { MATCH_FILE_FIELDS_STEP, UPLOAD_FILE_STEP } from "../../constants";

function IndexDisplay() {
  const unparsedBOMs = useSelector(unparsedBOMsSelector);
  const currentIndex = useSelector(currentBOMImportIndexSelector);
  const [totalBoms, setTotalBoms] = useState(0);

  useEffect(() => {
    if (unparsedBOMs && Array.isArray(unparsedBOMs)) {
      setTotalBoms(unparsedBOMs.length);
    }
  }, [currentIndex, unparsedBOMs]);

  if (unparsedBOMs.length <= 1) {
    return "";
  }

  return (
    <p className="text-center">
      Importing the <b>{toOrdinal(currentIndex + 1)}</b> of <b>{totalBoms} BOMs.</b>
    </p>
  );
}

function ProgressBar({ step }) {
  const items = useMemo(
    () => [
      {
        name: "Upload File",
        active: step >= UPLOAD_FILE_STEP,
        percentComplete: step > UPLOAD_FILE_STEP ? 100 : 0,
      },
      {
        name: "Match File Fields",
        active: step >= MATCH_FILE_FIELDS_STEP,
        percentComplete: 0,
      },
      {
        name: "Submission",
        active: step > MATCH_FILE_FIELDS_STEP,
        percentComplete: 0,
      },
    ],
    [step],
  );

  return (
    <div className="d-flex flex-column gap-3">
      <IndexDisplay />
      <Progress items={items} />
    </div>
  );
}

ProgressBar.propTypes = {
  step: PropTypes.number.isRequired,
};

export default ProgressBar;
