import { encodeURI } from "js-base64";
import { useEffect, useState } from "react";

export default function useEncodedBomId(bomId) {
  const [encodedId, setEncodedId] = useState(null);

  useEffect(() => {
    if (bomId) {
      setEncodedId(encodeURI(bomId));
    } else {
      setEncodedId(null);
    }
  }, [bomId]);
  return { encodedId };
}
