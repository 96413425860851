import React, { useMemo } from "react";

import certificatePropType from "../../../../propTypes/certificatePropType";
import DataCard from "../DataCard/DataCard";

function Certifier({ certificate }) {
  const dataCardItems = useMemo(
    () => [
      {
        type: "Name",
        text: certificate.certifier_name,
      },
      {
        type: "Role",
        text: certificate.certifier_type,
      },
      {
        type: "Phone",
        text: certificate.certifier_phone_number,
      },
      {
        type: "Email",
        text: certificate.certifier_email,
      },
      {
        type: "Address",
        text: certificate.certifier_address,
      },
    ],
    [certificate],
  );
  return (
    <div>
      <h2 className="mb-4">Certifier Data</h2>
      <DataCard items={dataCardItems} titleData={{ type: "Certifying Company", title: certificate.certifying_company }} />
    </div>
  );
}

Certifier.propTypes = {
  certificate: certificatePropType.isRequired,
};

export default Certifier;
