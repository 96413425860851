import ScrollToBottomButton from "../ScrollToBottomButton/ScrollToBottomButton";

function FixedScrollToBottomButton() {
  return (
    <div className="position-fixed" style={{ bottom: "2rem", right: "2rem" }}>
      <ScrollToBottomButton />
    </div>
  );
}

export default FixedScrollToBottomButton;
