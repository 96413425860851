/* eslint no-param-reassign: 0 */
import { createSlice } from "@reduxjs/toolkit";

const navigationSlice = createSlice({
  name: "navigation",
  initialState: {
    openSidebar: false,
    currentPage: null,
  },
  reducers: {
    toggleSidebar(state) {
      state.openSidebar = !state.openSidebar;
    },
    setOpenSidebar(state, action) {
      state.openSidebar = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
  },
});

export default navigationSlice.reducer;

export const { toggleSidebar, setOpenSidebar, setCurrentPage } = navigationSlice.actions;

export function sidebarStateSelector(state) {
  return state.navigation.openSidebar;
}

export function currentPageSelector(state) {
  return state.navigation.currentPage;
}
