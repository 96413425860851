import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { certifierSelector, exporterSelector, setExporter } from "../../../../features/certificateOfOrigin/certificateOfOriginSlice";

export default function useExporterForm() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const currentCertifier = useSelector(certifierSelector);
  const currentExporter = useSelector(exporterSelector);

  const initialValues = useMemo(
    () => ({
      name: currentExporter?.name || (currentCertifier.role === "exporter" && currentCertifier.name) || "",
      email: currentExporter?.email || (currentCertifier.role === "exporter" && currentCertifier.emailAddress) || "",
      address: currentExporter?.address || (currentCertifier.role === "exporter" && currentCertifier.address) || "",
      phoneNumber: currentExporter?.phoneNumber || (currentCertifier.role === "exporter" && currentCertifier.phoneNumber) || "",
    }),
    [currentExporter, currentCertifier],
  );

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      setSubmitting(true);
      dispatch(setExporter(values));
      navigate("/dashboard/certificate-of-origin/generate/producer-data/");
      setSubmitting(false);
    },
    [navigate, dispatch],
  );

  useEffect(() => {
    if (!currentCertifier) {
      Swal.fire({
        title: "Error!",
        text: "Please provide certifier information first.",
        icon: "error",
      }).then(() => {
        navigate("/dashboard/certificate-of-origin/generate/certifier-form/", { replace: true });
      });
    } else {
      setLoading(false);
    }

    return () => setLoading(true);
  }, [navigate, currentCertifier]);

  return useMemo(() => ({ loading, handleSubmit, initialValues }), [loading, handleSubmit, initialValues]);
}
