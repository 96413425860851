import { useCallback, useEffect, useMemo, useState } from "react";

import useQualificationPdf from "../../hooks/useQualificationPdf";

function useViewPDFButton(bomId) {
  const [viewing, setViewing] = useState(false);
  const [loading, setLoading] = useState(true);
  const { pdf: pdfData } = useQualificationPdf(bomId);

  const handleStartViewing = useCallback(() => {
    setViewing(true);
  }, []);

  const handleStopViewing = useCallback(() => {
    setViewing(() => false);
  }, [setViewing]);

  useEffect(() => {
    if (pdfData) {
      setLoading(false);
    } else {
      setLoading(true);
    }

    return () => {
      setLoading(true);
    };
  }, [pdfData]);

  return useMemo(
    () => ({ viewing, loading, pdfUrl: pdfData?.url, handleStopViewing, handleStartViewing }),
    [viewing, loading, pdfData, handleStopViewing, handleStartViewing],
  );
}

export default useViewPDFButton;
