import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { isAutomotive as _isAutomotive } from "../../utils/automotive";
import FormikSelectField from "../FormikSelectField";

function useIsAutomotive() {
	const [isAutomotive, setIsAutomotive] = useState(false);
	const { values } = useFormikContext();

	useEffect(() => {
		if (values?.hts) {
			if (values.hts.length >= 9) {
				setIsAutomotive(_isAutomotive);
			} else {
				setIsAutomotive(false);
			}
		} else {
			setIsAutomotive(false);
		}

		return () => {
			setIsAutomotive(false);
		};
	}, [values]);

	return isAutomotive;
}

export default function AutomotiveCard() {
	const [show, setShow] = useState(false);
	const isAutomotive = useIsAutomotive();

	useEffect(() => {
		setShow(isAutomotive);
	}, [isAutomotive]);

	if (!show) {
		return;
	}

	return (
		<div className="card">
			<div className="card-header">
				<h4 className="card-title m-0">Automotive Details</h4>
			</div>
			<div className="card-body">
				<div className="mb-3">
					<FormikSelectField
						name="autoType"
						options={[
							{
								name: "Passenger vehicles, light trucks, and parts thereof",
								value: "PASS",
							},
							{
								name: "Heavy trucks and parts thereof",
								value: "HEAVY",
							},
							{
								name: "Other vehicles",
								value: "OTHER",
							},
						]}
						label="Type*"
						helpText="Select the category your good falls within"
						allowNone
					/>
				</div>
				<FormikSelectField
					name="autoTimeframe"
					options={[
						{
							name: "beginning on January 1, 2020, or the date of entry into force of this Agreement, whichever is later",
							value: "2020",
						},
						{
							name: "January 1, 2021, or one year after the date of entry into force of this Agreement, whichever is later",
							value: "2021",
						},
						{
							name: "January 1, 2022, or two years after the date of entry into force of this Agreement, whichever is later",
							value: "2022",
						},
						{
							name: "beginning on January 1, 2023, or three years after the date of entry into force of this Agreement, whichever is later",
							value: "2023",
						},
					]}
					label="Type*"
					helpText="Select the applicable timeframe for this claim"
					allowNone
				/>
			</div>
		</div>
	);
}
