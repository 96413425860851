import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { PiCaretDown, PiCheck } from "react-icons/pi";
import OutsideClickHandler from "react-outside-click-handler";
import { useSearchParams } from "react-router-dom";

function SortByButton({ options }) {
  const [showDropdown, setShowDropDown] = useState(false);
  const [currentOption, setCurrentOption] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClick = useCallback(
    (option) => {
      setSearchParams((currentParams) => {
        const newParams = new URLSearchParams(currentParams.toString());
        newParams.set("sort-by", option);
        return newParams;
      });
      setShowDropDown(false);
    },
    [setSearchParams],
  );

  useEffect(() => {
    if (searchParams) {
      const o = searchParams.get("sort-by");
      if (o) {
        setCurrentOption(o);
      } else {
        setCurrentOption(null);
      }
    } else {
      setCurrentOption(null);
    }

    return () => {
      setCurrentOption(null);
    };
  }, [searchParams]);
  return (
    <div className="position-relative">
      <OutsideClickHandler onOutsideClick={() => setShowDropDown(false)}>
        <button
          onClick={() => setShowDropDown((current) => !current)}
          type="button"
          className="btn btn-outline-primary sort-by-btn text-primary fw-bold text-uppercase no-border-no-outline d-flex align-items-center gap-1"
        >
          Sort By
          <PiCaretDown />
        </button>
      </OutsideClickHandler>

      <div
        style={{ top: "2rem", right: ".825rem" }}
        className={`sort-by-btn__dropdown ${
          showDropdown ? "sort-by-btn__dropdown--show" : ""
        } d-flex flex-column position-absolute border rounded py-2 px-3 bg-light-0 gap-1`}
      >
        {options.map(([option, name], idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <button key={idx} type="button" onClick={() => handleClick(option)} className="btn p-0 no-border-no-outline d-flex gap-2 align-items-center">
            {name}
            {currentOption === option && <PiCheck />}
          </button>
        ))}
      </div>
    </div>
  );
}

SortByButton.propTypes = {
  options: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
};
export default SortByButton;
