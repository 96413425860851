import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import { useDeleteBOMMutation } from "../../features/api/boms";

export default function DeleteBOMConfirmationModal({ bomId, show, onHide, onSuccess }) {
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState(null);
  const [deleteBOM, { isError, isSuccess }] = useDeleteBOMMutation();

  function handleDelete() {
    setInProgress(true);
    setError(null);
    deleteBOM(bomId).finally(() => setInProgress(false));
  }

  useEffect(() => {
    if (isError) {
      setError("Failed to delete BOM. Please try again.");
    }

    return () => {
      setError(null);
    };
  }, [isError]);

  return (
    <Modal show={show} onHide={isSuccess ? onSuccess : onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Delete BOM {bomId}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <p className="alert alert-danger">{error}</p>}
        {isSuccess ? <p className="alert alert-info">BOM deleted.</p> : <p className="alert alert-warning">Are you sure you want to delete this BOM?</p>}
      </Modal.Body>
      <Modal.Footer>
        {isSuccess ? (
          <button onClick={() => onSuccess()} type="button" className="btn btn-outline-primary">
            Close
          </button>
        ) : (
          <>
            <button onClick={() => onHide()} type="button" className="btn btn-outline-primary">
              Cancel
            </button>
            <button onClick={handleDelete} type="button" className="btn btn-primary" disabled={inProgress}>
              Delete
            </button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}
