import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function useToggleView() {
  const [currentView, setCurrentView] = useState("card");
  const [searchParams, setSearchParams] = useSearchParams();

  const handleToggleView = useCallback(() => {
    const v = searchParams.get("view");
    if (v) {
      if (v === "card") {
        setSearchParams((currentParams) => {
          const newParams = new URLSearchParams(currentParams.toString());
          newParams.set("view", "list");
          return newParams;
        });
      } else {
        setSearchParams((currentParams) => {
          const newParams = new URLSearchParams(currentParams.toString());
          newParams.set("view", "card");
          return newParams;
        });
      }
    } else {
      setSearchParams((currentParams) => {
        const newParams = new URLSearchParams(currentParams.toString());
        newParams.set("view", "list");
        return newParams;
      });
    }
  }, [setSearchParams, searchParams]);

  useEffect(() => {
    if (searchParams) {
      const view = searchParams.get("view");
      if (view) {
        setCurrentView(view);
      }
    } else {
      setCurrentView("card");
    }

    return () => {
      setCurrentView("card");
    };
  }, [searchParams]);

  return useMemo(() => ({ handleToggleView, currentView }), [handleToggleView, currentView]);
}
