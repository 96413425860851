import PropTypes from "prop-types";
import React, { createContext, useContext, useMemo } from "react";
import { useSelector } from "react-redux";

import { bomIdSelector } from "../../../features/bomWizard/bomWizardSlice";
import useBOMStateRequired from "../../../hooks/useBOMStateRequired";
import useBom from "../../../hooks/useBom";

const FinishedGoodQuestionsContext = createContext();

function FinishedGoodQuestionsContextProvider({ children = "" }) {
  const bomId = useSelector(bomIdSelector);
  const { loading: bomLoading, bom } = useBom(bomId);
  useBOMStateRequired(bom?.BOM_State, "FG_QUALIFYING");

  const value = useMemo(() => ({ bomLoading, bom }), [bomLoading, bom]);
  return <FinishedGoodQuestionsContext.Provider value={value}>{children}</FinishedGoodQuestionsContext.Provider>;
}

FinishedGoodQuestionsContextProvider.propTypes = {
  children: PropTypes.node,
};

export { FinishedGoodQuestionsContextProvider };

export function useFinishedGoodQuestionsContext() {
  return useContext(FinishedGoodQuestionsContext);
}
