import Decimal from "decimal.js";

export default function getExtCost(cost, qty) {
  const costFloat = parseFloat(cost);
  const qtyFloat = parseFloat(qty);

  if (Number.isNaN(costFloat) || Number.isNaN(qtyFloat)) {
    return 0;
  }

  const costDecimal = new Decimal(costFloat);
  return costDecimal.mul(qtyFloat).toString();
}
