import dayjs from "dayjs";
import { Base64, decode, encodeURI, encodeURL } from "js-base64";

import api from "./apiSlice";

function transformBomListItem(bomListItem) {
	return {
		BOMID: Base64.decode(bomListItem.id),
		BOM_State: bomListItem.status,
		TS: dayjs(bomListItem.created_at).unix(),
		SubmitTS: dayjs(bomListItem.created_at).unix(),
		Qualified: bomListItem.qualified ? "Y" : "N",
		QualPref: bomListItem.qualification_preference,
		QualUnder: bomListItem.qualified_under,
		Hts: bomListItem.fg.hts,
		Part: bomListItem.fg.part_number,
		Desc: bomListItem.fg.description,
		Value: bomListItem.fg.value,
		Origin: bomListItem.fg.Origin,
	};
}

const bomsApi = api.injectEndpoints({
	endpoints(build) {
		return {
			listBoms: build.query({
				query() {
					return "/extras/api/v2/boms";
				},
				transformResponse(baseQueryReturnValue) {
					if (
						baseQueryReturnValue &&
						Array.isArray(baseQueryReturnValue) &&
						baseQueryReturnValue.length > 0
					) {
						const boms = [...baseQueryReturnValue];
						boms.sort((bomA, bomB) => {
							const timeA = dayjs(bomA.updated_at);
							const timeB = dayjs(bomB.updated_at);
							if (timeA.isBefore(timeB)) {
								return 1;
							}
							if (timeA.isAfter(timeB)) {
								return -1;
							}
							return 0;
						});
						return boms.map(transformBomListItem);
					}
					return [];
				},
				providesTags: ["BOMList"],
			}),
			getBom: build.query({
				query(bomId) {
					const encodedBomId = encodeURI(bomId);
					return `/extras/api/v2/boms/${encodedBomId}`;
				},
				transformResponse(baseQueryReturnValue) {
					if (!baseQueryReturnValue) {
						return {};
					}
					let submitTimeStamp = "";
					const submittedAt = baseQueryReturnValue.submitted_at;
					if (submittedAt) {
						submitTimeStamp = dayjs(submittedAt).unix();
					}
					const transformed = {
						BOMID: decode(baseQueryReturnValue.id),
						BOM_State: baseQueryReturnValue.status,
						SubmitTS: submitTimeStamp,
						Qualified: baseQueryReturnValue.qualified ? "Y" : "N",
						QualPref: baseQueryReturnValue.qualification_preference,
						QualUnder: baseQueryReturnValue.qualified_under,
						QualNotes: baseQueryReturnValue.qualification_notes || "",
						FG: {
							Hts: baseQueryReturnValue.fg.hts,
							Part: baseQueryReturnValue.fg.part_number,
							Desc: baseQueryReturnValue.fg.description,
							Value: baseQueryReturnValue.fg.transaction_value,
							NetCost: baseQueryReturnValue.fg.net_cost,
							TotalCost: baseQueryReturnValue.fg.total_cost,
							Currency: baseQueryReturnValue.currency,
							Origin: baseQueryReturnValue.fg.origin,
							AutoType: baseQueryReturnValue.fg.auto_type,
							AutoTimeframe: baseQueryReturnValue.fg.auto_timeframe,
						},
						FG_Qs_Length: null,
						FG_Qs: baseQueryReturnValue.fg.questions.map((question) => ({
							RuleID: question.rule_id,
							Question: question.question,
							AnsLabel: question.answer_label,
							Ans: question.accepted ? "Y" : "N",
						})),
						Length: null,
						Comp_Qs_Length: null,
						Comp_Qs: baseQueryReturnValue.components.flatMap((component) =>
							component.questions.flatMap((question) => ({
								RuleID: question.component_id,
								Question: question.question,
								AnsLabel: question.answer_label,
								Ans: question.accepted ? "Y" : "N",
							})),
						),
						Components: baseQueryReturnValue.components.map(
							(component, idx) => ({
								LineItem: String(idx + 1),
								Category: component.category,
								Hts: component.hts,
								Part: component.part_number,
								Desc: component.description,
								Origin: component.origin,
								Qty: component.qty,
								Unit: component.units,
								Cost: component.cost,
								USMCA: component.usmca ? "Y" : "N",
								Originating: component.originating ? "Y" : "N",
							}),
						),
					};
					return transformed;
				},
				providesTags(result, _, bomId) {
					if (result) {
						return [{ type: "BOMDetail", id: bomId }];
					}
					return [];
				},
			}),
			createBOM: build.mutation({
				query: (body) => ({
					url: "/extras/api/v2/boms",
					method: "POST",
					body,
				}),
				invalidatesTags: ["BOMList"],
			}),
			updateBOM: build.mutation({
				query: ({ bomId, body }) => ({
					url: `/extras/api/v2/boms/${encodeURL(bomId)}`,
					method: "PATCH",
					body,
				}),
				invalidatesTags(result, error, { bomId }) {
					return [{ type: "BOMDetail", id: bomId }, "BOMList"];
				},
			}),
			deleteBOM: build.mutation({
				query(bomId) {
					return {
						url: `/extras/api/v2/boms/${encodeURL(bomId)}`,
						method: "DELETE",
					};
				},
				invalidatesTags(result, error, bomId) {
					return [{ type: "BOMDetail", id: bomId }, "BOMList"];
				},
			}),
			submitFinishedGood: build.mutation({
				query(bomId) {
					return {
						url: `/extras/api/v2/boms/submit/${encodeURL(bomId)}`,
						method: "POST",
					};
				},
				invalidatesTags(result, error, bomId) {
					return [{ type: "BOMDetail", id: bomId }, "BOMList"];
				},
			}),
			submitComponents: build.mutation({
				query(bomId) {
					return {
						url: `/extras/api/v2/boms/submit/components/${encodeURL(bomId)}`,
						method: "POST",
					};
				},
				invalidatesTags(result, error, bomId) {
					return [{ type: "BOMDetail", id: bomId }, "BOMList"];
				},
			}),
			createComponents: build.mutation({
				query({ BOMID, Components }) {
					return {
						url: "/comps",
						method: "POST",
						body: { BOMID, Components },
					};
				},
			}),
			answerFinishedGoodQuestions: build.mutation({
				query: ({ bomId, body }) => ({
					url: `/extras/api/v2/boms/submit/fg-questions/${encodeURL(bomId)}`,
					method: "PUT",
					body,
				}),
				invalidatesTags(result, error, { bomId }) {
					return [{ type: "BOMDetail", id: bomId }, "BOMList"];
				},
			}),
			answerComponentQuestions: build.mutation({
				query({ bomId, body }) {
					return {
						url: `/extras/api/v2/boms/submit/component-questions/${encodeURL(bomId)}`,
						method: "POST",
						body,
					};
				},
				invalidatesTags(result, error, { bomId }) {
					return [{ type: "BOMDetail", id: bomId }, "BOMList"];
				},
			}),
			closeBom: build.mutation({
				query(bomId) {
					return {
						url: `/extras/api/v2/boms/close/${encodeURL(bomId)}`,
						method: "POST",
					};
				},
				invalidatesTags(result, error, bomId) {
					return [{ type: "BOMDetail", id: bomId }, "BOMList"];
				},
			}),
		};
	},
});

export const {
	useListBomsQuery,
	useGetBomQuery,
	useCreateBOMMutation,
	useCreateComponentsMutation,
	useAnswerFinishedGoodQuestionsMutation,
	useAnswerComponentQuestionsMutation,
	useCloseBomMutation,
	useUpdateBOMMutation,
	useDeleteBOMMutation,
	useSubmitFinishedGoodMutation,
	useSubmitComponentsMutation,
} = bomsApi;
