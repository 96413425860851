import { Field, useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import FormError from "../../../components/FormError";

function ComponentField({ id, name, idx, placeholder = "", type = "text", as = "input", label, children = null, formClass = "form-control", disabled = false }) {
  const { errors, touched } = useFormikContext();
  const [error, setError] = useState(null);

  useEffect(() => {
    if (errors && touched) {
      if (errors.components && touched.components) {
        if (errors.components[idx] && touched.components[idx]) {
          if (errors.components[idx][name] && touched.components[idx][name]) {
            setError(errors.components[idx][name]);
          } else {
            setError(null);
          }
        } else {
          setError(null);
        }
      } else {
        setError(null);
      }
    } else {
      setError(null);
    }
  }, [errors, touched, idx, name]);

  return (
    <>
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <Field
        disabled={disabled}
        type={type}
        as={as}
        placeholder={placeholder}
        id={id}
        name={`components.${idx}.${name}`}
        className={`${formClass} ${error ? "is-invalid" : ""}`}
      >
        {children}
      </Field>
      {error && <FormError error={error} />}
    </>
  );
}

ComponentField.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  idx: PropTypes.number.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  as: PropTypes.string,
  children: PropTypes.node,
  formClass: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ComponentField;
