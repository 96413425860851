import { useMemo } from "react";
import { useSelector } from "react-redux";

import { useGetCustomerQuery } from "../features/api/customers";
import { currentIdTokenPayloadSelector } from "../features/auth/authSlice";

export default function useAuthenticatedUserData() {
  const { data: customer } = useGetCustomerQuery();
  const idTokenPayload = useSelector(currentIdTokenPayloadSelector);

  return useMemo(
    () => ({
      userDisplay: customer?.name || (idTokenPayload && idTokenPayload["cognito:username"]),
    }),
    [idTokenPayload, customer],
  );
}
