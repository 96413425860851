import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { useCreateCustomerMutation, useGetCustomerQuery } from "../../features/api/customers";
import { useGetPresignedPostQuery } from "../../features/api/storage";
import generateAvatarFileKey from "../../utils/generateAvatarFileKey";

export default function useCompleteProfile() {
  const [inProgress, setInProgress] = useState(false);
  const [customerData, setCustomerData] = useState(null);
  const [avatarProcessed, setAvatarProcessed] = useState(false);
  const [avatarFileKey, setAvatarFileKey] = useState(null);
  const [avatarFileBlob, setAvatarFileBlob] = useState(null);
  const [avatarIsLoading, setAvatarIsLoading] = useState(false);
  // As data uri
  const [currentAvatarImage, setCurrentAvatarImage] = useState(null);
  const { data: customer } = useGetCustomerQuery();
  const navigate = useNavigate();
  const [createCustomer, { isError: createCustomerIsError }] = useCreateCustomerMutation();
  const { data: presignedUpload } = useGetPresignedPostQuery({ key: avatarFileKey }, { skip: !avatarFileKey });

  const handleSetAvatarImage = useCallback((file) => {
    setAvatarIsLoading(true);
    setAvatarFileBlob(file);
    const fileReader = new FileReader();
    fileReader.addEventListener("load", (evt) => {
      setCurrentAvatarImage(evt.target.result);
      setAvatarIsLoading(false);
    });
    fileReader.readAsDataURL(file);
  }, []);

  const handleSubmitCustomerData = useCallback((values) => {
    setInProgress(true);
    setCustomerData({
      name: values.name,
      company_name: values.companyName,
      phone_number: values.phoneNumber,
      street_address: values.streetAddress,
      city: values.city,
      state: values.state,
      country: values.country,
      zip_code: values.zipCode,
      tax_id: values.taxId,
      subscription_type: "payg",
    });
  }, []);

  useEffect(() => {
    if (customer) {
      navigate("/dashboard/create-bill-of-material/");
    }
  }, [customer, navigate]);

  useEffect(() => {
    if (createCustomerIsError) {
      Swal.fire({
        title: "Error!",
        text: "Failed to create your profile. Please try again.",
        icon: "error",
      });
    }
  }, [createCustomerIsError]);

  useEffect(() => {
    if (customerData) {
      if (avatarFileBlob) {
        if (!avatarProcessed) {
          if (customerData.avatar_file_key) {
            setAvatarProcessed(true);
          } else {
            setAvatarFileKey(generateAvatarFileKey(avatarFileBlob.name));
          }
        } else if (avatarProcessed && customerData.avatar_file_key) {
          createCustomer(customerData);
        }
      } else {
        createCustomer(customerData);
      }
    }
  }, [customerData, avatarFileBlob, avatarProcessed, createCustomer]);

  useEffect(() => {
    if (presignedUpload && avatarFileKey) {
      const formData = new FormData();
      Object.entries(presignedUpload.fields).forEach(([k, v]) => {
        formData.append(k, v);
      });
      formData.append("file", avatarFileBlob);
      fetch(presignedUpload.url, {
        method: "POST",
        body: formData,
      }).then((res) => {
        if (res.ok) {
          setCustomerData((currentData) => ({ ...currentData, avatar_file_key: avatarFileKey }));
        } else {
          Swal.fire({
            title: "Error!",
            text: "Failed to upload your avatar!",
            icon: "error",
          });
        }
      });
    }
  }, [presignedUpload, avatarFileKey, avatarFileBlob]);

  return useMemo(
    () => ({ currentAvatarImage, handleSetAvatarImage, avatarIsLoading, handleSubmitCustomerData, inProgress }),
    [currentAvatarImage, handleSetAvatarImage, avatarIsLoading, handleSubmitCustomerData, inProgress],
  );
}
