import PropTypes from "prop-types";

export default PropTypes.shape({
  certificate_id: PropTypes.string,
  certifying_company: PropTypes.string,
  certifier_name: PropTypes.string,
  certifier_email: PropTypes.string,
  certifier_phone_number: PropTypes.string,
  certifier_address: PropTypes.string,
  exporter_name: PropTypes.string,
  exporter_email: PropTypes.string,
  exporter_phone_number: PropTypes.string,
  exporter_address: PropTypes.string,
  importer_name: PropTypes.string,
  importer_email: PropTypes.string,
  importer_phone: PropTypes.string,
  importer_address: PropTypes.string,
  producers: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  qualified_goods: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  pdf_url: PropTypes.string,
});
