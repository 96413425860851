import { Form, Formik } from "formik";
import { nanoid } from "nanoid";
import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import {
  csvHeadersSelector,
  currentBOMImportIndexSelector,
  headerValidation as headerValidationSelector,
  parsedComponents,
  parsedFinishedGood,
  showHeaderConfirmationModal,
  unparsedBOMsSelector,
} from "../../features/fileImport/fileImportSlice";
import rightArrow from "../../images/right-arrow.svg";
import { toOrdinal } from "../../utils/text";
import useCancelImport from "./hooks/useCancelImport";
import useHeaderConfirmationModal from "./hooks/useHeaderConfirmationModal";

/**
 *
 * @param name {string}
 */
function getShortItemName(name) {
  if (name.length > 5) {
    return `${name.slice(0, 4)}...`;
  }
  return name;
}

function HeadersPreviewTable() {
  const headers = useSelector(csvHeadersSelector);
  const finishedGood = useSelector(parsedFinishedGood);
  const components = useSelector(parsedComponents);

  if (!headers || !finishedGood || !components) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <table style={{ overflow: "hidden", borderSpacing: 0, borderCollapse: "separate" }} className="table table-borderless border border-1 rounded table-rounded-corners">
      <thead>
        <tr>
          {headers.map((header, idx) => (
            <th className={`${idx !== headers.length - 1 ? "border-end" : ""} border-bottom border-bottom-1`} key={`csvHeader${header}`} scope="col">
              {getShortItemName(header)}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          {finishedGood.map((column, idx) => (
            <td className={`${idx !== headers.length - 1 ? "border-end" : ""}`} key={nanoid()}>
              {column.length > 0 ? getShortItemName(column) : "-"}
            </td>
          ))}
        </tr>
        {components.map((componentRow) => (
          <tr key={nanoid()}>
            {componentRow.map((column, idx) => (
              <td className={`${idx !== headers.length - 1 ? "border-end" : ""}`} key={nanoid()}>
                {column.length > 0 ? getShortItemName(column) : "-"}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function HeaderConfirmationModal() {
  const currentIndex = useSelector(currentBOMImportIndexSelector);
  const unparsedBOMs = useSelector(unparsedBOMsSelector);
  const { cancel } = useCancelImport();
  const { handleConfirmHeaders: handleSubmit } = useHeaderConfirmationModal();
  const show = useSelector(showHeaderConfirmationModal);
  const headerValidation = useSelector(headerValidationSelector);

  return (
    <Modal size="xl" centered show={show} onHide={() => cancel()}>
      <Modal.Body>
        <Formik onSubmit={handleSubmit} initialValues={{ isHeaders: "Y" }}>
          {({ isSubmitting }) => (
            <Form>
              {unparsedBOMs && unparsedBOMs.length > 1 && (
                <p className="text-center">
                  Importing the <b>{toOrdinal(currentIndex + 1)}</b> of <b>{unparsedBOMs.length}</b> BOMs.
                </p>
              )}
              <div className="d-flex gap-3">
                <div>
                  <div className="d-flex align-items-start gap-2 pt-1 mb-3">
                    <p style={{ width: "168px" }} className="m-0">
                      Is this the header of your file? (Do not modify or change.)
                    </p>
                    <img style={{ marginTop: "4px" }} src={rightArrow} alt="Right allow" />
                  </div>
                </div>
                <div>
                  <div className="mb-5">
                    <HeadersPreviewTable />
                  </div>
                  {headerValidation && headerValidation.done && !headerValidation.isValid && (
                    <p className="alert alert-danger mb-5 text-center">Your file doesn&apos;t contain the correct headers. Please see the sample spreadsheet.</p>
                  )}
                  <div className="d-flex justify-content-center align-items-center gap-2">
                    <button type="button" onClick={() => cancel()} className="btn btn-outline-primary text-uppercase fw-bold">
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary text-uppercase fw-bold" disabled={isSubmitting}>
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default HeaderConfirmationModal;
