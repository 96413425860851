/* eslint no-param-reassign: 0 */
import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: { isLoggedIn: false, idToken: null, accessToken: null, refreshToken: null, idTokenPayload: null },
  reducers: {
    setCredentials: (state, action) => {
      const { accessToken, refreshToken, idToken, idTokenPayload } = action.payload;
      state.isLoggedIn = true;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.idToken = idToken;
      state.idTokenPayload = idTokenPayload || state.idTokenPayload;
    },
    logOut: (state) => {
      state.isLoggedIn = false;
      state.accessToken = null;
      state.refreshToken = null;
      state.idToken = null;
      state.idTokenPayload = null;
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

export const currentIdTokenPayloadSelector = (state) => state.auth.idTokenPayload;
export const currentIdTokenSelector = (state) => state.auth.idToken;
export const currentLoginStateSelector = (state) => state.auth.isLoggedIn;
export const currentAccessTokenSelector = (state) => state.auth.accessToken;
export const currentRefreshTokenSelector = (state) => state.auth.refreshToken;
