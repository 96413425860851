import React, { useEffect, useState } from "react";
import { PiCaretLeftBold } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import BOMUsage from "../../components/BOMUsage/BOMUsage";
import LimitReachedModal from "../../components/LimitReachedModal/LimitReachedModal";
import { DASHBOARD_PAGE } from "../../constants";
import { useGetCustomerQuery } from "../../features/api/customers";
import { setCurrentPage } from "../../features/ui/navigationSlice";
import { bomCountSelector } from "../../features/usageLimits/usageLimitsSlice";
import useBomList from "../../hooks/useBomList";
import csvFileIcon from "../../images/csv-file.svg";
import fileStackIcon from "../../images/file-stack.svg";

export default function ChooseBillCreationMethod() {
  const { data: customer } = useGetCustomerQuery();
  const { boms } = useBomList();
  const [disabled, setDisabled] = useState(false);
  const bomCount = useSelector(bomCountSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLimitReachedModal, setShowLimitReachedModal] = useState(false);

  useEffect(() => {
    if (dispatch) {
      dispatch(setCurrentPage(DASHBOARD_PAGE));
    }

    return () => {
      setCurrentPage(null);
    };
  }, [dispatch]);

  useEffect(() => {
    if (customer && typeof bomCount !== "undefined" && bomCount !== null) {
      if (bomCount === 0) {
        setShowLimitReachedModal(true);
        setDisabled(true);
      } else {
        setShowLimitReachedModal(false);
        setDisabled(false);
      }
    } else {
      setDisabled(true);
    }

    return () => {
      setShowLimitReachedModal(false);
      setDisabled(false);
    };
  }, [bomCount, customer]);

  return (
    <div className="container pt-5">
      <div className="row mb-5">
        <div className="mb-5 d-flex align-items-center justify-content-between">
          <h1 className="m-0">Let&apos;s get you started</h1>
          <div className="d-flex align-items-center gap-3">
            <BOMUsage />
            <Link to="/upgrade" className="btn btn-outline-primary fw-bold">
              Buy More
            </Link>
          </div>
        </div>
        {boms && boms.length === 0 && <p>Welcome to your free trial. Choose how you would like to create your first BOM.</p>}
      </div>
      <div className="row mb-5">
        <div style={{ gridTemplateColumns: "1fr 1fr" }} className="d-grid gap-4 text-light-0">
          <button
            disabled={disabled}
            onClick={() => navigate("/dashboard/import-bom-csv/")}
            type="button"
            className="btn btn-secondary rounded p-4 d-flex align-items-center"
          >
            <div className="d-flex align-items-center gap-3">
              <img style={{ width: "5rem" }} src={csvFileIcon} alt="CSV File" />
              <div>
                <h5>Upload a File</h5>
                <p style={{ fontSize: ".825rem" }} className="m-0">
                  Upload a CSV File or Spreadsheet containing data for the bill of material (BOM) that you would like to qualify for USMCA.
                </p>
              </div>
            </div>
          </button>
          <button
            disabled={disabled}
            onClick={() => navigate("/dashboard/create-bill-of-material/fg-details/")}
            type="button"
            className="btn btn-secondary rounded p-4 d-flex align-items-center"
          >
            <div className="d-flex align-items-center gap-3">
              <img style={{ width: "5rem" }} src={fileStackIcon} alt="File Stack" />
              <div>
                <h5>Create Bill of Material</h5>
                <p style={{ fontSize: ".825rem" }} className="m-0">
                  Fill in data for your BOM manually and submit for USMCA qualification.
                </p>
              </div>
            </div>
          </button>
        </div>
      </div>
      <div className="d-flex">
        <Link className="btn text-secondary text-uppercase fw-bold d-flex align-items-center gap-1" to="/dashboard">
          <PiCaretLeftBold />
          Qualification History
        </Link>
      </div>
      <LimitReachedModal show={showLimitReachedModal} onHide={() => setShowLimitReachedModal(false)} />
    </div>
  );
}
