import React from "react";
import { useSelector } from "react-redux";

import { submittingToApi as submittingToApiSelector } from "../../../../features/fileImport/fileImportSlice";
import { COMPONENT_FIELD_MATCHER_CARD_TYPE } from "../../constants";
import FieldMatcherCard from "../FieldMatcherCard";
import ComponentCards from "./ComponentCards";

function ComponentDetailsMatcher() {
  const submittingToApi = useSelector(submittingToApiSelector);
  return (
    <div style={{ visibility: submittingToApi ? "hidden" : "visible" }} className="d-flex gap-3 w-100">
      <div style={{ width: "50vw" }}>
        <FieldMatcherCard type={COMPONENT_FIELD_MATCHER_CARD_TYPE} />
      </div>
      <div style={{ width: "50vw" }}>
        <ComponentCards />
      </div>
    </div>
  );
}

export default ComponentDetailsMatcher;
