import print from "print-js";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { PiCertificate, PiEye, PiFilePdf, PiPrinter } from "react-icons/pi";
import { Link } from "react-router-dom";

import LoadingSpinner from "../../../../../components/LoadingSpinner/LoadingSpinner";
import PDFViewerModal from "../../../../../components/PDFViewerModal/PDFViewerModal";
import { COMPS_QUALIFYING_BOM_STATE, FG_COMPLETE_BOM_STATE, FG_QUALIFYING_BOM_STATE } from "../../../../../constants";
import useBom from "../../../../../hooks/useBom";
import useQualificationPdf from "../../../../../hooks/useQualificationPdf";
import { getBomDetailUrl, getSubmitTS, htsDisplay } from "../../../../../utils/bom";
import { useHistoryBodyContext } from "../HistoryBodyContext";

function BOMRow({ bomId }) {
  const [showPdf, setShowPdf] = useState(false);
  const [printing, setPrinting] = useState(false);
  const { bom } = useBom(bomId);
  const { pdf: pdfData } = useQualificationPdf(bomId);
  const [nextStep, setNextStep] = useState(null);

  const handlePrint = useCallback(() => {
    if (pdfData && pdfData.url) {
      setPrinting(true);
      fetch(pdfData.url)
        .then((response) => {
          if (response.ok) {
            return response.blob();
          }
          return false;
        })
        .then((responseData) => {
          if (responseData) {
            print(URL.createObjectURL(responseData));
          }
          setPrinting(false);
        });
    }
  }, [pdfData]);

  useEffect(() => {
    if (bom) {
      if (bom.BOM_State === FG_QUALIFYING_BOM_STATE) {
        setNextStep("Answer questions about the finished good.");
      } else if (bom.BOM_State === FG_COMPLETE_BOM_STATE) {
        setNextStep("Specify components.");
      } else if (bom.BOM_State === COMPS_QUALIFYING_BOM_STATE) {
        setNextStep("Answer questions about the components.");
      } else if (bom.BOM_State === "COMPS_COMPLETE") {
        setNextStep("Ready to submit for qualification.");
      }
    }
  }, [bom]);

  if (!bom) {
    return (
      <tr>
        <td>
          <LoadingSpinner />
        </td>
      </tr>
    );
  }

  return (
    <tr key={bom.BOMID}>
      <th scope="row">
        <Link to={getBomDetailUrl(bom.BOMID)} className="text-primary">
          {bom.BOMID}
        </Link>
      </th>
      <td>{bom.FG.Part}</td>
      <td>{bom.FG.Desc}</td>
      <td>{htsDisplay(bom.FG.Hts)}</td>
      <td>
        <b>Transaction Value:</b> {bom.FG.Value}
        <br />
        <b>Net Cost:</b> {bom.FG.NetCost}
        <br />
        <b>Total Cost:</b> {bom.FG.TotalCost}
        <br />
      </td>
      <td>{bom.FG.Origin}</td>
      <td>{bom.Qualified === "Y" ? "Yes" : "No"}</td>
      <td>{getSubmitTS(bom.SubmitTS)}</td>
      <td>{bom.BOM_State === "CLOSED" ? "Yes" : "No"}</td>
      <td>{nextStep || "-"}</td>
      <td>
        <div className="btn-group" role="group" aria-label="BOM Actions">
          <Link to={getBomDetailUrl(bom.BOMID)} className="btn btn-primary" title="View BOM" aria-label="View bom">
            <PiEye />
          </Link>
          <DropdownButton as={ButtonGroup} title="" id="bg-nested-dropdown">
            {bom.BOM_State === "CLOSED" && (
              <>
                <Dropdown.Item
                  as="button"
                  onClick={() => setShowPdf(true)}
                  disabled={!pdfData}
                  type="button"
                  className="btn rounded-0 d-flex justify-content-center py-2"
                  title="View Qualification PDF"
                >
                  <PiFilePdf />
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  onClick={handlePrint}
                  disabled={!pdfData || printing}
                  type="button"
                  className="btn rounded-0 d-flex justify-content-center py-2"
                  title="Print Qualification PDF"
                >
                  <PiPrinter />
                </Dropdown.Item>
              </>
            )}
            {bom.BOM_State === "CLOSED" && bom.Qualified === "Y" && (
              <Dropdown.Item
                as={Link}
                to="/dashboard/certificate-of-origin/"
                className="btn rounded-0 d-flex justify-content-center py-2"
                title="Generate USMCA Certificate"
              >
                <PiCertificate />
              </Dropdown.Item>
            )}
          </DropdownButton>
        </div>
      </td>
      {bom.BOM_State === "CLOSED" && (
        <td className="d-none">
          <PDFViewerModal show={showPdf} onHide={() => setShowPdf(false)} downloadUrl={pdfData?.url} />
        </td>
      )}
    </tr>
  );
}

BOMRow.propTypes = {
  bomId: PropTypes.string.isRequired,
};

function BOMTable() {
  const { currentBoms, loading, searchQuery } = useHistoryBodyContext();

  let body;

  if (loading) {
    body = (
      <div className="d-flex justify-content-center align-items-center">
        <LoadingSpinner />
      </div>
    );
  } else if (currentBoms && Array.isArray(currentBoms) && currentBoms.length === 0) {
    body = <p className="alert alert-info text-center">No BOMs matching this criteria found.</p>;
  } else {
    body = (
      <table style={{ verticalAlign: "middle" }} className="table">
        <thead>
          <tr>
            <th scope="col">BOM ID</th>
            <th scope="col">Part Number</th>
            <th scope="col">Description</th>
            <th scope="col">USHTS</th>
            <th scope="col">Costs</th>
            <th scope="col">Origin</th>
            <th scope="col">Qualified</th>
            <th scope="col">Qualified At</th>
            <th scope="col">Completed</th>
            <th scope="col">Next Step</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentBoms.map((bom) => (
            <BOMRow key={bom.BOMID} bomId={bom.BOMID} />
          ))}
        </tbody>
      </table>
    );
  }
  return (
    <div className="d-flex flex-column mt-5">
      {searchQuery && (
        <p className="mb-5">
          Showing BOMs matching &quot;<b>{searchQuery}</b>&quot;
        </p>
      )}
      {body}
    </div>
  );
}

export default BOMTable;
