import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

export default function useComponentsForm() {
	const location = useLocation();
	const [initialValues, setInitialValues] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (location?.state?.components && location?.state?.components.length > 0) {
			setInitialValues({
				components: location?.state?.components.map((comp) => ({
					partNumber: comp.partNumber,
					hts: comp.hts,
					description: comp.description,
					category: comp.category,
					qty: comp.qty,
					units: comp.units,
					cost: comp.cost,
					origin: comp.origin,
					originating: comp.originating,
				})),
			});
		} else {
			setInitialValues({ components: [] });
		}
		setLoading(false);

		return () => {
			setLoading(true);
		};
	}, [location]);

	return useMemo(() => ({ initialValues, loading }), [initialValues, loading]);
}
