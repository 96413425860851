import { nanoid } from "nanoid";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { PiCaretDown, PiCaretUp } from "react-icons/pi";

import blurredBgImg from "../../images/blurred-bg.svg";
import useNextSteps from "./useNextSteps";

const itemPropType = PropTypes.shape({
  title: PropTypes.string,
  body: PropTypes.string,
});

function StepItem({ item }) {
  return (
    <div className="bg-light-3 rounded p-3">
      <h3 className="mb-3">{item.title}</h3>
      <p className="m-0">{item.body}</p>
    </div>
  );
}

StepItem.propTypes = {
  item: itemPropType.isRequired,
};

function NextStepsCard() {
  const { items } = useNextSteps();
  const [dropDownExpanded, setDropDownExpanded] = useState(false);
  return (
    <button
      onClick={() => setDropDownExpanded(!dropDownExpanded)}
      type="button"
      className={`btn bg-light-2 rounded pt-3 px-3 pb-5 position-relative overflow-hidden no-border-no-outline bom-next-steps-card ${
        dropDownExpanded ? "bom-next-steps-card--expanded" : ""
      }`}
    >
      <h2 className="mb-4">Next Steps</h2>
      {items &&
        Array.isArray(items) &&
        items.map((item) => (
          <div key={nanoid()} className="mb-3">
            <StepItem item={item} />
          </div>
        ))}
      {!dropDownExpanded && <img src={blurredBgImg} alt="Blur" style={{ objectFit: "cover" }} className="position-absolute w-100 h-25 bottom-0 start-0" />}
      <div style={{ bottom: "0", right: "50%", transform: "translateX(50%)" }} className="position-absolute">
        {dropDownExpanded ? <PiCaretUp size={40} /> : <PiCaretDown size={40} />}
      </div>
    </button>
  );
}

export default NextStepsCard;
