import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useGetCustomerQuery } from "../features/api/customers";
import { setAvatarDataUri } from "../features/customerProfile/customerProfileSlice";

export default function useCustomerAvatar() {
  const { data: customer } = useGetCustomerQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    if (customer && customer.avatar_url) {
      fetch(customer.avatar_url)
        .then((response) => {
          if (response.ok) {
            return response.blob();
          }
          return false;
        })
        .then((responseData) => {
          if (responseData) {
            dispatch(setAvatarDataUri(URL.createObjectURL(responseData)));
          }
        });
    }
  }, [customer, dispatch]);
}
