import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { QUALIFICATION_HISTORY_PAGE } from "../../../constants";
import { clearState } from "../../../features/bomWizard/bomWizardSlice";
import { setCurrentUnparsedBOM, setHeaders } from "../../../features/fileImport/fileImportSlice";
import { setCurrentPage } from "../../../features/ui/navigationSlice";
import useBomList from "../../../hooks/useBomList";

export default function useDashboard() {
  const [pageTitle, setPageTitle] = useState("Your Boms");
  const { boms } = useBomList();
  const dispatch = useDispatch();

  useEffect(() => {
    if (boms && boms.length > 0) {
      setPageTitle("Qualification History");
    } else {
      setPageTitle("Your Boms");
    }

    return () => setPageTitle("Your Boms");
  }, [boms]);

  useEffect(() => {
    dispatch(clearState());
    dispatch(setCurrentUnparsedBOM(null));
    dispatch(setHeaders(null));
  });

  useEffect(() => {
    dispatch(setCurrentPage(QUALIFICATION_HISTORY_PAGE));

    return () => {
      setCurrentPage(null);
    };
  }, [dispatch]);

  return useMemo(
    () => ({
      pageTitle,
    }),
    [pageTitle],
  );
}
