import { nanoid } from "nanoid";
import React from "react";
import { useSelector } from "react-redux";

import { producerDataTypeSelector, producersSelector } from "../../../../../features/certificateOfOrigin/certificateOfOriginSlice";
import useProducerBodyPart from "./useProducerBodyPart";

function ProducerBodyPart() {
  const { title, hasProducers } = useProducerBodyPart();
  const producers = useSelector(producersSelector);
  const producerDataType = useSelector(producerDataTypeSelector);
  return (
    <div>
      <p className="fw-bold">{title}</p>
      {hasProducers && (
        <div className="mt-4">
          {producers.map((producer, idx) => (
            <div className="bg-light-2 rounded p-3 mb-5" key={nanoid()}>
              {producerDataType === "list" && <p className="fw-bold">Producer {idx + 1}</p>}
              <div className="mb-4 d-flex justify-content-between align-items-center">
                <h6 className="m-0">Producer&apos;s Name</h6>
                <p className="m-0 text-muted">{producer.name}</p>
              </div>

              <div className="mb-4 d-flex justify-content-between align-items-center">
                <h6 className="m-0">Producer&apos;s Email Address</h6>
                <p className="m-0 text-muted">{producer.email}</p>
              </div>

              <div className="mb-4 d-flex justify-content-between align-items-center">
                <h6 className="m-0">Producer&apos;s Phone Number</h6>
                <p className="m-0 text-muted">{producer.phoneNumber}</p>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <h6 className="m-0">Producer&apos;s Address</h6>
                <p className="m-0 text-muted">{producer.address}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ProducerBodyPart;
