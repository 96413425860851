import api from "./apiSlice";

const customersApi = api.injectEndpoints({
  endpoints(build) {
    return {
      getCustomer: build.query({
        query() {
          return "/extras/api/v1/customers/";
        },
        providesTags: ["Customer"],
      }),
      createCustomer: build.mutation({
        // eslint-disable-next-line camelcase
        query({ name, subscription_type, company_name, phone_number, street_address, city, state, country, zip_code, avatar_file_key }) {
          return {
            url: "/extras/api/v1/customers/",
            method: "POST",
            // eslint-disable-next-line camelcase
            body: { name, subscription_type, company_name, phone_number, street_address, city, state, country, zip_code, avatar_file_key },
          };
        },
        invalidatesTags: ["Customer"],
      }),
      updateCustomer: build.mutation({
        query(arg) {
          return {
            url: "/extras/api/v1/customers/",
            method: "PATCH",
            body: arg,
          };
        },
        invalidatesTags: ["Customer"],
      }),
      updateAvatar: build.mutation({
        query({ fileKey }) {
          return {
            url: `/extras/api/v1/customers/avatar`,
            method: "POST",
            body: { file_key: fileKey },
          };
        },
        invalidatesTags: ["Customer"],
      }),
      requestDataExport: build.mutation({
        query() {
          return {
            url: "/extras/api/v1/customers/download-data",
            method: "POST",
          };
        },
        invalidatesTags: ["DataExport"],
      }),
      retrieveExportedData: build.query({
        query() {
          return "/extras/api/v1/customers/download-data";
        },
        providesTags: ["DataExport"],
      }),
    };
  },
});

export const {
  useRequestDataExportMutation,
  useRetrieveExportedDataQuery,
  useGetCustomerQuery,
  useCreateCustomerMutation,
  useUpdateCustomerMutation,
  useUpdateAvatarMutation,
} = customersApi;
