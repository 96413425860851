/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field, Form, Formik } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import { object, string } from "yup";

import FormError from "../../components/FormError";
import useForgotPassword from "./useForgotPassword";

const schema = object().shape({
  username: string().required("Please enter your username."),
});
export default function ForgotPassword() {
  const { handleSubmit, errorMsg } = useForgotPassword();
  return (
    <div>
      <h1 className="text-center mb-5">Password reset</h1>
      <p className="alert alert-info text-center mb-5">Enter your username below and we&apos;ll email you instructions on how to reset your password.</p>
      {errorMsg && <p className="alert alert-danger text-center">{errorMsg}</p>}
      <Formik onSubmit={handleSubmit} validationSchema={schema} initialValues={{ username: "" }}>
        {({ isSubmitting, errors }) => (
          <Form>
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label w-100">
                Username*
                <Field placeholder="Your username" type="text" name="username" className={`form-control mt-1 ${errors.username ? "is-invalid" : ""}`} required />
                {errors.username && <FormError error={errors.username} />}
              </label>
            </div>
            <button type="submit" className="btn btn-primary w-100 mb-4" disabled={isSubmitting}>
              Reset password
            </button>
            <p className="text-center">
              Remembered your password?
              <Link className="text-decoration-none fw-bold text-uppercase text-secondary ms-1" to="/auth/login">
                Login now.
              </Link>
            </p>
          </Form>
        )}
      </Formik>
    </div>
  );
}
