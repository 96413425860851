import { useEffect, useMemo, useState } from "react";

import { useBomContext } from "./BOMContext";

export default function useNextSteps() {
  const { bom } = useBomContext();
  const [items, setItems] = useState(null);
  useEffect(() => {
    if (bom && bom.Qualified === "Y") {
      setItems([
        {
          title: "Certificate of Origin (USMCA)",
          body: "Prepare a Certificate of Origin containing the product that has qualified for duty-free status. This certificate is a key document that proves the product's eligibility.",
        },
        {
          title: "Document Verification",
          body: "Review the information in the Certificate of Origin to ensure it accurately reflects the product's details, including its origin and compliance with USMCA rules.",
        },
        {
          title: "Signature and Authentication",
          body: "The Certificate of Origin may require a signature from the exporter, manufacturer, or authorized representative. Ensure that the document is properly signed and authenticated as per the regulations of your country.",
        },
        {
          title: "Submission to Customs Authorities",
          body: "Submit the Certificate of Origin and any other required documentation to the relevant customs authorities.",
        },
      ]);
    } else if (bom && bom.Qualified === "N") {
      setItems([
        {
          title: "Review Sourcing Decisions",
          body: "Work with your procurement and engineering teams to see if making changes to component sourcing would enable you to meet the requirements outline above.",
        },
        {
          title: "Create a New Bill of Material",
          body: "Submit a new bill of material through our tool after having made changes to see if it qualifies.",
        },
      ]);
    }

    return () => {
      setItems(null);
    };
  }, [bom]);

  return useMemo(() => ({ items }), [items]);
}
