import { useEffect, useMemo, useState } from "react";

import { DANGER_STATUS_PILL, SUCCESS_STATUS_PILL, WARNING_STATUS_PILL } from "../../../StatusPill/constants";

export default function useStatusPill(bom) {
  const [pillType, setPillType] = useState(null);
  const [pillName, setPillName] = useState(null);
  const [badgeText, setBadgeText] = useState(null);
  useEffect(() => {
    if (bom) {
      if (bom.Qualified === "Y") {
        setPillType(SUCCESS_STATUS_PILL);
        setPillName("Qualified");
      } else if (bom.BOM_State === "CLOSED") {
        setPillType(DANGER_STATUS_PILL);
        setPillName("Unqualified");
      } else {
        setPillType(WARNING_STATUS_PILL);
        setPillName("Saved");
        if (bom.BOM_State === "FG_QUALIFYING") {
          setBadgeText("Answer questions about the finished good.");
        } else if (bom.BOM_State === "FG_COMPLETE") {
          setBadgeText("Specify components.");
        } else if (bom.BOM_State === "COMPS_QUALIFYING") {
          setBadgeText("Answer questions about the components.");
        } else if (bom.BOM_State === "COMPS_COMPLETE") {
          setBadgeText("Ready to submit for qualification.");
        }
      }
    }
  }, [bom]);

  return useMemo(
    () => ({
      pillType,
      pillName,
      badgeText,
    }),
    [pillType, pillName, badgeText],
  );
}
