/* eslint no-param-reassign: 0 */
import { createSlice } from "@reduxjs/toolkit";

const certificateOfOriginSlice = createSlice({
  name: "certificateOfOrigin",
  initialState: {
    selectedBomIds: [],
    certifier: null,
    exporter: null,
    importer: null,
    producerDataType: null,
    producers: [],
  },
  reducers: {
    setSelectedBomIds: (state, action) => {
      state.selectedBomIds = action.payload;
    },
    setCertifier(state, action) {
      state.certifier = action.payload;
    },
    setExporter(state, action) {
      state.exporter = action.payload;
    },
    setImporter(state, action) {
      state.importer = action.payload;
    },
    setProducerDataType(state, action) {
      state.producerDataType = action.payload;
    },
    setProducers(state, action) {
      state.producers = action.payload;
    },
    clear(state) {
      state.selectedBomIds = [];
      state.certifier = null;
      state.importer = null;
      state.exporter = null;
      state.producerDataType = null;
      state.producers = [];
    },
  },
});

export default certificateOfOriginSlice.reducer;

export const {
  setSelectedBomIds,
  setCertifier,
  setExporter,
  setImporter,
  setProducerDataType,
  setProducers,
  clear: clearCertificateData,
} = certificateOfOriginSlice.actions;

export function selectedBomIdsSelector(state) {
  return state.certificateOfOrigin.selectedBomIds;
}

export function certifierSelector(state) {
  return state.certificateOfOrigin.certifier;
}

export function exporterSelector(state) {
  return state.certificateOfOrigin.exporter;
}

export function importerSelector(state) {
  return state.certificateOfOrigin.importer;
}

export function producerDataTypeSelector(state) {
  return state.certificateOfOrigin.producerDataType;
}

export function producersSelector(state) {
  return state.certificateOfOrigin.producers;
}
