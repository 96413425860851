import { nanoid } from "nanoid";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import certificatePropType from "../../../../propTypes/certificatePropType";
import DataCard from "../DataCard/DataCard";
import useProducers from "./useProducers";

function Producer({ producer }) {
  const cardItems = useMemo(
    () => [
      {
        type: "Producer Email",
        text: producer.email,
      },
      {
        type: "Producer Phone",
        text: producer.phone,
      },
      {
        type: "Producer Address",
        text: producer.address,
      },
    ],
    [producer],
  );
  return <DataCard titleData={{ type: "Producer Name", title: producer.name }} items={cardItems} />;
}

Producer.propTypes = {
  producer: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    address: PropTypes.string,
  }).isRequired,
};

function Producers({ certificate }) {
  const { producerTypeText, hasProducers } = useProducers(certificate);
  return (
    <div>
      <div className="mb-4">
        <h2 className="mb-2">Producer Data</h2>
        <p>{producerTypeText}</p>
        {hasProducers &&
          certificate.producers.map((producer) => (
            <div key={nanoid()} className="mb-3">
              <Producer producer={producer} />
            </div>
          ))}
      </div>
    </div>
  );
}

Producers.propTypes = {
  certificate: certificatePropType.isRequired,
};

export default Producers;
