import PropTypes from "prop-types";
import React from "react";
import { PiFilePdf } from "react-icons/pi";

import useBom from "../../hooks/useBom";
import PDFViewerModal from "../PDFViewerModal/PDFViewerModal";
import useViewPDFButton from "./useViewPDFButton";

function ViewPDFButton({ bomId }) {
  const { viewing, loading, pdfUrl, handleStopViewing, handleStartViewing } = useViewPDFButton(bomId);
  const { bom } = useBom(bomId);

  return (
    <>
      <button onClick={handleStartViewing} type="button" className="btn btn-primary" disabled={loading}>
        <PiFilePdf /> {loading ? "Please wait..." : "View PDF"}
      </button>
      <PDFViewerModal
        title={bom?.BOMID ? `Qualification PDF for ${bom?.BOMID}` : "View PDF"}
        show={viewing}
        bomId={bomId}
        onHide={handleStopViewing}
        downloadUrl={pdfUrl}
      />
    </>
  );
}

ViewPDFButton.propTypes = {
  bomId: PropTypes.string.isRequired,
};

export default ViewPDFButton;
