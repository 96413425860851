import Decimal from "decimal.js";
import { array, number, object, string } from "yup";

import { htsDisplay, moneyValuePresent, transactionValueOrNetCostValid, validMoneyValue } from "../../../utils/bom";
import { stringArrayToString } from "../../../utils/text";

const finishedGoodSchema = object().shape({
  partNumber: string().required("Please provide a part number for your finished good."),
  hts: string().required("Please provide the HTS number for your finished good."),
  description: string(),
  transactionValue: string(),
  origin: string()
    .matches(/US|CA|MX/, "Your finished good's origin must be either US, MX, or CA.")
    .required("Please provide the Country of Origin (COO) of your finished good."),
  netCost: string(),
  totalCost: string(),
});

const componentsSchema = array().of(
  object().shape({
    partNumber: string().required("Please provide a part number for every component."),
    hts: string(),
    description: string(),
    qty: number().positive("Quantity must be a positive number."),
    units: string().required("Please provide the units of measurement for each component."),
    origin: string().required("Please provide the Country of Origin (COO) of every component."),
    originating: string().matches(/[YN]/, "Originating can only be either Y or N").required("Please specify whether each component is originating or not."),
    category: string()
      .matches(
        /Material|OtherProduct|Period|Other|Accessories|Packaging/,
        "Category must be one of the following: Material, OtherProduct, Period, Other, Accessories, Packaging",
      )
      .required("Please provide a category for each component."),
    unitCost: number().required("Please provide each component's unit price."),
  }),
);

export function moneyStringToNumber(val) {
  if (val && val.length > 0) {
    try {
      const transValueDecimal = new Decimal(val);
      return transValueDecimal.toDecimalPlaces(2, Decimal.ROUND_UP).toNumber();
    } catch (_) {
      /* empty */
    }
  }
  return 0;
}

export function validateFinishedGood(finishedGood) {
  return new Promise((resolve, reject) => {
    const moneyValid = [true, null];
    const { transactionValue, netCost, totalCost } = finishedGood;
    const [theyreValid, invalidMsg] = transactionValueOrNetCostValid(transactionValue, netCost);
    if (!theyreValid) {
      moneyValid[0] = false;
      moneyValid[1] = invalidMsg;
    }
    if (theyreValid) {
      if (moneyValuePresent(totalCost)) {
        if (!validMoneyValue(totalCost)) {
          moneyValid[0] = false;
          moneyValid[1] = "Your finished good's total cost must be a positive number.";
        }
      }
    }
    if (!moneyValid[0]) {
      reject(new Error(moneyValid[1]));
    } else {
      finishedGoodSchema
        .validate(finishedGood)
        .then(() => resolve(finishedGood))
        .catch((validationError) => reject(new Error(stringArrayToString(validationError.errors))));
    }
  });
}

export function validateComponents(csvComponents, mappedHeaders) {
  return new Promise((resolve, reject) => {
    const components = csvComponents.map((csvComponent) => ({
      partNumber: csvComponent[mappedHeaders.partNumber],
      hts: csvComponent[mappedHeaders.hts],
      description: csvComponent[mappedHeaders.description],
      qty: parseFloat(csvComponent[mappedHeaders.qty]),
      units: csvComponent[mappedHeaders.units],
      origin: csvComponent[mappedHeaders.origin],
      originating: csvComponent[mappedHeaders.originating],
      unitCost: csvComponent[mappedHeaders.unitCost].length > 0 ? moneyStringToNumber(csvComponent[mappedHeaders.unitCost]) : null,
      category: csvComponent[mappedHeaders.category],
    }));
    componentsSchema
      .validate(components)
      .then(() => {
        for (let i = 0; i < components.length - 1; i += 1) {
          const comp = components[i];
          if (comp[mappedHeaders.category] === "Material" && comp[mappedHeaders.hts] === "") {
            return reject(new Error("Please provide HTS for every Material component."));
          }
        }
        return resolve();
      })
      .catch((validationError) => reject(new Error(stringArrayToString(validationError.errors))));
  });
}

export function getFinishedGoodMappedValues(finishedGood, mappedHeaders) {
  const result = {
    partNumber: finishedGood[mappedHeaders.partNumber],
    hts: "",
    description: finishedGood[mappedHeaders.description],
    transactionValue: 0,
    netCost: 0,
    totalCost: 0,
    origin: finishedGood[mappedHeaders.origin],
  };
  const hts = finishedGood[mappedHeaders.hts];
  if (hts === "" || hts === "0") {
    result.hts = "";
  } else {
    result.hts = htsDisplay(hts);
  }
  const transactionValue = finishedGood[mappedHeaders.transactionValue];
  result.transactionValue = moneyStringToNumber(transactionValue);
  const netCost = finishedGood[mappedHeaders.netCost];
  result.netCost = moneyStringToNumber(netCost);
  const totalCost = finishedGood[mappedHeaders.totalCost];
  result.totalCost = moneyStringToNumber(totalCost);
  return result;
}

export function getComponentMappedValues(component, mappedHeaders) {
  const result = {
    partNumber: component[mappedHeaders.partNumber],
    hts: "",
    description: component[mappedHeaders.description],
    units: component[mappedHeaders.units],
    qty: component[mappedHeaders.qty],
    unitCost: component[mappedHeaders.unitCost],
    category: component[mappedHeaders.category],
    origin: component[mappedHeaders.origin],
    originating: component[mappedHeaders.originating],
  };
  const hts = component[mappedHeaders.hts];
  if (hts === "" || hts === "0") {
    result.hts = "";
  } else {
    result.hts = htsDisplay(hts);
  }
  return result;
}
