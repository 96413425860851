import { csvParse } from "d3";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { read, utils } from "xlsx";

import { currentFileSelector, currentUnparsedBOMSelector, setCurrentFile, setUnParsedBOMs, setUploadProgression } from "../../../features/fileImport/fileImportSlice";

function extractBOMsFromRows(rows) {
  const boms = [];
  const headers = Object.keys(rows[0]);
  const hasMultipleBOMs = headers.indexOf("BOM") !== -1;
  if (hasMultipleBOMs) {
    const bomIndicesInCsv = rows.map((row) => row.BOM).filter((bomIndex, currentIndex, array) => array.indexOf(bomIndex) === currentIndex);
    bomIndicesInCsv.forEach(() => boms.push([]));
    rows.forEach((row) => {
      const currentBomIndex = bomIndicesInCsv.indexOf(row.BOM);
      const rowCopy = { ...row };
      delete rowCopy.BOM;
      boms[currentBomIndex].push(rowCopy);
    });
  } else {
    boms.push([...rows]);
  }
  return boms;
}

export default function useProcessUploadedFiles() {
  const dispatch = useDispatch();
  const currentUnparsedBOM = useSelector(currentUnparsedBOMSelector);
  const currentFile = useSelector(currentFileSelector);

  const processCsvFile = useCallback(() => {
    const rows = csvParse(currentFile.data);
    const boms = extractBOMsFromRows(rows);
    dispatch(setUnParsedBOMs(boms));
  }, [dispatch, currentFile]);

  const processExcelFile = useCallback(() => {
    fetch(currentFile.data)
      .then((response) => response.blob())
      .then((responseData) => {
        responseData.arrayBuffer().then((arrayBuffer) => {
          const workbook = read(arrayBuffer);
          let boms = [];
          if (workbook.SheetNames.length > 1) {
            workbook.SheetNames.forEach((sheetName) => {
              const metaData = workbook.Workbook.Sheets.find((sheetMetadata) => sheetMetadata.name === sheetName);
              if (metaData.Hidden === 0) {
                const sheet = workbook.Sheets[sheetName];
                const sheetJson = utils.sheet_to_json(sheet, { raw: false, defval: "" });
                const rows = sheetJson.map((sheetRow) => {
                  const sheetRowCopy = { ...sheetRow };
                  Object.keys(sheetRow).forEach((header) => {
                    if (header.startsWith("__")) {
                      delete sheetRowCopy[header];
                    }
                  });
                  return sheetRowCopy;
                });
                boms.push(rows);
              }
            });
          } else {
            const firstSheetName = workbook.SheetNames[0];
            const firstSheet = workbook.Sheets[firstSheetName];
            const firstSheetAsJson = utils.sheet_to_json(firstSheet, { raw: false, defval: "" });
            const rows = firstSheetAsJson.map((sheetRow) => {
              const sheetRowCopy = { ...sheetRow };
              Object.keys(sheetRow).forEach((header) => {
                if (header.startsWith("__")) {
                  delete sheetRowCopy[header];
                }
              });
              return sheetRowCopy;
            });
            boms = extractBOMsFromRows(rows);
          }
          dispatch(setUnParsedBOMs(boms));
        });
      });
  }, [currentFile, dispatch]);

  const handleFiles = useCallback(
    (files) => {
      const file = files[0];
      const fileType = file.type;
      const reader = new FileReader();
      reader.addEventListener("load", (e) => {
        dispatch(
          setCurrentFile({
            type: fileType,
            data: e.target.result,
            name: file.name,
          }),
        );
      });
      if (fileType === "text/csv" || fileType === "text/plain") {
        reader.readAsText(file);
      } else {
        reader.readAsDataURL(file);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (currentFile && (currentFile.type === "text/csv" || currentFile.type === "text/plain") && processCsvFile) {
      processCsvFile();
    } else if (currentFile && processExcelFile) {
      processExcelFile();
    }
  }, [currentFile, processCsvFile, processExcelFile]);

  useEffect(() => {
    if (currentFile && currentUnparsedBOM) {
      dispatch(
        setUploadProgression({
          fileName: currentFile.name,
          percentComplete: 100,
          timeRemaining: "0s",
        }),
      );
    } else if (currentFile && !currentUnparsedBOM) {
      dispatch(
        setUploadProgression({
          fileName: currentFile.name,
          percentComplete: 50,
          timeRemaining: "30s",
        }),
      );
    }
  }, [currentUnparsedBOM, currentFile, dispatch]);

  return useMemo(() => ({ handleFiles }), [handleFiles]);
}
