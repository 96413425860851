import React from "react";

import SearchButton from "../../../../../components/SearchButton/SearchButton";
import ToggleViewButton from "../../../../../components/ToggleViewButton/ToggleViewButton";
import Filters from "./Filters";
import SortBy from "./SortBy";

export default function Toolbar() {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <Filters />
      <div className="d-flex align-items-center gap-2">
        <SearchButton />
        <SortBy />
        <ToggleViewButton />
      </div>
    </div>
  );
}
