import React, { useMemo } from "react";

import certificatePropType from "../../../../propTypes/certificatePropType";
import DataCard from "../DataCard/DataCard";

function Exporter({ certificate }) {
  const dataCardItems = useMemo(
    () => [
      {
        type: "Phone",
        text: certificate.exporter_phone_number || "-",
      },
      {
        type: "Email",
        text: certificate.exporter_email || "-",
      },
      {
        type: "Address",
        text: certificate.exporter_address || "-",
      },
    ],
    [certificate],
  );
  return (
    <div>
      <h2 className="mb-4">Exporter Data</h2>
      <DataCard items={dataCardItems} titleData={{ type: "Exporter Name", title: certificate.exporter_name || "-" }} />
    </div>
  );
}

Exporter.propTypes = {
  certificate: certificatePropType.isRequired,
};

export default Exporter;
