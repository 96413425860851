import React from "react";

import useAuthenticatedUserData from "../../../../hooks/useAuthenticatedUserData";

export default function UserInfo() {
  const { userDisplay } = useAuthenticatedUserData();

  return (
    <div className="py-3">
      <p style={{ fontWeight: "600" }} className="mb-1 text-start">
        {userDisplay}
      </p>
      <p style={{ fontWeight: "600" }} className="text-start m-0">
        <small>FREE PLAN</small>
      </p>
    </div>
  );
}
