import React from "react";

function ServerError() {
  return (
    <div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-3" />
          <div className="col-lg-6">
            <h1 className="text-center mb-5">Server error</h1>
            <p className="alert alert-danger text-center mb-5">We encountered an error. Please reload the page to try again or start over by clicking the link below.</p>
            <div className="d-flex justify-content-center">
              <a className="btn btn-primary" href="/">
                Back to home
              </a>
            </div>
          </div>
          <div className="col-lg-3" />
        </div>
      </div>
    </div>
  );
}

export default ServerError;
