import print from "print-js";
import React, { useCallback, useEffect, useState } from "react";
import { PiDownloadSimple, PiEye, PiGear, PiPrinter, PiTrash } from "react-icons/pi";
import OutsideClickHandler from "react-outside-click-handler";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

import { useDeleteCertificateMutation } from "../../../../features/api/certificates";
import certificatePropType from "../../../../propTypes/certificatePropType";

function SettingsDropdown({ certificate }) {
  const [show, setShow] = useState(false);
  const [printing, setPrinting] = useState(false);
  const [deleteCertificate, { isError: deleteIsError }] = useDeleteCertificateMutation();
  const handlePrint = useCallback(() => {
    setPrinting(true);
    fetch(certificate.pdf_url)
      .then((response) => {
        if (response.ok) {
          return response.blob();
        }
        return { failed: true };
      })
      .then((responseData) => {
        print(URL.createObjectURL(responseData));
        if (responseData.failed) {
          Swal.fire({
            title: "Error!",
            text: "Failed to print PDF. Please try again.",
            icon: "error",
            confirmButtonText: "Dismiss",
          });
        }
        setPrinting(false);
      });
  }, [certificate]);

  const handleDelete = useCallback(() => {
    Swal.fire({
      title: "Confirmation",
      text: "Are you sure you want to delete this certificate?",
      icon: "warning",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      showCancelButton: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCertificate({ certificateId: certificate.certificate_id });
      }
    });
  }, [certificate, deleteCertificate]);

  useEffect(() => {
    if (deleteIsError) {
      Swal.fire({
        title: "Delete failed!",
        text: "We were unable to delete this certificate. Please try again.",
        icon: "error",
      });
    }
  }, [deleteIsError]);

  return (
    <div className="position-relative">
      <OutsideClickHandler onOutsideClick={() => setShow(false)}>
        <button onClick={() => setShow(!show)} type="button" className="btn p-0 usmca-certificate__card-settings-dropdown-toggle" aria-label="Certificate options">
          <PiGear />
        </button>
      </OutsideClickHandler>
      <div
        style={{ backgroundColor: "white", top: "2rem", right: ".25rem", width: "max-content" }}
        className={`rounded position-absolute usmca-certificate__card-settings-dropdown ${!show ? "usmca-certificate__card-settings-dropdown--hidden" : ""}`}
      >
        <Link
          to={`/dashboard/certificate-of-origin/${certificate.certificate_id}`}
          className="btn usmca-certificate__card-settings-dropdown-item d-flex align-items-center gap-1 text-primary"
        >
          <PiEye />
          View certificate
        </Link>
        <a
          rel="noreferrer"
          href={certificate.pdf_url}
          target="_blank"
          className="btn usmca-certificate__card-settings-dropdown-item d-flex align-items-center gap-1 text-primary"
        >
          <PiDownloadSimple />
          Download certificate
        </a>
        <button
          onClick={handlePrint}
          type="button"
          className="btn usmca-certificate__card-settings-dropdown-item d-flex align-items-center gap-1 text-primary"
          disabled={printing}
        >
          <PiPrinter />
          Print certificate
        </button>
        <button onClick={handleDelete} type="button" className="btn usmca-certificate__card-settings-dropdown-item d-flex align-items-center gap-1 text-primary">
          <PiTrash />
          Delete certificate
        </button>
      </div>
    </div>
  );
}

SettingsDropdown.propTypes = {
  certificate: certificatePropType.isRequired,
};

export default SettingsDropdown;
