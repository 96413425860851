import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { selectedBomIdsSelector } from "../../../features/certificateOfOrigin/certificateOfOriginSlice";

export default function useSelectedBoms() {
  const [bomIds, setBomIds] = useState([]);
  const selectedBomIds = useSelector(selectedBomIdsSelector);

  const addOrRemoveId = useCallback((bomId) => {
    setBomIds((currentIds) => {
      const exists = currentIds.indexOf(bomId) !== -1;
      if (!exists) {
        return [...currentIds, bomId];
      }
      return currentIds.filter((id) => id !== bomId);
    });
  }, []);

  const isSelected = useCallback((bomId) => bomIds.indexOf(bomId) !== -1, [bomIds]);

  useEffect(() => {
    if (selectedBomIds && Array.isArray(selectedBomIds) && selectedBomIds.length > 0) {
      setBomIds([...selectedBomIds]);
    }

    return () => setBomIds([]);
  }, [selectedBomIds]);
  return useMemo(() => ({ bomIds, addOrRemoveId, isSelected }), [bomIds, addOrRemoveId, isSelected]);
}
