import { object, string } from "yup";

export const producerRequiredSchema = object().shape({
  name: string().required("Please enter the producer's full name."),
  email: string().email("Please enter a valid email address.").required("Please enter the producer's email address."),
  phoneNumber: string()
    .min(8, "Phone number must be at least 8 characters long.")
    .matches(/[+0-9]+/, "Please enter a valid phone number")
    .required("Please enter the producer's phone number."),
  address: string().required("Please enter the producer's address."),
});
