import { configureStore } from "@reduxjs/toolkit";

import apiSlice from "./features/api/apiSlice";
import appStateReducer from "./features/app/appStateSlice";
import authReducer from "./features/auth/authSlice";
import bomWizardReducer from "./features/bomWizard/bomWizardSlice";
import certificateOfOriginReducer from "./features/certificateOfOrigin/certificateOfOriginSlice";
import customerProfileReducer from "./features/customerProfile/customerProfileSlice";
import fileImportReducer from "./features/fileImport/fileImportSlice";
import qualificationModalReducer from "./features/qualificationModal/qualificationModalSlice";
import mainLayoutReducer from "./features/ui/mainLayoutSlice";
import mainSidebarReducer from "./features/ui/mainSidebarSlice";
import miscUIReducer from "./features/ui/miscUISlice";
import navigationReducer from "./features/ui/navigationSlice";
import usageLimitsReducer from "./features/usageLimits/usageLimitsSlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    appState: appStateReducer,
    bomWizard: bomWizardReducer,
    certificateOfOrigin: certificateOfOriginReducer,
    qualificationModal: qualificationModalReducer,
    navigation: navigationReducer,
    mainSidebar: mainSidebarReducer,
    fileImport: fileImportReducer,
    usageLimits: usageLimitsReducer,
    customerProfile: customerProfileReducer,
    mainLayout: mainLayoutReducer,
    miscUI: miscUIReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
});
