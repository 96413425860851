import { useCallback, useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";

/**
 *
 * @param downloadUrl {string}
 * @returns {{pageCount: number, onDocumentLoadSuccess: function({numPages: number}): void, pageNumber: number, loading: boolean, pdfDataUri: string}}
 */
function usePDFViewerModal(downloadUrl) {
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfDataUri, setPdfDataUri] = useState(null);

  useEffect(() => {
    if (downloadUrl) {
      fetch(downloadUrl)
        .then((res) => {
          if (res.ok) {
            return res.blob();
          }
          return false;
        })
        .then((data) => {
          if (data) {
            setLoading(false);
            setPdfDataUri(URL.createObjectURL(data));
          } else {
            Swal.fire({
              title: "Error!",
              text: "Unable to download PDF. Please try again later.",
              icon: "error",
            });
          }
        });
    }

    return () => {
      setLoading(false);
      setPdfDataUri(null);
    };
  }, [downloadUrl]);

  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setPageCount(numPages);
  }, []);

  return useMemo(
    () => ({ pageCount, pageNumber, onDocumentLoadSuccess, loading, pdfDataUri, setPageNumber }),
    [pageCount, pageNumber, onDocumentLoadSuccess, loading, pdfDataUri, setPageNumber],
  );
}

export default usePDFViewerModal;
