import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { setShowBomUsageLimit } from "../features/ui/mainLayoutSlice";

export default function useBomUsageLimitVisibility(visible = true) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (dispatch) {
      if (typeof visible !== "undefined") {
        dispatch(setShowBomUsageLimit(visible));
      } else {
        dispatch(setShowBomUsageLimit(true));
      }
    }

    return () => {
      if (dispatch) {
        dispatch(setShowBomUsageLimit(true));
      }
    };
  }, [visible, dispatch]);
}
