import print from "print-js";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import { useGetCertificateQuery } from "../../features/api/certificates";
import { clearCertificateData } from "../../features/certificateOfOrigin/certificateOfOriginSlice";

/**
 *
 * @returns {{handlePrint: function(): void, certificate: *, loading: boolean, printing: boolean}}
 */
export default function useCertificateDetail() {
  const { certificateId } = useParams();
  const [mustFetch, setMustFetch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [printing, setPrinting] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: certificate, isLoading, isError } = useGetCertificateQuery({ certificateId }, { skip: !mustFetch, pollingInterval: 3000 });

  const handlePrint = useCallback(() => {
    setPrinting(true);
    if (certificate && certificate.pdf_url) {
      fetch(certificate.pdf_url)
        .then((response) => {
          if (response.ok) {
            return response.blob();
          }
          return false;
        })
        .then((responseData) => {
          if (responseData) {
            print(URL.createObjectURL(responseData));
          }
        })
        .finally(() => {
          setPrinting(false);
        });
    }
  }, [certificate]);

  useEffect(() => {
    if (!certificateId && !certificate) {
      setLoading(true);
    } else if (certificateId && !certificate) {
      setLoading(true);
    } else if (certificate && !certificate?.pdf_url) {
      setLoading(true);
    } else if (isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    return () => setLoading(true);
  }, [certificateId, certificate, isLoading]);

  useEffect(() => {
    if (!certificate || !certificate?.pdf_url) {
      setMustFetch(true);
    } else if (certificate && certificate.pdf_url) {
      setMustFetch(false);
    }

    return () => {
      setMustFetch(false);
    };
  }, [certificate]);

  useEffect(() => {
    if (isError) {
      Swal.fire({
        title: "Error!",
        text: "Unable to load certificate. Please try again.",
        icon: "error",
      }).then(() => {
        navigate("/dashboard/certificate-of-origin/", { replace: true });
      });
    }
  }, [isError, navigate]);

  useEffect(() => {
    dispatch(clearCertificateData());
  }, [dispatch, certificateId]);

  return useMemo(() => ({ loading, certificate, printing, handlePrint }), [loading, certificate, printing, handlePrint]);
}
