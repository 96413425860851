import { Field, useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";

import FormError from "../FormError";

function FinishedGoodOriginSelector({ name, label = null }) {
  const { errors } = useFormikContext();
  return (
    <>
      {label && (
        <label htmlFor={`input${name}`} className="form-label">
          {label}
        </label>
      )}
      <Field name={name} as="select" className={`form-select ${errors && errors[name] ? "is-invalid" : ""}`}>
        <option value="US">US</option>
        <option value="MX">MX</option>
        <option value="CA">CA</option>
      </Field>
      {errors && errors[name] && <FormError error={errors[name]} />}
    </>
  );
}

FinishedGoodOriginSelector.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default FinishedGoodOriginSelector;
