import PropTypes from "prop-types";
import React from "react";

import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import { useGetCertificateQuery } from "../../../../features/api/certificates";
import SettingsDropdown from "../SettingsDropdown/SettingsDropdown";

function CertificateCard({ certificateId }) {
  const { data: certificate, isLoading } = useGetCertificateQuery({ certificateId });

  let body;

  if (isLoading) {
    body = (
      <div className="d-flex justify-content-center align-items-center">
        <LoadingSpinner />
      </div>
    );
  } else {
    body = (
      <div className="h-100 d-flex flex-column justify-content-between">
        <div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h4 className="m-0">Certificate {certificate.certificate_id}</h4>
            <SettingsDropdown certificate={certificate} />
          </div>
          <p>
            {certificate.qualified_goods.map((fg, idx) => {
              let result = fg.id;
              if (idx !== certificate.qualified_goods.length - 1) {
                result += " | ";
              }
              return result;
            })}
          </p>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <p className="m-0 text-muted">Certifier | {certificate.certifier_name}</p>
          <p className="m-0 text-muted">Exporter | {certificate.exporter_name || "-"}</p>
          <p className="m-0 text-muted">Importer | {certificate.importer_name}</p>
        </div>
      </div>
    );
  }
  return (
    <div style={{ height: "175px" }} className="border border-light-3 rounded p-3 bg-light-2">
      {body}
    </div>
  );
}

CertificateCard.propTypes = {
  certificateId: PropTypes.string.isRequired,
};

export default CertificateCard;
