import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { PiCheck } from "react-icons/pi";
import { useSearchParams } from "react-router-dom";

import {
  SHOW_ALL,
  SHOW_COMPONENTS_REQUIRED,
  SHOW_COMPONENTS_REQUIRED_PARAM,
  SHOW_COMPONENT_QUESTIONS_REQUIRED,
  SHOW_COMPONENT_QUESTIONS_REQUIRED_PARAM,
  SHOW_FINISHED_GOOD_QUESTIONS_REQUIRED,
  SHOW_FINISHED_GOOD_QUESTIONS_REQUIRED_PARAM,
  SHOW_QUALIFIED,
  SHOW_READY_TO_SUBMIT,
  SHOW_READY_TO_SUBMIT_PARAM,
  SHOW_SAVED,
  SHOW_SAVED_PARAM,
  SHOW_UNQUALIFIED,
} from "./constants";
import useFilters from "./useFilters";

function FilterButton({ name, filterType, currentlyShowing = SHOW_ALL }) {
  const setSearchParams = useSearchParams()[1];
  const handleClick = useCallback(() => {
    let filterString = "all";
    if (filterType === SHOW_SAVED) {
      filterString = "saved";
    } else if (filterType === SHOW_QUALIFIED) {
      filterString = "qualified";
    } else if (filterType === SHOW_UNQUALIFIED) {
      filterString = "unqualified";
    }
    setSearchParams((currentParams) => {
      const newParams = new URLSearchParams(currentParams.toString());
      newParams.set("filter", filterString);
      return newParams;
    });
  }, [setSearchParams, filterType]);
  return (
    <button
      onClick={handleClick}
      type="button"
      className={`no-border-no-outline btn bom-history__toolbar-filter-button fw-light ${
        filterType === currentlyShowing ? "bom-history__toolbar-filter-button--active" : ""
      }`}
    >
      {name}
    </button>
  );
}

FilterButton.propTypes = {
  name: PropTypes.string.isRequired,
  currentlyShowing: PropTypes.number,
  filterType: PropTypes.number.isRequired,
};

function SavedDropdown({ currentlyShowing }) {
  const [active, setActive] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams) {
      const filter = searchParams.get("filter");
      if (filter) {
        setCurrentFilter(filter);
      } else {
        setCurrentFilter(null);
      }
    } else {
      setCurrentFilter(null);
    }

    return () => {
      setCurrentFilter(null);
    };
  }, [searchParams]);

  const handleClick = useCallback(
    (val) => {
      setSearchParams((currentParams) => {
        const paramsCopy = new URLSearchParams(currentParams.toString());
        paramsCopy.set("filter", val);
        return paramsCopy;
      });
    },
    [setSearchParams],
  );

  useEffect(() => {
    if (
      [SHOW_SAVED, SHOW_FINISHED_GOOD_QUESTIONS_REQUIRED, SHOW_COMPONENTS_REQUIRED, SHOW_COMPONENT_QUESTIONS_REQUIRED, SHOW_READY_TO_SUBMIT].indexOf(currentlyShowing) !==
      -1
    ) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [currentlyShowing]);

  return (
    <Dropdown>
      <Dropdown.Toggle variant="light-1" id="saved-boms-dropdown" className={`${active ? "text-dynamic-black" : "text-light-4"}`}>
        Saved
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => handleClick(SHOW_SAVED_PARAM)} as="button" className="d-flex align-items-center gap-1">
          All Saved
          {currentFilter === SHOW_SAVED_PARAM && <PiCheck />}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleClick(SHOW_FINISHED_GOOD_QUESTIONS_REQUIRED_PARAM)} as="button" className="d-flex align-items-center gap-1">
          Finished Good Questions
          {currentFilter === SHOW_FINISHED_GOOD_QUESTIONS_REQUIRED_PARAM && <PiCheck />}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleClick(SHOW_COMPONENTS_REQUIRED_PARAM)} as="button" className="d-flex align-items-center gap-1">
          Specify Components
          {currentFilter === SHOW_COMPONENTS_REQUIRED_PARAM && <PiCheck />}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleClick(SHOW_COMPONENT_QUESTIONS_REQUIRED_PARAM)} as="button" className="d-flex align-items-center gap-1">
          Component Questions
          {currentFilter === SHOW_COMPONENT_QUESTIONS_REQUIRED_PARAM && <PiCheck />}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleClick(SHOW_READY_TO_SUBMIT_PARAM)} as="button" className="d-flex align-items-center gap-1">
          Ready to Submit
          {currentFilter === SHOW_READY_TO_SUBMIT_PARAM && <PiCheck />}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

function Filters() {
  const { showing } = useFilters();

  return (
    <div className="d-flex gap-3 bom-history__toolbar-filters">
      <FilterButton name="All" filterType={SHOW_ALL} currentlyShowing={showing} />
      <FilterButton name="Qualified" filterType={SHOW_QUALIFIED} currentlyShowing={showing} />
      <FilterButton name="Unqualified" filterType={SHOW_UNQUALIFIED} currentlyShowing={showing} />
      <SavedDropdown currentlyShowing={showing} />
    </div>
  );
}

export default Filters;
