import React, { useMemo } from "react";

import certificatePropType from "../../../../propTypes/certificatePropType";
import DataCard from "../DataCard/DataCard";

function Importer({ certificate }) {
  const dataCardItems = useMemo(
    () => [
      {
        type: "Phone",
        text: certificate.importer_phone_number || "-",
      },
      {
        type: "Email",
        text: certificate.importer_email || "-",
      },
      {
        type: "Address",
        text: certificate.importer_address || "-",
      },
    ],
    [certificate],
  );
  return (
    <div>
      <h2 className="mb-4">Importer Data</h2>
      <DataCard items={dataCardItems} titleData={{ type: "Importer Name", title: certificate.importer_name || "-" }} />
    </div>
  );
}

Importer.propTypes = {
  certificate: certificatePropType.isRequired,
};

export default Importer;
