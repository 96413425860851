import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { currentBOMImportIndexSelector, setCurrentUnparsedBOM, unparsedBOMsSelector } from "../../../features/fileImport/fileImportSlice";

export default function useSetCurrentBOMData() {
  const currentIndex = useSelector(currentBOMImportIndexSelector);
  const unparsedBOMs = useSelector(unparsedBOMsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (dispatch && unparsedBOMs && Array.isArray(unparsedBOMs)) {
      dispatch(setCurrentUnparsedBOM(unparsedBOMs[currentIndex]));
    }
  }, [currentIndex, unparsedBOMs, dispatch]);
}
