import React, { useCallback } from "react";
import { PiUserCircle } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";

import { avatarDataUriSelector } from "../../../../features/customerProfile/customerProfileSlice";
import { setOpenSidebar } from "../../../../features/ui/navigationSlice";

function AvatarButton() {
  const avatarImg = useSelector(avatarDataUriSelector);
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(setOpenSidebar(true));
  }, [dispatch]);
  let avatar;

  if (avatarImg) {
    avatar = <img src={avatarImg} alt="Avatar" className="rounded-circle" />;
  } else {
    avatar = <PiUserCircle size={60} />;
  }

  return (
    <button onClick={handleClick} type="button" className="btn p-0 rounded-circle navbar-avatar-btn">
      {avatar}
    </button>
  );
}

export default AvatarButton;
