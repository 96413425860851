import React from "react";

import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import useBomList from "../../../../hooks/useBomList";
import HistoryBody from "../HistoryBody/HistoryBody";
import NoBoms from "../NoBoms/NoBoms";

export default function Body() {
  const { boms, isLoading } = useBomList();

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (boms && boms.length > 0) {
    return <HistoryBody />;
  }

  return <NoBoms />;
}
