import React from "react";
import { Link, Outlet } from "react-router-dom";

import CustomerAvatarImage from "../components/CustomerAvatarImage/CustomerAvatarImage";
import ktLogo from "../images/horizontal-logo.svg";
import { UpgradeContextProvider, useUpgradeContext } from "../pages/Upgrade/UpgradeContext";
import { priceDisplay } from "../utils/payments";

function RightSide() {
  const { checkoutIsOpen, currentUnitPrice, startCheckout, purchaseAmount, setPurchaseAmount, total } = useUpgradeContext();
  let body;
  if (!checkoutIsOpen) {
    body = (
      <div className="d-flex w-75 mx-auto flex-column align-items-center">
        <div>
          <p className="m-0 mb-2">Number of BOMs to order</p>
          <div className="input-group mb-3">
            <input
              value={purchaseAmount}
              onChange={(e) => setPurchaseAmount(e.target.value)}
              type="number"
              min="1"
              max="999999"
              className="form-control"
              placeholder="Number of BOMs"
              aria-label="Number of BOMs"
            />
            <span className="input-group-text border-0">@ $ {currentUnitPrice}</span>
          </div>
        </div>

        <p>
          Total: <b>$ {priceDisplay(total)}</b>
        </p>

        <button id="checkout-button" onClick={startCheckout} type="button" className="btn btn-primary fw-bold text-uppercase">
          Checkout
        </button>
      </div>
    );
  } else {
    body = <div />;
  }

  return (
    <div className="h-100 w-100">
      <header className="p-3 pe-5 d-flex justify-content-end">
        <div style={{ width: "2.5rem", height: "2.5rem", objectFit: "cover" }} className="rounded-circle">
          <CustomerAvatarImage />
        </div>
      </header>
      <div style={{ paddingTop: "30vh" }}>{body}</div>
    </div>
  );
}

function UpgradeLayout() {
  return (
    <div style={{ gridTemplateColumns: "40% 60%", gridTemplateRows: "max-content" }} className="d-grid">
      <UpgradeContextProvider>
        <div style={{ height: "max-content", minHeight: "100vh" }} className="pt-3 px-5 pb-5 bg-light-1">
          <div style={{ marginBottom: "6rem" }}>
            <Link to="/">
              <img style={{ width: "10rem" }} src={ktLogo} alt="Kendrick Trade logo" />
            </Link>
          </div>
          <Outlet />
        </div>
        <div className="w-100 h-100">
          <RightSide />
        </div>
      </UpgradeContextProvider>
    </div>
  );
}

export default UpgradeLayout;
