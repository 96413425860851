import { useCallback, useMemo, useState } from "react";

export default function useDeleteAccount() {
  const [errorMsg, setErrorMsg] = useState(null);

  const handleSubmit = useCallback((values, { setSubmitting }) => {
    setErrorMsg(null);
    setSubmitting(true);
  }, []);

  return useMemo(
    () => ({
      handleSubmit,
      errorMsg,
    }),
    [handleSubmit, errorMsg],
  );
}
