import dayjs from "dayjs";

import api from "./apiSlice";

const paymentsApi = api.injectEndpoints({
  endpoints(build) {
    return {
      listPrices: build.query({
        query() {
          return "/extras/api/v1/payments/prices";
        },
        providesTags: ["PaymentPrice"],
        transformResponse(baseQueryReturnValue) {
          let lowestPrice = 99999999999;
          let highestPrice = 0;
          baseQueryReturnValue.forEach((priceData) => {
            const currentPrice = Number(priceData.price);
            if (lowestPrice > currentPrice) {
              lowestPrice = currentPrice;
            }
            if (highestPrice < currentPrice) {
              highestPrice = currentPrice;
            }
          });
          return { lowestPrice, highestPrice, prices: baseQueryReturnValue };
        },
      }),
      getBomCountAvailable: build.query({
        query() {
          return "/extras/api/v1/payments/boms-available";
        },
        providesTags: ["BOMCountAvailable"],
        transformResponse(baseQueryReturnValue) {
          return baseQueryReturnValue.count || 0;
        },
      }),
      getTotalBomsPurchased: build.query({
        query() {
          return "/extras/api/v1/payments/total-boms";
        },
        providesTags: ["TotalBOMsPurchased"],
        transformResponse(baseQueryReturnValue) {
          return baseQueryReturnValue.total || 0;
        },
      }),
      listTransactions: build.query({
        query() {
          return "/extras/api/v1/payments/list-transactions";
        },
        providesTags: ["PaddleTransactionList"],
        transformResponse(baseQueryReturnValue) {
          const transactions = [];
          if (baseQueryReturnValue.transactions && Array.isArray(baseQueryReturnValue.transactions)) {
            baseQueryReturnValue.transactions.forEach((txn) => transactions.push(txn));
          }
          transactions.sort((a, b) => {
            const dateA = dayjs(a.billed_at);
            const dateB = dayjs(b.billed_at);

            if (dateA.isAfter(dateB)) {
              return -1;
            }
            if (dateA.isBefore(dateB)) {
              return 1;
            }
            return 0;
          });
          return transactions;
        },
      }),
    };
  },
});

export const { useListPricesQuery, useGetBomCountAvailableQuery, useListTransactionsQuery, useGetTotalBomsPurchasedQuery } = paymentsApi;
