/* eslint-disable-next-line */
export const DEFAULT_DAY_JS_DATETIME_FORMAT = "DD-MM-YYYY HH:mm";

export const DASHBOARD_PAGE = "dashboard";
export const CERTIFICATES_PAGE = "certificates";
export const QUALIFICATION_HISTORY_PAGE = "qualification-history";
export const PAYMENTS_PAGE = "payments";

export const FILE_IMPORT_INITIAL_STEP = "initial";
export const FILE_IMPORT_CURRENCY_STEP = "currency";
export const FILE_IMPORT_HEADER_CONFIRMATION_STEP = "headers";
export const FILE_MATCH_FIELDS_STEP = "match";

export const FG_QUALIFYING_BOM_STATE = "FG_QUALIFYING";
export const FG_COMPLETE_BOM_STATE = "FG_COMPLETE";
export const COMPS_QUALIFYING_BOM_STATE = "COMPS_QUALIFYING";
export const COMPS_COMPLETE_BOM_STATE = "COMPS_COMPLETE";
export const CLOSED_BOM_STATE = "CLOSED";
