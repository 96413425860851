import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { useListPricesQuery } from "../../../features/api/payments";
import { calculateSavingsPercentage } from "../../../utils/payments";

function ProductPriceCard({ productPrice }) {
  const [hasSavings, setHasSavings] = useState(false);
  const { data: priceData } = useListPricesQuery();

  useEffect(() => {
    if (priceData && productPrice) {
      const currentPrice = Number(productPrice.price);
      if (currentPrice >= priceData.highestPrice) {
        setHasSavings(false);
      } else {
        setHasSavings(true);
      }
    }

    return () => {
      setHasSavings(false);
    };
  }, [productPrice, priceData]);

  return (
    <div style={{ height: "8rem" }} className="card d-flex flex-column justify-content-between no-border-no-outline w-100">
      <div className="card-body d-flex flex-column justify-content-between">
        <div className="d-flex gap-2 align-items-center">
          <h5 className="m-0">{productPrice.name}</h5>
          {hasSavings && (
            <span style={{ marginTop: "5px" }} className="fw-light badge rounded-pill bg-her-highness">
              Save {calculateSavingsPercentage(priceData.highestPrice, Number(productPrice.price))}%
            </span>
          )}
        </div>
        <div style={{ lineHeight: 1 }} className="w-100 d-flex justify-content-between align-items-end">
          <p className="m-0">Price per BOM</p>
          <p style={{ fontSize: "1.5rem" }} className="m-0 fw-bold">
            ${Number(productPrice.price) / 100}
          </p>
        </div>
      </div>
    </div>
  );
}

ProductPriceCard.propTypes = {
  productPrice: PropTypes.shape({ id: PropTypes.string, name: PropTypes.string, description: PropTypes.string, price: PropTypes.string, amount: PropTypes.string })
    .isRequired,
};
export default ProductPriceCard;
