import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useGetCustomerQuery } from "../../../../features/api/customers";
import { certifierSelector, setCertifier } from "../../../../features/certificateOfOrigin/certificateOfOriginSlice";
import getFullAddress from "../../../../utils/customer";

export default function useCertifierForm() {
  const { data: customer } = useGetCustomerQuery();
  const currentCertifier = useSelector(certifierSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialValues = useMemo(
    () => ({
      company: currentCertifier?.company || customer?.company_name || "",
      name: currentCertifier?.name || customer?.name || "",
      role: currentCertifier?.role || "producer",
      address: currentCertifier?.address || getFullAddress(customer),
      phoneNumber: currentCertifier?.phoneNumber || customer?.phone_number || "",
      emailAddress: currentCertifier?.emailAddress || customer?.email || "",
      effectiveDate: currentCertifier?.effectiveDate || new Date().toISOString(),
      expirationDate: currentCertifier?.expirationDate || new Date().toISOString(),
    }),
    [customer, currentCertifier],
  );

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      setSubmitting(true);
      dispatch(setCertifier(values));
      navigate("/dashboard/certificate-of-origin/generate/exporter-form/");
      setSubmitting(false);
    },
    [navigate, dispatch],
  );

  return useMemo(
    () => ({
      initialValues,
      handleSubmit,
    }),
    [initialValues, handleSubmit],
  );
}
