import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { useGetCustomerQuery } from "../features/api/customers";

export default function useHubSpot() {
  const { data: customer } = useGetCustomerQuery();
  // eslint-disable-next-line no-underscore-dangle
  const hubspot = useMemo(() => (typeof window !== "undefined" && window._hsq ? window._hsq : []), []);
  const { pathname } = useLocation();
  useEffect(() => {
    if (hubspot && pathname) {
      hubspot.push(["setPath", pathname]);
      hubspot.push(["trackPageView"]);
      if (customer) {
        hubspot.push(["identify", { email: customer.email }]);
      }
    }
  }, [pathname, hubspot, customer]);
}
