import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

export default function useFinishedGoodForm() {
  const [existingFinishedGood, setExistingFinishedGood] = useState(null);
  const location = useLocation();

  const initialValues = useMemo(
    () => ({
      currency: existingFinishedGood?.currency || "USD",
      partNumber: existingFinishedGood?.partNumber || "",
      description: existingFinishedGood?.description || "",
      hts: existingFinishedGood?.hts || "",
      value: existingFinishedGood && existingFinishedGood.value && existingFinishedGood.value !== "0" ? existingFinishedGood.value : "",
      netCost: existingFinishedGood && existingFinishedGood.netCost && existingFinishedGood.netCost !== "0" ? existingFinishedGood.netCost : "",
      totalCost: existingFinishedGood && existingFinishedGood.totalCost && existingFinishedGood.totalCost !== "0" ? existingFinishedGood.totalCost : "",
      coo: existingFinishedGood?.coo || existingFinishedGood?.origin || "US",
    }),
    [existingFinishedGood],
  );

  useEffect(() => {
    if (location && location.state && location.state.finishedGood) {
      setExistingFinishedGood(location.state.finishedGood);
    }
  }, [location]);

  return useMemo(() => ({ initialValues }), [initialValues]);
}
