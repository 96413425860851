import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { PAYMENTS_PAGE } from "../../constants";
import { setCurrentPage } from "../../features/ui/navigationSlice";

export default function usePayments() {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (dispatch) {
      dispatch(setCurrentPage(PAYMENTS_PAGE));
    }

    return () => {
      dispatch(setCurrentPage(null));
    };
  }, [dispatch, location]);
}
