/* eslint import/no-unresolved: 0 */
import PropTypes from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";
import { PiCaretLeft, PiCaretRight } from "react-icons/pi";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import usePDFViewerModal from "./usePDFViewerModal";

function PDFViewerModal({ show, title = "View PDF", downloadUrl = null, onHide }) {
  const { loading, pageNumber, setPageNumber, onDocumentLoadSuccess, pageCount, pdfDataUri } = usePDFViewerModal(downloadUrl);
  let body;

  if (loading || !downloadUrl) {
    body = (
      <div className="d-flex justify-content-center align-items-center">
        <LoadingSpinner />
      </div>
    );
  } else {
    body = (
      <>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <Document file={pdfDataUri} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} />
            </Document>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between w-100">
            <button type="button" onClick={onHide} className="btn btn-outline-secondary fw-bold text-uppercase">
              Close
            </button>
            <div className="d-flex align-items-center gap-2">
              <button
                disabled={pageNumber === 1}
                onClick={() => setPageNumber((currentNumber) => currentNumber - 1)}
                type="button"
                className="btn p-0 no-border-no-outline"
                aria-label="Previous page"
              >
                <PiCaretLeft />
              </button>
              <p className="m-0">
                Page {pageNumber} / {pageCount}
              </p>
              <button
                disabled={pageNumber === pageCount}
                onClick={() => setPageNumber((currentPageNumber) => currentPageNumber + 1)}
                type="button"
                className="btn p-0 no-border-no-outline"
                aria-label="Next page"
              >
                <PiCaretRight />
              </button>
            </div>
            <a href={downloadUrl} className="btn btn-secondary fw-bold text-uppercase">
              Download
            </a>
          </div>
        </Modal.Footer>
      </>
    );
  }
  return (
    <Modal show={show} scrollable centered size="lg" onHide={onHide}>
      {body}
    </Modal>
  );
}

PDFViewerModal.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string,
  downloadUrl: PropTypes.string,
  onHide: PropTypes.func.isRequired,
};

export default PDFViewerModal;
