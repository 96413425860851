/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const miscUISlice = createSlice({
  name: "miscUI",
  initialState: {
    showLoadingModal: false,
  },
  reducers: {
    setShowLoadingModal(state, action) {
      state.showLoadingModal = action.payload;
    },
  },
});

export default miscUISlice.reducer;

export const { setShowLoadingModal } = miscUISlice.actions;

export function showLoadingModal(state) {
  return state.miscUI.showLoadingModal;
}
