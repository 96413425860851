import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

import { showModalSelector } from "../../features/qualificationModal/qualificationModalSlice";
import BOMDetails from "../BOMDetails/BOMDetails";
import useSubmitForQualification from "./useSubmitForQualification";

export default function SubmitForQualificationModal() {
  const { handleCancel, handleSubmit, errorMsg, submitting } = useSubmitForQualification();
  const show = useSelector(showModalSelector);

  return (
    <Modal show={show} onHide={handleCancel} centered size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h3>Submit for Qualification</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-5">
          <BOMDetails />
        </div>
        {errorMsg && <p className="alert alert-danger text-center mb-5">{String(errorMsg)}</p>}
        <p className="alert alert-success text-center">BOM ready for qualification.</p>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between w-100">
          <button onClick={handleCancel} type="button" className="btn btn-outline-secondary">
            Cancel
          </button>
          <button onClick={handleSubmit} type="button" className="btn btn-success" disabled={submitting}>
            Submit
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
