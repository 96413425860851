import React from "react";

import PageHeader from "../../components/PageHeader/PageHeader";
import InvoiceHistory from "./InvoiceHistory";
import usePayments from "./usePayments";

function Payments() {
  usePayments();
  return (
    <>
      <div className="row mb-5">
        <PageHeader title="Payments" />
      </div>
      <div className="row">
        <div className="container px-4">
          <InvoiceHistory />
        </div>
      </div>
    </>
  );
}

export default Payments;
