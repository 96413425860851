import React from "react";
import { useSelector } from "react-redux";

import { userDropdownStateSelector } from "../../../../features/ui/mainSidebarSlice";
import BootstrapSVG from "../../../BootstrapSVG";

export default function DropdownIcon() {
  const dropdownActive = useSelector(userDropdownStateSelector);

  let icon;

  if (dropdownActive) {
    icon = <BootstrapSVG iconName="chevron-up" />;
  } else {
    icon = <BootstrapSVG iconName="chevron-down" />;
  }

  return icon;
}
