import React from "react";
import { Navigate } from "react-router-dom";

import useAlreadyLoggedIn from "../../hooks/useAlreadyLoggedIn";

export default function Home() {
  useAlreadyLoggedIn();

  return <Navigate to="/login" replace />;
}
