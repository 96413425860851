import { Field, Form, Formik } from "formik";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { object, string } from "yup";

import FormError from "../../../../components/FormError";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import { EXPORTER_DETAILS_STEP } from "../../constants";
import useSelectedBomsRequired from "../../hooks/useSelectedBomsRequired";
import useExporterForm from "./useExporterForm";

const schema = object().shape({
  name: string(),
  email: string().email("Please enter a valid email address"),
  address: string(),
  phoneNumber: string()
    .min(8, "Phone number must be at least 8 characters long.")
    .matches(/[+0-9]/, "Please enter a valid phone number."),
});

function ExporterForm() {
  useSelectedBomsRequired();
  const location = useLocation();
  const { loading, handleSubmit, initialValues } = useExporterForm();
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-2" />
        <div className="col-lg-8">
          <div className="my-5">
            <ProgressBar step={EXPORTER_DETAILS_STEP} />
          </div>
          <div className="mb-5">
            <h1 className="mb-4">Exporter&apos;s Data</h1>
            <p>Add your exporter&apos;s information.</p>
          </div>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center">
              <LoadingSpinner />
            </div>
          ) : (
            <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
              {({ isSubmitting, errors, touched }) => (
                <Form>
                  <div className="mb-5">
                    <div className="mb-3">
                      <label htmlFor="inputName" className="form-label">
                        Exporter Name
                      </label>
                      <Field placeholder="Ex. John Doe" name="name" id="inputName" className={`form-control ${errors.name && touched.name ? "is-invalid" : ""}`} />
                      {errors.name && <FormError error={errors.name} />}
                    </div>

                    <div className="mb-3">
                      <label htmlFor="inputEmail" className="form-label">
                        Exporter Email Address
                      </label>
                      <Field
                        placeholder="Ex. john@acme.com"
                        name="email"
                        id="inputEmail"
                        type="email"
                        className={`form-control ${errors.email && touched.email ? "is-invalid" : ""}`}
                      />
                      {errors.email && <FormError error={errors.email} />}
                    </div>

                    <div className="mb-3">
                      <label htmlFor="inputPhone" className="form-label">
                        Exporter Phone Number
                      </label>
                      <Field
                        placeholder="Ex. +17173064124"
                        name="phoneNumber"
                        type="text"
                        id="inputPhone"
                        className={`form-control ${errors.phoneNumber && touched.phoneNumber ? "is-invalid" : ""}`}
                      />
                      {errors.phoneNumber && <FormError error={errors.phoneNumber} />}
                    </div>

                    <div className="mb-3">
                      <label htmlFor="inputAddress" className="form-label">
                        Exporter&apos;s Address
                      </label>
                      <Field
                        placeholder="Ex. 253 Hill Haven Drive, Killeen, TX 76541"
                        name="address"
                        id="inputAddress"
                        type="text"
                        className={`form-control ${errors.address && touched.address ? "is-invalid" : ""}`}
                      />
                      {errors.address && <FormError error={errors.address} />}
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-center gap-2">
                    <Link
                      className="text-uppercase fw-bold btn btn-outline-secondary"
                      to="/dashboard/certificate-of-origin/generate/certifier-form/"
                      state={{ ...location.state }}
                    >
                      Cancel & Go back
                    </Link>
                    <button type="submit" className="btn btn-primary fw-bold" disabled={isSubmitting}>
                      Save & Continue
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
        <div className="col-lg-2" />
      </div>
    </div>
  );
}

export default ExporterForm;
