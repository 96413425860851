import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { sidebarStateSelector } from "../../features/ui/navigationSlice";
import logoImg from "../../images/horizontal-logo.svg";
import CollapseButton from "./components/CollapseButton";
import DropdownItems from "./components/UserDropdown/DropdownItems";
import NavItems from "./components/UserDropdown/NavItems";
import UserDropdown from "./components/UserDropdown/UserDropdown";

function MainSidebar() {
  const openSidebar = useSelector(sidebarStateSelector);

  return (
    <aside className={`border-end min-vh-100 d-flex flex-column justify-content-between main-sidebar ${!openSidebar ? "main-sidebar--hidden" : ""}`}>
      <div style={{ width: "22vw" }} className="main-sidebar__body p-3">
        <div className="mb-5">
          <Link to="/">
            <img src={logoImg} style={{ width: "70%" }} alt="Logo" />
          </Link>
        </div>
        <div className="mb-3">
          <UserDropdown />
          <DropdownItems />
        </div>
        <NavItems />
      </div>
      <CollapseButton />
    </aside>
  );
}

export default MainSidebar;
