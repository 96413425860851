import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { setSelectedBomIds } from "../../../features/certificateOfOrigin/certificateOfOriginSlice";

export default function useGenerateCertificate() {
  const [continueError, setContinueError] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleContinue = useCallback(
    (selectedBomIds) => {
      setContinueError(null);
      if (Array.isArray(selectedBomIds) && selectedBomIds.length > 0) {
        dispatch(setSelectedBomIds(selectedBomIds));
        navigate("/dashboard/certificate-of-origin/generate/certifier-form/");
      } else {
        setContinueError("Please select some BOMs first.");
      }
    },
    [dispatch, navigate],
  );

  useEffect(() => {
    if (continueError) {
      Swal.fire({
        title: "Error!",
        text: continueError,
        icon: "error",
      });
    }
  }, [continueError]);

  return useMemo(() => ({ handleContinue }), [handleContinue]);
}
