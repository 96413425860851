import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { SORT_BY_LATEST } from "../../constants";

export default function useSortByDropdown() {
  const [show, setShow] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentMethod, setCurrentMethod] = useState(SORT_BY_LATEST);

  const setSortBy = useCallback(
    (method) => {
      setSearchParams((currentParams) => {
        const paramsCopy = new URLSearchParams(currentParams.toString());
        paramsCopy.set("sort-by", method);
        return paramsCopy;
      });
    },
    [setSearchParams],
  );

  useEffect(() => {
    if (searchParams && searchParams.get("sort-by")) {
      setCurrentMethod(searchParams.get("sort-by"));
    } else {
      setCurrentMethod(SORT_BY_LATEST);
    }

    return () => {
      setCurrentMethod(SORT_BY_LATEST);
    };
  }, [searchParams]);

  return useMemo(() => ({ show, setShow, setSortBy, currentMethod }), [show, setShow, setSortBy, currentMethod]);
}
