import PropTypes from "prop-types";
import React, { useState } from "react";

import PDFViewerModal from "../../components/PDFViewerModal/PDFViewerModal";

function ViewCertPDFButton({ pdfUrl = null }) {
  const [viewing, setViewing] = useState(false);

  return (
    <>
      <button type="button" onClick={() => setViewing(true)} className="btn btn-primary fw-bold text-uppercase">
        View PDF
      </button>
      <PDFViewerModal show={viewing} onHide={() => setViewing(false)} title="USMCA Certificate" downloadUrl={pdfUrl} />
    </>
  );
}

ViewCertPDFButton.propTypes = {
  pdfUrl: PropTypes.string,
};

export default ViewCertPDFButton;
