import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { useSubmitComponentsMutation } from "../../../features/api/boms";
import useBom from "../../../hooks/useBom";
import useBomIdFromParams from "../../../hooks/useDecodedBomId";

export default function SubmitComponents() {
  const { encodedBomId } = useParams();
  const { bomId } = useBomIdFromParams();
  const { bom } = useBom(bomId);
  const navigate = useNavigate();
  const [submitComponents, { isError, error }] = useSubmitComponentsMutation();

  useEffect(() => {
    if (bom) {
      if (bom.BOM_State !== "FG_COMPLETE") {
        navigate(`/dashboard/bom/qualify/${encodedBomId}`);
      } else {
        submitComponents(bomId);
      }
    }
  }, [bom, encodedBomId, bomId, navigate, submitComponents]);

  useEffect(() => {
    if (isError) {
      Swal.fire({
        title: "Error!",
        text: `Failed to process components. ${error.error || "Unknown error."}`,
      }).then(() => {
        navigate(`/dashboard/bom/${encodedBomId}`);
      });
    }
  }, [isError, error, encodedBomId, navigate]);

  if (!bomId) {
    return (
      <div className="container pt-5">
        <div className="row">
          <div className="d-flex justify-content-center">
            <LoadingSpinner />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="container pt-5">
      <div className="row mb-5">
        <h1 className="text-center">Qualify BOM {bomId}</h1>
      </div>
      <p className="alert alert-info text-center">Please wait while we process the BOM&apos;s components.</p>
    </div>
  );
}
