/* eslint no-param-reassign: 0 */
import { createSlice } from "@reduxjs/toolkit";

const usageLimitsSlice = createSlice({
  name: "usageLimits",
  initialState: {
    bomCount: 0,
    totalBomsPurchased: 0,
  },
  reducers: {
    setBomCount(state, action) {
      state.bomCount = action.payload;
    },
    setTotalBomsPurchased(state, action) {
      state.totalBomsPurchased = action.payload;
    },
  },
});

export default usageLimitsSlice.reducer;

export const { setBomCount, setTotalBomsPurchased } = usageLimitsSlice.actions;

export function bomCountSelector(state) {
  return state.usageLimits.bomCount;
}

export function totalBomsPurchasedSelector(state) {
  return state.usageLimits.totalBomsPurchased;
}
