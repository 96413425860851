import { decode } from "js-base64";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function useBomIdFromParams() {
  const { encodedBomId } = useParams();
  const [bomId, setBomId] = useState(null);
  useEffect(() => {
    if (encodedBomId) {
      setBomId(decode(encodedBomId));
    } else {
      setBomId(null);
    }

    return () => {
      setBomId(null);
    };
  }, [encodedBomId]);

  return { bomId };
}
