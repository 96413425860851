import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

import { qualificationReasonsToMarkdownList } from "../../utils/bom";
import { useBomContext } from "./BOMContext";

function getDaySuffix(day) {
  const dayStr = String(day);
  const endsWith = dayStr.slice(dayStr.length - 1);
  if (endsWith === "1") {
    return "st";
  }
  if (endsWith === "2") {
    return "nd";
  }
  return "th";
}

function DateDisplay({ date }) {
  const [dateData, setDateData] = useState(null);

  useEffect(() => {
    if (date) {
      const dayOfMonth = date.date();
      const daySuffix = getDaySuffix(dayOfMonth);
      setDateData({
        time: date.format("HH:mm"),
        day: dayOfMonth,
        month: date.format("MMM"),
        year: date.format("YYYY"),
        daySuffix,
      });
    }
  }, [date]);

  if (dateData) {
    return (
      <p>
        {dateData.time} on {dateData.day}
        {dateData.daySuffix} {dateData.month} {dateData.year}
      </p>
    );
  }

  return "";
}

function QualificationCard() {
  const { bom } = useBomContext();
  const [qualifiedDate, setQualifiedDate] = useState(null);
  const [archiveDate, setArchiveDate] = useState(null);

  useEffect(() => {
    if (qualifiedDate) {
      setArchiveDate(qualifiedDate.add(5, "year"));
    }

    return () => {
      setArchiveDate(null);
    };
  }, [qualifiedDate]);

  useEffect(() => {
    if (bom) {
      setQualifiedDate(dayjs.unix(bom.SubmitTS));
    }
  }, [bom]);

  return (
    <div
      style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridTemplateRows: "1fr", gap: "2rem", alignItems: "center" }}
      className="bg-dynamic-black text-light-0 rounded p-5"
    >
      <div>
        <h2 style={{ fontSize: "8rem", marginLeft: "-10px", marginBottom: 0 }}>{bom.QualPref}</h2>
        <p className="fw-bold">Qualification Preference</p>
      </div>
      <div>
        <div className="mb-3">
          <p className="fw-bold">Qualification Type</p>
          <p>ANNEX 401 rules of origin</p>
        </div>
        <div className="mb-3">
          <p className="fw-bold">Qualified Under</p>
          <div>
            <ReactMarkdown>{qualificationReasonsToMarkdownList(bom.QualUnder)}</ReactMarkdown>
          </div>
        </div>

        <div className="mb-3">
          <p className="fw-bold">Qualified At</p>
          <DateDisplay date={qualifiedDate} />
        </div>

        <div>
          <p className="fw-bold">Archive Date</p>
          <DateDisplay date={archiveDate} />
        </div>
      </div>
    </div>
  );
}
export default QualificationCard;
