import React from "react";

export default function BOMTableHeader() {
  return (
    <thead>
      <tr>
        <th scope="col">BOM ID</th>
        <th scope="col">Submitted on</th>
        <th scope="col">USHTS</th>
        <th scope="col">Part Number</th>
        <th scope="col">Description</th>
        <th scope="col">Value</th>
        <th scope="col">COO</th>
        <th scope="col">Qualified</th>
        <th scope="col">Options</th>
      </tr>
    </thead>
  );
}
