import PropTypes from "prop-types";
import React, { createContext, useContext, useMemo } from "react";

import useBomDetail from "./useBomDetail";

const BOMContext = createContext(null);

function BOMContextProvider({ children = "" }) {
  const { bom, loading } = useBomDetail();

  const contextValue = useMemo(() => ({ bom, loading }), [bom, loading]);
  return <BOMContext.Provider value={contextValue}>{children}</BOMContext.Provider>;
}

BOMContextProvider.propTypes = {
  children: PropTypes.node,
};

export { BOMContextProvider };

export function useBomContext() {
  return useContext(BOMContext);
}
