import React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import FinishedGoodForm from "../../../components/FinishedGoodForm/FinishedGoodForm";
import { isAutomotive } from "../../../utils/automotive";
import ProgressBar from "../components/ProgressBar/ProgressBar";
import { FINISHED_GOOD_DETAILS_STEP } from "../constants";

export default function FinishedGoodDetails() {
	const navigate = useNavigate();
	function handleSubmit(values, { setSubmitting }) {
		setSubmitting(true);
		const errors = [];
		const { value, netCost } = values;
		if (value === "" && netCost === "") {
			errors.push(
				"Please provide either the transaction value, the net cost of your finished good, or both of them.",
			);
		}
		if (isAutomotive(values.hts)) {
			if (values.autoType === "") {
				errors.push(
					"Please select the category your automotive good falls within.",
				);
			}

			if (values.autoTimeframe === "") {
				errors.push("Please select the applicable timeframe for this claim.");
			}
		}

		if (errors.length > 0) {
			Swal.fire({
				title: "Error!",
				html: `<ul>${errors.map((error) => `<li>${error} </li>`)}</ul>`,
				icon: "error",
			}).then(() => {
				setSubmitting(false);
			});
		} else {
			navigate("/dashboard/create-bill-of-material/component-details/", {
				state: {
					finishedGood: {
						currency: values.currency,
						partNumber: values.partNumber,
						description: values.description,
						origin: values.coo,
						id: "DRAFT",
						hts: values.hts,
						transactionValue: String(values.value),
						netCost: String(values.netCost),
						totalCost: String(values.totalCost),
						autoType: values.autoType,
						autoTimeframe: values.autoTimeframe,
					},
				},
			});
		}
	}
	return (
		<div className="container pt-5">
			<div className="row">
				<div className="col-lg-2" />
				<div className="col-lg-8">
					<div className="mb-5 d-flex w-100">
						<ProgressBar step={FINISHED_GOOD_DETAILS_STEP} />
					</div>
					<div className="mb-5">
						<h1 className="mb-3">Create a Bill of Material</h1>
						<p>
							Start by providing details about the finished good you would like
							to qualify for USMCA.
						</p>
					</div>
					<FinishedGoodForm handleSubmit={handleSubmit} />
				</div>
				<div className="col-lg-2" />
			</div>
		</div>
	);
}
