import PropTypes from "prop-types";

export const bomDetailsPropType = PropTypes.shape({
  id: PropTypes.string,
  user: PropTypes.string,
  description: PropTypes.string,
  producer: PropTypes.string,
  hts: PropTypes.string,
  value: PropTypes.string,
  coo: PropTypes.string,
  currency: PropTypes.string,
});

export const componentPropType = PropTypes.shape({
  lineItem: PropTypes.number,
  partNumber: PropTypes.string,
  hts: PropTypes.string,
  desc: PropTypes.string,
  qty: PropTypes.number,
  units: PropTypes.string,
  cost: PropTypes.number,
  extCost: PropTypes.number,
  coo: PropTypes.string,
  usmca: PropTypes.string,
});
export const componentsPropType = PropTypes.arrayOf(componentPropType);

export const qualificationPropType = PropTypes.shape({
  bomId: PropTypes.string,
  qualificationType: PropTypes.string,
  qualificationPreference: PropTypes.string,
  qualifiedUnder: PropTypes.string,
});
