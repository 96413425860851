import print from "print-js";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { PiPrinter } from "react-icons/pi";

import useQualificationPdf from "../../hooks/useQualificationPdf";

function PrintPDFButton({ bomId }) {
  const [printing, setPrinting] = useState(false);
  const { pdf } = useQualificationPdf(bomId);

  let body;

  if (!pdf || printing) {
    body = "Please wait...";
  } else {
    body = "Print PDF";
  }

  const handleClick = useCallback(() => {
    if (pdf && pdf.url) {
      setPrinting(true);
      fetch(pdf.url)
        .then((response) => {
          if (response.ok) {
            return response.blob();
          }
          return false;
        })
        .then((responseData) => {
          if (responseData) {
            print(URL.createObjectURL(responseData));
          }
          setPrinting(false);
        });
    }
  }, [pdf]);

  return (
    <button disabled={!pdf || printing} onClick={handleClick} type="button" className="btn btn-primary">
      <PiPrinter /> {body}
    </button>
  );
}

PrintPDFButton.propTypes = {
  bomId: PropTypes.string.isRequired,
};

export default PrintPDFButton;
