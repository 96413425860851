import dayjs from "dayjs";
import PropTypes from "prop-types";
import React from "react";
import { PiCalendar } from "react-icons/pi";

import { DEFAULT_DAY_JS_DATETIME_FORMAT } from "../../constants";
import useBom from "../../hooks/useBom";
import { htsDisplay } from "../../utils/bom";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Header from "./components/Header/Header";

function BOMCard({ bomId = null }) {
  const { bom, isError, loading } = useBom(bomId);

  let body;

  if (loading || isError) {
    body = (
      <div className="d-flex justify-content-center align-items-center">
        <LoadingSpinner />
      </div>
    );
  } else {
    body = (
      <>
        <Header bom={bom} />
        <h4>{bom?.FG.Part}</h4>
        <p>{bom?.FG.Desc}</p>
        <div className="d-flex justify-content-between align-items-end">
          <p className="m-0">
            <PiCalendar /> {bom?.SubmitTS && bom?.SubmitTS.length > 0 ? dayjs.unix(bom?.SubmitTS).format(DEFAULT_DAY_JS_DATETIME_FORMAT) : "-"}
          </p>
          <p className="m-0">BOM ID | {bom?.BOMID}</p>
          <p className="m-0">HTS | {htsDisplay(bom?.FG.Hts)}</p>
          <p className="m-0">COO | {bom?.FG.Origin}</p>
          <p className="m-0">Transaction Value | {bom?.FG.Value}</p>
          <p className="m-0">Net Cost | {bom?.FG.NetCost}</p>
          <p className="m-0">Total Cost | {bom?.FG.TotalCost}</p>
        </div>
      </>
    );
  }
  return <div className={`bom-card ${!loading ? "bom-card--grid" : "bom-card--loading"} w-100 border border-light-4 p-3 rounded bg-light-2`}>{body}</div>;
}

BOMCard.propTypes = {
  bomId: PropTypes.string,
};

export default BOMCard;
