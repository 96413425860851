import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import PageHeader from "../../components/PageHeader/PageHeader";
import { CERTIFICATES_PAGE } from "../../constants";
import { setCurrentPage } from "../../features/ui/navigationSlice";
import useBomUsageLimitVisibility from "../../hooks/useBomUsageLimitVisibility";
import CertificateList from "./components/CertificateList/CertificateList";
import NoCertificates from "./components/NoCertificates";
import useCertificates from "./useCertificates";

export default function CertificateOfOriginList() {
  const { isLoading, certificates, isError, error, searchQuery } = useCertificates();
  useBomUsageLimitVisibility(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentPage(CERTIFICATES_PAGE));

    return () => {
      dispatch(setCurrentPage(null));
    };
  }, [dispatch]);
  let body;

  if (isLoading) {
    body = (
      <div className="d-flex justify-content-center">
        <LoadingSpinner />
      </div>
    );
  } else if (isError) {
    body = <p className="alert alert-danger text-center">{JSON.stringify(error.data || "Unable to fetch list of certificates. Please try again.")}</p>;
  } else if (certificates && Array.isArray(certificates) && certificates.length > 0) {
    body = <CertificateList certificates={certificates} isLoading={isLoading} />;
  } else if (searchQuery !== null) {
    body = <CertificateList certificates={certificates} isLoading={isLoading} />;
  } else {
    body = <NoCertificates />;
  }
  return (
    <div className="p-3">
      <div style={{ marginBottom: "20vh" }} className="row">
        <PageHeader title="USMCA Certificates" />
      </div>
      <div className="row">
        <div className="col">{body}</div>
      </div>
    </div>
  );
}
