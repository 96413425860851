import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { currentLoginStateSelector } from "../../features/auth/authSlice";
import { sidebarStateSelector } from "../../features/ui/navigationSlice";
import logoImg from "../../images/horizontal-logo.svg";
import AvatarButton from "./components/AvatarButton/AvatarButton";

export default function MainNavbar() {
  const isLoggedIn = useSelector(currentLoginStateSelector);
  const openSidebar = useSelector(sidebarStateSelector);

  return (
    <header className={`main-header ${openSidebar ? "main-header--hidden" : "p-3"} border-bottom border-light-3`}>
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <Link to="/">
            <img style={{ width: "10rem" }} src={logoImg} alt="Kendrick Trade Logo" />
          </Link>
          {isLoggedIn ? (
            <AvatarButton />
          ) : (
            <Link className="btn btn-primary" to="/login">
              Log in
            </Link>
          )}
        </div>
      </div>
    </header>
  );
}
