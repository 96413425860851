import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { PiCaretDownBold, PiCheck } from "react-icons/pi";
import OutsideClickHandler from "react-outside-click-handler";
import { useSearchParams } from "react-router-dom";

import { SORT_BY_LATEST, SORT_BY_OLDEST } from "./constants";

function DropDownItem({ name, itemType }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selected, setSelected] = useState(false);

  const handleClick = useCallback(() => {
    setSearchParams((current) => {
      const newParams = new URLSearchParams(current.toString());
      newParams.set("sort-by", itemType);
      return newParams;
    });
  }, [itemType, setSearchParams]);

  useEffect(() => {
    if (searchParams) {
      const sortBy = searchParams.get("sort-by");
      if (sortBy === itemType) {
        setSelected(true);
      }
    }

    return () => {
      setSelected(false);
    };
  }, [searchParams, itemType]);

  return (
    <button
      onClick={handleClick}
      type="button"
      className="w-100 btn text-start p-0 bom-history__sort-dropdown-item no-border-no-outline d-flex justify-content-between align-items-center"
    >
      {name} {selected && <PiCheck />}
    </button>
  );
}

DropDownItem.propTypes = {
  name: PropTypes.string.isRequired,
  itemType: PropTypes.string.isRequired,
};

function DropDown({ show }) {
  return (
    <div
      style={{ backgroundColor: "white" }}
      className={`bom-history__sort-dropdown rounded position-absolute p-2 d-flex flex-column justify-content-start align-items-start ${
        show ? "bom-history__sort-dropdown--visible" : ""
      }`}
    >
      <DropDownItem itemType={SORT_BY_LATEST} name="Newest" />
      <DropDownItem itemType={SORT_BY_OLDEST} name="Oldest" />
    </div>
  );
}

DropDown.propTypes = {
  show: PropTypes.bool.isRequired,
};

function SortBy() {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleClick = useCallback(() => {
    setShowDropdown((cur) => !cur);
  }, [setShowDropdown]);

  return (
    <div className="position-relative">
      <OutsideClickHandler onOutsideClick={() => setShowDropdown(false)}>
        <>
          <button onClick={handleClick} type="button" className="btn text-primary d-flex align-items-center p-0 gap-1 fw-bold bom-history__sort-dropdown-btn">
            SORT BY
            <PiCaretDownBold />
          </button>
          <DropDown show={showDropdown} />
        </>
      </OutsideClickHandler>
    </div>
  );
}

export default SortBy;
