/* eslint no-param-reassign: 0 */
import { createSlice } from "@reduxjs/toolkit";

const customerProfileSlice = createSlice({
  name: "customerProfile",
  initialState: {
    avatarDataUri: null,
    isLoading: false,
  },
  reducers: {
    setAvatarDataUri(state, action) {
      state.avatarDataUri = action.payload;
    },
    setAvatarIsLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
});

export default customerProfileSlice.reducer;

export const { setAvatarDataUri, setAvatarIsLoading } = customerProfileSlice.actions;

export function avatarDataUriSelector(state) {
  return state.customerProfile.avatarDataUri;
}

export function avatarLoadingSelector(state) {
  return state.customerProfile.isLoading;
}
