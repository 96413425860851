import * as Sentry from "@sentry/react";
import { Auth } from "aws-amplify";
import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setCredentials } from "../../features/auth/authSlice";
import useAlreadyLoggedIn from "../../hooks/useAlreadyLoggedIn";

export default function useLogin() {
  useAlreadyLoggedIn();
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      setSubmitting(true);
      const { username, password } = values;
      Auth.signIn(username, password)
        .then((cognitoUser) => {
          const session = cognitoUser.getSignInUserSession();
          dispatch(
            setCredentials({
              accessToken: session.getAccessToken().getJwtToken(),
              idToken: session.getIdToken().getJwtToken(),
              refreshToken: session.getRefreshToken().getToken(),
              idTokenPayload: session.idToken.payload,
            }),
          );
        })
        .catch((err) => {
          if (err.code === "UserNotConfirmedException") {
            navigate("/auth/register/confirm", { state: { username } });
          }
          Sentry.captureException(err);
          setError(err.message || JSON.stringify(err));
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [dispatch, navigate],
  );

  return useMemo(() => ({ handleSubmit, error }), [handleSubmit, error]);
}
