import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import useBom from "../../hooks/useBom";
import useBomIdFromParams from "../../hooks/useDecodedBomId";

export default function QualifyBOM() {
  const { encodedBomId } = useParams();
  const { bomId } = useBomIdFromParams();
  const { bom, isError } = useBom(bomId);
  const navigate = useNavigate();
  useEffect(() => {
    if (bom) {
      const { BOM_State: state } = bom;
      if (state === "unsubmitted") {
        navigate(`/dashboard/bom/qualify/submit-fg/${encodedBomId}/`);
      } else if (state === "FG_QUALIFYING") {
        navigate(`/dashboard/bom/qualify/answer-fg-questions/${encodedBomId}/`);
      } else if (state === "FG_COMPLETE") {
        navigate(`/dashboard/bom/qualify/submit-components/${encodedBomId}/`);
      } else if (state === "COMPS_QUALIFYING") {
        navigate(`/dashboard/bom/qualify/answer-component-questions/${encodedBomId}/`);
      } else if (state === "COMPS_COMPLETE") {
        navigate(`/dashboard/bom/qualify/close/${encodedBomId}/`);
      } else if (state === "CLOSED") {
        navigate(`/dashboard/bom/${encodedBomId}/`, { replace: true });
      } else {
        Swal.fire({
          title: "Error!",
          text: "This BOM is in an invalid state. Please contact us.",
        }).then(() => navigate(`/dashboard/bom/${encodedBomId}`, { replace: true }));
      }
    }
  }, [bom, navigate, encodedBomId]);

  if (!bomId) {
    return (
      <div className="container pt-5">
        <div className="row">
          <div className="d-flex justify-content-center">
            <LoadingSpinner />
          </div>
        </div>
      </div>
    );
  }

  let body;

  if (isError) {
    body = <p className="alert alert-danger text-center">BOM not found!</p>;
  } else {
    body = <p className="alert alert-info text-center">Please wait while we process your BOM.</p>;
  }
  return (
    <div className="container pt-5">
      <div className="row mb-5">
        <h1 className="text-center">Qualify BOM {bomId}</h1>
      </div>
      <div className="row">
        <div className="col-lg-4" />
        <div className="col-lg-4">{body}</div>
        <div className="col-lg-4" />
      </div>
    </div>
  );
}
