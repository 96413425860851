/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field, Form, Formik } from "formik";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { object, string } from "yup";

import FormError from "../../components/FormError";
import ProgressDots from "../../components/ProgressDots/ProgressDots";
import useConfirmRegistration from "./useConfirmRegistration";

const schema = object().shape({
  username: string().required("Please enter your username."),
  code: string().required("Please enter the code emailed to you."),
});
export default function ConfirmRegistration() {
  const { handleSubmit, errorMsg } = useConfirmRegistration();
  const location = useLocation();

  return (
    <div>
      <p className="alert alert-info text-center mb-5">
        {location?.state?.email ? (
          <span>
            We emailed you a confirmation code at <b>{location?.state?.email}</b>. Please enter it below.
          </span>
        ) : (
          <span>Please check your email for a confirmation code.</span>
        )}
      </p>
      {errorMsg && <p className="alert alert-danger text-center mb-5">{errorMsg}</p>}
      <div className="mb-5">
        <Formik onSubmit={handleSubmit} validationSchema={schema} initialValues={{ code: "", username: location?.state?.username || "" }}>
          {({ isSubmitting, errors }) => (
            <Form>
              <div className={`form-group mb-3 ${location?.state?.username ? "d-none" : ""}`}>
                <label className="form-label w-100">
                  Username*
                  <Field type="text" name="username" className={`form-control mt-1 ${errors && errors.username ? "is-invalid" : ""}`} required />
                  {errors && errors.username && <FormError error={errors.username} />}
                </label>
              </div>
              <div className="form-group mb-3">
                <label className="form-label w-100">
                  Code*
                  <Field type="text" name="code" className={`form-control mt-1 ${errors && errors.code ? "is-invalid" : ""}`} required />
                  <span className="form-text">The code we emailed you.</span>
                  {errors && errors.code && <FormError error={errors.code} />}
                </label>
              </div>
              <div className="d-flex align-items-center gap-2">
                <Link className="btn btn-outline-secondary flex-grow-1" to="/auth/register">
                  Cancel & Go Back
                </Link>
                <button type="submit" className="btn btn-primary flex-grow-1" disabled={isSubmitting}>
                  Confirm account
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className="d-flex justify-content-center">
        <ProgressDots currentStep={1} stepCount={3} />
      </div>
    </div>
  );
}
