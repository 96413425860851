import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { useCreateCertificateMutation } from "../../features/api/certificates";
import { useGetCustomerQuery } from "../../features/api/customers";
import { selectedBomIdsSelector } from "../../features/certificateOfOrigin/certificateOfOriginSlice";
import apiErrorToString from "../../utils/response";
import { validateEffectiveAndExpiryDate, validateExporterData } from "./utils";

export default function useCertificateOfOriginForm() {
  const [errorMsg, setErrorMsg] = useState(null);
  const { data: customer } = useGetCustomerQuery();
  const selectedBomIds = useSelector(selectedBomIdsSelector);
  const createCertificate = useCreateCertificateMutation()[0];
  const navigate = useNavigate();
  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      setSubmitting(true);
      setErrorMsg(null);
      let canSubmit = true;
      const [effectiveAndExpiryIsValid, effectiveAndExpiryError] = validateEffectiveAndExpiryDate(values.effectiveDate, values.expirationDate);
      if (!effectiveAndExpiryIsValid) {
        setErrorMsg(effectiveAndExpiryError);
        canSubmit = false;
      }

      if (values.certifierType !== "producer") {
        const [exporterDataIsValid, exporterDataError] = await validateExporterData({
          exporterName: values.exporterName,
          exporterEmail: values.exporterEmail,
          exporterAddress: values.exporterAddress,
          exporterPhone: values.exporterPhone,
        });
        if (!exporterDataIsValid) {
          setErrorMsg(exporterDataError);
          canSubmit = false;
        }
      }

      if (canSubmit) {
        // eslint-disable-next-line no-console
        const requestBody = {
          certifying_company: values.certifyingCompany,
          certifier_name: values.certifierName,
          certifier_type: values.certifierType,
          certifier_address: values.certifierAddress,
          certifier_phone_number: values.certifierPhoneNumber,
          certifier_email: values.certifierEmail,
          effective_date: values.effectiveDate,
          expiration_date: values.expirationDate,
          producer_data_type: values.producerDataType,
          producers: values.producers,
          importer_name: values.importerName,
          importer_email: values.importerEmail,
          importer_phone_number: values.importerPhone,
          importer_address: values.importerAddress,
          qualified_good_ids: selectedBomIds,
        };
        if (values.exporterName) {
          requestBody.exporter_name = values.exporterName;
          requestBody.exporter_email = values.exporterEmail;
          requestBody.exporter_phone_number = values.exporterPhone;
          requestBody.exporter_address = values.exporterAddress;
        }
        const response = await createCertificate(requestBody);
        if (response.data && response.data.message) {
          await Swal.fire({
            title: "Success!",
            text: "Your certificate has been generated.",
            icon: "success",
            confirmButtonText: "Ok",
          });
          navigate(`/dashboard/certificate-of-origin/${response.data.message}`, { replace: true });
        } else {
          await Swal.fire({
            title: "Sorry!",
            text: `We couldn't create your certificate. Please try again.`,
            icon: "error",
            confirmButtonText: "Dismiss",
          });
          setErrorMsg(apiErrorToString(response.error?.data));
        }
      } else {
        window.scroll({
          top: 0,
          behavior: "smooth",
        });
      }
      setSubmitting(false);
    },
    [selectedBomIds, createCertificate, navigate],
  );

  return useMemo(
    () => ({
      errorMsg,
      customer,
      handleSubmit,
    }),
    [errorMsg, customer, handleSubmit],
  );
}
