import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useCreateCertificateMutation } from "../../../features/api/certificates";
import {
  certifierSelector,
  exporterSelector,
  importerSelector,
  producerDataTypeSelector,
  producersSelector,
  selectedBomIdsSelector,
} from "../../../features/certificateOfOrigin/certificateOfOriginSlice";
import apiErrorToString from "../../../utils/response";

export default function useSummary() {
  const navigate = useNavigate();
  const selectedBomIds = useSelector(selectedBomIdsSelector);
  const [errorMsg, setErrorMsg] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [createCertificate, { isError, error, data: certificateData }] = useCreateCertificateMutation();
  const certifier = useSelector(certifierSelector);
  const exporter = useSelector(exporterSelector);
  const importer = useSelector(importerSelector);
  const producerDataType = useSelector(producerDataTypeSelector);
  const producers = useSelector(producersSelector);

  const handleSubmit = useCallback(() => {
    setSubmitting(true);
    setErrorMsg(null);
    const requestBody = {
      certifying_company: certifier.company,
      certifier_name: certifier.name,
      certifier_type: certifier.role,
      certifier_address: certifier.address,
      certifier_phone_number: certifier.phoneNumber,
      certifier_email: certifier.emailAddress,
      effective_date: certifier.effectiveDate,
      expiration_date: certifier.expirationDate,
      producer_data_type: producerDataType,
      producers: producers.map((producer) => ({
        name: producer.name,
        email: producer.email,
        phone: producer.phoneNumber,
        address: producer.address,
      })),
      importer_name: importer.name,
      importer_email: importer.email,
      importer_phone_number: importer.phoneNumber,
      importer_address: importer.address,
      qualified_good_ids: selectedBomIds,
    };

    if (exporter.name && exporter.name.length > 0) {
      requestBody.exporter_name = exporter.name;
      requestBody.exporter_email = exporter.email;
      requestBody.exporter_phone_number = exporter.phoneNumber;
      requestBody.exporter_address = exporter.address;
    }

    createCertificate(requestBody);
  }, [certifier, exporter, importer, producerDataType, producers, selectedBomIds, createCertificate]);

  useEffect(() => {
    if (isError && error) {
      if (error.data) {
        setErrorMsg(apiErrorToString(error.data));
      } else {
        setErrorMsg("Unknown error! Please try again.");
      }
    }

    return () => {
      setErrorMsg(null);
    };
  }, [isError, error]);

  useEffect(() => {
    if (certificateData) {
      setSubmitting(false);
      navigate(`/dashboard/certificate-of-origin/${certificateData.message}`, { replace: true });
    }
  }, [navigate, certificateData]);

  return useMemo(() => ({ handleSubmit, submitting, errorMsg }), [handleSubmit, submitting, errorMsg]);
}
