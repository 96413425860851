import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useGetBomCountAvailableQuery } from "../features/api/payments";
import { setBomCount } from "../features/usageLimits/usageLimitsSlice";

export default function useUpdateUsageLimits() {
  const { data: bomCount } = useGetBomCountAvailableQuery(null, { pollingInterval: 3000 });
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof bomCount !== "undefined") {
      dispatch(setBomCount(bomCount));
    }
  }, [bomCount, dispatch]);
}
