import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import {
  certifierSelector,
  producerDataTypeSelector,
  producersSelector,
  setProducerDataType,
  setProducers,
} from "../../../../features/certificateOfOrigin/certificateOfOriginSlice";
import { producerRequiredSchema } from "./schemas";

export default function useProducerData() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentCertifier = useSelector(certifierSelector);
  const currentProducers = useSelector(producersSelector);
  const currentProducerDataType = useSelector(producerDataTypeSelector);

  const initialValues = useMemo(() => {
    let producers;
    if (currentProducers && Array.isArray(currentProducers) && currentProducers.length > 0) {
      producers = currentProducers;
    } else if (currentCertifier?.role === "producer") {
      producers = [
        {
          name: currentCertifier?.name || "",
          email: currentCertifier?.emailAddress || "",
          phoneNumber: currentCertifier?.phoneNumber || "",
          address: currentCertifier?.address || "",
        },
      ];
    } else {
      producers = [];
    }

    return {
      producers,
      producerDataType: currentProducerDataType || (currentCertifier.role === "producer" && "single") || "",
    };
  }, [currentProducers, currentProducerDataType, currentCertifier]);

  const validateProducer = useCallback(
    (producerData, index) =>
      new Promise((resolve, reject) => {
        producerRequiredSchema
          .validate(producerData)
          .then(() => resolve())
          .catch((err) => {
            // eslint-disable-next-line no-param-reassign
            err.index = index;
            reject(err);
          });
      }),
    [],
  );

  const validateProducers = useCallback(
    (producers) =>
      new Promise((resolve, reject) => {
        Promise.allSettled(producers.map((producer, idx) => validateProducer(producer, idx))).then((results) => {
          const errors = [];
          results.forEach((result) => {
            if (result.status === "rejected") {
              errors.push([`producers.${result.reason.index}.${result.reason.path}`, result.reason.message]);
            }
          });
          if (errors.length > 0) {
            reject(errors);
          } else {
            resolve();
          }
        });
      }),
    [validateProducer],
  );

  const handleSubmit = useCallback(
    (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true);
      dispatch(setProducerDataType(values.producerDataType));
      if (values.producerDataType === "single" || values.producerDataType === "list") {
        validateProducers(values.producers)
          .then(() => {
            dispatch(setProducers(values.producers));
            navigate("/dashboard/certificate-of-origin/generate/importer-form/");
          })
          .catch((err) => {
            err.forEach(([fieldName, message]) => setFieldError(fieldName, message));
          });
      } else {
        navigate("/dashboard/certificate-of-origin/generate/importer-form/");
      }
      setSubmitting(false);
    },
    [dispatch, navigate, validateProducers],
  );

  useEffect(() => {
    if (!currentCertifier) {
      Swal.fire({
        title: "Error!",
        text: "Please provide certifier information first.",
        icon: "error",
      }).then(() => {
        navigate("/dashboard/certificate-of-origin/generate/certifier-form/", { replace: true });
      });
    } else {
      setLoading(false);
    }

    return () => setLoading(true);
  }, [currentCertifier, navigate]);

  return useMemo(() => ({ loading, handleSubmit, initialValues }), [loading, handleSubmit, initialValues]);
}
