import dayjs from "dayjs";
import { object, string } from "yup";

/**
 * Check if effective date is before expiry date. Then check that expiry date is no more
 * than 12 months in the future.
 * @param {string} effectiveDate - Date string in ISO format.
 * @param {string} expiryDate - Date string in ISO format.
 */
export function validateEffectiveAndExpiryDate(effectiveDate, expiryDate) {
  const effective = dayjs(effectiveDate);
  const expiry = dayjs(expiryDate);

  if (effective.isAfter(expiry)) {
    return [false, "Expiry must be later than the effective date."];
  }

  if (expiry.isAfter(effective.add(12, "months"))) {
    return [false, "Expiry date can't be more than 12 months after the effective date."];
  }

  return [true, null];
}

export async function validateExporterData(exporterData) {
  const schema = object().shape({
    exporterName: string().required("Please enter the exporter's name."),
    exporterEmail: string().email("Please enter a valid email address.").required("Please enter the exporter's email address."),
    exporterAddress: string().required("Please enter the exporter's full address."),
    exporterPhone: string()
      .matches(/[+0-9]/, "Please enter a valid phone number for the exporter.")
      .required("Please enter the exporter's phone number."),
  });
  try {
    await schema.validate(exporterData);
  } catch (err) {
    return [false, JSON.stringify(err.errors)];
  }

  return [true, null];
}
