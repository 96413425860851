import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import SearchButton from "../../../../components/SearchButton/SearchButton";
import ToggleViewButton from "../../../../components/ToggleViewButton/ToggleViewButton";
import useViewAs from "../../../../hooks/useViewAs";
import certificateSummaryPropType from "../../../../propTypes/certificateSummaryPropType";
import CertificateCard from "../CertificateCard/CertificateCard";
import CertificateTable from "../CertificateTable/CertificateTable";
import SortByDropdown from "../SortByDropdown/SortByDropdown";

function Content({ certificates }) {
  const { viewAs } = useViewAs();

  if (viewAs === "card") {
    return (
      <div>
        {certificates.map((certificate) => (
          <div className="mb-3" key={certificate.certificate_id}>
            <CertificateCard certificateId={certificate.certificate_id} />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div>
      <CertificateTable certificates={certificates} />
    </div>
  );
}

Content.propTypes = {
  certificates: PropTypes.arrayOf(certificateSummaryPropType).isRequired,
};

function CertificateList({ certificates, isLoading }) {
  const searchParams = useSearchParams()[0];
  const [searchQuery, setSearchQuery] = useState(null);

  useEffect(() => {
    if (searchParams) {
      const q = searchParams.get("query");
      if (q) {
        setSearchQuery(decodeURI(q));
      }
    } else {
      setSearchQuery(null);
    }

    return () => {
      setSearchQuery(null);
    };
  }, [searchParams]);

  let body;

  if (isLoading) {
    body = (
      <div className="d-flex justify-content-center align-items-center">
        <LoadingSpinner />
      </div>
    );
  } else {
    body = (
      <div>
        {searchQuery && (
          <p>
            Showing certificates matching <b>{searchQuery}</b>
          </p>
        )}
        {certificates && Array.isArray(certificates) && certificates.length === 0 && <p className="alert alert-info">No certificate matching this criteria found.</p>}
        {certificates && Array.isArray(certificates) && certificates.length > 0 && <Content certificates={certificates} />}
      </div>
    );
  }
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center pb-4 mb-5 border-bottom">
        <div className="d-flex align-items-center gap-2">
          <Link className="btn btn-primary fw-bold text-uppercase" to="/dashboard/certificate-of-origin/generate/">
            Create certificate
          </Link>
          <Link to="/dashboard/" className="btn btn-outline-primary fw-bold text-uppercase">
            Qualification History
          </Link>
        </div>

        <div className="d-flex align-items-center gap-2">
          <SearchButton />
          <SortByDropdown />
          <ToggleViewButton />
        </div>
      </div>
      {body}
    </div>
  );
}

CertificateList.propTypes = {
  certificates: PropTypes.arrayOf(certificateSummaryPropType).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default CertificateList;
