import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { useCreateBOMMutation } from "../../../../features/api/boms";
import { setBomId } from "../../../../features/bomWizard/bomWizardSlice";
import {
  addSuccessfulImport,
  currencySelector,
  currentBOMImportIndexSelector,
  currentUnparsedBOMSelector,
  mappedComponentHeaders,
  mappedFinishedGoodHeaders,
  setSubmittingToApi,
  unparsedBOMsSelector,
} from "../../../../features/fileImport/fileImportSlice";
import { validateComponents, validateFinishedGood } from "../utils";

const INITIAL_FINISHED_GOOD_VALIDATION = {
  done: false,
  isValid: null,
  message: null,
  validData: null,
};

const INITIAL_COMPONENTS_VALIDATION = {
  done: false,
  isValid: null,
  message: null,
};

export default function useContinue() {
  const [createBOM, { isSuccess, isError, data: newBom }] = useCreateBOMMutation();
  const currency = useSelector(currencySelector);
  const dispatch = useDispatch();
  const [csvFinishedGood, setCsvFinishedGood] = useState(null);
  const [csvComponents, setCsvComponents] = useState(null);
  const currentUnparsedBOM = useSelector(currentUnparsedBOMSelector);
  const matchedFinishedGoodHeaders = useSelector(mappedFinishedGoodHeaders);
  const matchedComponentHeaders = useSelector(mappedComponentHeaders);
  const [finishedGoodValidation, setFinishedGoodValidation] = useState(INITIAL_FINISHED_GOOD_VALIDATION);
  const [componentsValidation, setComponentsValidation] = useState(INITIAL_COMPONENTS_VALIDATION);
  const [validComponents, setValidComponents] = useState(null);
  const navigate = useNavigate();
  const unparsedBoms = useSelector(unparsedBOMsSelector);
  const currentImportIndex = useSelector(currentBOMImportIndexSelector);
  const [nextAction, setNextAction] = useState(null);
  useEffect(() => {
    if (currentUnparsedBOM) {
      setCsvFinishedGood(currentUnparsedBOM.find((row) => row.Item === "FG"));
      setCsvComponents(currentUnparsedBOM.filter((row) => row.item === "Component"));
    }

    return () => {
      setCsvFinishedGood(null);
      setCsvComponents(null);
    };
  }, [currentUnparsedBOM]);

  const handleContinue = useCallback(() => {
    dispatch(setSubmittingToApi(true));
    const fg = {
      partNumber: csvFinishedGood[matchedFinishedGoodHeaders.partNumber],
      hts: csvFinishedGood[matchedFinishedGoodHeaders.hts],
      description: csvFinishedGood[matchedFinishedGoodHeaders.description],
      transactionValue: csvFinishedGood[matchedFinishedGoodHeaders.transactionValue],
      netCost: csvFinishedGood[matchedFinishedGoodHeaders.netCost],
      totalCost: csvFinishedGood[matchedFinishedGoodHeaders.totalCost],
      origin: csvFinishedGood[matchedFinishedGoodHeaders.origin],
    };
    validateFinishedGood(fg)
      .then((validFg) => {
        setFinishedGoodValidation({ done: true, isValid: true, validData: validFg });
      })
      .catch((err) => {
        setFinishedGoodValidation({ done: true, isValid: false, message: err.message });
      });
  }, [dispatch, csvFinishedGood, matchedFinishedGoodHeaders]);

  useEffect(() => {
    if (finishedGoodValidation && finishedGoodValidation.done) {
      if (finishedGoodValidation.isValid) {
        validateComponents(csvComponents, matchedComponentHeaders)
          .then(() => {
            setComponentsValidation({
              done: true,
              isValid: true,
            });
            const unparsedComponents = currentUnparsedBOM.filter((row) => row.Item === "Component");
            setValidComponents(
              unparsedComponents.map((component) => ({
                category: component[matchedComponentHeaders.category],
                hts: component[matchedComponentHeaders.hts],
                part_number: component[matchedComponentHeaders.partNumber],
                description: component[matchedComponentHeaders.description],
                origin: component[matchedComponentHeaders.origin],
                qty: component[matchedComponentHeaders.qty],
                units: component[matchedComponentHeaders.units],
                cost: component[matchedComponentHeaders.unitCost],
                originating: component[matchedComponentHeaders.originating],
                questions: [],
              })),
            );
          })
          .catch((err) => {
            setComponentsValidation({
              done: true,
              isValid: false,
              message: err.message,
            });
          });
      } else {
        Swal.fire({
          title: "Error!",
          text: `Invalid finished good. ${finishedGoodValidation.message}`,
          icon: "error",
        }).then(() => {
          dispatch(setSubmittingToApi(false));
        });
      }
    }

    return () => {
      setComponentsValidation(INITIAL_COMPONENTS_VALIDATION);
    };
  }, [finishedGoodValidation, currentUnparsedBOM, dispatch, csvComponents, matchedComponentHeaders]);

  useEffect(() => {
    if (componentsValidation && componentsValidation.done) {
      if (componentsValidation.isValid) {
        const requestBody = {
          currency,
          fg: {
            part_number: finishedGoodValidation.validData.partNumber,
            description: finishedGoodValidation.validData.description,
            hts: finishedGoodValidation.validData.hts,
            origin: finishedGoodValidation.validData.origin,
            transaction_value: finishedGoodValidation.validData.transactionValue,
            net_cost: finishedGoodValidation.validData.netCost,
            total_cost: finishedGoodValidation.validData.totalCost,
            questions: [],
          },
          components: validComponents,
        };
        createBOM(requestBody);
      } else {
        Swal.fire({
          title: "Error!",
          text: componentsValidation.message,
          icon: "error",
        }).then(() => {
          dispatch(setSubmittingToApi(false));
        });
      }
    }
  }, [componentsValidation, finishedGoodValidation, currency, createBOM, dispatch, validComponents]);

  useEffect(() => {
    if (isSuccess && newBom) {
      dispatch(setSubmittingToApi(false));
      dispatch(setBomId(newBom.id));
      dispatch(addSuccessfulImport({ id: newBom.dispatch }));
      const hasUnparsed = unparsedBoms && Array.isArray(unparsedBoms) && unparsedBoms.length > 0;
      const importsRemaining = unparsedBoms.length - (currentImportIndex + 1);
      if (hasUnparsed && importsRemaining > 0) {
        setNextAction("continue");
      } else if (unparsedBoms && unparsedBoms.length > 1 && importsRemaining === 0) {
        setNextAction("summary");
      } else {
        setNextAction("detail");
      }
    }
  }, [isSuccess, navigate, newBom, dispatch, unparsedBoms, currentImportIndex]);

  useEffect(() => {
    if (nextAction) {
      navigate("/dashboard/import-bom-csv/finish/", { state: { bomId: newBom.id, action: nextAction } });
    }
  }, [nextAction, navigate, newBom]);

  useEffect(() => {
    if (isError) {
      Swal.fire({
        title: "Error!",
        text: "Failed to create BOM.",
      }).then(() => {
        setSubmittingToApi(false);
      });
    }
  }, [isError]);

  return useMemo(() => ({ handleContinue }), [handleContinue]);
}
