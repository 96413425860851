import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { PiDownloadSimple } from "react-icons/pi";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { useListTransactionsQuery } from "../../features/api/payments";

function TransactionRow({ transaction, transactionCount, transactionIdx }) {
  const [paddingClass, setPaddingClass] = useState("py-2");

  useEffect(() => {
    if (typeof transactionIdx !== "undefined" && typeof transactionCount !== "undefined") {
      if (transactionIdx === transactionCount - 1) {
        setPaddingClass("pb-4 pt-2");
      } else if (transactionIdx === 0) {
        setPaddingClass("pt-4 pb-2");
      } else {
        setPaddingClass("py-2");
      }
    } else {
      setPaddingClass("py-2");
    }

    return () => {
      setPaddingClass("py-2");
    };
  }, [transactionCount, transactionIdx]);
  return (
    <>
      <p className={`border-end text-center m-0 ${paddingClass}`}>{dayjs(transaction.billed_at).format("MMM D YYYY")}</p>
      <p className={`d-flex flex-wrap gap-2 justify-content-center m-0 border-end ${paddingClass}`}>
        {transaction.items.map((transactionItem, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <span key={idx}>
            {transactionItem.name} x {transactionItem.qty}
            {idx !== transaction.items.length - 1 && ","}
          </span>
        ))}
      </p>
      <p className={`m-0 border-end ${paddingClass} text-center`}>$ {parseInt(transaction.cost, 10) / 100}</p>
      <button
        onClick={() => window.open(transaction.pdf_url, "_blank")}
        type="button"
        className={`d-flex justify-content-center text-decoration-none text-secondary text-uppercase fw-bold align-items-center gap-2 p-0 btn-link btn m-0 ${paddingClass} text-center`}
      >
        <PiDownloadSimple />
        Download
      </button>
    </>
  );
}

TransactionRow.propTypes = {
  transaction: PropTypes.shape({
    billed_at: PropTypes.string,
    id: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, qty: PropTypes.number })),
    cost: PropTypes.string,
    pdf_url: PropTypes.string,
  }).isRequired,
  transactionCount: PropTypes.number.isRequired,
  transactionIdx: PropTypes.number.isRequired,
};

function InvoiceHistory() {
  const { data: transactions, isLoading } = useListTransactionsQuery(null, { refetchOnMountOrArgChange: true });

  let body;

  if (isLoading) {
    body = (
      <div className="d-flex justify-content-center">
        <LoadingSpinner />
      </div>
    );
  } else if (transactions && Array.isArray(transactions) && transactions.length === 0) {
    body = <p className="alert alert-info text-center">You haven&apos;t purchased any BOMs yet.</p>;
  } else if (transactions && Array.isArray(transactions) && transactions.length > 0) {
    body = (
      <div style={{ gridTemplateColumns: "1fr 2fr 1fr 1fr" }} className="d-grid border rounded">
        <p className="text-center bg-light-2 py-2 border-end m-0 fw-bold">Date</p>
        <p className="text-center bg-light-2 py-2 border-end m-0 fw-bold">Items</p>
        <p className="text-center bg-light-2 py-2 border-end m-0 fw-bold">Cost</p>
        <p className="text-center bg-light-2 py-2 m-0 fw-bold">Invoice</p>
        {transactions.map((transaction, idx) => (
          <TransactionRow key={transaction.id} transaction={transaction} transactionCount={transactions.length} transactionIdx={idx} />
        ))}
      </div>
    );
  }
  return (
    <div>
      <h2 className="mb-5">Invoice History</h2>
      {body}
    </div>
  );
}

export default InvoiceHistory;
