import React from "react";
import { PiUserCircle } from "react-icons/pi";
import { useSelector } from "react-redux";

import { avatarDataUriSelector } from "../../features/customerProfile/customerProfileSlice";

function CustomerAvatarImage() {
  const avatarImg = useSelector(avatarDataUriSelector);

  if (avatarImg) {
    return <img className="img-fluid rounded-circle" src={avatarImg} alt="Your avatar" />;
  }
  return <PiUserCircle style={{ width: "100%", height: "100%" }} />;
}

export default CustomerAvatarImage;
