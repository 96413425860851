import api from "./apiSlice";

const storageApi = api.injectEndpoints({
  endpoints(build) {
    return {
      getPresignedPost: build.query({
        query({ key }) {
          return {
            url: `/extras/api/v1/storage/presigned-upload-url/${key}/`,
          };
        },
        providesTags: (result, error, { key }) => {
          if (result) {
            return [{ type: "PresignedPost", id: key }];
          }
          return [];
        },
      }),
    };
  },
});

export const { useGetPresignedPostQuery } = storageApi;
