import * as Sentry from "@sentry/react";
import { Auth } from "aws-amplify";
import { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import useAlreadyLoggedIn from "../../hooks/useAlreadyLoggedIn";

export default function useConfirmRegistration() {
  useAlreadyLoggedIn();
  const location = useLocation();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState(null);
  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      setErrorMsg(null);
      setSubmitting(true);
      Auth.confirmSignUp(location?.state?.username || values.username, values.code)
        .then(() => {
          Swal.fire({
            title: "Success!",
            text: "Your account has been confirmed.",
            icon: "success",
            confirmButtonText: "Login Now",
          }).then(() => {
            navigate("/login");
          });
        })
        .catch((err) => {
          setErrorMsg("We couldn't confirm your account. Please try again.");
          Sentry.captureException(err);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [location, navigate],
  );

  return useMemo(
    () => ({
      handleSubmit,
      errorMsg,
    }),
    [handleSubmit, errorMsg],
  );
}
