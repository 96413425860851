import React from "react";

import PageHeader from "../../components/PageHeader/PageHeader";
import Body from "./components/Body/Body";
import useDashboard from "./hooks/useDashboard";

export default function Dashboard() {
  const { pageTitle } = useDashboard();

  return (
    <>
      <div style={{ marginBottom: "20vh" }} className="row">
        <PageHeader title={pageTitle} />
      </div>
      <div className="row">
        <Body />
      </div>
    </>
  );
}
