import { useEffect, useMemo, useState } from "react";

export default function useProducers(certificate) {
  const [producerTypeText, setProducerTypeText] = useState(null);
  const [hasProducers, setHasProducers] = useState(false);
  useEffect(() => {
    if (certificate) {
      if (certificate.producer_data_type === "single") {
        setProducerTypeText("Single Producer");
        setHasProducers(true);
      } else if (certificate.producer_data_type === "list") {
        setProducerTypeText("List of Producers");
        setHasProducers(true);
      } else if (certificate.producer_data_type === "various") {
        setProducerTypeText("Various Producers");
        setHasProducers(false);
      } else if (certificate.producer_data_type === "request") {
        setProducerTypeText("Available upon request by importing authorities.");
        setHasProducers(false);
      }
    } else {
      setProducerTypeText(null);
    }

    return () => {
      setProducerTypeText(null);
      setHasProducers(false);
    };
  }, [certificate]);

  return useMemo(() => ({ producerTypeText, hasProducers }), [producerTypeText, hasProducers]);
}
