import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useGetCustomerQuery } from "../../features/api/customers";
import { currentLoginStateSelector } from "../../features/auth/authSlice";
import useInitialState from "../../hooks/useInitialState";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

export default function PrivateRoute() {
  const { loading } = useInitialState();
  const isLoggedIn = useSelector(currentLoginStateSelector);
  const location = useLocation();
  const { isError: customerIsError, error: customerError } = useGetCustomerQuery();

  if (loading) {
    return (
      <div style={{ marginTop: "20vh" }} className="d-flex  justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (isLoggedIn) {
    if (customerIsError) {
      if (customerError.status === 404) {
        if (location.pathname.includes("complete-profile")) {
          return <Outlet />;
        }
        return <Navigate to="/dashboard/complete-profile" replace />;
      }
      throw new Error("Failed to fetch profile.");
    }
    return <Outlet />;
  }

  return <Navigate to="/login" state={{ from: location.pathname }} />;
}
