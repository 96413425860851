import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { csvHeadersSelector, setHeaderValidation, setHeaders, setShowHeaderConfirmationModal } from "../../../features/fileImport/fileImportSlice";

const DEFAULT_VALIDATION_STATE = { done: false, isValid: null };

function checkHeaders(importedHeaders) {
  let isValid = true;
  const requiredHeaders = ["Item", "PartNumber", "HTS", "Desc", "Qty", "Units", "TransactionValue", "COO", "Originating", "UnitCost", "Category", "NetCost", "TotalCost"];
  if (importedHeaders.length !== requiredHeaders.length) {
    isValid = false;
  }
  importedHeaders.forEach((header) => {
    if (requiredHeaders.indexOf(header) === -1) {
      isValid = false;
    }
  });
  return isValid;
}

export default function useHeaderConfirmationModal() {
  const dispatch = useDispatch();
  const parsedHeaders = useSelector(csvHeadersSelector);

  const handleConfirmHeaders = useCallback(
    (_, { setSubmitting }) => {
      setSubmitting(true);
      dispatch(setHeaderValidation(DEFAULT_VALIDATION_STATE));
      const headersValid = checkHeaders(parsedHeaders);
      if (headersValid) {
        dispatch(setHeaders(parsedHeaders));
        dispatch(setHeaderValidation({ done: true, isValid: true }));
        dispatch(setShowHeaderConfirmationModal(false));
      } else {
        dispatch(setHeaderValidation({ done: true, isValid: false }));
      }
      setSubmitting(false);
    },
    [parsedHeaders, dispatch],
  );

  return useMemo(() => ({ handleConfirmHeaders }), [handleConfirmHeaders]);
}
