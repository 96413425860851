import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useCloseBomMutation } from "../../features/api/boms";
import { bomIdSelector, clearState } from "../../features/bomWizard/bomWizardSlice";
import { setShow, toggleModal } from "../../features/qualificationModal/qualificationModalSlice";
import useEncodedBomId from "../../hooks/useEncodedBomId";

export default function useSubmitForQualification() {
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const closeBom = useCloseBomMutation()[0];
  const bomId = useSelector(bomIdSelector);
  const navigate = useNavigate();
  const { encodedBomId } = useEncodedBomId(bomId);

  const handleCancel = useCallback(() => {
    dispatch(clearState());
    dispatch(toggleModal());
  }, [dispatch]);

  const handleSubmit = useCallback(() => {
    setErrorMsg(null);
    setSubmitting(true);
    closeBom({ BOMID: bomId })
      .then(() => {
        setSubmitting(false);
        dispatch(clearState());
        dispatch(setShow(false));
        navigate(`/dashboard/bom/${encodedBomId}`);
      })
      .catch((err) => {
        setErrorMsg(JSON.stringify(err.data || "Failed to submit. Please try again."));
      })
      .finally(() => {
        setSubmitting(false);
      });
  }, [closeBom, bomId, dispatch, navigate, encodedBomId]);

  return useMemo(
    () => ({
      handleCancel,
      handleSubmit,
      errorMsg,
      submitting,
    }),
    [handleCancel, handleSubmit, errorMsg, submitting],
  );
}
