import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { PiDownload } from "react-icons/pi";

import BootstrapSVG from "../../components/BootstrapSVG";
import CustomerProfileForm from "../../components/CustomerProfileForm/CustomerProfileForm";
import DataExportModal from "../../components/DataExportModal";
import PageHeader from "../../components/PageHeader/PageHeader";
import { useGetCustomerQuery } from "../../features/api/customers";
import Avatar from "./Avatar/Avatar";
import ChangePasswordModal from "./ChangePasswordModal";
import DeleteAccountModal from "./DeleteAccountModal";

export default function CustomerProfile() {
  const [creationDate, setCreationDate] = useState(null);
  const { data: customer } = useGetCustomerQuery();
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const [showDataDownload, setShowDataDownload] = useState(false);

  useEffect(() => {
    if (customer) {
      const d = dayjs(customer.created_at);
      const day = d.format("DD");
      const dayString = day.toString();
      const endsWith = dayString[dayString.length - 1];
      let daySuffix;
      if (endsWith === "1") {
        daySuffix = "st";
      } else if (endsWith === "2") {
        daySuffix = "nd";
      } else if (endsWith === "3") {
        daySuffix = "rd";
      } else {
        daySuffix = "th";
      }
      setCreationDate({
        day: d.format("D"),
        month: d.format("MMM"),
        year: d.format("YYYY"),
        daySuffix,
      });
    }

    return () => {
      setCreationDate(null);
    };
  }, [customer]);

  return (
    <>
      <div className="row mb-5">
        <PageHeader title="Account" />
      </div>
      <div className="row mb-5">
        <div className="col">
          <Avatar />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h3 className="mb-4">Profile Information</h3>
          <div className="mb-5">
            <CustomerProfileForm />
          </div>
          <div>
            <h3 className="mb-4">Account Information</h3>
            <button onClick={() => setShowChangePassword(true)} type="button" className="btn btn-secondary icon-btn w-100 mb-3">
              <BootstrapSVG iconName="key" />
              Change password
            </button>
            <button onClick={() => setShowDataDownload(true)} type="button" className="btn btn-outline-secondary icon-btn w-100 mb-3">
              <PiDownload />
              Export your data
            </button>
            <div>
              {creationDate && (
                <p>
                  Account created on the {creationDate.day}
                  {creationDate.daySuffix} of {creationDate.month} {creationDate.year}.
                </p>
              )}
              <button onClick={() => setShowDeleteAccount(true)} type="button" className="btn btn-danger icon-btn w-100">
                <BootstrapSVG iconName="trash" />
                Delete account
              </button>
            </div>
          </div>
        </div>
      </div>
      {showDataDownload && <DataExportModal onHide={() => setShowDataDownload(false)} />}
      {showChangePassword && <ChangePasswordModal onHide={() => setShowChangePassword(false)} />}
      {showDeleteAccount && <DeleteAccountModal onHide={() => setShowDeleteAccount(false)} />}
    </>
  );
}
