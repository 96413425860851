import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { userDropdownStateSelector } from "../../../../features/ui/mainSidebarSlice";
import useLogout from "../../../../hooks/useLogout";
import BootstrapSVG from "../../../BootstrapSVG";

function LogOutButton() {
  const logOut = useLogout();
  return (
    <button onClick={logOut} type="button" className="btn w-100 icon-btn justify-content-start">
      <BootstrapSVG iconName="box-arrow-right" />
      Logout
    </button>
  );
}

function DropdownItems() {
  const dropDownActive = useSelector(userDropdownStateSelector);
  return (
    <div className={`main-sidebar__user-dropdown-items ${!dropDownActive ? "main-sidebar__user-dropdown-items--hidden" : ""} d-flex flex-column rounded-bottom`}>
      <Link to="/dashboard/customer/" className="btn w-100 icon-btn justify-content-start">
        <BootstrapSVG iconName="gear" />
        Settings
      </Link>
      <LogOutButton />
    </div>
  );
}

export default DropdownItems;
