import { useFormikContext } from "formik";
import { useEffect, useState } from "react";

function useInsertHtsDots(htsFieldName, htsFieldValue) {
  const { setFieldValue } = useFormikContext();
  const [hts, setHts] = useState("");

  useEffect(() => {
    if (htsFieldValue) {
      setHts((currentHts) => {
        if (htsFieldValue.length === 4 || htsFieldValue.length === 7) {
          if (currentHts[currentHts.length - 1] !== ".") {
            return `${htsFieldValue}.`;
          }
        } else if (htsFieldValue.length >= 9) {
          const dotsFound = htsFieldValue.match(/\./g);
          if (dotsFound === null) {
            const newHts = `${htsFieldValue.slice(0, 4)}.${htsFieldValue.slice(4, 6)}.${htsFieldValue.slice(6)}`;
            return htsFieldValue.length === 9 ? `0${newHts}` : newHts;
          }
        }
        return htsFieldValue;
      });
    }
  }, [htsFieldValue]);

  useEffect(() => {
    if (hts && setFieldValue && htsFieldName) {
      setFieldValue(htsFieldName, hts);
    }
  }, [hts, setFieldValue, htsFieldName]);
}

export default useInsertHtsDots;
