import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { currentUnparsedBOMSelector } from "../../../../features/fileImport/fileImportSlice";

export default function useFileDataRequired() {
  const currentUnparsedBOM = useSelector(currentUnparsedBOMSelector);
  const navigate = useNavigate();
  useEffect(() => {
    if (!currentUnparsedBOM) {
      Swal.fire({
        title: "Error!",
        text: "CSV data not found. Please re-upload your file.",
        icon: "error",
      }).then(() => {
        navigate("/dashboard/import-bom-csv/", { replace: true });
      });
    }
  }, [currentUnparsedBOM, navigate]);
}
