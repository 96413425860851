import print from "print-js";
import React, { useCallback } from "react";
import { Modal } from "react-bootstrap";
import { PiFilePdf, PiPrinter } from "react-icons/pi";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import tosPdf from "../../data/terms-of-service.pdf";
import { useRegistrationContext } from "./RegistrationContext";

function LegalTextModal() {
  const { showTermsModal: show, setShowTermsModal: setShow, setAgreedTos, agreedTos, legalText } = useRegistrationContext();

  const handleAgreeTos = useCallback(
    (e) => {
      const { checked } = e.target;
      if (checked) {
        setAgreedTos(true);
      } else {
        setAgreedTos(false);
      }
    },
    [setAgreedTos],
  );

  const handleHide = useCallback(() => {
    setShow(false);
  }, [setShow]);

  return (
    <Modal show={show} centered onHide={handleHide} size="lg" scrollable>
      <Modal.Header closeButton>
        <Modal.Title>
          <h3>Terms of Service</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card">
          <div className="card-body">
            {legalText ? (
              <Markdown linkTarget="__blank" rehypePlugins={[rehypeRaw]}>{legalText}</Markdown>
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                <LoadingSpinner />
              </div>
            )}
          </div>
          <div className="card-footer d-flex justify-content-between align-items-center">
            <div className="form-check">
              <input onChange={handleAgreeTos} id="radioAgreeTos" type="checkbox" className="form-check-input" checked={agreedTos} />
              <label htmlFor="radioAgreeTos" className="form-check-label fw-bold">
                I agree to the Terms of Service
              </label>
            </div>
            <div className="d-flex align-items-center gap-2">
              <button onClick={() => window.open(tosPdf, "_blank")} type="button" className="btn btn-outline-secondary d-flex align-items-center gap-1">
                <PiFilePdf />
                Download PDF
              </button>
              <button onClick={() => print(tosPdf)} type="button" className="btn btn-outline-secondary d-flex align-items-center gap-1">
                <PiPrinter />
                Print
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          title={agreedTos ? "Agree to our Terms of Service" : "Please read the whole text and check the checkbox at the bottom to continue."}
          disabled={!agreedTos}
          type="button"
          onClick={handleHide}
          className="btn btn-primary w-100"
        >
          Agree & Continue
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default LegalTextModal;
