import PropTypes from "prop-types";

export default PropTypes.shape({
  BOMID: PropTypes.string,
  BOM_State: PropTypes.string,
  SubmitTS: PropTypes.string,
  Qualified: PropTypes.string,
  QualPref: PropTypes.string,
  QualUnder: PropTypes.string,
  QualNotes: PropTypes.string,
  FG: PropTypes.shape({
    Hts: PropTypes.string,
    Part: PropTypes.string,
    Desc: PropTypes.string,
    Value: PropTypes.string,
    TotalCost: PropTypes.string,
    NetCost: PropTypes.string,
    Currency: PropTypes.string,
    Origin: PropTypes.string,
  }),
  FG_Qs_Length: PropTypes.number,
  FG_Qs: PropTypes.arrayOf(
    PropTypes.shape({
      RuleID: PropTypes.string,
      Question: PropTypes.string,
      AnsLabel: PropTypes.string,
      Ans: PropTypes.oneOf(["Y", "N"]),
    }),
  ),
  Comp_Qs_Length: PropTypes.number,
  Comp_Qs: PropTypes.arrayOf(
    PropTypes.shape({
      RuleID: PropTypes.string,
      Question: PropTypes.string,
      AnsLabel: PropTypes.string,
      Ans: PropTypes.oneOf(["Y", "N"]),
    }),
  ),
  Length: PropTypes.number,
  Components: PropTypes.arrayOf(
    PropTypes.shape({
      LineItem: PropTypes.string,
      Hts: PropTypes.string,
      Part: PropTypes.string,
      Origin: PropTypes.string,
      Qty: PropTypes.string,
      Unit: PropTypes.string,
      Cost: PropTypes.string,
      USMCA: PropTypes.oneOf(["Y", "N"]),
    }),
  ),
});
