import React from "react";
import { useSelector } from "react-redux";

import { currentIdTokenPayloadSelector } from "../../features/auth/authSlice";
import { bomIdSelector, defaultCurrencySelector, finishedGoodSelector } from "../../features/bomWizard/bomWizardSlice";
import unpackHts from "../../utils/unpackHts";

export default function BOMDetails() {
  const bomId = useSelector(bomIdSelector);
  const currency = useSelector(defaultCurrencySelector);
  const finishedGood = useSelector(finishedGoodSelector);
  const idTokenPayload = useSelector(currentIdTokenPayloadSelector);
  return (
    <div className="card mb-3">
      <div className="card-header">
        <div className="card-title m-0">
          <h2 className="text-center m-0">BOM Details</h2>
        </div>
      </div>
      <div className="card-body">
        <div className="d-flex flex-wrap gap-5">
          <div>
            <p>
              <strong>BOM REFERENCE</strong>
            </p>
            <p>{bomId}</p>
          </div>
          <div>
            <p>
              <strong>USER</strong>
            </p>
            <p>{idTokenPayload["cognito:username"]}</p>
          </div>

          <div>
            <p>
              <strong>DESCRIPTION</strong>
            </p>
            <p>{finishedGood?.Desc}</p>
          </div>

          <div>
            <p>
              <strong>Producer</strong>
            </p>
            <p>{finishedGood?.Producer}</p>
          </div>

          <div>
            <p>
              <strong>USHTS Classification</strong>
            </p>
            <p>{finishedGood?.Hts ? unpackHts(finishedGood.Hts) : ""}</p>
          </div>

          <div>
            <p>
              <strong>Value ({currency})</strong>
            </p>
            <p>{finishedGood?.Value}</p>
          </div>

          <div>
            <p>
              <strong>COO</strong>
            </p>
            <p>{finishedGood?.Origin}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
