import React from "react";
import { Link } from "react-router-dom";

import MainNavbar from "../components/MainNavbar/MainNavbar";

export default function PageNotFound() {
  return (
    <div>
      <header className="p-3 mb-5">
        <MainNavbar />
      </header>
      <div className="container">
        <div className="row">
          <div className="col-lg-3" />
          <div className="col-lg-6">
            <h1 className="text-center mb-5">Page not found</h1>
            <p className="alert alert-warning text-center mb-5">The page you're looking for either never existed or no longer exists.</p>
            <div className="d-flex justify-content-center">
              <Link className="btn btn-primary" to="/">
                Back to home
              </Link>
            </div>
          </div>
          <div className="col-lg-3" />
        </div>
      </div>
    </div>
  );
}
