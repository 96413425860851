import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  csvHeadersSelector,
  currentUnparsedBOMSelector,
  parsedComponents as parsedComponentsSelector,
  parsedFinishedGood as parsedFinishedGoodSelector,
  setHeaders,
  setParsedComponents,
  setParsedFinishedGood,
  setParsingDone,
} from "../../../features/fileImport/fileImportSlice";

export default function useParseFileData() {
  const dispatch = useDispatch();
  const currentUnparsedBOM = useSelector(currentUnparsedBOMSelector);
  const parsedHeaders = useSelector(csvHeadersSelector);
  const parsedFinishedGood = useSelector(parsedFinishedGoodSelector);
  const parsedComponents = useSelector(parsedComponentsSelector);

  const parseHeaders = useCallback(() => {
    dispatch(setHeaders(Object.keys(currentUnparsedBOM[0])));
  }, [currentUnparsedBOM, dispatch]);

  const extractFinishedGood = useCallback(() => {
    const fg = currentUnparsedBOM.find((data) => data.Item === "FG");
    dispatch(setParsedFinishedGood(Object.values(fg).map((val) => String(val))));
  }, [currentUnparsedBOM, dispatch]);

  const extractComponents = useCallback(() => {
    const components = currentUnparsedBOM.filter((data) => data.Item === "Component");
    dispatch(
      setParsedComponents(
        components.map((component) => {
          const result = Object.values(component).map((val) => String(val));
          return result;
        }),
      ),
    );
  }, [currentUnparsedBOM, dispatch]);

  useEffect(() => {
    if (currentUnparsedBOM && parseHeaders) {
      parseHeaders();
    }
  }, [currentUnparsedBOM, parseHeaders]);

  useEffect(() => {
    if (parsedHeaders) {
      extractFinishedGood();
      extractComponents();
    }
  }, [parsedHeaders, extractComponents, extractFinishedGood]);

  useEffect(() => {
    if (currentUnparsedBOM && parsedHeaders && parsedComponents && parsedFinishedGood) {
      dispatch(setParsingDone(true));
    } else {
      dispatch(setParsingDone(false));
    }
  }, [currentUnparsedBOM, parsedHeaders, dispatch, parsedComponents, parsedFinishedGood]);
}
