import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { FILE_IMPORT_HEADER_CONFIRMATION_STEP, FILE_IMPORT_INITIAL_STEP, FILE_MATCH_FIELDS_STEP } from "../../../constants";
import {
  currencySelector,
  currentStepSelector,
  headerValidation as headerValidationSelector,
  parsingDone as parsingDoneSelector,
  setCurrentStep,
  setShowChooseCurrencyModal,
  setShowHeaderConfirmationModal,
} from "../../../features/fileImport/fileImportSlice";

export default function useImportManager() {
  const parsingDone = useSelector(parsingDoneSelector);
  const currentStep = useSelector(currentStepSelector);
  const headerValidation = useSelector(headerValidationSelector);
  const currency = useSelector(currencySelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (parsingDone && currentStep === FILE_IMPORT_INITIAL_STEP) {
      dispatch(setCurrentStep(FILE_IMPORT_HEADER_CONFIRMATION_STEP));
    }
  }, [parsingDone, currentStep, dispatch]);

  useEffect(() => {
    if (dispatch && currentStep === FILE_IMPORT_HEADER_CONFIRMATION_STEP) {
      dispatch(setShowHeaderConfirmationModal(true));
    }
  }, [currentStep, dispatch]);

  useEffect(() => {
    if (dispatch && headerValidation && headerValidation.done && headerValidation.isValid) {
      dispatch(setShowChooseCurrencyModal(true));
    }
  }, [headerValidation, dispatch]);

  useEffect(() => {
    if (currency && currentStep === FILE_IMPORT_HEADER_CONFIRMATION_STEP) {
      dispatch(setCurrentStep(FILE_MATCH_FIELDS_STEP));
    }
  }, [currency, dispatch, currentStep]);

  useEffect(() => {
    if (currentStep === FILE_MATCH_FIELDS_STEP) {
      navigate("/dashboard/import-bom-csv/match-csv-fields/", { replace: true });
    }
  }, [currentStep, navigate]);
}
