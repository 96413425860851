/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { Link } from "react-router-dom";

import ProgressDots from "../../components/ProgressDots/ProgressDots";
import leftArrow from "../../images/left-arrow.svg";
import LegalTextModal from "./LegalTextModal";
import { RegistrationContextProvider } from "./RegistrationContext";
import RegistrationForm from "./RegistrationForm";

export default function Registration() {
  return (
    <RegistrationContextProvider>
      <div>
        <div className="mb-5">
          <Link to="/auth/login" className="text-uppercase fw-bold text-primary d-flex gap-3 align-items-center text-decoration-none">
            <img src={leftArrow} alt="Left arrow" />
            Go Back
          </Link>
        </div>
        <h1 style={{ fontSize: "1.825rem" }} className="mb-5">
          Create your free account
        </h1>
        <p className="fw-bold mb-5">
          Already Registered?{" "}
          <Link className="text-uppercase text-decoration-none text-secondary" to="/auth/login">
            Sign In
          </Link>
        </p>
        <div className="mb-4">
          <RegistrationForm />
        </div>
        <div className="d-flex justify-content-center">
          <ProgressDots currentStep={0} stepCount={3} />
        </div>
        <LegalTextModal />
      </div>
    </RegistrationContextProvider>
  );
}
