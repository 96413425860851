import React from "react";
import { useNavigate } from "react-router-dom";

import closeCircleIcon from "../../images/close-circle.svg";
import progressionPropType from "./progressionPropType";

function ProgressZone({ progression }) {
  const navigate = useNavigate();
  return (
    <button
      type="button"
      onClick={() => navigate("/dashboard")}
      style={{ borderStyle: "dashed", borderWidth: "1.75px", height: "275px" }}
      className="btn p-5 rounded border-light-4 d-flex align-items-center justify-content-center w-100"
    >
      <div className="d-flex flex-column align-items-center gap-3">
        <img src={closeCircleIcon} alt="Close icon" />
        {progression.percentComplete < 100 ? <p className="m-0">Uploading &quot;{progression.fileName}&quot;</p> : <p className="m-0">File Successfully Uploaded</p>}
        <div>
          <div style={{ height: "10px", width: "20rem" }} className="progress mb-2">
            <div
              style={{ width: `${progression.percentComplete}%` }}
              className="progress-bar bg-secondary"
              role="progressbar"
              aria-valuenow={progression.percentComplete}
              aria-valuemin="0"
              aria-valuemax="100"
              aria-label="Progress bar"
            />
          </div>
          <div className="d-flex justify-content-between">
            <p className="m-0 text-muted">{progression.percentComplete}%</p>
            <p className="m-0 text-muted">{progression.timeRemaining}</p>
          </div>
        </div>
      </div>
    </button>
  );
}

ProgressZone.propTypes = {
  progression: progressionPropType.isRequired,
};

export default ProgressZone;
