import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { currentLoginStateSelector } from "../features/auth/authSlice";
import useInitialState from "./useInitialState";

export default function useAlreadyLoggedIn() {
  useInitialState();
  const isLoggedIn = useSelector(currentLoginStateSelector);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(location.state?.from || "/dashboard/create-bill-of-material");
    }
  }, [location, isLoggedIn, navigate]);
}
