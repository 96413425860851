import { nanoid } from "nanoid";
import React, { useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { clearErrors, errorsSelector } from "../features/app/appStateSlice";

function ErrorsModal() {
  const dispatch = useDispatch();
  const errors = useSelector(errorsSelector);
  const handleClose = useCallback(() => {
    dispatch(clearErrors());
  }, [dispatch]);
  return (
    <Modal show centered onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>
          <h5>Errors</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errors.map((error) => (
          <div key={nanoid()} className="alert alert-danger">
            <p className="m-0">{error}</p>
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" onClick={handleClose} className="btn btn-secondary w-100">
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ErrorsModal;
