import { nanoid } from "nanoid";
import PropTypes from "prop-types";
import React from "react";

import ellipsisFilledImg from "../../images/ellipsis-filled.svg";
import ellipsisImg from "../../images/ellipsis.svg";

function DotItem({ isActive }) {
  let img;
  if (isActive) {
    img = ellipsisFilledImg;
  } else {
    img = ellipsisImg;
  }

  return (
    <li>
      <img width={12} height={12} src={img} alt="Ellipsis" />{" "}
    </li>
  );
}

DotItem.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

function ProgressDots({ stepCount, currentStep }) {
  return (
    <ul className="list-unstyled d-flex gap-2">
      {Array.from(Array(stepCount)).map((_, idx) => (
        <DotItem isActive={currentStep === idx} key={nanoid()} />
      ))}
    </ul>
  );
}

ProgressDots.propTypes = {
  stepCount: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
};

export default ProgressDots;
