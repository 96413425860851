import { Field, Form, Formik } from "formik";
import { nanoid } from "nanoid";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import CustomerAvatarChanger from "../../components/CustomerAvatarChanger/CustomerAvatarChanger";
import customerProfileFormSchema from "../../components/CustomerProfileForm/customerProfileFormSchema";
import FormikFieldGroup from "../../components/FormikFieldGroup/FormikFieldGroup";
import ProgressDots from "../../components/ProgressDots/ProgressDots";
import countries from "../../data/countries.json";
import useIsLoggedIn from "../../hooks/useIsLoggedIn";
import useLogout from "../../hooks/useLogout";
import useCompleteProfile from "./useCompleteProfile";

export default function CompleteProfile() {
  const { handleSubmitCustomerData, currentAvatarImage, handleSetAvatarImage, avatarIsLoading, inProgress } = useCompleteProfile();
  const { loading: loginStateIsLoading, isLoggedIn } = useIsLoggedIn();
  const navigate = useNavigate();

  const logOut = useLogout();
  useEffect(() => {
    if (typeof loginStateIsLoading !== "undefined" && typeof isLoggedIn !== "undefined" && typeof navigate !== "undefined") {
      if (!loginStateIsLoading && !isLoggedIn) {
        navigate("/auth/register", { replace: true });
      }
    }
  }, [loginStateIsLoading, isLoggedIn, navigate]);

  return (
    <div>
      <h1 style={{ fontSize: "2rem" }} className="mb-5">
        Complete your profile
      </h1>
      <div className="mb-5">
        <CustomerAvatarChanger setImage={handleSetAvatarImage} currentImage={currentAvatarImage} isLoading={avatarIsLoading} />
      </div>
      <div className="mb-5">
        <Formik
          schema={customerProfileFormSchema}
          onSubmit={handleSubmitCustomerData}
          initialValues={{
            name: "",
            companyName: "",
            phoneNumber: "",
            streetAddress: "",
            city: "",
            state: "",
            country: "US",
            zipCode: "",
            taxId: "",
            subscriptionType: "payg",
          }}
        >
          {({ errors }) => (
            <Form>
              <div className="mb-3">
                <FormikFieldGroup name="name" placeholder="Ex. John Doe" label="Name*" type="text" required />
              </div>

              <div className="mb-3">
                <FormikFieldGroup name="companyName" label="Company Name*" placeholder="Ex. ACME Inc." type="text" required />
              </div>

              <div className="mb-3">
                <FormikFieldGroup name="phoneNumber" placeholder="Ex. +12087351358" label="Phone Number*" type="text" required />
              </div>

              <div className="mb-3">
                <FormikFieldGroup name="streetAddress" placeholder="Ex. 2971 Seltice Way" label="Street Address*" type="text" required />
              </div>

              <div className="mb-3">
                <FormikFieldGroup name="city" placeholder="Ex. Twin Falls" label="City*" type="text" required />
              </div>

              <div className="mb-3">
                <FormikFieldGroup name="state" placeholder="Ex. ID" label="State*" type="text" required />
              </div>

              <div className="mb-4">
                <label htmlFor="selectCountry" className="form-label">
                  Country*
                </label>
                <Field as="select" className={`form-select ${errors && errors.country ? "is-invalid" : ""}`} id="selectCountry" name="country">
                  {countries.map((country) => (
                    <option key={nanoid()} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </Field>
              </div>

              <div className="mb-3">
                <FormikFieldGroup name="zipCode" placeholder="Ex. 01701" type="text" required label="Zip Code*" />
              </div>

              <div className="d-flex align-items-center gap-2">
                <button onClick={() => logOut()} type="button" className="btn btn-outline-primary fw-bold text-uppercase">
                  Cancel & Go Back
                </button>
                <button disabled={inProgress} type="submit" className="btn btn-primary fw-bold text-uppercase flex-grow-1">
                  Save & Continue
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className="d-flex justify-content-center">
        <ProgressDots currentStep={2} stepCount={3} />
      </div>
    </div>
  );
}
