import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { showBomUsageLimitSelector } from "../../../../features/ui/mainLayoutSlice";
import BOMUsage from "../../../BOMUsage/BOMUsage";

function HeaderItems() {
  const showUsageLimit = useSelector(showBomUsageLimitSelector);

  if (!showUsageLimit) {
    return null;
  }

  return (
    <div className="d-flex gap-4 justify-content-between align-items-center">
      <BOMUsage />
      <Link to="/upgrade" className="btn btn-outline-primary fw-bold">
        Buy More
      </Link>
    </div>
  );
}

export default HeaderItems;
