import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import ErrorsModal from "../components/ErrorsModal";
import LoadingModal from "../components/LoadingModal/LoadingModal";
import MainNavbar from "../components/MainNavbar/MainNavbar";
import MainSidebar from "../components/MainSidebar/MainSidebar";
import SubmitForQualificationModal from "../components/SubmitForQualificationModal/SubmitForQualificationModal";
import { errorsSelector } from "../features/app/appStateSlice";
import useScrollToTop from "../hooks/useScrollToTop";
import useUpdateTotalBomsPurchased from "../hooks/useUpdateTotalBomsPurchased";
import useUpdateUsageLimits from "../hooks/useUpdateUsageLimits";

export default function MainLayout() {
  const errors = useSelector(errorsSelector);
  useScrollToTop();
  useUpdateUsageLimits();
  useUpdateTotalBomsPurchased();

  return (
    <div className="mb-5 vh-100">
      <MainNavbar />

      <div className="d-flex">
        <MainSidebar />
        <div className="flex-grow-1 pt-3 bg-light-1 pb-5">
          <div className="container">
            <Outlet />
          </div>
        </div>
      </div>
      {errors.length > 0 && <ErrorsModal />}
      <SubmitForQualificationModal />
      <LoadingModal />
    </div>
  );
}
