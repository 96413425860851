import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const BOM_STATES = {
  FG_QUALIFYING: {
    level: 0,
    redirectUrl: "/dashboard/create-bill-of-material/fg-questions/",
  },
  FG_COMPLETE: {
    level: 1,
    redirectUrl: "/dashboard/create-bill-of-material/component-details/",
  },
  COMPS_QUALIFYING: {
    level: 2,
    redirectUrl: "/dashboard/create-bill-of-material/component-questions/",
  },
  COMPS_COMPLETE: {
    level: 3,
    redirectUrl: "/dashboard/create-bill-of-material/submission/",
  },
  CLOSED: {
    level: 4,
    redirectUrl: "/dashboard/",
  },
};

export default function useBOMStateRequired(currentState, requiredState) {
  const navigate = useNavigate();
  useEffect(() => {
    if (currentState && !(currentState === requiredState)) {
      const { level: currentLevel } = BOM_STATES[currentState];
      const { level: requiredLevel } = BOM_STATES[requiredState];
      const { redirectUrl } = BOM_STATES[currentState];
      let message;

      if (requiredState === "FG_QUALIFYING" && currentLevel > requiredLevel) {
        message = "You already answered finished good questions. Please go to the next step.";
      } else if (requiredState === "FG_QUALIFYING" && currentLevel < requiredLevel) {
        message = "Please complete the previous steps first.";
      } else if (requiredState === "FG_COMPLETE" && currentLevel > requiredLevel) {
        message = "You already added components. Please progress to the next step";
      } else if (requiredState === "FG_COMPLETE" && currentLevel < requiredLevel) {
        message = "Please complete the previous steps first.";
      } else if (requiredState === "COMPS_QUALIFYING" && currentLevel > requiredLevel) {
        message = "You already answered component questions. Please progress to the next step.";
      } else if (requiredState === "COMPS_QUALIFYING" && currentLevel < requiredLevel) {
        message = "Please complete the previous steps first.";
      } else if (requiredState === "COMPS_COMPLETE" && currentLevel > requiredLevel) {
        message = "You already submitted this finished good for qualification.";
      } else if (requiredState === "COMPS_COMPLETE" && currentLevel < requiredLevel) {
        message = "Please complete the previous steps first.";
      } else if (requiredState === "CLOSED" && currentLevel < requiredLevel) {
        message = "Please complete the previous steps first.";
      } else {
        message = "BOM is in an invalid state. Please refresh the page.";
      }

      Swal.fire({
        title: "Error!",
        text: message,
        icon: "error",
        confirmButtonText: "Continue",
      }).then(() => {
        navigate(redirectUrl, { replace: true });
      });
    }
  }, [currentState, requiredState, navigate]);
}
