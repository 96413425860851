import * as Sentry from "@sentry/react";
import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import AppInitializer from "./components/AppInitializer/AppInitializer";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import AuthLayout from "./layouts/AuthLayout";
import MainLayout from "./layouts/MainLayout";
import UpgradeLayout from "./layouts/UpgradeLayout";
import BOMDetail from "./pages/BOMDetail/BOMDetail";
import BOMImporter from "./pages/BOMImporter/BOMImporter";
import FinishImport from "./pages/BOMImporter/FinishImport";
import ImportSummary from "./pages/BOMImporter/ImportSummary";
import MatchFileFields from "./pages/BOMImporter/MatchFileFields/MatchFileFields";
import CertifierForm from "./pages/CertificateOfOrigin/DetailForms/CertifierForm/CertifierForm";
import ExporterForm from "./pages/CertificateOfOrigin/DetailForms/ExporterForm/ExporterForm";
import ImporterForm from "./pages/CertificateOfOrigin/DetailForms/ImporterForm/ImporterForm";
import ProducerData from "./pages/CertificateOfOrigin/DetailForms/ProducerData/ProducerData";
import GenerateCertificateOfOrigin from "./pages/CertificateOfOrigin/GenerateCertificateOfOrigin/GenerateCertificateOfOrigin";
import Summary from "./pages/CertificateOfOrigin/Summary/Summary";
import CertificateOfOriginDetail from "./pages/CertificateOfOriginDetail/CertificateOfOriginDetail";
import CertificateOfOriginForm from "./pages/CertificateOfOriginForm/CertificateOfOriginForm";
import CertificateOfOriginList from "./pages/CertificateOfOriginList/CertificateOfOriginList";
import CheckPayment from "./pages/CheckPayment/CheckPayment";
import ChooseBillCreationMethod from "./pages/ChooseBillCreationMethod/ChooseBillCreationMethod";
import CompleteProfile from "./pages/CompleteProfile/CompleteProfile";
import ConfirmRegistration from "./pages/ConfirmRegistration/ConfirmRegistration";
import ComponentDetails from "./pages/CreateBillOfMaterial/ComponentDetails/ComponentDetails";
import ComponentQuestions from "./pages/CreateBillOfMaterial/ComponentQuestions/ComponentQuestions";
import FinishedGoodDetails from "./pages/CreateBillOfMaterial/FinishedGoodDetails/FinishedGoodDetails";
import FinishedGoodQuestions from "./pages/CreateBillOfMaterial/FinishedGoodQuestions/FinishedGoodQuestions";
import Submission from "./pages/CreateBillOfMaterial/Submission/Submission";
import CustomerProfile from "./pages/CustomerProfile/CustomerProfile";
import Dashboard from "./pages/Dashboard/Dashboard";
import EditComponents from "./pages/EditComponents/EditComponents";
import EditBOM from "./pages/EditFinishedGood/EditFinishedGood";
import EnterCode from "./pages/ForgotPassword/EnterCode";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import PageNotFound from "./pages/PageNotFound";
import Payments from "./pages/Payments/Payments";
import QualifyBOM from "./pages/QualifyBOM";
import AnswerComponentQuestions from "./pages/QualifyBOM/AnswerComponentQuestions";
import AnswerFinishedGoodQuestions from "./pages/QualifyBOM/AnswerFinishedGoodQuestions";
import CloseBOM from "./pages/QualifyBOM/CloseBOM";
import SubmitComponents from "./pages/QualifyBOM/SubmitComponents";
import SubmitFinishedGood from "./pages/QualifyBOM/SubmitFinishedGood";
import Registration from "./pages/Registration/Registration";
import ServerError from "./pages/ServerError";
import ChooseProduct from "./pages/Upgrade/ChooseProduct";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  return (
    <Sentry.ErrorBoundary fallback={<ServerError />}>
      <BrowserRouter>
        <SentryRoutes>
          <Route element={<AppInitializer />}>
            <Route path="/" element={<MainLayout />}>
              <Route index element={<Home />} />
              <Route path="dashboard/" element={<PrivateRoute />}>
                <Route index element={<Dashboard />} />
                <Route path="bom/">
                  <Route path="qualify/">
                    <Route path="close/:encodedBomId/" element={<CloseBOM />} />
                    <Route path="answer-component-questions/:encodedBomId/" element={<AnswerComponentQuestions />} />
                    <Route path="submit-components/:encodedBomId/" element={<SubmitComponents />} />
                    <Route path="answer-fg-questions/:encodedBomId/" element={<AnswerFinishedGoodQuestions />} />
                    <Route path="submit-fg/:encodedBomId/" element={<SubmitFinishedGood />} />
                    <Route path=":encodedBomId/" element={<QualifyBOM />} />
                  </Route>
                  <Route path="edit-fg/:encodedBomId/" element={<EditBOM />} />
                  <Route path="edit-components/:encodedBomId/" element={<EditComponents />} /> <Route path=":encodedBomId/" element={<BOMDetail />} />
                </Route>
                <Route path="certificate-of-origin/">
                  <Route index element={<CertificateOfOriginList />} />
                  <Route path=":certificateId/" element={<CertificateOfOriginDetail />} />
                  <Route path="generate/">
                    <Route index element={<GenerateCertificateOfOrigin />} />
                    <Route path="certifier-form/" element={<CertifierForm />} />
                    <Route path="exporter-form/" element={<ExporterForm />} />
                    <Route path="producer-data/" element={<ProducerData />} />
                    <Route path="importer-form/" element={<ImporterForm />} />
                    <Route path="summary/" element={<Summary />} />
                  </Route>
                  <Route path="generate/form/" element={<CertificateOfOriginForm />} />
                </Route>
                <Route path="complete-profile/" element={<Navigate to="/auth/complete-profile" />} />
                <Route path="customer/">
                  <Route index element={<CustomerProfile />} />
                </Route>
                <Route path="create-bill-of-material/">
                  <Route index element={<ChooseBillCreationMethod />} />
                  <Route path="fg-details/" element={<FinishedGoodDetails />} />
                  <Route path="fg-questions/" element={<FinishedGoodQuestions />} />
                  <Route path="submission/" element={<Submission />} />
                  <Route path="component-details/" element={<ComponentDetails />} />
                  <Route path="comp-questions/" element={<ComponentQuestions />} />
                </Route>
                <Route path="import-bom-csv/">
                  <Route index element={<BOMImporter />} />
                  <Route path="match-csv-fields/" element={<MatchFileFields />} />
                  <Route path="summary/" element={<ImportSummary />} />
                  <Route path="finish/" element={<FinishImport />} />
                </Route>
                <Route path="payments/">
                  <Route index element={<Payments />} />
                  <Route path="check" element={<CheckPayment />} />
                </Route>
              </Route>
              <Route path="/login" element={<Navigate to="/auth/login" replace />} />
              <Route path="/register" element={<Navigate to="/auth/register" replace />} />
              <Route path="/register/confirm" element={<Navigate to="/auth/register/confirm" replace />} />
              <Route path="/forgot-password" element={<Navigate to="/auth/forgot-password" />} />
              <Route path="/forgot-password/new-password" element={<Navigate to="/auth/forgot-password/new-password" />} />
            </Route>
            <Route path="/auth/" element={<AuthLayout />}>
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Registration />} />
              <Route path="register/confirm" element={<ConfirmRegistration />} />
              <Route path="complete-profile/" element={<CompleteProfile />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="forgot-password/new-password" element={<EnterCode />} />
            </Route>
            <Route path="/upgrade/" element={<UpgradeLayout />}>
              <Route index element={<ChooseProduct />} />
            </Route>
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </SentryRoutes>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  );
}

export default App;
