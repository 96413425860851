import { object, string } from "yup";

export default object().shape({
  name: string().required("Please enter your name.").min(2, "Your name can't be less than 2 characters."),
  companyName: string().required("Please enter your company name.").min(2, "Company name can't be less than 2 characters."),
  phoneNumber: string().required("Please enter your phone number").min(7, "Phone number can't be shorter than 7 characters."),
  streetAddress: string().required("Please enter your street address.").min(2, "Street address can't be shorter than 2 characters."),
  city: string().required("Please enter your city.").min(2, "City can't be shorter than 2 characters."),
  state: string().required("Please enter your state.").min(2, "State can't be less than 2 characters."),
  country: string().required("Please enter your country.").min(2, "Country must be at least 2 characters."),
  zipCode: string().required("Please enter your zip code.").min(5, "Zip code must be at least 5 characters"),
  taxId: string(),
  subscriptionType: string()
    .required("Please choose a type of subscription.")
    .matches(/sub|payg/, "You have to choose between Pay-As-You-Go or Subscription."),
});
