import { nanoid } from "nanoid";
import React from "react";

import BOMCard from "../../../../../components/BOMCard/BOMCard";
import LoadingSpinner from "../../../../../components/LoadingSpinner/LoadingSpinner";
import { useHistoryBodyContext } from "../HistoryBodyContext";

function BOMCards() {
  const { currentBoms, loading, searchQuery } = useHistoryBodyContext();

  let body;

  if (loading) {
    body = (
      <div className="d-flex justify-content-center align-items-center">
        <LoadingSpinner />
      </div>
    );
  } else if (Array.isArray(currentBoms) && currentBoms.length === 0) {
    body = <p className="alert alert-info text-center">No BOMs matching this criteria found.</p>;
  } else {
    body = (
      <>
        {currentBoms.map((bom) => (
          <div key={nanoid()} className="mb-4">
            <BOMCard bomId={bom.BOMID} />
          </div>
        ))}
      </>
    );
  }

  return (
    <div className="d-flex flex-column mt-5">
      {searchQuery && (
        <p className="mb-5">
          Showing BOMs matching &quot;<b>{searchQuery}</b>&quot;
        </p>
      )}
      {body}
    </div>
  );
}

export default BOMCards;
