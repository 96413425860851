import { useMemo } from "react";
import { useSelector } from "react-redux";

import { currentLoginStateSelector } from "../features/auth/authSlice";
import useInitialState from "./useInitialState";

export default function useIsLoggedIn() {
  const { loading } = useInitialState();
  const isLoggedIn = useSelector(currentLoginStateSelector);

  return useMemo(() => ({ loading, isLoggedIn }), [loading, isLoggedIn]);
}
