import Decimal from "decimal.js";
import { nanoid } from "nanoid";
import PropTypes from "prop-types";
import React from "react";

import { componentPropType } from "../../../components/BOMSummaryPDF/propTypes";
import { htsDisplay } from "../../../utils/bom";

function ComponentsTable({ components }) {
  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">Part Number</th>
          <th scope="col">USHTS</th>
          <th scope="col">Description</th>
          <th scope="col">Origin</th>
          <th scope="col">Quantity</th>
          <th scope="col">Units</th>
          <th scope="col">Cost</th>
          <th scope="col">EXT Cost</th>
          <th scope="col">Originating</th>
        </tr>
      </thead>
      <tbody>
        {components.map((component) => (
          <tr key={nanoid()}>
            <td>{component.Part}</td>
            <td>{htsDisplay(component.Hts)}</td>
            <td>{component.Desc}</td>
            <td>{component.Origin}</td>
            <td>{component.Qty}</td>
            <td>{component.Unit}</td>
            <td>{component.Cost}</td>
            <td>{new Decimal(component.Qty).mul(new Decimal(component.Cost)).toString()}</td>
            <td>{component.Originating === "Y" ? "Yes" : "No"}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

ComponentsTable.propTypes = {
  components: PropTypes.arrayOf(componentPropType).isRequired,
};

export default ComponentsTable;
