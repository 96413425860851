import { Field, Form, Formik } from "formik";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { useAnswerFinishedGoodQuestionsMutation } from "../../../features/api/boms";
import useBom from "../../../hooks/useBom";
import useBomIdFromParams from "../../../hooks/useDecodedBomId";

function QuestionsForm({ bom, handleSubmit }) {
  const { encodedBomId } = useParams();

  const initialValues = {};
  if (bom) {
    for (let i = 0; i < bom.FG_Qs.length; i += 1) {
      initialValues[`question${i}`] = "N";
    }
  }
  if (!bom) {
    return (
      <div className="d-flex justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  <Formik onSubmit={handleSubmit} initialValues={initialValues}>
    {({ isSubmitting }) => (
      <Form>
        {bom &&
          bom.FG_Qs.map((question, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`question${idx}`} className="mb-3">
              <p>{question.Question}</p>
              <div className="form-check">
                <Field id={`questionY${idx}`} type="radio" className="form-check-input" name={`question${idx}`} value="Y" />
                <label htmlFor={`questionY${idx}`} className="form-check-label">
                  Yes
                </label>
              </div>
              <div className="form-check">
                <Field id={`questionN${idx}`} type="radio" className="form-check-input" name={`question${idx}`} value="N" />
                <label htmlFor={`questionN${idx}`} className="form-check-label">
                  No
                </label>
              </div>
            </div>
          ))}
        <div className="d-flex justify-content-center align-items-center gap-2">
          <Link className="btn btn-outline-primary fw-bold text-uppercase" to={`/dashboard/bom/${encodedBomId}`}>
            Cancel & Go Back
          </Link>
          <button disabled={isSubmitting} type="submit" className="btn btn-primary fw-bold text-uppercase">
            Save & Continue
          </button>
        </div>
      </Form>
    )}
  </Formik>;
}

export default function AnswerFinishedGoodQuestions() {
  const { encodedBomId } = useParams();
  const { bomId } = useBomIdFromParams();
  const { bom } = useBom(bomId);
  const [answerFinishedGoodQuestions, { isError, error }] = useAnswerFinishedGoodQuestionsMutation();
  const navigate = useNavigate();

  function handleSubmit(values, { setSubmitting }) {
    setSubmitting(true);
    const requestBody = Object.values(values).map((val) => val === "Y");
    answerFinishedGoodQuestions({ bomId, body: requestBody }).finally(() => setSubmitting(false));
  }

  useEffect(() => {
    if (isError) {
      Swal.fire({
        title: "Error!",
        text: `Failed to submit answers. ${error.error || "Unknown error."}`,
      }).then(() => {
        navigate(`/dashboard/bom/${encodedBomId}`);
      });
    }
  }, [isError, encodedBomId, error, navigate]);

  useEffect(() => {
    if (bom) {
      if (bom.BOM_State !== "FG_QUALIFYING") {
        navigate(`/dashboard/bom/qualify/${encodedBomId}`);
      }
    }
  }, [bom, encodedBomId, navigate]);

  if (!bomId) {
    return (
      <div className="container pt-5">
        <div className="row">
          <div className="d-flex justify-content-center">
            <LoadingSpinner />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="conntainer pt-5">
      <div className="row mb-5">
        <h1 className="text-center">Finished Good Questions | BOM {bomId}</h1>
      </div>
      <div className="row">
        <div className="col-lg-4" />
        <div className="col-lg-4">
          <p className="alert alert-info text-center mb-5">
            Answer the following questions about your finished finished good. Some questions may be repetitive; this is because we are asking all qualification questions
            up front for various rules.
          </p>
          <QuestionsForm bom={bom} handleSubmit={handleSubmit} />
        </div>
        <div className="col-lg-4" />
      </div>
    </div>
  );
}
