import { Field, Form, Formik, useFormikContext } from "formik";
import { nanoid } from "nanoid";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { csvHeadersSelector, setMatchedComponentHeaders, setMatchedFinishedGoodHeaders } from "../../../features/fileImport/fileImportSlice";
import rightArrowIcon from "../../../images/right-arrow.svg";
import { FG_FIELD_MATCHER_CARD_TYPE } from "../constants";

const finishedGoodExpectedHeaders = ["Part Number", "Description", "HTS", "Transaction Value", "Net Cost", "Total Cost", "Origin"];

const componentExpectedHeaders = ["Part Number", "Description", "Category", "HTS", "Units", "Unit Cost", "Quantity", "Origin", "Originating"];

const headerFieldMap = {
  "Part Number": "partNumber",
  Description: "description",
  HTS: "hts",
  "Transaction Value": "transactionValue",
  "Net Cost": "netCost",
  "Total Cost": "totalCost",
  Origin: "origin",
  Category: "category",
  Units: "units",
  "Unit Cost": "unitCost",
  Originating: "originating",
  Quantity: "qty",
};

function FieldMatcherRow({ header }) {
  const headers = useSelector(csvHeadersSelector);

  if (!headers) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <p className="m-0 py-1 px-2 rounded bg-light-3 fw-bold">{header}</p>
      <img className="img-fluid" src={rightArrowIcon} alt="Right arrow" />
      <Field name={headerFieldMap[header]} aria-label={`Select column for ${header}`} as="select" className="form-select">
        <option value="-">-</option>
        {headers.map((h, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <option key={`header${h}${idx}`} value={h}>
            {h}
          </option>
        ))}
      </Field>
    </>
  );
}

function FieldsForm({ type, expectedHeaders, title }) {
  const { values } = useFormikContext();
  const dispatch = useDispatch();
  useEffect(() => {
    if (dispatch && typeof type !== "undefined" && values) {
      if (type === FG_FIELD_MATCHER_CARD_TYPE) {
        dispatch(setMatchedFinishedGoodHeaders(values));
      } else {
        dispatch(setMatchedComponentHeaders(values));
      }
    }
  }, [type, values, dispatch]);
  return (
    <Form
      style={{ gridTemplateColumns: "1fr 50px 1fr", gridTemplateRows: "repeat(auto-fit, 1fr)", height: "max-content" }}
      className="p-3 bg-light-2 gap-4 align-items-center d-grid flex-grow-1"
    >
      <h5>{title}</h5>
      <div />
      <h5>Imported File</h5>
      {expectedHeaders && expectedHeaders.map((header) => <FieldMatcherRow key={nanoid()} header={header} />)}
    </Form>
  );
}

function FieldMatcherCard({ type }) {
  const [title, setTitle] = useState(null);
  const [expectedHeaders, setExpectedHeaders] = useState(null);
  const parsedHeaders = useSelector(csvHeadersSelector);

  useEffect(() => {
    if (type === FG_FIELD_MATCHER_CARD_TYPE) {
      setTitle("BOM Details");
      setExpectedHeaders(finishedGoodExpectedHeaders);
    } else {
      setTitle("Component Details");
      setExpectedHeaders(componentExpectedHeaders);
    }

    return () => {
      setTitle(null);
      setExpectedHeaders(null);
    };
  }, [type]);

  const initialValues = useMemo(() => {
    if (!parsedHeaders) return {};
    const partNumberHeader = parsedHeaders.indexOf("PartNumber") !== -1 ? "PartNumber" : "-";
    const descriptionHeader = parsedHeaders.indexOf("Desc") !== -1 ? "Desc" : "-";
    const htsHeader = parsedHeaders.indexOf("HTS") !== -1 ? "HTS" : "-";
    const transactionValueHeader = parsedHeaders.indexOf("TransactionValue") !== -1 ? "TransactionValue" : "-";
    const netCostHeader = parsedHeaders.indexOf("NetCost") !== -1 ? "NetCost" : "-";
    const totalCostHeader = parsedHeaders.indexOf("TotalCost") !== -1 ? "TotalCost" : "-";
    const originHeader = parsedHeaders.indexOf("COO") !== -1 ? "COO" : "-";
    if (typeof type !== "undefined" && type === FG_FIELD_MATCHER_CARD_TYPE) {
      return {
        partNumber: partNumberHeader,
        description: descriptionHeader,
        hts: htsHeader,
        transactionValue: transactionValueHeader,
        netCost: netCostHeader,
        totalCost: totalCostHeader,
        origin: originHeader,
      };
    }
    return {
      partNumber: partNumberHeader,
      description: descriptionHeader,
      hts: htsHeader,
      units: parsedHeaders.indexOf("Units") !== -1 ? "Units" : "-",
      qty: parsedHeaders.indexOf("Qty") !== -1 ? "Qty" : "-",
      unitCost: parsedHeaders.indexOf("UnitCost") !== -1 ? "UnitCost" : "-",
      origin: originHeader,
      originating: parsedHeaders.indexOf("Originating") !== -1 ? "Originating" : "-",
      category: parsedHeaders.indexOf("Category") !== -1 ? "Category" : "-",
    };
  }, [type, parsedHeaders]);

  if (typeof type === "undefined" && !initialValues) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Formik onSubmit={() => {}} initialValues={initialValues}>
      {() => <FieldsForm title={title} type={type} expectedHeaders={expectedHeaders} />}
    </Formik>
  );
}

export default FieldMatcherCard;
