import { Field, Form, Formik } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import { componentPropType } from "../../../components/BOMSummaryPDF/propTypes";
import ComponentCard from "../../../components/ComponentCard/ComponentCard";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import componentQuestionPropType from "../../../propTypes/componentQuestionPropType";
import { useComponentQuestionsContext } from "./ComponentQuestionsContext";
import useComponentQuestionsForm from "./useComponentQuestionsForm";

function QuestionField({ question }) {
  return (
    <div className="mb-3">
      <p>{question.Question}</p>
      <div className="form-check">
        <Field id={`questionY${question.RuleID}`} className="form-check-input" type="radio" name={`${question.RuleID.replaceAll("#", "_")}`} value="Y" />
        <label htmlFor={`questionY${question.RuleID}`} className="form-check-label">
          Yes
        </label>
      </div>

      <div className="form-check">
        <Field id={`questionN${question.RuleID}`} className="form-check-input" type="radio" name={`${question.RuleID.replaceAll("#", "_")}`} value="N" />
        <label htmlFor={`questionN${question.RuleID}`} className="form-check-label">
          No
        </label>
      </div>
    </div>
  );
}

QuestionField.propTypes = {
  question: componentQuestionPropType.isRequired,
};

function QuestionsList({ questions }) {
  return (
    <div>
      {questions.map((question, idx) => (
        <div key={question.RuleID} className="mb-3">
          <h4 className="mb-3">Question {idx + 1}</h4>
          <QuestionField question={question} />
        </div>
      ))}
    </div>
  );
}

QuestionsList.propTypes = {
  questions: PropTypes.arrayOf(componentQuestionPropType).isRequired,
};

function QuestionDisplay({ component, questions }) {
  return (
    <div className="mb-3 border p-3 rounded">
      <div className="mb-4">
        <ComponentCard component={component} />
      </div>
      <QuestionsList questions={questions} />
    </div>
  );
}

QuestionDisplay.propTypes = {
  component: componentPropType.isRequired,
  questions: PropTypes.arrayOf(componentQuestionPropType).isRequired,
};

function ComponentQuestionsForm() {
  const { componentAndQuestions } = useComponentQuestionsContext();
  const { handleSubmit, initialValues } = useComponentQuestionsForm();

  if (initialValues) {
    return (
      <Formik onSubmit={handleSubmit} initialValues={initialValues}>
        {({ isSubmitting }) => (
          <Form>
            {componentAndQuestions.map((c, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <QuestionDisplay key={`question${idx}`} questions={c.questions} component={c.component} />
            ))}
            <div className="d-flex justify-content-center align-items-center gap-2">
              <Link to="/dashboard" className="btn btn-outline-primary fw-bold text-uppercase">
                Cancel & Go Back
              </Link>
              <button type="submit" disabled={isSubmitting} className="btn btn-primary fw-bold text-uppercase">
                Save & Continue
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }

  return (
    <div className="d-flex justify-content-center align-items-center">
      <LoadingSpinner />
    </div>
  );
}

export default ComponentQuestionsForm;
