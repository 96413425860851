import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { PiPencil } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import HTSField from "../../../components/FinishedGoodForm/HTSField";
import FinishedGoodOriginSelector from "../../../components/FinishedGoodOriginSelector/FinishedGoodOriginSelector";
import FormikFieldGroup from "../../../components/FormikFieldGroup/FormikFieldGroup";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import {
  currentUnparsedBOMSelector,
  mappedFinishedGoodHeaders as mappedFinishedGoodHeadersSelector,
  setCurrentUnparsedBOM,
} from "../../../features/fileImport/fileImportSlice";
import hts from "../../../schemas/hts";
import { htsDisplay } from "../../../utils/bom";
import { FG_FIELD_MATCHER_CARD_TYPE } from "../constants";
import FieldMatcherCard from "./FieldMatcherCard";
import { getFinishedGoodMappedValues } from "./utils";

const schema = object().shape({
  partNumber: string().required("Please enter a part number."),
  hts: hts.required("Please enter the HTS number."),
  description: string(),
  transactionValue: string(),
  netCost: string(),
  totalCost: string(),
  origin: string().required("Please enter the country of origin."),
});

function BOMEditModal({ show, onHide }) {
  const [errorMsg, setErrorMsg] = useState(null);
  const currentUnparsedBOM = useSelector(currentUnparsedBOMSelector);
  const finishedGoodHeadersMap = useSelector(mappedFinishedGoodHeadersSelector);
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    const result = {};
    if (currentUnparsedBOM) {
      const fg = currentUnparsedBOM.find((row) => row.Item === "FG");
      if (fg) {
        const mappedFgValues = getFinishedGoodMappedValues(fg, finishedGoodHeadersMap);
        Object.keys(mappedFgValues).forEach((key) => {
          result[key] = mappedFgValues[key];
        });
      }
    }
    return result;
  }, [currentUnparsedBOM, finishedGoodHeadersMap]);

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      setSubmitting(true);
      setErrorMsg(null);
      if (values.transactionValue === 0 && values.netCost === 0) {
        setErrorMsg("Please provide either the transaction value or the net cost of your finished good.");
        setSubmitting(false);
        return;
      }
      const newData = currentUnparsedBOM.map((data) => {
        if (data.Item === "FG") {
          const dataCopy = { ...data };
          Object.keys(values).forEach((key) => {
            const header = finishedGoodHeadersMap[key];
            dataCopy[header] = String(values[key]);
          });

          return dataCopy;
        }

        return data;
      });
      dispatch(setCurrentUnparsedBOM(newData));
      setSubmitting(false);
      onHide();
    },
    [currentUnparsedBOM, dispatch, onHide, finishedGoodHeadersMap],
  );

  useEffect(() => {
    if (!show) {
      setErrorMsg(null);
    }
  }, [show]);

  return (
    <Modal scrollable show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit BOM Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik enableReinitialize validateSchema={schema} initialValues={initialValues} onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <Form>
              <div className="mb-4">
                <div className="mb-3">
                  <FormikFieldGroup name="partNumber" required type="text" label="Part Number*" placeholder="Ex. P1" />
                </div>
                <div className="mb-3">
                  <HTSField />
                </div>
                <div className="mb-3">
                  <FormikFieldGroup name="description" type="text" label="Description" placeholder="Finished good description" />
                </div>
                <div className="card mb-3">
                  <div className="card-header">
                    <h5 className="m-0">Costs</h5>
                  </div>
                  <div className="card-body">
                    <p>
                      <b>Please provide either the transaction value, or the net cost.</b>
                    </p>
                    <div className="mb-3">
                      <FormikFieldGroup name="transactionValue" type="number" label="Transaction Value" placeholder="100" />
                    </div>
                    <div className="mb-3">
                      <FormikFieldGroup name="netCost" type="number" label="Net Cost" placeholder="100" />
                    </div>
                    <div className="mb-3">
                      <FormikFieldGroup helpText="Total cost is optional." name="totalCost" type="number" label="Total Cost" placeholder="100" />
                    </div>
                  </div>
                </div>
                <FinishedGoodOriginSelector name="origin" label="Origin*" />
              </div>
              {errorMsg && <p className="alert alert-danger text-center">{errorMsg}</p>}
              <div className="d-flex justify-content-center align-items-center gap-2">
                <button onClick={onHide} disabled={isSubmitting} type="button" className="btn btn-outline-primary text-uppercase fw-bold">
                  Cancel
                </button>
                <button disabled={isSubmitting} type="submit" className="btn btn-primary text-uppercase fw-bold">
                  Save
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

BOMEditModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

function BOMCard() {
  const [showEditModal, setShowEditModal] = useState(false);
  const [finishedGood, setFinishedGood] = useState(null);
  const currentUnparsedBOM = useSelector(currentUnparsedBOMSelector);
  const finishedGoodHeadersMap = useSelector(mappedFinishedGoodHeadersSelector);

  useEffect(() => {
    if (currentUnparsedBOM && finishedGoodHeadersMap) {
      const fg = currentUnparsedBOM.find((row) => row.Item === "FG");
      setFinishedGood({
        partNumber: fg[finishedGoodHeadersMap.partNumber],
        hts: fg[finishedGoodHeadersMap.hts],
        description: fg[finishedGoodHeadersMap.description],
        transactionValue: fg[finishedGoodHeadersMap.transactionValue],
        netCost: fg[finishedGoodHeadersMap.netCost],
        totalCost: fg[finishedGoodHeadersMap.totalCost],
        origin: fg[finishedGoodHeadersMap.origin],
      });
    }
  }, [currentUnparsedBOM, finishedGoodHeadersMap]);

  if (!finishedGood) {
    return (
      <div style={{ width: "250px" }} className="d-flex justify-content-center align-items-center">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <div style={{ height: "205px" }} className="rounded p-3 bg-dynamic-black text-light-0 d-flex flex-column justify-content-between flex-grow-1">
      <div>
        <div className="d-flex align-items-center justify-content-between">
          <p style={{ width: "max-content", fontSize: ".825rem" }} className="bg-light-0 rounded-pill text-uppercase text-dark py-1 px-3 fw-bold">
            BOM Details
          </p>
          <button
            onClick={() => setShowEditModal(true)}
            aria-label="Edit BOM information"
            title="Edit BOM information"
            type="button"
            className="btn no-border-no-outline text-light-0"
          >
            <PiPencil />
          </button>
        </div>
        <p>{finishedGood.description}</p>
      </div>
      <div className="d-flex justify-content-between flex-wrap">
        <p className="m-0">Transaction Value | {finishedGood.transactionValue || "-"}</p>
        <p className="m-0">Net Cost | {finishedGood.netCost || "-"}</p>
        <p className="m-0">Total Cost | {finishedGood.totalCost || "-"}</p>
      </div>
      <div className="d-flex justify-content-between flex-wrap">
        <p className="m-0">Part Number | {finishedGood.partNumber}</p>
        <p className="m-0">HTS | {htsDisplay(finishedGood.hts)}</p>
        <p className="m-0">Origin | {finishedGood.origin}</p>
      </div>
      <BOMEditModal show={showEditModal} onHide={() => setShowEditModal(false)} />
    </div>
  );
}

function BOMDetailsMatcher() {
  return (
    <div className="d-flex gap-3 w-100">
      <div style={{ width: "50vw" }}>
        <FieldMatcherCard type={FG_FIELD_MATCHER_CARD_TYPE} />
      </div>
      <div style={{ width: "50vw" }}>
        <BOMCard />
      </div>
    </div>
  );
}

export default BOMDetailsMatcher;
