import print from "print-js";
import Swal from "sweetalert2";

export default function printPDF(pdfUrl) {
  return new Promise((resolve, reject) => {
    if (pdfUrl) {
      fetch(pdfUrl)
        .then((response) => {
          if (response.ok) {
            return response.blob();
          }
          return false;
        })
        .then((responseData) => {
          if (responseData) {
            print(URL.createObjectURL(responseData));
            resolve();
          } else {
            Swal.fire({
              title: "Error!",
              text: "Could not print your PDF. Please try again.",
              icon: "error",
            });
            reject();
          }
        });
    }
  });
}
