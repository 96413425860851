import bootstrapIcons from "bootstrap-icons/bootstrap-icons.svg";
import PropTypes from "prop-types";
import React from "react";

function BootstrapSVG({ iconName, className = "" }) {
  return (
    <svg className={className}>
      <use xlinkHref={`${bootstrapIcons}#${iconName}`} />
    </svg>
  );
}

BootstrapSVG.propTypes = {
  iconName: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default BootstrapSVG;
