/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field, Form, Formik } from "formik";
import React from "react";
import { useLocation } from "react-router-dom";
import { object, string } from "yup";

import FormError from "../../components/FormError";
import password from "../../schemas/password";
import useEnterCode from "./useEnterCode";

const schema = object().shape({
  username: string().required("Please enter your username."),
  code: string().required("Please enter the code we emailed you."),
  password1: password,
  password2: string().min(6).required("Please enter the password confirmation."),
});

export default function EnterCode() {
  const { handleSubmit, errorMsg } = useEnterCode();
  const location = useLocation();

  return (
    <div>
      <h1>Password reset verification</h1>
      <p className="alert alert-info text-center mb-5">We sent a verification code to your registered email address. Please enter it below.</p>
      {errorMsg && <p className="alert alert-danger text-center mb-5">{errorMsg}</p>}
      <Formik validationSchema={schema} onSubmit={handleSubmit} initialValues={{ username: location.state?.username || "", code: "", password1: "", password2: "" }}>
        {({ isSubmitting, errors }) => (
          <Form>
            <div className={`form-group mb-3 ${location.state?.username ? "d-none" : ""}`}>
              <label className="form-label w-100">
                Username*
                <Field className={`form-control mt-1 ${errors.username ? "is-invalid" : ""}`} placeholder="Your username" type="text" name="username" required />
                {errors.username && <FormError error={errors.username} />}
              </label>
            </div>

            <div className={`form-group mb-3 ${location.state?.code ? "d-none" : ""}`}>
              <label className="form-label w-100">
                Code*
                <Field className={`form-control mt-1 ${errors.code ? "is-invalid" : ""}`} placeholder="The code from our email" type="text" name="code" required />
                {errors.code && <FormError error={errors.code} />}
              </label>
            </div>

            <div className={`form-group mb-3 ${location.state?.password1 ? "d-none" : ""}`}>
              <label className="form-label w-100">
                New password*
                <Field className={`form-control mt-1 ${errors.password1 ? "is-invalid" : ""}`} placeholder="A new password" type="password" name="password1" required />
                {errors.password1 && <FormError error={errors.password1} />}
              </label>
            </div>

            <div className={`form-group mb-4 ${location.state?.password2 ? "d-none" : ""}`}>
              <label className="form-label w-100">
                Password confirmation*
                <Field
                  className={`form-control mt-1 ${errors.password2 ? "is-invalid" : ""}`}
                  placeholder="The same password as above"
                  type="password"
                  name="password2"
                  required
                />
                {errors.password2 && <FormError error={errors.password2} />}
              </label>
            </div>

            <button type="submit" className="btn btn-primary w-100" disabled={isSubmitting}>
              Continue
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
