import React from "react";

import ProgressBar from "../components/ProgressBar/ProgressBar";
import { COMPONENT_QUESTIONS_STEP } from "../constants";
import { ComponentQuestionsProvider } from "./ComponentQuestionsContext";
import ComponentQuestionsForm from "./ComponentQuestionsForm";

function ComponentQuestions() {
  return (
    <ComponentQuestionsProvider>
      <div className="container pt-5">
        <div className="row">
          <div className="col-lg-2" />
          <div className="col-lg-8">
            <div className="mb-5 d-flex w-100">
              <ProgressBar step={COMPONENT_QUESTIONS_STEP} />
            </div>

            <div className="mb-5">
              <h1 className="mb-3">Answer Component Questions</h1>
              <p>Answer the following questions about your components up front for various rules.</p>
            </div>
            <ComponentQuestionsForm />
          </div>
          <div className="col-lg-2" />
        </div>
      </div>
    </ComponentQuestionsProvider>
  );
}

export default ComponentQuestions;
