import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { currentFileSelector, successfulImportsSelector, unparsedBOMsSelector } from "../features/fileImport/fileImportSlice";

export default function useNavigateToImportSummary() {
  const navigate = useNavigate();
  const unparsedBoms = useSelector(unparsedBOMsSelector);
  const successfulImports = useSelector(successfulImportsSelector);
  const currentFile = useSelector(currentFileSelector);

  return useCallback(() => {
    navigate("/dashboard/import-bom-csv/summary/", { replace: true, state: { unparsedBoms, successfulImports, fileName: currentFile.name } });
  }, [navigate, unparsedBoms, currentFile, successfulImports]);
}
