import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

import { showLoadingModal } from "../../features/ui/miscUISlice";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

function LoadingModal({ centered = true }) {
  const show = useSelector(showLoadingModal);

  return (
    <Modal show={show} centered={centered}>
      <Modal.Body style={{ height: "15rem", width: "30rem" }}>
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          <LoadingSpinner />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default LoadingModal;
