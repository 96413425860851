import PropTypes from "prop-types";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import useGenerateCertificate from "./useGenerateCertificate";
import useSelectedBoms from "./useSelectedBoms";

const GenerateCertificateContext = createContext(null);

function GenerateCertificateContextProvider({ children = null }) {
  const [currentSearchQuery, setCurrentSearchQuery] = useState("");
  const [viewAs, setViewAs] = useState("card");
  const { isSelected: bomIsSelected, addOrRemoveId: addOrRemoveBomId, bomIds: selectedBomIds } = useSelectedBoms();
  const { handleContinue } = useGenerateCertificate();
  const searchParams = useSearchParams()[0];

  useEffect(() => {
    if (searchParams) {
      const v = searchParams.get("view");
      if (v) {
        setViewAs(v);
      }
    } else {
      setViewAs("card");
    }

    return () => {
      setViewAs("card");
    };
  }, [searchParams]);

  const value = useMemo(
    () => ({ bomIsSelected, addOrRemoveBomId, selectedBomIds, handleContinue, viewAs, currentSearchQuery, setCurrentSearchQuery }),
    [bomIsSelected, addOrRemoveBomId, selectedBomIds, handleContinue, viewAs, currentSearchQuery, setCurrentSearchQuery],
  );

  return <GenerateCertificateContext.Provider value={value}>{children}</GenerateCertificateContext.Provider>;
}

GenerateCertificateContextProvider.propTypes = {
  children: PropTypes.node,
};

export { GenerateCertificateContextProvider };

export function useGenerateCertificateContext() {
  return useContext(GenerateCertificateContext);
}
