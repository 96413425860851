/* eslint no-param-reassign: 0 */
import { createSlice } from "@reduxjs/toolkit";

const appStateSlice = createSlice({
  name: "appState",
  initialState: { ready: false, loading: true, errors: [] },
  reducers: {
    setReady: (state, action) => {
      const { ready } = action.payload;
      state.ready = ready;
    },
    setLoading: (state, action) => {
      const { loading } = action.payload;
      state.loading = loading;
    },
    toggleLoading: (state) => {
      state.loading = !state.loading;
    },
    addErrors: (state, action) => {
      state.errors = [...state.errors, ...action.payload];
    },
    clearErrors: (state) => {
      state.errors = [];
    },
  },
});

export const { setReady, setLoading, addErrors, clearErrors } = appStateSlice.actions;

export default appStateSlice.reducer;
export const loadingSelector = (state) => state.appState.loading;

export const readySelector = (state) => state.appState.ready;

export const errorsSelector = (state) => state.appState.errors;
