/* eslint no-param-reassign: 0 */
import { createSlice } from "@reduxjs/toolkit";

const mainLayoutSlice = createSlice({
  name: "mainLayout",
  initialState: {
    showBomUsageLimit: true,
  },
  reducers: {
    setShowBomUsageLimit(state, action) {
      state.showBomUsageLimit = action.payload;
    },
  },
});

export default mainLayoutSlice.reducer;

export const { setShowBomUsageLimit } = mainLayoutSlice.actions;

export function showBomUsageLimitSelector(state) {
  return state.mainLayout.showBomUsageLimit;
}
