/* eslint react/jsx-props-no-spreading: 0 */
import PropTypes from "prop-types";
import React from "react";
import Dropzone from "react-dropzone";

import darkBlueFolderIcon from "../../images/dark-blue-folder.svg";

function UploadZone({ handleFiles }) {
  return (
    <Dropzone onDrop={(files) => handleFiles(files)}>
      {({ getRootProps, getInputProps }) => (
        <div className="w-100">
          <div
            style={{ borderStyle: "dashed", borderWidth: "1.75px", height: "275px", cursor: "pointer" }}
            className="p-5 rounded border-light-4 d-flex align-items-center justify-content-center w-100"
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <div className="d-flex flex-column align-items-center gap-3">
              <img src={darkBlueFolderIcon} alt="Dark blue folder" />
              <p className="m-0">
                Drag & Drop Your Files Here Or <span className="fw-bold text-uppercase text-primary">Choose a file</span>
              </p>
            </div>
          </div>
        </div>
      )}
    </Dropzone>
  );
}

UploadZone.propTypes = {
  handleFiles: PropTypes.func.isRequired,
};

export default UploadZone;
