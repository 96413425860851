import { urlSafeB64Encode } from "../../utils/encoding";
import api from "./apiSlice";

const qualificationPDFsApi = api.injectEndpoints({
  endpoints(build) {
    return {
      getQualificationPdf: build.query({
        query({ bomId }) {
          return `/extras/api/v1/boms/qualification-pdf/${urlSafeB64Encode(bomId)}`;
        },
        providesTags: (result, error, { bomId }) => (result ? [{ type: "QualificationPDF", id: bomId }] : []),
      }),
      generateQualificationPdf: build.mutation({
        query({ bomId }) {
          return {
            url: `/extras/api/v1/boms/qualification-pdf/${urlSafeB64Encode(bomId)}`,
            method: "POST",
          };
        },
        invalidatesTags: (result, error, { bomId }) => (result ? [{ type: "QualificationPDF", id: bomId }] : []),
      }),
    };
  },
});

export const { useGetQualificationPdfQuery, useGenerateQualificationPdfMutation } = qualificationPDFsApi;
