import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { PiDownload, PiFileZip } from "react-icons/pi";

import { DEFAULT_DAY_JS_DATETIME_FORMAT } from "../../constants";
import { useRequestDataExportMutation, useRetrieveExportedDataQuery } from "../../features/api/customers";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const POLL_INTERVAL = 10_000;

function RequestArchive() {
  const [requestArchive, { isLoading, isError, error }] = useRequestDataExportMutation();
  return (
    <div>
      {isError && <p className="alert alert-danger">{JSON.stringify(error.data || "Unknown error.")}</p>}
      <p className="alert alert-info">
        Please click the button below to request an archive of all your information. <b>Note: You can only request an archive every 3 days.</b>
      </p>
      <div className="d-flex justify-content-center">
        <button disabled={isLoading || isError} onClick={() => requestArchive()} type="button" className="btn btn-primary">
          <span className="d-inline-flex gap-1 align-items-center">
            <PiFileZip />
            Request archive
          </span>
        </button>
      </div>
    </div>
  );
}

function Body() {
  const [expired, setExpired] = useState(false);
  const [pollInterval, setPollInterval] = useState(0);
  const { data, isLoading, isError, error } = useRetrieveExportedDataQuery(null, { pollingInterval: pollInterval });
  const [errorMsg, setErrorMsg] = useState(null);

  useEffect(() => {
    if (isError) {
      if (error.status > 404) {
        setErrorMsg(JSON.stringify(error.data || "Unknown error."));
      }
      setPollInterval(0);
    }
  }, [isError, error]);

  useEffect(() => {
    if (data && data.status === "processing") {
      setPollInterval(POLL_INTERVAL);
    } else if (data && data.status === "success") {
      setPollInterval(0);
      if (data.expiry_date && dayjs(data.expiry_date).isBefore(dayjs())) {
        setExpired(true);
      } else {
        setExpired(false);
      }
    }

    return () => {
      setPollInterval(0);
    };
  }, [data]);

  const handleDownload = useCallback(() => {
    window.open(data.url, "_blank");
  }, [data]);
  let body;

  if (isLoading) {
    body = (
      <div>
        <p className="alert alert-info">
          Please wait while we process your request. Feel free to close this window and check back later if the export is taking too long.
        </p>
        <div className="d-flex justify-content-center align-items-center">
          <LoadingSpinner />
        </div>
      </div>
    );
  } else if (!isLoading && data) {
    body = (
      <div className="d-flex flex-column gap-3 align-items-center">
        <div>
          <p className="alert alert-info">
            You requested this archive on <b>{dayjs(data.requestedAt).format(DEFAULT_DAY_JS_DATETIME_FORMAT)}</b>.
            {!expired && " You can request another archive after 3 days."}
          </p>
          <div className="d-flex justify-content-center">
            <button disabled={data.status !== "success"} onClick={handleDownload} className="btn btn-primary" type="button">
              <span className="d-inline-flex gap-1 align-items-center">
                <PiDownload />
                {data.status !== "success" ? "Please wait..." : "Download archive"}
              </span>
            </button>
          </div>
        </div>
        {expired && <RequestArchive />}
      </div>
    );
  } else {
    body = <RequestArchive />;
  }

  return (
    <div>
      {errorMsg && <p className="alert alert-danger">{errorMsg}</p>}
      {body}
    </div>
  );
}

export default function DataExportModal({ onHide }) {
  return (
    <Modal show centered onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Download Your Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Body />
      </Modal.Body>
    </Modal>
  );
}
