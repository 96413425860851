import { useEffect, useMemo, useState } from "react";

import { useGetBomQuery } from "../features/api/boms";

export default function useBom(bomId) {
  const [loading, setLoading] = useState(true);
  const { data: bom, isError, isLoading: bomIsLoading, error } = useGetBomQuery(bomId, { skip: !bomId });

  useEffect(() => {
    if (bom && !bomIsLoading) {
      setLoading(false);
    } else if (!bom && !bomIsLoading) {
      setLoading(false);
    } else {
      setLoading(true);
    }

    return () => {
      setLoading(true);
    };
  }, [bom, bomIsLoading]);

  return useMemo(
    () => ({
      bom,
      loading,
      isError,
      error,
    }),
    [loading, bom, isError, error],
  );
}
