import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import * as Sentry from "@sentry/react";
import { Auth } from "aws-amplify";

import { logOut, setCredentials } from "../auth/authSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    headers.set("Content-Type", "application/json");
    const { idToken } = getState().auth;
    if (idToken) {
      headers.set("Authorization", idToken);
    }
    return headers;
  },
});

const baseQueryWithReAuth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    let refreshSuccess = false;
    try {
      const refreshedSession = await Auth.currentSession();
      api.dispatch(
        setCredentials({
          accessToken: refreshedSession.getAccessToken().getJwtToken(),
          refreshToken: refreshedSession.getRefreshToken().getToken(),
          idToken: refreshedSession.getIdToken().getJwtToken(),
        }),
      );
      refreshSuccess = true;
    } catch (refreshError) {
      Sentry.captureException(refreshError);
    }
    if (!refreshSuccess) {
      api.dispatch(logOut());
    } else {
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

const api = createApi({
  baseQuery: baseQueryWithReAuth,
  tagTypes: [
    "BOMDetail",
    "BOMList",
    "Customer",
    "QualificationPDF",
    "CertificateOfOrigin",
    "PresignedPost",
    "PaymentPrice",
    "BOMCountAvailable",
    "PaddleTransactionList",
    "TotalBOMsPurchased",
    "DataExport",
  ],
  endpoints() {
    return {};
  },
});

export default api;
