export function snakeToPhrase(s) {
  if (s && typeof s === "string") {
    const result = s.replaceAll("_", " ");
    return `${result[0].toUpperCase()}${result.slice(1)}`;
  }
  return "";
}

export function stringArrayToString(arr, delimeter = ",") {
  return arr.reduce((prev, cur, idx) => {
    if (idx === 0) {
      return cur;
    }
    return `${prev}${delimeter} ${cur}`;
  }, "");
}

export function toOrdinal(n) {
  const nStr = String(n);
  const nToCheck = Number(nStr[nStr.length - 1]);
  if (nStr.length > 1 && nStr.slice(nStr.length - 2) === "11") {
    return "th";
  }
  if (nToCheck === 1) {
    return `${nStr}st`;
  }
  if (nToCheck === 2) {
    return `${nStr}nd`;
  }
  if (nToCheck === 3) {
    return `${nStr}rd`;
  }
  return `${nStr}th`;
}
