import { useEffect, useMemo, useState } from "react";

import { CERTIFIER_DATA_PART_TYPE, EXPORTER_DATA_PART_TYPE, IMPORTER_DATA_PART_TYPE, PRODUCER_DATA_PART_TYPE, QUALIFIED_BILLS_PART_TYPE } from "../../constants";

export default function useSummaryPart(type) {
  const [title, setTitle] = useState(null);
  const [editText, setEditText] = useState(null);
  const [editLink, setEditLink] = useState(null);

  useEffect(() => {
    if (typeof type !== "undefined") {
      if (type === QUALIFIED_BILLS_PART_TYPE) {
        setTitle("Qualified Bills of Material");
        setEditText("Edit list");
        setEditLink("/dashboard/certificate-of-origin/generate/");
      } else if (type === CERTIFIER_DATA_PART_TYPE) {
        setTitle("Certifier Data");
        setEditText("Edit form");
        setEditLink("/dashboard/certificate-of-origin/generate/certifier-form/");
      } else if (type === EXPORTER_DATA_PART_TYPE) {
        setTitle("Exporter's Data");
        setEditText("Edit form");
        setEditLink("/dashboard/certificate-of-origin/generate/exporter-form");
      } else if (type === IMPORTER_DATA_PART_TYPE) {
        setTitle("Importer's Data");
        setEditText("Edit form");
        setEditLink("/dashboard/certificate-of-origin/generate/importer-form/");
      } else if (type === PRODUCER_DATA_PART_TYPE) {
        setTitle("Producer's Data");
        setEditText("Edit form & preferences");
        setEditLink("/dashboard/certificate-of-origin/generate/producer-data/");
      }
    } else {
      setTitle(null);
    }

    return () => {
      setTitle(null);
      setEditText(null);
    };
  }, [type]);

  return useMemo(() => ({ title, editText, editLink }), [title, editText, editLink]);
}
