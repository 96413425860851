/* eslint no-param-reassign: 0 */
import { createSlice } from "@reduxjs/toolkit";

const qualificationModalSlice = createSlice({
  name: "qualificationModal",
  initialState: {
    showModal: false,
  },
  reducers: {
    toggleModal(state) {
      state.showModal = !state.showModal;
    },
    setShow(state, action) {
      state.showModal = action.payload;
    },
  },
});

export default qualificationModalSlice.reducer;

export const { toggleModal, setShow } = qualificationModalSlice.actions;

export function showModalSelector(state) {
  return state.qualificationModal.showModal;
}
