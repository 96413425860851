import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { COMPS_COMPLETE_BOM_STATE, COMPS_QUALIFYING_BOM_STATE, FG_COMPLETE_BOM_STATE, FG_QUALIFYING_BOM_STATE } from "../../../../constants";
import useBomList from "../../../../hooks/useBomList";
import {
  SHOW_ALL,
  SHOW_COMPONENTS_REQUIRED,
  SHOW_COMPONENTS_REQUIRED_PARAM,
  SHOW_COMPONENT_QUESTIONS_REQUIRED,
  SHOW_COMPONENT_QUESTIONS_REQUIRED_PARAM,
  SHOW_FINISHED_GOOD_QUESTIONS_REQUIRED,
  SHOW_FINISHED_GOOD_QUESTIONS_REQUIRED_PARAM,
  SHOW_QUALIFIED,
  SHOW_QUALIFIED_PARAM,
  SHOW_READY_TO_SUBMIT,
  SHOW_READY_TO_SUBMIT_PARAM,
  SHOW_SAVED,
  SHOW_SAVED_PARAM,
  SHOW_UNQUALIFIED,
  SHOW_UNQUALIFIED_PARAM,
  SORT_BY_OLDEST,
} from "./Toolbar/constants";

const HistoryBodyContext = createContext(null);

function HistoryBodyContextProvider({ children = null }) {
  const { boms, completedOnly, openOnly, isLoading } = useBomList();
  const [loading, setLoading] = useState(true);
  const [filteredBoms, setFilteredBoms] = useState([]);
  const [searchedBoms, setSearchedBoms] = useState([]);
  const [currentBoms, setCurrentBoms] = useState([]);
  const [showOnly, setShowOnly] = useState(SHOW_ALL);
  const [searchQuery, setSearchQuery] = useState(null);

  const searchParams = useSearchParams()[0];

  const value = useMemo(() => ({ loading, currentBoms, searchQuery }), [loading, currentBoms, searchQuery]);

  useEffect(() => {
    const q = searchParams.get("query");
    if (q) {
      setSearchQuery(decodeURI(q));
    } else {
      setSearchQuery(null);
    }

    return () => {
      setSearchQuery(null);
    };
  }, [searchParams]);

  useEffect(() => {
    if (searchParams) {
      const filter = searchParams.get("filter");
      if (filter) {
        if (filter === SHOW_QUALIFIED_PARAM) {
          setShowOnly(SHOW_QUALIFIED);
        } else if (filter === SHOW_UNQUALIFIED_PARAM) {
          setShowOnly(SHOW_UNQUALIFIED);
        } else if (filter === SHOW_SAVED_PARAM) {
          setShowOnly(SHOW_SAVED);
        } else if (filter === SHOW_FINISHED_GOOD_QUESTIONS_REQUIRED_PARAM) {
          setShowOnly(SHOW_FINISHED_GOOD_QUESTIONS_REQUIRED);
        } else if (filter === SHOW_COMPONENTS_REQUIRED_PARAM) {
          setShowOnly(SHOW_COMPONENTS_REQUIRED);
        } else if (filter === SHOW_COMPONENT_QUESTIONS_REQUIRED_PARAM) {
          setShowOnly(SHOW_COMPONENT_QUESTIONS_REQUIRED);
        } else if (filter === SHOW_READY_TO_SUBMIT_PARAM) {
          setShowOnly(SHOW_READY_TO_SUBMIT);
        } else {
          setShowOnly(SHOW_ALL);
        }
      } else {
        setShowOnly(SHOW_ALL);
      }
    }

    return () => {
      setShowOnly(SHOW_ALL);
    };
  }, [searchParams]);

  useEffect(() => {
    if (boms) {
      if (showOnly === SHOW_QUALIFIED) {
        setFilteredBoms(completedOnly.filter((bom) => bom.Qualified === "Y"));
      } else if (showOnly === SHOW_UNQUALIFIED) {
        setFilteredBoms(completedOnly.filter((bom) => bom.Qualified === "N"));
      } else if (showOnly === SHOW_SAVED) {
        if (Array.isArray(openOnly)) {
          setFilteredBoms([...openOnly]);
        } else {
          setFilteredBoms([]);
        }
      } else if (showOnly === SHOW_FINISHED_GOOD_QUESTIONS_REQUIRED) {
        if (Array.isArray(openOnly)) {
          setFilteredBoms(openOnly.filter((bom) => bom.BOM_State === FG_QUALIFYING_BOM_STATE));
        } else {
          setFilteredBoms([]);
        }
      } else if (showOnly === SHOW_COMPONENTS_REQUIRED) {
        if (Array.isArray(openOnly)) {
          setFilteredBoms(openOnly.filter((bom) => bom.BOM_State === FG_COMPLETE_BOM_STATE));
        } else {
          setFilteredBoms([]);
        }
      } else if (showOnly === SHOW_COMPONENT_QUESTIONS_REQUIRED) {
        if (Array.isArray(openOnly)) {
          setFilteredBoms(openOnly.filter((bom) => bom.BOM_State === COMPS_QUALIFYING_BOM_STATE));
        } else {
          setFilteredBoms([]);
        }
      } else if (showOnly === SHOW_READY_TO_SUBMIT) {
        if (Array.isArray(openOnly)) {
          setFilteredBoms(openOnly.filter((bom) => bom.BOM_State === COMPS_COMPLETE_BOM_STATE));
        } else {
          setFilteredBoms([]);
        }
      } else {
        let newBoms = [];
        if (Array.isArray(openOnly)) {
          newBoms = [...newBoms, ...openOnly];
        }

        if (Array.isArray(completedOnly)) {
          newBoms = [...newBoms, ...completedOnly];
        }
        setFilteredBoms(newBoms);
      }
    } else {
      setFilteredBoms([]);
    }

    return () => {
      setFilteredBoms([]);
    };
  }, [showOnly, boms, completedOnly, openOnly]);

  useEffect(() => {
    const s = searchParams.get("query");
    if (s && filteredBoms && Array.isArray(filteredBoms) && filteredBoms.length > 0) {
      setSearchedBoms(filteredBoms.filter((bom) => bom.Desc.toLowerCase().match(new RegExp(decodeURI(s))) !== null));
    } else {
      setSearchedBoms([...filteredBoms]);
    }

    return () => {
      setSearchedBoms([]);
    };
  }, [filteredBoms, searchParams]);

  useEffect(() => {
    if (searchParams && Array.isArray(searchedBoms) && searchedBoms.length > 0) {
      const sortBy = searchParams.get("sort-by");
      if (sortBy === SORT_BY_OLDEST) {
        setCurrentBoms(
          [...searchedBoms].sort((bomA, bomB) => {
            const dayA = dayjs.unix(bomA.TS);
            const dayB = dayjs.unix(bomB.TS);
            if (dayA.isAfter(dayB)) {
              return 1;
            }
            if (dayA.isBefore(dayB)) {
              return -1;
            }

            return 0;
          }),
        );
      } else {
        setCurrentBoms(
          [...searchedBoms].sort((bomA, bomB) => {
            const dayA = dayjs.unix(bomA.TS);
            const dayB = dayjs.unix(bomB.TS);
            if (dayA.isAfter(dayB)) {
              return -1;
            }
            if (dayA.isBefore(dayB)) {
              return 1;
            }

            return 0;
          }),
        );
      }
    } else {
      setCurrentBoms([]);
    }
  }, [searchParams, searchedBoms]);

  useEffect(() => {
    if (isLoading || !currentBoms) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    return () => {
      setLoading(true);
    };
  }, [isLoading, currentBoms]);

  return <HistoryBodyContext.Provider value={value}>{children}</HistoryBodyContext.Provider>;
}

HistoryBodyContextProvider.propTypes = {
  children: PropTypes.node,
};

export { HistoryBodyContextProvider };

export function useHistoryBodyContext() {
  return useContext(HistoryBodyContext);
}
