/* eslint jsx-a11y/label-has-associated-control: 0, react/no-danger: 0 */
import dayjs from "dayjs";
import { Field, FieldArray, Form, Formik, useFormikContext } from "formik";
import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { array, object, string } from "yup";

import BootstrapSVG from "../../components/BootstrapSVG";
import FormError from "../../components/FormError";
import FormikFlatpickr from "../../components/FormikFlatpickr/FormikFlatpickr";
import { useGetCustomerQuery } from "../../features/api/customers";
import { selectedBomIdsSelector } from "../../features/certificateOfOrigin/certificateOfOriginSlice";
import getFullAddress from "../../utils/customer";
import QualifiedGoodCard from "./components/QualifiedGoodCard";
import useCertificateOfOriginForm from "./useCertificateOfOriginForm";

const schema = object().shape({
  certifyingCompany: string().required("Please enter the certifying company.").min(2, "Certifying company must be at least 2 characters."),
  certifierName: string().required("Please enter the certifier name.").min(2, "Certifier name must be at least 2 characters."),
  certifierType: string()
    .required("Please enter the type of certifier.")
    .min(2, "Certifier type must be at least 2 characters")
    .matches(/importer|exporter|producer/, "Certifier type must be either importer or exporter or producer."),
  certifierAddress: string().required("Please enter the certifier's full address.").min(2, "Certifier address must be at least 2 characters."),
  certifierPhoneNumber: string()
    .required("Please enter the certifier's phone number.")
    .min(7, "Certifier's phone number must be at least 7 characters long.")
    .matches(/[+0-9]/, "Please enter a valid phone number."),
  certifierEmail: string().email("Please enter a valid email address.").required("Please enter the certifier's email address"),
  effectiveDate: string().required("Please enter the effective date."),
  expirationDate: string().required("Please enter an expiration date."),
  exporterName: string(),
  exporterEmail: string().email("Please enter a valid email address"),
  exporterAddress: string(),
  exporterPhone: string().matches(/[+0-9]/, "Please enter a valid phone number."),
  producerDataType: string()
    .required("Please specify how you'd like to supply producer information.")
    .matches(/single|list|various|request/, "Please choose a type from the available radio buttons."),
  producers: array().of(
    object().shape({
      name: string(),
      email: string(),
      phone: string().matches(/[+0-9]/, "Please enter a valid phone number."),
      address: string(),
    }),
  ),
  importerName: string().required("Please enter the importer's name."),
  importerAddress: string().required("Please enter the importer's address."),
  importerPhone: string()
    .required("Please enter the importer's phone number.")
    .matches(/[+0-9]/, "Please enter a valid phone number."),
  importerEmail: string().email("Please enter a valid email address.").required("Please enter the importer's phone number."),
});

function ExporterFields() {
  const { errors, values, setFieldValue } = useFormikContext();
  const { data: customer } = useGetCustomerQuery();

  useEffect(() => {
    if (values.certifierType === "exporter" && customer) {
      setFieldValue("exporterName", customer.name);
      setFieldValue("exporterEmail", customer.email);
      setFieldValue("exporterAddress", getFullAddress(customer));
      setFieldValue("exporterPhone", customer.phone_number);
    }
  }, [values, customer, setFieldValue]);

  return (
    <>
      <div className="form-group mb-3">
        <label className="form-label w-100">
          Exporter Name
          <Field placeholder="The exporter's full name" name="exporterName" type="text" className={`form-control mt-1 ${errors.exporterName ? "is-invalid" : ""}`} />
          {errors.exporterName && <FormError error={errors.exporterName} />}
        </label>
      </div>
      <div className="form-group mb-3">
        <div className="form-label w-100">
          Exporter Email Address
          <Field
            placeholder="The exporter's email address"
            name="exporterEmail"
            type="text"
            className={`form-control mt-1 ${errors.exporterEmail ? "is-invalid" : ""}`}
          />
          {errors.exporterEmail && <FormError error={errors.exporterEmail} />}
        </div>
      </div>

      <div className="form-group mb-3">
        <div className="form-label w-100">
          Exporter Phone Number
          <Field placeholder="The exporter's phone number" name="exporterPhone" type="text" className={`form-control mt-1 ${errors.exporterPhone ? "is-invalid" : ""}`} />
          {errors.exporterPhone && <FormError error={errors.exporterPhone} />}
        </div>
      </div>

      <div className="form-group mb-3">
        <div className="form-label w-100">
          Exporter&apos;s Address
          <Field
            placeholder="The exporter's full address (incl. country)"
            name="exporterAddress"
            type="text"
            className={`form-control mt-1 ${errors.exporterAddress ? "is-invalid" : ""}`}
          />
          {errors.exporterAddress && <FormError error={errors.exporterAddress} />}
        </div>
      </div>
    </>
  );
}

function ProducersFields() {
  const { values } = useFormikContext();

  return (
    <FieldArray name="producers">
      {(arrayHelpers) => (
        <div>
          {values.producerDataType === "single" && (
            <div>
              <div className="form-group mb-3">
                <label className="form-label w-100">
                  Producer Name
                  <Field name="producers.0.name" type="text" placeholder="The producer's full name" className="form-control mt-1" />
                </label>
              </div>

              <div className="form-group mb-3">
                <label className="form-label w-100">
                  Producer Email
                  <Field name="producers.0.email" type="email" placeholder="The producer's email address" className="form-control mt-1" />
                </label>
              </div>

              <div className="form-group mb-3">
                <label className="form-label w-100">
                  Producer Phone Number
                  <Field name="producers.0.phone" type="text" placeholder="The producer's phone number" className="form-control mt-1" />
                </label>
              </div>

              <div className="form-group mb-3">
                <label className="form-label w-100">
                  Producer Address
                  <Field name="producers.0.address" type="text" placeholder="The producer's full address" className="form-control mt-1" />
                </label>
              </div>
            </div>
          )}

          {values.producerDataType === "list" && (
            <div className="card mb-5">
              <div className="card-body">
                {values.producers && values.producers.length > 0 ? (
                  <div>
                    {values.producers.map((producer, idx) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div className="card mb-3" key={`producer-${idx}`}>
                        <div className="card-header">
                          <h4 className="mb-0">Producer {idx + 1}</h4>
                        </div>
                        <div className="card-body">
                          <div className="form-group mb-3">
                            <label className="form-label w-100">
                              Producer Name
                              <Field name={`producers.${idx}.name`} type="text" placeholder="The producer's full name" className="form-control mt-1" />
                            </label>
                          </div>

                          <div className="form-group mb-3">
                            <label className="form-label w-100">
                              Producer Email
                              <Field name={`producers.${idx}.email`} type="email" placeholder="The producer's email address" className="form-control mt-1" />
                            </label>
                          </div>

                          <div className="form-group mb-3">
                            <label className="form-label w-100">
                              Producer Phone Number
                              <Field name={`producers.${idx}.phone`} type="text" placeholder="The producer's phone number" className="form-control mt-1" />
                            </label>
                          </div>

                          <div className="form-group mb-3">
                            <label className="form-label w-100">
                              Producer Address
                              <Field name={`producers.${idx}.address`} type="text" placeholder="The producer's full address" className="form-control mt-1" />
                            </label>
                          </div>
                        </div>
                        <div className="card-footer">
                          <button type="button" onClick={() => arrayHelpers.remove(idx)} className="btn btn-outline-danger w-100 icon-btn">
                            <BootstrapSVG iconName="trash" />
                            Remove
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="alert alert-info text-center">Please add a producer.</p>
                )}
              </div>
              <div className="card-footer">
                <button
                  onClick={() => arrayHelpers.push({ name: "", email: "", phone: "", address: "" })}
                  type="button"
                  className="btn btn-outline-primary w-100 icon-btn"
                >
                  <BootstrapSVG iconName="plus-circle" />
                  Add producer
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </FieldArray>
  );
}

function ImporterFields() {
  const { errors, values, setFieldValue } = useFormikContext();
  const { data: customer } = useGetCustomerQuery();

  useEffect(() => {
    if (values && values.certifierType === "importer") {
      setFieldValue("importerName", customer.name);
      setFieldValue("importerAddress", getFullAddress(customer));
      setFieldValue("importerPhone", customer.phone_number);
      setFieldValue("importerEmail", customer.email);
    }
  }, [values, customer, setFieldValue]);

  return (
    <>
      <div className="form-group mb-3">
        <label className="form-label w-100">
          Importer Name
          <Field name="importerName" type="text" placeholder="The importer's name" className={`form-control mt-1 ${errors.importerName ? "is-invalid" : ""}`} />
          {errors.importerName && <FormError error={errors.importerName} />}
        </label>
      </div>

      <div className="form-group mb-3">
        <label className="form-label w-100">
          Importer Email
          <Field
            name="importerEmail"
            type="email"
            placeholder="The importer's email address"
            className={`form-control mt-1 ${errors.importerEmail ? "is-invalid" : ""}`}
          />
          {errors.importerEmail && <FormError error={errors.importerEmail} />}
        </label>
      </div>

      <div className="form-group mb-3">
        <label className="form-label w-100">
          Importer Phone
          <Field name="importerPhone" type="text" placeholder="The importer's phone number" className={`form-control mt-1 ${errors.importerPhone ? "is-invalid" : ""}`} />
          {errors.importerPhone && <FormError error={errors.importerPhone} />}
        </label>
      </div>

      <div className="form-group mb-3">
        <label className="form-label w-100">
          Importer Address
          <Field name="importerAddress" type="text" placeholder="The importer's address" className={`form-control mt-1 ${errors.importerAddress ? "is-invalid" : ""}`} />
          {errors.importerAddress && <FormError error={errors.importerAddress} />}
        </label>
      </div>
    </>
  );
}

export default function CertificateOfOriginForm() {
  const selectedBomIds = useSelector(selectedBomIdsSelector);
  const { errorMsg, customer, handleSubmit } = useCertificateOfOriginForm();

  const formInitialValues = useMemo(
    () => ({
      certifyingCompany: customer?.company_name || "",
      certifierName: customer?.name || "",
      certifierType: "producer",
      certifierAddress: getFullAddress(customer),
      certifierPhoneNumber: customer?.phone_number || "",
      certifierEmail: customer?.email || "",
      effectiveDate: new Date().toISOString(),
      expirationDate: new Date().toISOString(),
      exporterName: "",
      exporterEmail: "",
      exporterPhone: "",
      exporterAddress: "",
      producerDataType: "list",
      producers: [],
      importerName: "",
      importerEmail: "",
      importerPhone: "",
      importerAddress: "",
    }),
    [customer],
  );

  return (
    <>
      <div className="row mb-5">
        <div className="col-lg-3" />
        <div className="col-lg-6">
          <div className="d-flex justify-content-center">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard" replace>
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/dashboard/certificate-of-origin/generate/">Generate USMCA Certificate</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Form
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="col-lg-3" />
      </div>
      <div className="row mb-5">
        <div className="col">
          <h1 className="text-center">Generate USMCA Certificate</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3" />
        {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
        <div className="col-lg-6">
          <Formik onSubmit={handleSubmit} validationSchema={schema} initialValues={formInitialValues}>
            {({ isSubmitting, errors }) => (
              <Form>
                <div className="mb-5">
                  <h2 className="text-center mb-4">I. CERTIFIER DATA</h2>
                  <div className="form-group mb-3">
                    <label className="form-label w-100">
                      Certifying Company*
                      <Field type="text" name="certifyingCompany" className={`form-control mt-1 ${errors.certifyingCompany ? "is-invalid" : ""}`} required />
                      {errors.certifyingCompany && <FormError error={errors.certifyingCompany} />}
                    </label>
                  </div>

                  <div className="form-group mb-3">
                    <label className="form-label w-100">
                      Certifier Name*
                      <Field type="text" name="certifierName" className={`form-control mt-1 ${errors.certifierName ? "is-invalid" : ""}`} required />
                      {errors.certifierName && <FormError error={errors.certifierName} />}
                    </label>
                  </div>

                  <div className="form-group mb-3">
                    <label className="form-label w-100">
                      Importer / Exporter / Producer*
                      <Field as="select" name="certifierType" className={`form-select mt-1 ${errors.certifierType ? "is-invalid" : ""}`} required>
                        <option value="importer">Importer</option>
                        <option value="exporter">Exporter</option>
                        <option value="producer">Producer</option>
                      </Field>
                      {errors.certifierType && <FormError error={errors.certifierType} />}
                    </label>
                  </div>

                  <div className="form-group mb-3">
                    <label className="form-label w-100">
                      Certifier Address*
                      <Field type="text" name="certifierAddress" className={`form-control mt-1 ${errors.certifierAddress ? "is-invalid" : ""}`} required />
                      {errors.certifierAddress && <FormError error={errors.certifierAddress} />}
                    </label>
                  </div>

                  <div className="form-group mb-3">
                    <label className="form-label w-100">
                      Certifier Phone Number*
                      <Field type="text" name="certifierPhoneNumber" className={`form-control mt-1 ${errors.certifierPhoneNumber ? "is-invalid" : ""}`} required />
                      {errors.certifierPhoneNumber && <FormError error={errors.certifierPhoneNumber} />}
                    </label>
                  </div>

                  <div className="form-group mb-3">
                    <label className="form-label w-100">
                      Certifier Email Address*
                      <Field type="email" name="certifierEmail" className={`form-control mt-1 ${errors.certifierEmail ? "is-invalid" : ""}`} required />
                      {errors.certifierEmail && <FormError error={errors.certifierEmail} />}
                    </label>
                  </div>

                  <div className="form-group mb-3">
                    <label htmlFor="" className="form-label w-100">
                      Effective Date*
                      <FormikFlatpickr
                        name="effectiveDate"
                        className={`form-control mt-1 ${errors.effectiveDate ? "is-invalid" : ""}`}
                        options={{ minDate: new Date() }}
                      />
                      {errors.effectiveDate && <FormError error={errors.effectiveDate} />}
                    </label>
                  </div>

                  <div className="form-group mb-3">
                    <label htmlFor="" className="form-label w-100">
                      Expiration Date*
                      <FormikFlatpickr
                        name="expirationDate"
                        className={`form-control mt-1 ${errors.expirationDate ? "is-invalid" : ""}`}
                        options={{ minDate: new Date(), maxDate: dayjs().add(12, "months").toDate() }}
                      />
                      <span className="form-text">May be up to 12 months as per Article 5.2</span>
                      {errors.expirationDate && <FormError error={errors.expirationDate} />}
                    </label>
                  </div>
                </div>

                <div className="mb-5">
                  <h2 className="text-center mb-4">II. Exporter Data</h2>

                  <ExporterFields />
                </div>

                <div className="mb-5">
                  <h2 className="text-center mb-4">III. Producer Data</h2>

                  <div className="mb-4">
                    <div className="form-check">
                      <Field id="producerDataTypeRadio" name="producerDataType" type="radio" className="form-check-input" value="single" />
                      <label className="form-check-label" htmlFor="producerDataTypeRadio">
                        Single producer
                      </label>
                    </div>

                    <div className="form-check">
                      <Field id="producerDataTypeRadio" name="producerDataType" type="radio" className="form-check-input" value="list" />
                      <label className="form-check-label" htmlFor="producerDataTypeRadio">
                        Provide a list of producers
                      </label>
                    </div>

                    <div className="form-check">
                      <Field id="producerDataTypeRadio" name="producerDataType" type="radio" className="form-check-input" value="various" />
                      <label className="form-check-label" htmlFor="producerDataTypeRadio">
                        Various producers
                      </label>
                    </div>

                    <div className="form-check">
                      <Field id="producerDataTypeRadio" name="producerDataType" type="radio" className="form-check-input" value="request" />
                      <label className="form-check-label" htmlFor="producerDataTypeRadio">
                        Available upon request by the importing authorities
                      </label>
                    </div>
                  </div>

                  <ProducersFields />
                </div>

                <div className="mb-5">
                  <h2 className="text-center mb-4">IV. Importer Data</h2>

                  <ImporterFields />
                </div>

                <div className="mb-5">
                  <h2 className="text-center mb-4">V. Qualified Goods</h2>

                  {selectedBomIds && selectedBomIds.map((bomId) => <QualifiedGoodCard key={bomId} bomId={bomId} />)}
                </div>
                {errorMsg && <p className="alert alert-danger text-center mb-5" dangerouslySetInnerHTML={{ __html: errorMsg }} />}
                <div className="d-flex justify-content-between">
                  <button type="button" className="btn btn-outline-secondary" disabled={isSubmitting}>
                    Back
                  </button>
                  <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                    Continue
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="col-lg-3" />
      </div>
    </>
  );
}
