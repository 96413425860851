import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import Progress from "../../../../components/Progress/Progress";
import { currentBOMImportIndexSelector, unparsedBOMsSelector } from "../../../../features/fileImport/fileImportSlice";
import { toOrdinal } from "../../../../utils/text";
import { COMPONENT_DETAILS_STEP, COMPONENT_QUESTIONS_STEP, FINISHED_GOOD_DETAILS_STEP, FINISHED_GOOD_QUESTIONS_STEP, SUBMISSION_STEP } from "../../constants";

function FileImportCounter() {
  const unparsedBOMs = useSelector(unparsedBOMsSelector);
  const currentIndex = useSelector(currentBOMImportIndexSelector);
  if (unparsedBOMs && unparsedBOMs.length > 1) {
    return (
      <p className="text-center mb-5">
        Importing the <b>{toOrdinal(currentIndex + 1)}</b> of <b>{unparsedBOMs.length}</b> BOMs.
      </p>
    );
  }
  return "";
}

function ProgressBar({ step }) {
  const [finishedGoodPercentComplete, setFinishedGoodPercentComplete] = useState(0);
  const [componentPercentComplete, setComponentPercentComplete] = useState(0);

  const items = useMemo(
    () => [
      {
        name: "Finished Good Details",
        active: step >= FINISHED_GOOD_DETAILS_STEP,
        percentComplete: finishedGoodPercentComplete,
      },
      {
        name: "Component Details",
        active: step >= COMPONENT_DETAILS_STEP,
        percentComplete: componentPercentComplete,
      },
      {
        name: "Submission",
        active: step === SUBMISSION_STEP,
        percentComplete: componentPercentComplete,
      },
    ],
    [step, componentPercentComplete, finishedGoodPercentComplete],
  );

  useEffect(() => {
    if (step && step === FINISHED_GOOD_DETAILS_STEP) {
      setFinishedGoodPercentComplete(0);
    } else if (step && step === FINISHED_GOOD_QUESTIONS_STEP) {
      setFinishedGoodPercentComplete(50);
    } else if (step && step > FINISHED_GOOD_QUESTIONS_STEP) {
      setFinishedGoodPercentComplete(100);
    }

    if (step && step === COMPONENT_DETAILS_STEP) {
      setComponentPercentComplete(0);
    } else if (step && step === COMPONENT_QUESTIONS_STEP) {
      setComponentPercentComplete(50);
    } else if (step && step > COMPONENT_QUESTIONS_STEP) {
      setComponentPercentComplete(100);
    }
  }, [step]);

  return (
    <div className="w-100">
      <FileImportCounter />
      <Progress items={items} />
    </div>
  );
}

ProgressBar.propTypes = {
  step: PropTypes.number.isRequired,
};

export default ProgressBar;
