import * as Sentry from "@sentry/react";
import { Auth } from "aws-amplify";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import useAlreadyLoggedIn from "../../hooks/useAlreadyLoggedIn";

export default function useEnterCode() {
  useAlreadyLoggedIn();
  const [errorMsg, setErrorMsg] = useState(null);
  const navigate = useNavigate();
  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      setSubmitting(true);
      if (values.password1 !== values.password2) {
        setErrorMsg("Both passwords must be the same.");
        setSubmitting(false);
      } else {
        Auth.forgotPasswordSubmit(values.username, values.code, values.password1)
          .then(() => {
            navigate("/login");
          })
          .catch((err) => {
            Sentry.captureException(err);
            setErrorMsg(JSON.stringify(err.message || "Unable to set a new password. Please try again."));
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    },
    [navigate],
  );

  return useMemo(
    () => ({
      handleSubmit,
      errorMsg,
    }),
    [handleSubmit, errorMsg],
  );
}
