import { Field, useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";

import FormError from "../FormError";

function FormikComponentCategoryField({ name, disabled = false }) {
  const { errors, touched } = useFormikContext();
  return (
    <>
      <label htmlFor={`select${name}`} className="form-label">
        Category*
      </label>
      <Field name={name} disabled={disabled} as="select" className={`form-select ${touched && touched[name] && errors && errors[name] ? "is-invalid" : ""}`}>
        <option value="Material">Material Costs</option>
        <option value="OtherProduct">Other Product Costs</option>
        <option value="Period">Period Costs</option>
        <option value="Other">Other Costs</option>
        <option value="Accessories">Accessories, spare parts, tools, or instructional or other information materials</option>
        <option value="Packaging">Packaging materials and containers</option>
      </Field>
      {errors && errors[name] && <FormError error={errors[name]} />}
    </>
  );
}

FormikComponentCategoryField.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default FormikComponentCategoryField;
