import { encodeURI } from "js-base64";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { useSubmitFinishedGoodMutation } from "../../../features/api/boms";
import useBom from "../../../hooks/useBom";
import useBomIdFromParams from "../../../hooks/useDecodedBomId";

export default function SubmitFinishedGood() {
  const { encodedBomId } = useParams();
  const { bomId } = useBomIdFromParams();
  const { bom } = useBom(bomId);
  const [submitFinishedGood, { isError, isSuccess, data, error }] = useSubmitFinishedGoodMutation();
  const navigate = useNavigate();
  useEffect(() => {
    if (bom) {
      if (bom.BOM_State === "unsubmitted") {
        submitFinishedGood(bomId);
      } else if (bom.BOM_State === "FG_QUALIFYING") {
        navigate(`/dashboard/bom/qualify/answer-fg-questions/${encodedBomId}/`);
      } else if (bom.BOM_State === "FG_COMPLETE") {
        navigate(`/dashboard/bom/qualify/submit-components/${encodedBomId}/`);
      } else {
        navigate(`/dashboard/bom/qualify/${encodedBomId}/`);
      }
    }
  }, [bom, navigate, bomId, encodedBomId, submitFinishedGood]);

  useEffect(() => {
    if (isSuccess) {
      navigate(`/dashboard/bom/qualify/${encodeURI(data.bom_id)}`);
    }
  }, [isSuccess, data, navigate]);

  useEffect(() => {
    if (isError) {
      Swal.fire({
        title: "Error!",
        text: `Failed to qualify finished good. ${error.error || "Unknown error."}`,
      }).then(() => navigate(`/dashboard/bom/${encodedBomId}`));
    }
  }, [error, isError, encodedBomId, navigate]);

  if (!bomId) {
    return (
      <div className="container">
        <div className="row">
          <div className="d-flex justify-content-center">
            <LoadingSpinner />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container pt-5">
      <div className="row mb-5">
        <h1 className="text-center">Qualify BOM {bomId}</h1>
      </div>
      <div className="row">
        <div className="col-lg-4" />
        <div className="col-lg-4">
          <p className="alert alert-info">Please wait while we process your finished good.</p>
        </div>
        <div className="col-lg-4" />
      </div>
    </div>
  );
}
