import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { certifierSelector, importerSelector, producerDataTypeSelector, setImporter } from "../../../../features/certificateOfOrigin/certificateOfOriginSlice";

export default function useImporterForm() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const currentCertifier = useSelector(certifierSelector);
  const currentImporter = useSelector(importerSelector);
  const producerDataType = useSelector(producerDataTypeSelector);
  const initialValues = useMemo(
    () => ({
      name: currentImporter?.name || (currentCertifier?.role === "importer" && currentCertifier?.name) || "",
      email: currentImporter?.email || (currentCertifier?.role === "importer" && currentCertifier?.emailAddress) || "",
      address: currentImporter?.address || (currentCertifier?.role === "importer" && currentCertifier?.address) || "",
      phoneNumber: currentImporter?.phoneNumber || (currentCertifier?.role === "importer" && currentCertifier?.phoneNumber) || "",
    }),
    [currentImporter, currentCertifier],
  );

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      setSubmitting(true);
      dispatch(setImporter(values));
      navigate("/dashboard/certificate-of-origin/generate/summary/");
      setSubmitting(false);
    },
    [navigate, dispatch],
  );

  useEffect(() => {
    if (!producerDataType) {
      Swal.fire({
        title: "Error!",
        text: "Please provide producer information first.",
        icon: "error",
      }).then(() => {
        navigate("/dashboard/certificate-of-origin/generate/producer-data/");
      });
    } else {
      setLoading(false);
    }

    return () => setLoading(true);
  }, [producerDataType, navigate]);

  return useMemo(() => ({ initialValues, handleSubmit, loading }), [initialValues, handleSubmit, loading]);
}
