import React from "react";
import { useSelector } from "react-redux";

import { bomCountSelector, totalBomsPurchasedSelector } from "../../features/usageLimits/usageLimitsSlice";
import useBomList from "../../hooks/useBomList";
import useBOMUsage from "./useBOMUsage";

function LimitReachedMessage() {
  return <p className="bg-light rounded m-0 py-1 px-2">Great Job! Click upgrade to purchase more BOMs.</p>;
}

function BOMCounter() {
  const { boms } = useBomList();
  const bomCountAvailable = useSelector(bomCountSelector);
  const totalBomsPurchased = useSelector(totalBomsPurchasedSelector);

  return (
    <div>
      <p className="m-0 mb-1 text-primary fw-bold">
        {(boms && boms.length) || 0}/{totalBomsPurchased || 0} BOMs created | You have {bomCountAvailable || 0} remaining.
      </p>
    </div>
  );
}

function BOMUsage() {
  const { maxReached } = useBOMUsage();

  return maxReached ? <LimitReachedMessage /> : <BOMCounter />;
}

export default BOMUsage;
