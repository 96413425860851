import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import ComponentCard from "../../../components/ComponentCard/ComponentCard";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { useAnswerComponentQuestionsMutation } from "../../../features/api/boms";
import useBom from "../../../hooks/useBom";
import useBomIdFromParams from "../../../hooks/useDecodedBomId";

function QuestionField({ question }) {
  return (
    <div className="mb-3">
      <p>{question.Question}</p>
      <div className="form-check">
        <Field id={`questionY${question.RuleID}`} className="form-check-input" type="radio" name={`${question.RuleID.replaceAll("#", "_")}`} value="Y" />
        <label htmlFor={`questionY${question.RuleID}`} className="form-check-label">
          Yes
        </label>
      </div>

      <div className="form-check">
        <Field id={`questionN${question.RuleID}`} className="form-check-input" type="radio" name={`${question.RuleID.replaceAll("#", "_")}`} value="N" />
        <label htmlFor={`questionN${question.RuleID}`} className="form-check-label">
          No
        </label>
      </div>
    </div>
  );
}

function QuestionsList({ questions }) {
  return (
    <div>
      {questions.map((question, idx) => (
        <div key={question.RuleID} className="mb-3">
          <h4 className="mb-3">Question {idx + 1}</h4>
          <QuestionField question={question} />
        </div>
      ))}
    </div>
  );
}

function QuestionDisplay({ component, questions }) {
  return (
    <div className="mb-3 border p-3 rounded">
      <div className="mb-4">
        <ComponentCard component={component} />
      </div>
      <QuestionsList questions={questions} />
    </div>
  );
}

function QuestionsForm({ bom, handleSubmit }) {
  const [questions, setQuestions] = useState(null);
  const [componentsAndQuestions, setComponentsAndQuestions] = useState(null);
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    if (bom && Array.isArray(bom.Comp_Qs) && bom.Comp_Qs.length > 0) {
      const values = {};
      bom.Comp_Qs.forEach((question) => {
        const name = question.RuleID.replaceAll("#", "_");
        values[name] = "N";
      });
      setInitialValues(values);
    } else {
      setInitialValues({});
    }

    return () => {
      setInitialValues({});
    };
  }, [bom]);

  useEffect(() => {
    if (bom && Array.isArray(bom.Comp_Qs) && bom.Comp_Qs.length > 0) {
      setQuestions([...bom.Comp_Qs]);
    } else {
      setQuestions(null);
    }

    return () => {
      setQuestions(null);
    };
  }, [bom]);

  useEffect(() => {
    if (questions) {
      const result = [];
      bom.Components.forEach((component) => {
        const currentQuestions = questions.filter((q) => {
          const hts = q.RuleID.split("#")[0];
          return hts === component.Hts;
        });
        if (currentQuestions && currentQuestions.length > 0) {
          result.push({ component, questions: currentQuestions });
        }
      });
      setComponentsAndQuestions(result);
    } else {
      setComponentsAndQuestions(null);
    }

    return () => {
      setComponentsAndQuestions(null);
    };
  }, [questions, bom]);

  if (!bom) {
    return (
      <div className="d-flex justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Formik enableReinitialize onSubmit={handleSubmit} initialValues={initialValues}>
      {({ isSubmitting }) => (
        <Form>
          {componentsAndQuestions &&
            componentsAndQuestions.map((c, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <QuestionDisplay key={`question${idx}`} questions={c.questions} component={c.component} />
            ))}
          <div className="d-flex justify-content-center align-items-center gap-2">
            <Link to="/dashboard" className="btn btn-outline-primary fw-bold text-uppercase">
              Cancel & Go Back
            </Link>
            <button type="submit" disabled={isSubmitting} className="btn btn-primary fw-bold text-uppercase">
              Save & Continue
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default function AnswerComponentQuestions() {
  const { encodedBomId } = useParams();
  const { bomId } = useBomIdFromParams();
  const { bom } = useBom(bomId);
  const navigate = useNavigate();
  const [answerComponentQuestions, { isError, error }] = useAnswerComponentQuestionsMutation();

  function handleSubmit(values, { setSubmitting }) {
    setSubmitting(true);
    const requestBody = Object.entries(values).map(([componentId, answer]) => ({
      component_id: componentId.replace("_", "#"),
      accepted: answer === "Y",
    }));
    answerComponentQuestions({ bomId, body: requestBody });
  }

  useEffect(() => {
    if (isError) {
      Swal.fire({
        title: "Error!",
        text: `Failed to process components. ${error.error || "Unknown error."}`,
      }).then(() => {
        navigate(`/dashboard/bom/${encodedBomId}`, { replace: true });
      });
    }
  }, [isError, error, navigate, encodedBomId]);

  useEffect(() => {
    if (bom && bom.BOM_State !== "COMPS_QUALIFYING") {
      navigate(`/dashboard/bom/qualify/${encodedBomId}`);
    }
  }, [bom, encodedBomId, navigate]);

  if (!bomId) {
    return (
      <div className="container pt-5">
        <div className="row">
          <div className="d-flex justify-content-center">
            <LoadingSpinner />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container pt-5">
      <div className="row mb-5">
        <h1 className="text-center">Component Questions | BOM {bomId}</h1>
      </div>
      <div className="row">
          <p className="mb-5 alert alert-info text-center">Answer the following questions about your components up front for various rules.</p>
          <QuestionsForm bom={bom} handleSubmit={handleSubmit} />
      </div>
    </div>
  );
}
