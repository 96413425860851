import PropTypes from "prop-types";
import React from "react";

import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import useBom from "../../../hooks/useBom";

function QualifiedGoodCard({ bomId }) {
  const { bom } = useBom(bomId);

  if (!bom) {
    return (
      <div className="d-flex justify-content-center mb-5">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="card mb-3">
      <div className="card-header">
        <h5 className="m-0">{bom.BOMID}</h5>
      </div>
      <ul className="list-group list-group-flush">
        <li className="list-group-item">
          <b>Finished Good Part Number:</b> {bom.FG.Part}
        </li>
        <li className="list-group-item">
          <b>USHTS:</b> {bom.FG.Hts}
        </li>
        <li className="list-group-item">
          <b>Origin Preference Criteria:</b> {bom.QualPref}
        </li>
      </ul>
    </div>
  );
}

QualifiedGoodCard.propTypes = {
  bomId: PropTypes.string.isRequired,
};

export default QualifiedGoodCard;
