import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useGetTotalBomsPurchasedQuery } from "../features/api/payments";
import { setTotalBomsPurchased } from "../features/usageLimits/usageLimitsSlice";

export default function useUpdateTotalBomsPurchased() {
  const { data: total } = useGetTotalBomsPurchasedQuery(null, { pollingInterval: 3000 });
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof total !== "undefined" && dispatch) {
      dispatch(setTotalBomsPurchased(total));
    }
  }, [total, dispatch]);
}
