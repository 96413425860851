import PropTypes from "prop-types";
import React, { useCallback, useRef } from "react";
import { PiUserCircle } from "react-icons/pi";

import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

function CustomerAvatarChanger({ currentImage = null, setImage, isLoading = false }) {
  const fileInputRef = useRef();

  const handleFileChange = useCallback(
    (e) => {
      const file = e.target.files[0];
      if (file) {
        setImage(file);
      }
    },
    [setImage],
  );

  let avatarDisplay;

  if (isLoading) {
    avatarDisplay = <LoadingSpinner />;
  } else if (currentImage) {
    avatarDisplay = <img className="img-fluid rounded-circle" src={currentImage} alt="Your avatar" />;
  } else {
    avatarDisplay = <PiUserCircle size={80} />;
  }

  return (
    <div>
      <h3 className="mb-4">Avatar</h3>
      <div className="d-flex align-items-center gap-4">
        <div style={{ width: "6rem" }}>{avatarDisplay}</div>

        <div style={{ width: "14rem" }} className="d-flex flex-column gap-2 justify-content-center">
          <button disabled={isLoading} style={{ width: "7rem" }} onClick={() => fileInputRef.current.click()} type="button" className="btn btn-secondary fw-bold">
            Upload
          </button>
          <input type="file" className="d-none" ref={fileInputRef} onChange={handleFileChange} />
          <p className="text-muted m-0">Upload images up to 256x256 and 4mb in size.</p>
        </div>
      </div>
    </div>
  );
}

CustomerAvatarChanger.propTypes = {
  currentImage: PropTypes.string,
  setImage: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default CustomerAvatarChanger;
