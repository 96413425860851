import PropTypes from "prop-types";
import React from "react";
import { PiPencilSimpleLine } from "react-icons/pi";
import { Link } from "react-router-dom";

import { CERTIFIER_DATA_PART_TYPE, EXPORTER_DATA_PART_TYPE, IMPORTER_DATA_PART_TYPE, PRODUCER_DATA_PART_TYPE, QUALIFIED_BILLS_PART_TYPE } from "../../constants";
import CertifierBodyPart from "../CertifierBodyPart/CertifierBodyPart";
import ExporterBodyPart from "../ExporterBodyPart/ExporterBodyPart";
import ImporterBodyPart from "../ImporterBodyPart/ImporterBodyPart";
import ProducerBodyPart from "../ProducerBodyPart/ProducerBodyPart";
import QualifiedBillsBodyPart from "../QualifiedBillsBodyPart/QualifiedBillsBodyPart";
import useSummaryPart from "./useSummaryPart";

function SummaryPart({ type }) {
  const { title, editText, editLink } = useSummaryPart(type);

  let bodyPart;

  if (type === QUALIFIED_BILLS_PART_TYPE) {
    bodyPart = <QualifiedBillsBodyPart />;
  } else if (type === CERTIFIER_DATA_PART_TYPE) {
    bodyPart = <CertifierBodyPart />;
  } else if (type === EXPORTER_DATA_PART_TYPE) {
    bodyPart = <ExporterBodyPart />;
  } else if (type === IMPORTER_DATA_PART_TYPE) {
    bodyPart = <ImporterBodyPart />;
  } else if (type === PRODUCER_DATA_PART_TYPE) {
    bodyPart = <ProducerBodyPart />;
  }
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>{title}</h2>
        <Link to={editLink} className="btn text-secondary fw-bold text-uppercase d-flex gap-1 align-items-center">
          <PiPencilSimpleLine />
          {editText}
        </Link>
      </div>
      {bodyPart}
    </div>
  );
}

SummaryPart.propTypes = {
  type: PropTypes.oneOf([QUALIFIED_BILLS_PART_TYPE, CERTIFIER_DATA_PART_TYPE, PRODUCER_DATA_PART_TYPE, EXPORTER_DATA_PART_TYPE, IMPORTER_DATA_PART_TYPE]).isRequired,
};

export default SummaryPart;
