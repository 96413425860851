import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { setOpenSidebar } from "../../../features/ui/navigationSlice";
import BootstrapSVG from "../../BootstrapSVG";

export default function CollapseButton() {
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(setOpenSidebar(false));
  }, [dispatch]);
  return (
    <button style={{ width: "22vw" }} onClick={handleClick} type="button" className="btn icon-btn btn-outline-secondary rounded-0 position-fixed bottom-0 left-0">
      <BootstrapSVG iconName="box-arrow-left" />
    </button>
  );
}
