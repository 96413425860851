import { Field, Form, Formik } from "formik";
import React, { useCallback, useState } from "react";
import { PiMagnifyingGlassBold } from "react-icons/pi";
import { useSearchParams } from "react-router-dom";

function SearchButton() {
  const [showInput, setShowInput] = useState(false);
  const setSearchParams = useSearchParams()[1];

  const handleSearch = useCallback(
    (values) => {
      setSearchParams((currentParams) => {
        const newParams = new URLSearchParams(currentParams.toString());
        newParams.set("query", encodeURI(values.query || ""));
        return newParams;
      });
    },
    [setSearchParams],
  );

  if (showInput) {
    return (
      <div className="position-relative me-2">
        <Formik onSubmit={handleSearch} initialValues={{ query: "" }}>
          {() => (
            <Form>
              <Field placeholder="Enter a search term" className="form-control" name="query" />
              <button type="submit" style={{ right: ".5rem", top: ".25rem" }} className="btn p-0 no-border-no-outline position-absolute">
                <PiMagnifyingGlassBold />
              </button>
            </Form>
          )}
        </Formik>
      </div>
    );
  }

  return (
    <button onClick={() => setShowInput(true)} type="button" className="btn icon-btn no-border-no-outline">
      <PiMagnifyingGlassBold />
    </button>
  );
}

export default SearchButton;
