import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { DEFAULT_DAY_JS_DATETIME_FORMAT } from "../../../constants";
import { setSelectedBomIds } from "../../../features/certificateOfOrigin/certificateOfOriginSlice";
import bomFromApiPropType from "../../../propTypes/bomSummaryPropType";
import { htsDisplay } from "../../../utils/bom";
import { useGenerateCertificateContext } from "./GenerateCertificateContext";

function QualifiedCardsTable({ qualifiedBoms }) {
  const { bomIsSelected, addOrRemoveBomId } = useGenerateCertificateContext();
  const dispatch = useDispatch();

  const handleToggleSelectAll = useCallback(
    (e) => {
      const { checked } = e.target;
      if (checked) {
        dispatch(setSelectedBomIds(qualifiedBoms.map((bom) => bom.BOMID)));
      } else {
        dispatch(setSelectedBomIds([]));
      }
    },
    [dispatch, qualifiedBoms],
  );

  return (
    <table style={{ verticalAlign: "middle" }} className="table">
      <thead>
        <tr>
          <th scope="col">BOM ID</th>
          <th scope="col">Part Number</th>
          <th scope="col">Description</th>
          <th scope="col">USHTS</th>
          <th scope="col">Value</th>
          <th scope="col">Origin</th>
          <th scope="col">Submitted At</th>
          <th scope="col">
            <input type="checkbox" className="form-check-input" title="Select all" aria-label="Select all boms" onChange={handleToggleSelectAll} />
          </th>
        </tr>
      </thead>
      <tbody>
        {qualifiedBoms.map((bom) => (
          <tr key={bom.BOMID}>
            <th scope="row">{bom.BOMID}</th>
            <td>{bom.Part}</td>
            <td>{bom.Desc}</td>
            <td>{htsDisplay(bom.Hts)}</td>
            <td>{bom.Value}</td>
            <td>{bom.Origin}</td>
            <td>{dayjs.unix(bom.TS).format(DEFAULT_DAY_JS_DATETIME_FORMAT)}</td>
            <td>
              <input type="checkbox" className="form-check-input" checked={bomIsSelected(bom.BOMID)} onChange={() => addOrRemoveBomId(bom.BOMID)} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

QualifiedCardsTable.propTypes = {
  qualifiedBoms: PropTypes.arrayOf(bomFromApiPropType).isRequired,
};

export default QualifiedCardsTable;
