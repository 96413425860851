import { nanoid } from "nanoid";
import { useCallback, useEffect, useState } from "react";
import { PiPencil } from "react-icons/pi";
import { useSelector } from "react-redux";

import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import { currentUnparsedBOMSelector, mappedComponentHeaders } from "../../../../features/fileImport/fileImportSlice";
import { componentCategoryDisplay, htsDisplay } from "../../../../utils/bom";
import EditComponentModal from "./EditComponentModal";

function ComponentCard({ componentFromCsv, openModal }) {
  const [actualComponent, setActualComponent] = useState(null);
  const matchedComponentHeaders = useSelector(mappedComponentHeaders);

  useEffect(() => {
    if (componentFromCsv && matchedComponentHeaders) {
      setActualComponent({
        partNumber: componentFromCsv[matchedComponentHeaders.partNumber],
        hts: componentFromCsv[matchedComponentHeaders.hts],
        description: componentFromCsv[matchedComponentHeaders.description],
        units: componentFromCsv[matchedComponentHeaders.units],
        qty: componentFromCsv[matchedComponentHeaders.qty],
        unitCost: componentFromCsv[matchedComponentHeaders.unitCost],
        origin: componentFromCsv[matchedComponentHeaders.origin],
        originating: componentFromCsv[matchedComponentHeaders.originating],
        category: componentFromCsv[matchedComponentHeaders.category],
      });
    }
  }, [componentFromCsv, matchedComponentHeaders]);

  if (!actualComponent) {
    return (
      <div style={{ width: "250px" }} className="d-flex justify-content-center align-items-center">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <div style={{ height: "max-content" }} className="bg-light-2 rounded p-3 d-flex flex-column justify-content-between">
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <h4>{actualComponent.partNumber}</h4>
          <button onClick={openModal} type="button" className="btn no-border-no-outline text-primary">
            <PiPencil />
          </button>
        </div>
        <p>{actualComponent.description}</p>
        <p className="mb-4">
          <b>Category:</b> {componentCategoryDisplay(actualComponent.category)}
        </p>
      </div>
      <div style={{ gridTemplateRows: "1fr 1fr", gridTemplateColumns: "repeat(3, max-content)", justifyItems: "center" }} className="d-grid gap-5">
        <p style={{ width: "max-content" }} className="m-0 px-2 py-1 bg-light-0 rounded-pill text-center text-secondary">
          COO | {actualComponent.origin}
        </p>
        <p style={{ width: "max-content" }} className="m-0 px-2 py-1 bg-light-0 rounded-pill text-center text-secondary">
          HTS | {htsDisplay(actualComponent.hts)}
        </p>
        <p style={{ width: "max-content", justifySelf: "end" }} className="m-0 px-2 py-1 bg-light-0 rounded-pill text-center text-secondary">
          Qty | {actualComponent.qty}
        </p>
        <p style={{ width: "max-content" }} className="m-0 px-2 py-1 bg-light-0 rounded-pill text-center text-secondary">
          Unit Cost | {actualComponent.unitCost}
        </p>
        <p style={{ width: "max-content" }} className="m-0 px-2 py-1 bg-light-0 rounded-pill text-center text-secondary">
          Originating | {actualComponent.originating === "Y" ? "Yes" : "No"}
        </p>
        <p style={{ width: "max-content" }} className="m-0 px-2 py-1 bg-light-0 rounded-pill text-center text-secondary">
          Units | {actualComponent.units}
        </p>
      </div>
    </div>
  );
}

export default function ComponentCards() {
  const [components, setComponents] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentlyEditing, setCurrentlyEditing] = useState(null);
  const currentUnparsedBOM = useSelector(currentUnparsedBOMSelector);

  useEffect(() => {
    if (currentUnparsedBOM) {
      setComponents(currentUnparsedBOM.filter((row) => row.Item === "Component"));
    }

    return () => {
      setComponents(null);
    };
  }, [currentUnparsedBOM]);

  const handleOpenModal = useCallback((componentIdx) => {
    setShowModal(true);
    setCurrentlyEditing(componentIdx);
  }, []);

  const handleHideModal = useCallback(() => {
    setShowModal(false);
    setCurrentlyEditing(null);
  }, []);

  if (!components) {
    return (
      <div style={{ width: "250px" }} className="d-flex justify-content-center align-items-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      {components.map((component, idx) => (
        <div key={nanoid()} className="mb-3">
          <ComponentCard componentFromCsv={component} openModal={() => handleOpenModal(idx)} />
        </div>
      ))}
      {showModal && <EditComponentModal show={showModal} onHide={handleHideModal} componentIdx={currentlyEditing} />}
    </div>
  );
}
