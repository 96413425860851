import print from "print-js";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { PiCopy, PiDownloadSimple, PiEye, PiGear, PiPrinter, PiTrash } from "react-icons/pi";
import OutsideClickHandler from "react-outside-click-handler";
import { Link } from "react-router-dom";

import useBom from "../../../../hooks/useBom";
import useCloneBom from "../../../../hooks/useCloneBom";
import useEncodedBomId from "../../../../hooks/useEncodedBomId";
import useQualificationPdf from "../../../../hooks/useQualificationPdf";
import bomDetail from "../../../../propTypes/bomDetail";
import DeleteBOMConfirmationModal from "../../../DeleteBOMConfirmationModal";
import LoadingSpinner from "../../../LoadingSpinner/LoadingSpinner";
import PDFViewerModal from "../../../PDFViewerModal/PDFViewerModal";

function DeleteBOMButton({ bomId }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <button
        onClick={() => setShowModal(true)}
        type="button"
        className="btn bom-card__settings-btn-dropdown-item d-flex align-items-center gap-1 justify-content-start w-100"
      >
        <PiTrash /> Delete
      </button>
      <DeleteBOMConfirmationModal bomId={bomId} show={showModal} onHide={() => setShowModal(false)} onSuccess={() => setShowModal(false)} />
    </>
  );
}

function DuplicateButton({ bom }) {
  const { handleClone, isCloning } = useCloneBom();

  return (
    <button
      onClick={() => handleClone(bom)}
      type="button"
      className="btn bom-card__settings-btn-dropdown-item d-flex align-items-center gap-1 justify-content-start w-100"
      disabled={isCloning}
    >
      <PiCopy />
      Duplicate
    </button>
  );
}

DuplicateButton.propTypes = {
  bom: bomDetail.isRequired,
};

function DownloadReportButton({ bom }) {
  const [showPdfReader, setShowPdfReader] = useState(false);
  const { pdf } = useQualificationPdf(bom.BOMID);

  const handleClick = useCallback(() => {
    setShowPdfReader(true);
  }, []);

  return (
    <>
      <button onClick={handleClick} type="button" className="btn bom-card__settings-btn-dropdown-item w-100 d-flex align-items-center gap-1 justify-content-start">
        <PiDownloadSimple />
        View Report
      </button>
      <PDFViewerModal show={showPdfReader} title={`BOM ID | ${bom.BOMID} Qualification Report`} downloadUrl={pdf?.url} onHide={() => setShowPdfReader(false)} />
    </>
  );
}

DownloadReportButton.propTypes = {
  bom: bomDetail.isRequired,
};

function PrintReportButton({ bom }) {
  const { pdf } = useQualificationPdf(bom.BOMID);

  const handleClick = useCallback(() => {
    if (pdf && pdf.url) {
      fetch(pdf.url)
        .then((response) => {
          if (response.ok) {
            return response.blob();
          }
          return false;
        })
        .then((responseData) => {
          if (responseData) {
            print(URL.createObjectURL(responseData));
          }
        });
    }
  }, [pdf]);

  return (
    <button onClick={handleClick} type="button" className="btn bom-card__settings-btn-dropdown-item w-100 d-flex align-items-center gap-1 justify-content-start">
      <PiPrinter />
      Print Report
    </button>
  );
}

PrintReportButton.propTypes = {
  bom: bomDetail.isRequired,
};

function ViewBOMButton({ bom }) {
  const { encodedId } = useEncodedBomId(bom.BOMID);

  if (!encodedId) {
    return <LoadingSpinner />;
  }
  return (
    <Link className="btn bom-card__settings-btn-dropdown-item w-100 d-flex align-items-center gap-1 justify-content-start" to={`/dashboard/bom/${encodedId}/`}>
      <PiEye />
      View BOM
    </Link>
  );
}

ViewBOMButton.propTypes = {
  bom: bomDetail.isRequired,
};

function SettingsButton({ bomId }) {
  const { bom, loading } = useBom(bomId);
  const [showDropdown, setShowDropdown] = useState(false);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="position-relative">
      <OutsideClickHandler onOutsideClick={() => setShowDropdown(false)}>
        <>
          <button
            aria-label="Options"
            onClick={() => setShowDropdown(!showDropdown)}
            data-current-bom-id={bom.BOMID}
            type="button"
            className="btn bom-card__settings-btn"
          >
            <PiGear />
          </button>
          <div
            style={{ backgroundColor: "white" }}
            className={`bom-card__settings-btn-dropdown ${
              showDropdown ? "bom-card__settings-btn-dropdown--active" : ""
            } d-flex flex-column position-absolute rounded p-2`}
          >
            <ViewBOMButton bom={bom} />
            <DuplicateButton bom={bom} />
            {bom && bom.BOM_State === "CLOSED" && (
              <>
                <DownloadReportButton bom={bom} />
                <PrintReportButton bom={bom} />
              </>
            )}
            {bom && bom.BOM_State === "unsubmitted" && <DeleteBOMButton bomId={bom.BOMID} />}
          </div>
        </>
      </OutsideClickHandler>
    </div>
  );
}

SettingsButton.propTypes = {
  bomId: PropTypes.string.isRequired,
};

export default SettingsButton;
