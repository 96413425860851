import React from "react";
import { Link } from "react-router-dom";

import { useGenerateCertificateContext } from "./GenerateCertificateContext";

function ActionButtons() {
  const { handleContinue, selectedBomIds } = useGenerateCertificateContext();
  return (
    <div className="d-flex gap-2 align-items-center justify-content-center">
      <Link className="btn btn-outline-primary text-uppercase fw-bold" to="/dashboard/certificate-of-origin/">
        Cancel & Go Back
      </Link>
      <button type="button" onClick={() => handleContinue(selectedBomIds)} className="btn btn-primary fw-bold text-uppercase">
        Save & Continue
      </button>
    </div>
  );
}

export default ActionButtons;
