import { Field, Form, Formik } from "formik";
import React from "react";
import { Link } from "react-router-dom";

import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { useFinishedGoodQuestionsContext } from "./FinishedGoodQuestionsContext";
import useFinishedGoodQuestionsForm from "./useFinishedGoodQuestionsForm";

function FinishedGoodQuestionsForm() {
  const { initialValues, handleSubmit } = useFinishedGoodQuestionsForm();
  const { bom, bomLoading } = useFinishedGoodQuestionsContext();

  if (bomLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues}>
      {({ isSubmitting }) => (
        <Form>
          {bom &&
            bom.FG_Qs.map((question, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`question${idx}`} className="mb-3">
                <p>{question.Question}</p>
                <div className="form-check">
                  <Field id={`questionY${idx}`} type="radio" className="form-check-input" name={`question${idx}`} value="Y" />
                  <label htmlFor={`questionY${idx}`} className="form-check-label">
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <Field id={`questionN${idx}`} type="radio" className="form-check-input" name={`question${idx}`} value="N" />
                  <label htmlFor={`questionN${idx}`} className="form-check-label">
                    No
                  </label>
                </div>
              </div>
            ))}
          <div className="d-flex justify-content-center align-items-center gap-2">
            <Link className="btn btn-outline-primary fw-bold text-uppercase" to="/dashboard">
              Cancel & Go Back
            </Link>
            <button disabled={isSubmitting} type="submit" className="btn btn-primary fw-bold text-uppercase">
              Save & Continue
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default FinishedGoodQuestionsForm;
