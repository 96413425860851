import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { clearState, successfulImportsSelector } from "../../../features/fileImport/fileImportSlice";
import useNavigateToImportSummary from "../../../hooks/useNavigateToImportSummary";

export default function useCancelImport() {
  const successfulImports = useSelector(successfulImportsSelector);
  const dispatch = useDispatch();
  const navigateToImportSummary = useNavigateToImportSummary();

  const cancel = useCallback(() => {
    if (successfulImports && successfulImports.length > 0) {
      navigateToImportSummary();
    } else {
      dispatch(clearState(true));
    }
  }, [dispatch, navigateToImportSummary, successfulImports]);

  return useMemo(() => ({ cancel }), [cancel]);
}
