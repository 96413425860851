import PropTypes from "prop-types";

export default PropTypes.shape({
  BOMID: PropTypes.string,
  TS: PropTypes.string,
  Hts: PropTypes.string,
  Part: PropTypes.string,
  Desc: PropTypes.string,
  Origin: PropTypes.string,
});
