import { nanoid } from "nanoid";
import React, { useEffect } from "react";
import { PiPlus } from "react-icons/pi";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import ComponentCard from "../../../components/ComponentCard/ComponentCard";
import FinishedGoodDetailsCard from "../../../components/FinishedGoodDetailsCard/FinishedGoodDetailsCard";
import FinishedGoodQuestions from "../../../components/FinishedGoodQuestions/FinishedGoodQuestions";
import FixedScrollToBottomButton from "../../../components/FixedScrollToBottomButton/FixedScrollToBottomButton";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import QuestionsListGroup from "../../../components/QuestionsListGroup/QuestionsListGroup";
import ToggleViewButton from "../../../components/ToggleViewButton/ToggleViewButton";
import { bomIdSelector } from "../../../features/bomWizard/bomWizardSlice";
import { sidebarStateSelector } from "../../../features/ui/navigationSlice";
import useBom from "../../../hooks/useBom";
import useViewAs from "../../../hooks/useViewAs";
import bomDetail from "../../../propTypes/bomDetail";
import ProgressBar from "../components/ProgressBar/ProgressBar";
import { SUBMISSION_STEP } from "../constants";
import ComponentsTable from "./ComponentsTable";
import useSubmission from "./useSubmission";

function Components({ bom }) {
  const { viewAs } = useViewAs();

  if (viewAs === "card") {
    return bom.Components.map((component) => (
      <div key={nanoid()} className="mb-3">
        <ComponentCard component={component} />
      </div>
    ));
  }

  return <ComponentsTable components={bom.Components} />;
}

Components.propTypes = {
  bom: bomDetail.isRequired,
};

function Submission() {
  const bomId = useSelector(bomIdSelector);
  const { bom, loading } = useBom(bomId);
  const navigate = useNavigate();
  const { handleSubmit, submitting, handleCancel } = useSubmission();
  const sidebarIsOpen = useSelector(sidebarStateSelector);

  useEffect(() => {
    if (bomId === null && typeof navigate !== "undefined") {
      navigate("/dashboard");
    }
  }, [bomId, navigate]);

  let body;

  if (loading) {
    body = (
      <div className="d-flex justify-content-center">
        <LoadingSpinner />
      </div>
    );
  } else if (bom) {
    body = (
      <div>
        <h2 className="mb-5">Components</h2>
        <div className="mb-4 d-flex justify-content-between gap-2">
          <Link to="/dashboard/create-bill-of-material/component-details/" className="btn btn-outline-secondary d-flex align-items-center gap-1">
            <PiPlus />
            {bom.Components.length > 0 ? "Add more" : "Add one"}
          </Link>
          <ToggleViewButton border />
        </div>
        <div className="mb-5">
          <div className="d-flex flex-column gap-3">
            <Components bom={bom} />
            <div>
              {bom.Comp_Qs.length > 0 && (
                <div>
                  <h3 className="mb-3">Questions</h3>
                  <QuestionsListGroup questions={bom.Comp_Qs} forComponent />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center gap-2 align-items-center">
          <button onClick={handleCancel} type="button" className="btn btn-outline-secondary text-uppercase fw-bold">
            Cancel & Go Back
          </button>
          <button disabled={submitting} onClick={handleSubmit} type="button" className="btn btn-primary fw-bold text-uppercase">
            Submit for revision
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className="container pt-5">
      <div className="row">
        <div className="col-lg-2" />
        <div className={`${sidebarIsOpen ? "col-lg-12" : "col-lg-8"}`}>
          <div className="mb-5 d-flex w-100">
            <ProgressBar step={SUBMISSION_STEP} />
          </div>

          <div className="mb-5">
            <h1 className="mb-3">Submission</h1>
            <p>Review the data below to ensure it is accurate before submitting this bill of material for review.</p>
          </div>

          <div className="mb-5 border-bottom pb-4">
            <div className="d-flex flex-column gap-4">
              <FinishedGoodDetailsCard bomId={bomId} />
              {bom?.FG_Qs.length > 0 && <FinishedGoodQuestions bom={bom} />}
            </div>
          </div>
          <div>{body}</div>
        </div>
        <div className="col-lg-2" />
      </div>
      <FixedScrollToBottomButton />
    </div>
  );
}

export default Submission;
