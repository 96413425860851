import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import useBomList from "../../hooks/useBomList";

const HAS_BOMS_TITLE = "Great Job!";
const HAS_BOMS_BODY = "You have used up all your BOM credits.";
const HAS_BOMS_BUTTON_TEXT = "Purchase more";
const NO_BOMS_TITLE = "No Credits!";
const NO_BOMS_BODY = "Add credits to your account to create your first BOM.";
const NO_BOMS_BUTTON_TEXT = "Buy credits";

function LimitReachedModal({ show, onHide }) {
  const [content, setContent] = useState({ title: NO_BOMS_BODY, body: NO_BOMS_BODY, btn: NO_BOMS_BUTTON_TEXT });
  const { boms } = useBomList();

  useEffect(() => {
    if (boms && boms.length > 0) {
      setContent({ title: HAS_BOMS_TITLE, body: HAS_BOMS_BODY, btn: HAS_BOMS_BUTTON_TEXT });
    } else {
      setContent({ title: NO_BOMS_TITLE, body: NO_BOMS_BODY, btn: NO_BOMS_BUTTON_TEXT });
    }

    return () => {
      setContent({ title: NO_BOMS_TITLE, body: NO_BOMS_BODY, btn: NO_BOMS_BUTTON_TEXT });
    };
  }, [boms]);

  return (
    <Modal show={show} centered onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h3>{content.title}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{content.body}</p>
        <Link className="btn btn-primary" to="/upgrade">
          {content.btn}
        </Link>
      </Modal.Body>
    </Modal>
  );
}

LimitReachedModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default LimitReachedModal;
