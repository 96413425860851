import React from "react";
import { useSelector } from "react-redux";

import { certifierSelector } from "../../../../../features/certificateOfOrigin/certificateOfOriginSlice";

function CertifierBodyPart() {
  const certifier = useSelector(certifierSelector);

  return (
    <div className="bg-light-2 p-3 rounded">
      <div className="mb-4 d-flex justify-content-between align-items-center">
        <h6 className="m-0">Certifier&apos;s Company</h6>
        <p className="m-0 text-muted">{certifier.company}</p>
      </div>

      <div className="mb-4 d-flex justify-content-between align-items-center">
        <h6 className="m-0">Certifier&apos;s Name</h6>
        <p className="m-0 text-muted">{certifier.name}</p>
      </div>

      <div className="mb-4 d-flex justify-content-between align-items-center">
        <h6 className="m-0">Certifier&apos;s Email Address</h6>
        <p className="m-0 text-muted">{certifier.emailAddress}</p>
      </div>

      <div className="mb-4 d-flex justify-content-between align-items-center">
        <h6 className="m-0">Certifier&apos;s Phone Number</h6>
        <p className="m-0 text-muted">{certifier.phoneNumber}</p>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <h6 className="m-0">Certifier&apos;s Address</h6>
        <p className="m-0 text-muted">{certifier.address}</p>
      </div>
    </div>
  );
}

export default CertifierBodyPart;
