/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field, Form, Formik } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import { object, string } from "yup";

import FormError from "../../components/FormError";
import FormikPasswordField from "../../components/FormikPasswordField/FormikPasswordField";
import leftArrowImage from "../../images/left-arrow.svg";
import useLogin from "./useLogin";

const schema = object().shape({
  username: string().required("Please enter your username.").min(2),
  password: string().required("Please enter your password.").min(4),
});

export default function Login() {
  const { handleSubmit, error } = useLogin();

  return (
    <div className="flex-grow-1 d-flex flex-column justify-content-between h-100">
      <div>
        <a href="https://kendricktrade.com" className="fw-bold text-decoration-none mb-4 text-uppercase text-primary d-flex align-items-center gap-3">
          <img src={leftArrowImage} alt="Left arrow" />
          Go Back
        </a>
        <h2 className="mb-4">Welcome Back</h2>
        <div className="mb-5">
          <p>
            New to Kendrick Trade?
            <Link className="fw-bold text-decoration-none text-uppercase text-primary ms-1" to="/auth/register">
              REGISTER FOR FREE
            </Link>
          </p>
        </div>
        {error && <p className="alert alert-danger">{error}</p>}
        <Formik validationSchema={schema} onSubmit={handleSubmit} initialValues={{ username: "", password: "" }}>
          {({ isSubmitting, errors }) => (
            <Form>
              <div className="mb-3">
                <label htmlFor="inputUsername" className="form-label">
                  Username
                </label>
                <Field id="inputUsername" className={`form-control ${errors && errors.username ? "is-invalid" : ""}`} name="username" placeholder="Enter your username" />
                {errors && errors.username && <FormError error={errors.username} />}
              </div>
              <div className="mb-3">
                <label htmlFor="inputPassword" className="form-label">
                  Password
                </label>
                <FormikPasswordField
                  name="password"
                  id="inputPassword"
                  className={`${errors && errors.password ? "is-invalid" : ""}`}
                  placeholder="Enter your password"
                />
                {errors && errors.password && <FormError error={errors.password} />}
              </div>
              <div className="mb-3">
                <button disabled={isSubmitting} type="submit" className="btn btn-secondary fw-bold text-uppercase w-100">
                  Sign In
                </button>
              </div>
              <div className="d-flex justify-content-center">
                <p>
                  Forgot your password?{" "}
                  <Link className="text-secondary text-decoration-none fw-bold" to="/auth/forgot-password">
                    Reset it here.
                  </Link>
                </p>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
