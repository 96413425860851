import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

function useViewAs() {
  const [viewAs, setViewAs] = useState("card");
  const searchParams = useSearchParams()[0];
  useEffect(() => {
    if (searchParams) {
      const v = searchParams.get("view");
      if (v) {
        setViewAs(v);
      }
    } else {
      setViewAs("card");
    }

    return () => {
      setViewAs("card");
    };
  }, [searchParams]);

  return useMemo(() => ({ viewAs }), [viewAs]);
}
export default useViewAs;
