import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

function CheckPayment() {
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate("/dashboard");
    }, 10000);

    return () => {
      clearTimeout(timeout);
    };
  }, [navigate]);
  return (
    <div className="row">
      <h1 className="my-5 text-center">Check Payment</h1>
      <p className="alert alert-info mb-5 text-center">Please wait while we confirm receipt of your payment.</p>
      <div className="d-flex justify-content-center mb-5">
        <LoadingSpinner />
      </div>
    </div>
  );
}

export default CheckPayment;
