/* eslint no-param-reassign: 0 */
import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  bomId: null,
  finishedGood: null,
  finishedGoodQuestions: null,
  components: null,
  componentQuestions: null,
  defaultCurrency: "USD",
  skipCreation: false,
  isCloning: false,
};

const bomWizardSlice = createSlice({
  name: "bomWizard",
  initialState: INITIAL_STATE,
  reducers: {
    setFinishedGood(state, action) {
      state.finishedGood = action.payload;
    },
    setDefaultCurrency(state, action) {
      state.defaultCurrency = action.payload;
    },
    setSkipCreation(state, action) {
      state.skipCreation = action.payload;
    },
    setFinishedGoodQuestions(state, action) {
      state.finishedGoodQuestions = action.payload;
    },
    setComponents(state, action) {
      state.components = action.payload;
    },
    setBomId(state, action) {
      state.bomId = action.payload;
    },
    setComponentQuestions(state, action) {
      state.componentQuestions = action.payload;
    },
    setIsCloning(state, action) {
      state.isCloning = action.payload;
    },
    clearState(state) {
      Object.keys(INITIAL_STATE).forEach((key) => {
        state[key] = INITIAL_STATE[key];
      });
    },
  },
});

export default bomWizardSlice.reducer;

export const {
  setFinishedGood,
  setDefaultCurrency,
  setSkipCreation,
  setFinishedGoodQuestions,
  setComponents,
  setBomId,
  setComponentQuestions,
  clearState,
  setIsCloning,
} = bomWizardSlice.actions;

export function finishedGoodSelector(state) {
  return state.bomWizard.finishedGood;
}

export function defaultCurrencySelector(state) {
  return state.bomWizard.defaultCurrency;
}

export function skipCreationSelector(state) {
  return state.bomWizard.skipCreation;
}

export function finishedGoodQuestionsSelector(state) {
  return state.bomWizard.finishedGoodQuestions;
}

export function componentsSelector(state) {
  return state.bomWizard.components;
}

export function bomIdSelector(state) {
  return state.bomWizard.bomId;
}

export function componentQuestionsSelector(state) {
  return state.bomWizard.componentQuestions;
}

export function isCloning(state) {
  return state.bomWizard.isCloning;
}
