import { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { useAnswerFinishedGoodQuestionsMutation } from "../../../features/api/boms";
import { useFinishedGoodQuestionsContext } from "./FinishedGoodQuestionsContext";

export default function useFinishedGoodQuestionsForm() {
  const { bom } = useFinishedGoodQuestionsContext();
  const [answerQuestions, { isError, isSuccess }] = useAnswerFinishedGoodQuestionsMutation();
  const navigate = useNavigate();
  const initialValues = useMemo(() => {
    const values = {};
    if (bom) {
      for (let i = 0; i < bom.FG_Qs.length; i += 1) {
        values[`question${i}`] = "N";
      }
    }
    return values;
  }, [bom]);

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      setSubmitting(true);
      const questionsWithAnswers = [];
      for (let i = 0; i < bom.FG_Qs.length; i += 1) {
        const answer = values[`question${i}`];
        const question = bom.FG_Qs[i];
        questionsWithAnswers.push({ ...question, Answer: answer });
      }
      answerQuestions({ BOMID: bom.BOMID, As: questionsWithAnswers }).finally(() => {
        setSubmitting(false);
      });
    },
    [bom, answerQuestions],
  );

  useEffect(() => {
    if (isError) {
      Swal.fire({
        title: "Error!",
        text: "Failed to submit answers. Pleas try again.",
        icon: "error",
      });
    } else if (isSuccess) {
      navigate("/dashboard/create-bill-of-material/component-details/", { replace: true });
    }
  }, [isError, isSuccess, navigate]);

  return useMemo(() => ({ initialValues, handleSubmit }), [initialValues, handleSubmit]);
}
