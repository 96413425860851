import React from "react";
import { useSelector } from "react-redux";

import { exporterSelector } from "../../../../../features/certificateOfOrigin/certificateOfOriginSlice";

function ExporterBodyPart() {
  const exporter = useSelector(exporterSelector);

  return (
    <div className="bg-light-2 p-3 rounded">
      <div className="mb-4 d-flex justify-content-between align-items-center">
        <h6 className="m-0">Exporter&apos;s Name</h6>
        <p className="m-0 text-muted">{exporter.name || "-"}</p>
      </div>

      <div className="mb-4 d-flex justify-content-between align-items-center">
        <h6 className="m-0">Exporter&apos;s Email Address</h6>
        <p className="m-0 text-muted">{exporter.email || "-"}</p>
      </div>

      <div className="mb-4 d-flex justify-content-between align-items-center">
        <h6 className="m-0">Exporter&apos;s Phone Number</h6>
        <p className="m-0 text-muted">{exporter.phoneNumber || "-"}</p>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <h6 className="m-0">Exporter&apos;s Address</h6>
        <p className="m-0 text-muted">{exporter.address || "-"}</p>
      </div>
    </div>
  );
}

export default ExporterBodyPart;
