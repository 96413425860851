import React from "react";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { useListPricesQuery } from "../../features/api/payments";
import ProductPriceCard from "./components/ProductPriceCard";

function ChooseProduct() {
  const { data: priceData, isLoading } = useListPricesQuery();
  let body;
  if (isLoading) {
    body = (
      <div className="d-flex justify-content-center align-items-center">
        <LoadingSpinner />
      </div>
    );
  } else if (priceData) {
    body = (
      <div className="d-flex flex-column gap-3">
        {priceData.prices.map((price) => (
          <ProductPriceCard key={price.id} productPrice={price} />
        ))}
      </div>
    );
  }
  return (
    <div>
      <h1 className="mb-5">Purchase BOMs</h1>
      <h4 className="border-bottom pb-4 mb-3">Pricing</h4>
      <div>{body}</div>
    </div>
  );
}

export default ChooseProduct;
