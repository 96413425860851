import React from "react";
import { PiUserCircle } from "react-icons/pi";
import { useSelector } from "react-redux";

import { avatarDataUriSelector } from "../../../../features/customerProfile/customerProfileSlice";

export default function Avatar() {
  const avatarImg = useSelector(avatarDataUriSelector);

  let avatar;

  if (avatarImg) {
    avatar = <img className="img-fluid rounded-circle" src={avatarImg} alt="avatar" />;
  } else {
    avatar = <PiUserCircle size={60} />;
  }
  return <div className="rounded-circle main-sidebar__user-dropdown-avatar me-1">{avatar}</div>;
}
