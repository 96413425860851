import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
  SHOW_ALL,
  SHOW_COMPONENTS_REQUIRED,
  SHOW_COMPONENTS_REQUIRED_PARAM,
  SHOW_COMPONENT_QUESTIONS_REQUIRED,
  SHOW_COMPONENT_QUESTIONS_REQUIRED_PARAM,
  SHOW_FINISHED_GOOD_QUESTIONS_REQUIRED,
  SHOW_FINISHED_GOOD_QUESTIONS_REQUIRED_PARAM,
  SHOW_QUALIFIED,
  SHOW_QUALIFIED_PARAM,
  SHOW_READY_TO_SUBMIT,
  SHOW_READY_TO_SUBMIT_PARAM,
  SHOW_SAVED,
  SHOW_SAVED_PARAM,
  SHOW_UNQUALIFIED,
  SHOW_UNQUALIFIED_PARAM,
} from "./constants";

export default function useFilters() {
  const searchParams = useSearchParams()[0];
  const [filter, setFilter] = useState("all");
  const [showing, setShowing] = useState(SHOW_ALL);
  useEffect(() => {
    if (filter) {
      if (filter === SHOW_SAVED_PARAM) {
        setShowing(SHOW_SAVED);
      } else if (filter === SHOW_QUALIFIED_PARAM) {
        setShowing(SHOW_QUALIFIED);
      } else if (filter === SHOW_UNQUALIFIED_PARAM) {
        setShowing(SHOW_UNQUALIFIED);
      } else if (filter === SHOW_FINISHED_GOOD_QUESTIONS_REQUIRED_PARAM) {
        setShowing(SHOW_FINISHED_GOOD_QUESTIONS_REQUIRED);
      } else if (filter === SHOW_COMPONENTS_REQUIRED_PARAM) {
        setShowing(SHOW_COMPONENTS_REQUIRED);
      } else if (filter === SHOW_COMPONENT_QUESTIONS_REQUIRED_PARAM) {
        setShowing(SHOW_COMPONENT_QUESTIONS_REQUIRED);
      } else if (filter === SHOW_READY_TO_SUBMIT_PARAM) {
        setShowing(SHOW_READY_TO_SUBMIT);
      } else {
        setShowing(SHOW_ALL);
      }
    } else {
      setShowing(SHOW_ALL);
    }

    return () => setShowing(SHOW_ALL);
  }, [filter]);

  useEffect(() => {
    if (searchParams) {
      setFilter(searchParams.get("filter") || "all");
    } else {
      setFilter("all");
    }

    return () => setFilter("all");
  }, [searchParams]);

  return useMemo(
    () => ({
      showing,
    }),
    [showing],
  );
}
