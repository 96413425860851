import { encodeURI } from "js-base64";
import { nanoid } from "nanoid";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { PiCards, PiList, PiPen } from "react-icons/pi";
import { Link } from "react-router-dom";

import ComponentCard from "../../components/ComponentCard/ComponentCard";
import ComponentsTable from "../../components/ComponentsTable/ComponentsTable";
import FinishedGoodDetailsCard from "../../components/FinishedGoodDetailsCard/FinishedGoodDetailsCard";
import FinishedGoodDetailsTable from "../../components/FinishedGoodDetailsTable/FinishedGoodDetailsTable";
import FinishedGoodQuestions from "../../components/FinishedGoodQuestions/FinishedGoodQuestions";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import QuestionsListGroup from "../../components/QuestionsListGroup/QuestionsListGroup";
import useToggleView from "../../hooks/useToggleView";
import bomDetail from "../../propTypes/bomDetail";
import { useBomContext } from "./BOMContext";

function FinishedGoodDetails({ bom, currentView }) {
  const [finishedGood, setFinishedGood] = useState(null);

  useEffect(() => {
    setFinishedGood({
      currency: bom.FG.Currency,
      partNumber: bom.FG.Part,
      description: bom.FG.Desc,
      origin: bom.FG.Origin,
      id: bom.BOMID,
      hts: bom.FG.Hts,
      transactionValue: bom.FG.Value,
      netCost: bom.FG.NetCost,
      totalCost: bom.FG.TotalCost,
    });
  }, [bom]);

  if (!finishedGood) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      {currentView === "card" ? (
        <FinishedGoodDetailsCard editable={bom.BOM_State === "unsubmitted"} finishedGood={finishedGood} />
      ) : (
        <FinishedGoodDetailsTable bomId={bom.BOMID} />
      )}
    </div>
  );
}

FinishedGoodDetails.propTypes = {
  bom: bomDetail.isRequired,
  currentView: PropTypes.string,
};

function Components({ bom }) {
  const { currentView } = useToggleView();
  if (!bom) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (currentView === "card") {
    return (
      <div>
        {bom.Components.map((component) => (
          <div key={nanoid()} className="mb-3">
            <ComponentCard component={component} />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="mb-5">
      <ComponentsTable bomId={bom.BOMID} />
    </div>
  );
}

export default function BOMContents() {
  const { currentView, handleToggleView } = useToggleView();
  const { bom } = useBomContext();
  if (!bom) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      <div className="mb-4">
        <button
          onClick={() => handleToggleView()}
          type="button"
          className="btn btn-outline-primary fw-bold w-100 text-uppercase d-flex align-items-center gap-2 justify-content-center"
        >
          {currentView === "card" ? (
            <>
              View as List <PiList />
            </>
          ) : (
            <>
              View as card <PiCards />
            </>
          )}
        </button>
      </div>
      <div className="mb-5 d-flex flex-column gap-4">
        <FinishedGoodDetails bom={bom} currentView={currentView} />
        {bom.FG_Qs.length > 0 && <FinishedGoodQuestions bom={bom} />}
      </div>
      {bom.Components.length > 0 && (
        <div className="d-flex flex-column mb-5">
          <div className="mb-3">
            {bom.BOM_State === "unsubmitted" && (
              <Link
                state={{
                  components: bom.Components.map((comp) => ({
                    partNumber: comp.Part,
                    hts: comp.Hts,
                    description: comp.Desc,
                    category: comp.Category,
                    qty: comp.Qty,
                    units: comp.Unit,
                    cost: comp.Cost,
                    origin: comp.Origin,
                    originating: comp.Originating === "Y",
                  })),
                }}
                className="btn btn-outline-primary w-100 mb-3"
                to={`/dashboard/bom/edit-components/${encodeURI(bom.BOMID)}`}
              >
                <PiPen /> Edit Components
              </Link>
            )}
            <Components bom={bom} />
          </div>
          {bom.Comp_Qs.length > 0 && (
            <div>
              <h3 className="mb-3">Questions</h3>
              <QuestionsListGroup questions={bom.Comp_Qs} forComponent />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
