import { snakeToPhrase } from "./text";

function apiErrorToString(apiError) {
  let result;
  const errorData = apiError.detail || apiError.message;
  if (!errorData) {
    if (apiError instanceof String) {
      result = apiError;
    }
  } else if (Array.isArray(errorData)) {
    const messages = errorData.map((error) => {
      const field = `<b>${snakeToPhrase(error.loc && error.loc[error.loc.length - 1]) || "General error"}</b>`;
      const errorMessage = error.msg;
      return `${field}: ${errorMessage || "Unknown error."}`;
    });
    result = messages.reduce((acc, cur) => `${acc}${cur}<br/><br />`, "");
  }
  return result;
}

export default apiErrorToString;
