import PropTypes from "prop-types";
import React from "react";
import { PiCards, PiList } from "react-icons/pi";

import useToggleView from "../../hooks/useToggleView";

function ToggleViewButton({ border = false }) {
  const { currentView, handleToggleView } = useToggleView();

  return (
    <button onClick={handleToggleView} type="button" className={`btn ${border ? "btn-outline-secondary" : "p-0 no-border-no-outline"}`}>
      {currentView === "card" ? <PiList /> : <PiCards />}
    </button>
  );
}

ToggleViewButton.propTypes = {
  border: PropTypes.bool,
};

export default ToggleViewButton;
