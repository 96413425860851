/* eslint  jsx-a11y/label-has-associated-control: 0 */
import { Field, Form, Formik } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";
import { object, string } from "yup";

import FormError from "../../components/FormError";
import password from "../../schemas/password";
import useChangePassword from "./useChangePassword";

const schema = object().shape({
  currentPassword: string().required("Please enter your current password."),
  password1: password,
  password2: password,
});

function ChangePasswordModal({ onHide }) {
  const { handleSubmit, errorMsg, passwordChanged } = useChangePassword();

  let body;

  if (passwordChanged) {
    body = (
      <div>
        <p className="alert alert-success text-center mb-3">Your password has been changed.</p>
        <button type="button" onClick={onHide} className="btn btn-success w-100">
          Dismiss
        </button>
      </div>
    );
  } else {
    body = (
      <Formik onSubmit={handleSubmit} validationSchema={schema} initialValues={{ currentPassword: "", password1: "", password2: "" }}>
        {({ isSubmitting, errors }) => (
          <Form>
            <div className="form-group">
              <label className="form-label w-100">
                Current password*
                <Field
                  placeholder="Your current password"
                  type="password"
                  className={`form-control mt-1 ${errors.currentPassword ? "is-invalid" : ""}`}
                  name="currentPassword"
                />
                {errors.currentPassword && <FormError error={errors.currentPassword} />}
              </label>
            </div>

            <div className="form-group">
              <label className="form-label w-100">
                New password*
                <Field placeholder="A new password" type="password" className={`form-control mt-1 ${errors.password1 ? "is-invalid" : ""}`} name="password1" />
                {errors.password1 && <FormError error={errors.password1} />}
              </label>
            </div>

            <div className="form-group">
              <label className="form-label w-100">
                Confirm new password*
                <Field
                  placeholder="The same password as above"
                  type="password"
                  className={`form-control mt-1 ${errors.password2 ? "is-invalid" : ""}`}
                  name="password2"
                />
                {errors.password2 && <FormError error={errors.password2} />}
              </label>
            </div>
            <div className="d-flex justify-content-between">
              <button type="button" onClick={onHide} className="btn btn-outline-secondary">
                Cancel
              </button>
              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }

  return (
    <Modal show centered onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h3>Change your password</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMsg && <p className="alert alert-danger text-center">{errorMsg}</p>}
        {body}
      </Modal.Body>
    </Modal>
  );
}

ChangePasswordModal.propTypes = {
  onHide: PropTypes.func.isRequired,
};

export default ChangePasswordModal;
