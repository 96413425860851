import React from "react";
import { Link, useLocation } from "react-router-dom";

import ProgressBar from "../components/ProgressBar/ProgressBar";
import { SUBMISSION_STEP } from "../constants";
import useSelectedBomsRequired from "../hooks/useSelectedBomsRequired";
import SummaryPart from "./components/SummaryPart/SummaryPart";
import { CERTIFIER_DATA_PART_TYPE, EXPORTER_DATA_PART_TYPE, IMPORTER_DATA_PART_TYPE, PRODUCER_DATA_PART_TYPE, QUALIFIED_BILLS_PART_TYPE } from "./constants";
import useSummary from "./useSummary";

export default function Summary() {
  useSelectedBomsRequired();
  const location = useLocation();
  const { handleSubmit, errorMsg, submitting } = useSummary();

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-lg-2" />
        <div className="col-lg-8">
          <div className="mb-5">
            <ProgressBar step={SUBMISSION_STEP} />
          </div>
          <div className="mb-5">
            <h1>Summary before submission</h1>
            <p>Here&apos;s what you filled in. Ensure everything is correct before submitting.</p>
          </div>

          <div className="mb-5">
            <div className="mb-5">
              <SummaryPart type={QUALIFIED_BILLS_PART_TYPE} />
            </div>
            <div className="mb-5">
              <SummaryPart type={CERTIFIER_DATA_PART_TYPE} />
            </div>

            <div className="mb-5">
              <SummaryPart type={EXPORTER_DATA_PART_TYPE} />
            </div>

            <div className="mb-5">
              <SummaryPart type={IMPORTER_DATA_PART_TYPE} />
            </div>

            <div className="mb-5">
              <SummaryPart type={PRODUCER_DATA_PART_TYPE} />
            </div>
          </div>

          {errorMsg && (
            // eslint-disable-next-line react/no-danger
            <div className="mb-5 alert alert-danger" dangerouslySetInnerHTML={{ __html: errorMsg }} />
          )}

          <div className="d-flex justify-content-center align-items-center gap-2">
            <Link
              className="btn btn-outline-secondary text-uppercase fw-bold"
              to="/dashboard/certificate-of-origin/generate/importer-form/"
              state={{ ...location.state }}
            >
              Cancel & Go back
            </Link>
            <button disabled={submitting} onClick={handleSubmit} type="button" className="btn btn-primary text-uppercase fw-bold">
              Submit
            </button>
          </div>
        </div>
        <div className="col-lg-2" />
      </div>
    </div>
  );
}
