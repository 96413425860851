import { nanoid } from "nanoid";
import PropTypes from "prop-types";
import React from "react";

import dataCardItemPropType from "./dataCardItemPropType";

function DataCardItem({ item }) {
  return (
    <div className="usmca-certificate__detail-data-card-grid-item p-2 rounded">
      <small className="mb-2 text-muted d-block">{item.type}</small>
      <p className="m-0 text-break">{item.text}</p>
    </div>
  );
}

DataCardItem.propTypes = {
  item: dataCardItemPropType.isRequired,
};

function DataCard({ titleData, items }) {
  return (
    <div className="border border-light-3 rounded p-3 d-flex flex-column justify-content-between">
      <div className="mb-4">
        <small className="d-block mb-2">{titleData.type}</small>
        <h4>{titleData.title}</h4>
      </div>
      <div className="usmca-certificate__detail-data-card-grid">
        {items.map((item) => (
          <DataCardItem key={nanoid()} item={item} />
        ))}
      </div>
    </div>
  );
}

DataCard.propTypes = {
  titleData: PropTypes.shape({ type: PropTypes.string, title: PropTypes.string }).isRequired,
  items: PropTypes.arrayOf(dataCardItemPropType).isRequired,
};

export default DataCard;
