import { Field } from "formik";
import PropTypes from "prop-types";
import React from "react";

function OriginatingRadioGroup({ idx, value, label }) {
  return (
    <div className="form-check form-check-inline">
      <Field type="radio" name={`components.${idx}.originating`} value={value} className="form-check-input" id={`radioUSMCA${value}${idx}`} />
      <label htmlFor={`radioUSMCA${value}${idx}`} className="form-check-label">
        {label}
      </label>
    </div>
  );
}

OriginatingRadioGroup.propTypes = {
  idx: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
export default OriginatingRadioGroup;
