import React from "react";
import { PiScroll } from "react-icons/pi";
import { Link } from "react-router-dom";

export default function TopActions() {
  return (
    <div className="d-flex border-bottom border-light-2 border-2 pb-4 mb-4">
      <Link className="btn btn-primary px-4 py-2" to="/dashboard/create-bill-of-material/">
        CREATE A BILL OF MATERIAL
      </Link>
      <Link to="/dashboard/certificate-of-origin/generate" className="btn icon-btn text-primary">
        <PiScroll />
        CREATE USMCA CERTIFICATE
      </Link>
    </div>
  );
}
