import * as Sentry from "@sentry/react";
import { Auth } from "aws-amplify";
import { useCallback, useMemo, useState } from "react";

export default function useChangePassword() {
  const [errorMsg, setErrorMsg] = useState(null);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const handleSubmit = useCallback(async (values, { setSubmitting }) => {
    setSubmitting(true);
    setErrorMsg(null);
    try {
      const user = await Auth.currentAuthenticatedUser();
      try {
        await Auth.changePassword(user, values.currentPassword, values.password1);
        setPasswordChanged(true);
      } catch (err) {
        Sentry.captureException(err);
        setErrorMsg("Unable to change password. Please try again.");
      } finally {
        setSubmitting(false);
      }
    } catch (err) {
      Sentry.captureException(err);
      setErrorMsg(JSON.stringify(err.message || "Unable to retrieve your profile. Please try again."));
    } finally {
      setSubmitting(false);
    }
  }, []);

  return useMemo(
    () => ({
      handleSubmit,
      errorMsg,
      passwordChanged,
    }),
    [handleSubmit, errorMsg, passwordChanged],
  );
}
