import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { toggleUserDropdown, userDropdownStateSelector } from "../../../../features/ui/mainSidebarSlice";
import Avatar from "./Avatar";
import DropdownIcon from "./DropdownIcon";
import UserInfo from "./UserInfo";

function UserDropdown() {
  const dropdownActive = useSelector(userDropdownStateSelector);
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(toggleUserDropdown(true));
  }, [dispatch]);
  return (
    <button
      onClick={handleClick}
      type="button"
      className={`main-sidebar__user-dropdown ${
        dropdownActive ? "main-sidebar__user-dropdown--active" : ""
      } rounded d-flex align-items-center justify-content-between btn p-0 p-2 w-100 gap-2`}
    >
      <div className="d-flex align-items-center gap-2">
        <Avatar />
        <UserInfo />
      </div>
      <DropdownIcon />
    </button>
  );
}

export default UserDropdown;
