import { useEffect, useState } from "react";

import { useListBomsQuery } from "../features/api/boms";

export default function useBomList() {
  const { data: boms, isLoading, isError, error, isFetching } = useListBomsQuery();
  const [openOnly, setOpenOnly] = useState([]);
  const [completedOnly, setCompletedOnly] = useState([]);

  useEffect(() => {
    if (boms && boms.length > 0) {
      setOpenOnly(boms.filter((bom) => bom.BOM_State !== "CLOSED"));
      setCompletedOnly(boms.filter((bom) => bom.BOM_State === "CLOSED"));
    } else {
      setOpenOnly([]);
      setCompletedOnly([]);
    }

    return () => {
      setOpenOnly([]);
      setCompletedOnly([]);
    };
  }, [boms]);
  return { boms, isLoading, isError, error, openOnly, completedOnly, isFetching };
}
