import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import useBomList from "../../../hooks/useBomList";

export default function useQualifiedBoms() {
  const [searchQuery, setSearchQuery] = useState(null);
  const [searching, setSearching] = useState(false);
  const [qualifiedBoms, setQualifiedBoms] = useState(null);
  const [sortedBoms, setSortedBoms] = useState(null);
  const [bomList, setBomList] = useState(null);
  const { completedOnly: boms, isError, error, isFetching } = useBomList();
  const searchParams = useSearchParams()[0];

  const handleSearch = useCallback((query) => {
    if (query && query.length > 0) {
      setSearchQuery(query);
    } else {
      setSearchQuery(null);
    }
  }, []);

  useEffect(() => {
    if (boms) {
      setQualifiedBoms(boms.filter((bom) => bom.Qualified === "Y"));
    }

    return () => {
      setQualifiedBoms(null);
    };
  }, [boms]);

  useEffect(() => {
    if (qualifiedBoms && searchQuery) {
      setBomList(qualifiedBoms.filter((bom) => bom.Desc.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1));
      setSearching(true);
    } else {
      setBomList(qualifiedBoms);
      setSearching(false);
    }

    return () => {
      setBomList(null);
      setSearching(false);
    };
  }, [searchQuery, qualifiedBoms]);

  useEffect(() => {
    if (bomList && Array.isArray(bomList) && bomList.length > 0) {
      const sortBy = searchParams.get("sort-by") || "newest";
      setSortedBoms([
        ...bomList.sort((bomA, bomB) => {
          const dayA = dayjs.unix(bomA.SubmitTS);
          const dayB = dayjs.unix(bomB.SubmitTS);
          if (sortBy === "newest") {
            if (dayA.isAfter(dayB)) {
              return -1;
            }
            return 1;
          }
          if (dayA.isBefore(dayB)) {
            return -1;
          }
          return 0;
        }),
      ]);
    } else {
      setSortedBoms(null);
    }

    return () => {
      setSortedBoms(null);
    };
  }, [bomList, searchParams]);

  return useMemo(
    () => ({ qualifiedBoms: sortedBoms, isError, error, isFetching, searching, handleSearch }),
    [isError, isFetching, error, searching, handleSearch, sortedBoms],
  );
}
