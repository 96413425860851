import React, { useState } from "react";
import { PiCaretLeft, PiTrash } from "react-icons/pi";
import Markdown from "react-markdown";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import DeleteBOMConfirmationModal from "../../components/DeleteBOMConfirmationModal";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import PrintPDFButton from "../../components/PrintPDFButton/PrintPDFButton";
import ViewPDFButton from "../../components/ViewPDFButton/ViewPDFButton";
import { sidebarStateSelector } from "../../features/ui/navigationSlice";
import useToggleView from "../../hooks/useToggleView";
import { qualificationReasonsToMarkdownList } from "../../utils/bom";
import BOMContents from "./BOMContents";
import { BOMContextProvider } from "./BOMContext";
import NextStepsCard from "./NextStepsCard";
import QualificationCard from "./QualificationCard";
import QualificationSummary from "./QualificationSummary";
import UnsubmittedActions from "./UnsubmittedActions";
import useBomDetail from "./useBomDetail";

function Title({ bom }) {
  let text;

  if (bom && bom.BOM_State === "CLOSED" && bom.Qualified === "Y") {
    text = `BOM ID | ${bom.BOMID} is qualified.`;
  } else if (bom && bom.BOM_State === "CLOSED" && bom.Qualified === "N") {
    text = `BOM ID | ${bom.BOMID} is not qualified`;
  } else if (bom && bom.BOM_State !== "CLOSED") {
    text = `BOM ID | ${bom.BOMID} is in a draft state.`;
  } else {
    text = "Please wait...";
  }
  return <h1>{text}</h1>;
}

export default function BOMDetail() {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { currentView } = useToggleView();
  const { bom, loading } = useBomDetail();
  const sidebarIsOpen = useSelector(sidebarStateSelector);
  const navigate = useNavigate();
  let body;

  if (loading) {
    body = (
      <div className="d-flex justify-content-center align-items-center">
        <LoadingSpinner />
      </div>
    );
  } else if (!loading && !bom) {
    body = (
      <div className="d-flex flex-column align-items-center">
        <p className="alert alert-info">BOM not found.</p>
        <Link className="btn btn-primary fw-bold text-uppercase" to="/dashboard" replace>
          Back to History
        </Link>
      </div>
    );
  } else if (bom && typeof bom.BOM_State !== "undefined" && bom.BOM_State !== "") {
    body = (
      <div>
        <div className={`${bom.Qualified === "Y" ? "mb-5" : "mb-4"}`}>
          <Title bom={bom} />
          <QualificationSummary bomState={bom?.BOM_State} qualified={bom?.Qualified} />
        </div>
        {bom.BOM_State === "unsubmitted" && (
          <button onClick={() => setShowDeleteModal(true)} type="button" className="btn btn-danger mb-4 w-100">
            <PiTrash /> Delete BOM
          </button>
        )}
        {bom.Qualified === "Y" && (
          <div className="mb-5">
            <QualificationCard />
          </div>
        )}
        {bom.BOM_State === "CLOSED" && bom.Qualified === "N" && (
          <div className="mb-5">
            <h3 className="mb-3">Reasons</h3>
            <Markdown>{qualificationReasonsToMarkdownList(bom.QualUnder)}</Markdown>
          </div>
        )}
        <BOMContents />
        {bom.BOM_State === "CLOSED" ? (
          <div>
            <div className="d-flex justify-content-center gap-2 align-items-center mb-5">
              {bom.BOM_State === "CLOSED" && (
                <>
                  <ViewPDFButton bomId={bom.BOMID} />
                  <PrintPDFButton bomId={bom.BOMID} />
                </>
              )}
              {bom.Qualified === "Y" && (
                <Link to="/dashboard/certificate-of-origin/" className="btn btn-primary fw-bold text-uppercase">
                  Generate USMCA Certificate
                </Link>
              )}
            </div>

            <NextStepsCard />
          </div>
        ) : (
          <UnsubmittedActions />
        )}
        <DeleteBOMConfirmationModal
          onSuccess={() => {
            setShowDeleteModal(false);
            navigate("/dashboard", { replace: true });
          }}
          bomId={bom.BOMID}
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
        />
      </div>
    );
  }

  return (
    <BOMContextProvider>
      <div className="container pt-5">
        <div className="row">
          {currentView === "card" ? (
            <>
              <div className="col-lg-2" />
              <div className={`${sidebarIsOpen ? "col-lg-12" : "col-lg-8"}`}>
                <div className="mb-4">
                  <Link to="/dashboard" className="fw-bold text-uppercase text-secondary text-decoration-none d-flex align-items-center gap-2">
                    <PiCaretLeft />
                    Return Home
                  </Link>
                </div>
                {body}
              </div>
              <div className="col-lg-2" />
            </>
          ) : (
            <div className="col">
              <div className="mb-4">
                <Link to="/dashboard" className="fw-bold text-uppercase text-secondary text-decoration-none d-flex align-items-center gap-2">
                  <PiCaretLeft />
                  Return Home
                </Link>
              </div>
              {body}
            </div>
          )}
        </div>
      </div>
    </BOMContextProvider>
  );
}
