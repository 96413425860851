import * as Sentry from "@sentry/react";
import { Auth } from "aws-amplify";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import useAlreadyLoggedIn from "../../hooks/useAlreadyLoggedIn";

export default function useForgotPassword() {
  useAlreadyLoggedIn();
  const [errorMsg, setErrorMsg] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      setSubmitting(true);
      Auth.forgotPassword(values.username)
        .then(() => {
          navigate("/forgot-password/new-password");
        })
        .catch((err) => {
          setErrorMsg(JSON.stringify(err.message || "We couldn't to reset your password. Please try again."));
          Sentry.captureException(err);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [navigate],
  );

  return useMemo(
    () => ({
      handleSubmit,
      errorMsg,
    }),
    [handleSubmit, errorMsg],
  );
}
