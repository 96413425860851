import { Field, Form, Formik } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import { object, string } from "yup";

import FormError from "../../../../components/FormError";
import FormikFlatpickr from "../../../../components/FormikFlatpickr/FormikFlatpickr";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import { FILL_IN_DETAILS_STEP } from "../../constants";
import useSelectedBomsRequired from "../../hooks/useSelectedBomsRequired";
import useCertifierForm from "./useCertifierForm";

const schema = object().shape({
  company: string().required("Please enter the certifying company.").min(2, "Certifying company must be at least 2 characters."),
  name: string().required("Please enter the certifier's name.").min(2, "The certifier's name must be at least 2 characters."),
  role: string()
    .required("Please enter the role of the certifier.")
    .min(2, "Certifier role must be at least 2 characters")
    .matches(/importer|exporter|producer/, "Certifier role must be either importer, exporter, or producer."),
  address: string()
    .min(6, "Address must contain at least 6 characters.")
    .required("Please enter the certifier's full address.")
    .min(2, "The certifier's address must be at least 2 characters."),
  phoneNumber: string()
    .required("Please enter the certifier's phone number.")
    .min(8, "The certifier's phone number must be at least 7 characters long.")
    .matches(/[+0-9]/, "Please enter a valid phone number."),
  emailAddress: string().email("Please enter a valid email address.").required("Please enter the certifier's email address"),
  effectiveDate: string().required("Please enter the effective date."),
  expirationDate: string().required("Please enter an expiration date."),
});

export default function CertifierForm() {
  useSelectedBomsRequired();
  const { initialValues, handleSubmit } = useCertifierForm();
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-2" />
        <div className="col-lg-8">
          <div className="my-5">
            <ProgressBar step={FILL_IN_DETAILS_STEP} />
          </div>
          <div className="mb-5">
            <h1 className="mb-4">Add Certifier&apos;s Data</h1>
            <p>Add details of the person who will be running this certification process.</p>
          </div>
          <Formik validationSchema={schema} onSubmit={handleSubmit} initialValues={initialValues}>
            {({ isSubmitting, errors, touched }) => (
              <Form>
                <div className="mb-5">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="inputCompany">
                      Certifying Company*
                    </label>
                    <Field
                      placeholder="Ex. ACME Inc."
                      id="inputCompany"
                      type="text"
                      name="company"
                      className={`form-control ${errors.company && touched.company ? "is-invalid" : ""}`}
                      required
                    />
                    {errors.company && <FormError error={errors.company} />}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="inputName" className="form-label">
                      Certifier Name*
                    </label>
                    <Field
                      placeholder="Ex. John Doe"
                      id="inputName"
                      type="text"
                      name="name"
                      className={`form-control ${errors.name && touched.name ? "is-invalid" : ""}`}
                      required
                    />
                    {errors.name && <FormError error={errors.name} />}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="selectRole" className="form-label">
                      Role of Certifier*
                    </label>
                    <Field as="select" name="role" id="selectRole" className={`form-select ${errors.role && touched.role ? "is-invalid" : ""}`}>
                      <option value="producer">Producer</option>
                      <option value="importer">Importer</option>
                      <option value="exporter">Exporter</option>
                    </Field>
                    {errors.role && <FormError error={errors.role} />}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="inputAddress" className="form-label">
                      Certifier Address*
                    </label>
                    <Field
                      placeholder="Ex. 259 Oakway Lane, Los Angeles, CA 90017"
                      id="inputAddress"
                      type="text"
                      name="address"
                      className={`form-control ${errors.address && touched.address ? "is-invalid" : ""}`}
                      required
                    />
                    {errors.address && <FormError error={errors.address} />}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="inputPhone" className="form-label">
                      Certifier Phone Number*
                    </label>
                    <Field
                      placeholder="Ex. +17173064124"
                      id="inputPhone"
                      type="text"
                      name="phoneNumber"
                      className={`form-control ${errors.phoneNumber && touched.phoneNumber ? "is-invalid" : ""}`}
                      required
                    />
                    {errors.phoneNumber && <FormError error={errors.phoneNumber} />}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="inputEmail" className="form-label">
                      Certifier Email Address*
                    </label>
                    <Field
                      placeholder="Ex. john@acme.com"
                      name="emailAddress"
                      type="email"
                      id="inputEmail"
                      className={`form-control ${errors.emailAddress && touched.emailAddress ? "is-invalid" : ""}`}
                    />
                    {errors.emailAddress && <FormError error={errors.emailAddress} />}
                  </div>

                  <div className="mb-3">
                    <label className="form-label w-100">
                      Effective Date*
                      <FormikFlatpickr
                        name="effectiveDate"
                        options={{ minDate: new Date() }}
                        className={`form-control mt-1 ${errors.effectiveDate && touched.effectiveDate ? "is-invalid" : ""}`}
                      />
                      {errors.effectiveDate && <FormError error={errors.effectiveDate} />}
                    </label>
                  </div>

                  <div className="mb-3">
                    <label className="form-label w-100">
                      Expiration Date*
                      <FormikFlatpickr
                        name="expirationDate"
                        options={{ minDate: new Date() }}
                        className={`form-control mt-1 ${errors.expirationDate && touched.expirationDate ? "is-invalid" : ""}`}
                      />
                      {errors.expirationDate && <FormError error={errors.expirationDate} />}
                    </label>
                  </div>
                </div>

                <div className="d-flex justify-content-center align-items-center gap-2">
                  <Link className="text-uppercase btn btn-outline-primary fw-bold" to="/dashboard/certificate-of-origin/generate/">
                    Cancel & Go back
                  </Link>
                  <button type="submit" className="btn btn-primary fw-bold text-uppercase" disabled={isSubmitting}>
                    Save & Continue
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="col-lg-2" />
      </div>
    </div>
  );
}
