import React from "react";
import { Link } from "react-router-dom";

import noContentImg from "../../../../images/no-content.svg";

export default function NoBoms() {
  return (
    <div style={{ width: "22rem" }} className="d-flex justify-content-center align-items-center flex-column mx-auto">
      <img src={noContentImg} alt="No content" className="mb-4" />
      <p className="lead mb-4 text-center">You currently don&apos;t have any bills of material</p>
      <Link className="btn btn-primary" to="/dashboard/create-bill-of-material/">
        CREATE ONE
      </Link>
    </div>
  );
}
