/* eslint no-param-reassign: 0 */
import { createSlice } from "@reduxjs/toolkit";

const mainSidebarSlice = createSlice({
  name: "mainSidebar",
  initialState: {
    userDropdownActive: false,
  },
  reducers: {
    setUserDropdownActive(state, action) {
      state.userDropdownActive = action.payload;
    },
    toggleUserDropdown(state) {
      state.userDropdownActive = !state.userDropdownActive;
    },
  },
});

export default mainSidebarSlice.reducer;

export const { setUserDropdownActive, toggleUserDropdown } = mainSidebarSlice.actions;

export function userDropdownStateSelector(state) {
  return state.mainSidebar.userDropdownActive;
}
