import { Field, Form, Formik } from "formik";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { array, object, string } from "yup";

import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import { PRODUCER_DETAILS_STEP } from "../../constants";
import useSelectedBomsRequired from "../../hooks/useSelectedBomsRequired";
import ProducerFieldArray from "./components/ProducerFieldArray";
import TypeCard from "./components/TypeCard";
import useProducerData from "./useProducerData";

const schema = object().shape({
  producerDataType: string()
    .required("Please specify how you'd like to supply producer information.")
    .matches(/single|list|various|request/, "Please choose a type from the available radio buttons."),
  producers: array().of(
    object().shape({
      name: string(),
      email: string(),
      phone: string()
        .min(8, "Phone number must contain at least 8 characters.")
        .matches(/[+0-9]/, "Please enter a valid phone number."),
      address: string(),
    }),
  ),
});

export default function ProducerData() {
  useSelectedBomsRequired();
  const location = useLocation();
  const { loading, handleSubmit, initialValues } = useProducerData();

  let body;

  if (loading) {
    body = (
      <div className="d-flex justify-content-center align-items-center">
        <LoadingSpinner />
      </div>
    );
  } else {
    body = (
      <div>
        <div className="d-flex">
          <Formik validationSchema={schema} onSubmit={handleSubmit} initialValues={initialValues}>
            {({ isSubmitting, values, errors }) => (
              <Form>
                <div className="d-flex gap-2 mb-5">
                  <TypeCard text="Single Producer" type="single" />
                  <TypeCard text="Multiple Producers (Provide a List)" type="list" />
                  <TypeCard text="Various Producers" type="various" />
                  <TypeCard text="Available Upon Request" type="request" />
                </div>
                <div className="d-none">
                  <div className="form-check">
                    <Field id="producerDataTypeRadio" name="producerDataType" type="radio" className="form-check-input" value="single" />
                    <label className="form-check-label" htmlFor="producerDataTypeRadio">
                      Single producer
                    </label>
                  </div>

                  <div className="form-check">
                    <Field id="producerDataTypeRadio" name="producerDataType" type="radio" className="form-check-input" value="list" />
                    <label className="form-check-label" htmlFor="producerDataTypeRadio">
                      Provide a list of producers
                    </label>
                  </div>

                  <div className="form-check">
                    <Field id="producerDataTypeRadio" name="producerDataType" type="radio" className="form-check-input" value="various" />
                    <label className="form-check-label" htmlFor="producerDataTypeRadio">
                      Various producers
                    </label>
                  </div>

                  <div className="form-check">
                    <Field id="producerDataTypeRadio" name="producerDataType" type="radio" className="form-check-input" value="request" />
                    <label className="form-check-label" htmlFor="producerDataTypeRadio">
                      Available upon request by the importing authorities
                    </label>
                  </div>
                </div>

                {(values.producerDataType === "single" || values.producerDataType === "list") && <ProducerFieldArray />}
                {errors.producerDataType && <p className="alert alert-danger text-center mb-5">{errors.producerDataType}</p>}
                <div className="d-flex justify-content-center align-items-center gap-2">
                  <Link
                    className="btn btn-outline-secondary fw-bold text-uppercase"
                    to="/dashboard/certificate-of-origin/generate/exporter-form/"
                    state={{ ...location.state }}
                  >
                    Cancel & Go Back
                  </Link>
                  <button type="submit" className="btn btn-primary fw-bold text-uppercase" disabled={isSubmitting}>
                    Save & Continue
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-2" />
        <div className="col-lg-8">
          <div className="my-5">
            <ProgressBar step={PRODUCER_DETAILS_STEP} />
          </div>
          <div className="mb-5">
            <h1 className="mb-4">Producer Data</h1>
            <p>Add your producer&apos;s data. Choose how you&apos;d like to specify producer(s) below and then fill the form if needed.</p>
          </div>
          {body}
        </div>
        <div className="col-lg-2" />
      </div>
    </div>
  );
}
