export default function unpackHts(hts) {
  let htsCopy;
  if (typeof hts === "number") {
    htsCopy = hts.toString();
  } else {
    htsCopy = hts.slice();
  }
  if (htsCopy.length === 9) {
    htsCopy = `0${htsCopy}`;
  }
  return `${htsCopy.slice(0, 4)}.${htsCopy.slice(4, 6)}.${htsCopy.slice(6)}`;
}
