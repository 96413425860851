import { Auth } from "aws-amplify";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { logOut as logOutState } from "../features/auth/authSlice";

export default function useLogout() {
  const dispatch = useDispatch();
  return useCallback(() => {
    Auth.signOut()
      .then(() => {
        localStorage.removeItem("avatarDataUri");
        dispatch(logOutState());
        window.location.reload();
      })
      .catch(() => {});
  }, [dispatch]);
}
