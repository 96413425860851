import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { producerDataTypeSelector } from "../../../../../features/certificateOfOrigin/certificateOfOriginSlice";

export default function useProducerBodyPart() {
  const producerDataType = useSelector(producerDataTypeSelector);
  const [title, setTitle] = useState(null);
  const [hasProducers, setHasProducers] = useState(false);

  useEffect(() => {
    if (producerDataType) {
      if (producerDataType === "single") {
        setTitle("Single Producer");
        setHasProducers(true);
      } else if (producerDataType === "list") {
        setTitle("List of Producers");
        setHasProducers(true);
      } else if (producerDataType === "various") {
        setTitle("Various Producers");
      } else {
        setTitle("Available Upon Request");
      }
    } else {
      setTitle(null);
    }

    return () => {
      setTitle(null);
    };
  }, [producerDataType]);

  return useMemo(() => ({ title, hasProducers }), [title, hasProducers]);
}
