export const SELECT_BOMS_STEP = 0;

export const FILL_IN_DETAILS_STEP = 1;

export const CERTIFIER_DETAILS_STEP = 2;

export const EXPORTER_DETAILS_STEP = 3;

export const IMPORTER_DETAILS_STEP = 4;

export const PRODUCER_DETAILS_STEP = 5;

export const SUBMISSION_STEP = 6;

export const CERTIFICATE_STEP = 7;
