import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { PiEye, PiFilePdf, PiPrinter } from "react-icons/pi";
import { Link } from "react-router-dom";

import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import PDFViewerModal from "../../../../components/PDFViewerModal/PDFViewerModal";
import { DEFAULT_DAY_JS_DATETIME_FORMAT } from "../../../../constants";
import { useGetCertificateQuery } from "../../../../features/api/certificates";
import certificateSummaryPropType from "../../../../propTypes/certificateSummaryPropType";
import printPDF from "../../../../utils/printPDF";

function CertificateRow({ certificateId }) {
  const [showPdf, setShowPdf] = useState(false);
  const [printing, setPrinting] = useState(false);
  const { data: certificate, isLoading } = useGetCertificateQuery({ certificateId });

  const handlePrint = useCallback(() => {
    setPrinting(true);
    printPDF(certificate.pdf_url).finally(() => setPrinting(false));
  }, [certificate]);

  if (isLoading) {
    return (
      <tr>
        <td>
          <LoadingSpinner />
        </td>
      </tr>
    );
  }
  return (
    <tr>
      <th scope="row">
        <Link to={`/dashboard/certificate-of-origin/${certificateId}/`} className="text-primary">
          {certificateId}
        </Link>
      </th>
      <td className="text-wrap">{certificate.qualified_goods.map((fg, idx) => (idx === certificate.qualified_goods.length - 1 ? `${fg.id}` : `${fg.id} | `))}</td>
      <td>{dayjs(certificate.created_at).format(DEFAULT_DAY_JS_DATETIME_FORMAT)}</td>
      <td>
        <div className="btn-group" role="group" aria-label="Certificate actions">
          <Link to={`/dashboard/certificate-of-origin/${certificateId}/`} className="btn btn-primary">
            <PiEye />
          </Link>
          <button onClick={() => setShowPdf(true)} type="button" className="btn btn-primary">
            <PiFilePdf />
          </button>
          <button onClick={handlePrint} disabled={printing} type="button" className="btn btn-primary">
            <PiPrinter />
          </button>
        </div>
      </td>
      <td className="d-none">
        <PDFViewerModal show={showPdf} onHide={() => setShowPdf(false)} downloadUrl={certificate.pdf_url} />
      </td>
    </tr>
  );
}

CertificateRow.propTypes = {
  certificateId: PropTypes.string.isRequired,
};

function CertificateTable({ certificates }) {
  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">Certificate ID</th>
          <th scope="col">Finished Goods</th>
          <th scope="col">Created At</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        {certificates.map((certificate) => (
          <CertificateRow key={certificate.certificate_id} certificateId={certificate.certificate_id} />
        ))}
      </tbody>
    </table>
  );
}

CertificateTable.propTypes = {
  certificates: PropTypes.arrayOf(certificateSummaryPropType).isRequired,
};

export default CertificateTable;
