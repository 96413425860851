import * as Sentry from "@sentry/react";
import { Auth } from "aws-amplify";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { useGetCustomerQuery } from "../features/api/customers";
import { setCredentials } from "../features/auth/authSlice";
import { clearState as clearBomWizardState } from "../features/bomWizard/bomWizardSlice";
import { clearState as clearImportCsvState } from "../features/fileImport/fileImportSlice";

export default function useInitialState() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [tokensLoading, setTokensLoading] = useState(true);
  const dispatch = useDispatch();
  const { isLoading: customerIsLoading } = useGetCustomerQuery(null, { skip: tokensLoading });

  useEffect(() => {
    Auth.currentSession()
      .then((currentSession) => {
        const accessToken = currentSession.getAccessToken().getJwtToken();
        const refreshToken = currentSession.getRefreshToken().getToken();
        const idToken = currentSession.getIdToken().getJwtToken();
        const { payload } = currentSession.idToken;
        Sentry.setUser({ email: payload.email });
        dispatch(setCredentials({ accessToken, refreshToken, idToken, idTokenPayload: payload }));
      })
      .catch((err) => {
        Sentry.captureException(err);
      })
      .finally(() => {
        setTokensLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    if (!tokensLoading) {
      if (!customerIsLoading) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    } else {
      setLoading(true);
    }

    return () => {
      setLoading(true);
    };
  }, [tokensLoading, customerIsLoading]);

  useEffect(() => {
    if (location && !location.pathname.includes("import-bom-csv")) {
      dispatch(clearImportCsvState());
    }

    if (location && !location.pathname.includes("create-bill-of-material")) {
      dispatch(clearBomWizardState());
    }
  }, [dispatch, location]);

  return useMemo(
    () => ({
      loading,
    }),
    [loading],
  );
}
