import React, { useEffect, useState } from "react";

import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { DANGER_STATUS_PILL, INFO_STATUS_PILL, SUCCESS_STATUS_PILL, WARNING_STATUS_PILL } from "./constants";

function StatusPill({ name, type, badgeText = null, bom }) {
  const [showToolTip, setShowToolTip] = useState(false);
  const [bg, setBg] = useState("bg-transparent");

  useEffect(() => {
    if (type !== null) {
      if (type === SUCCESS_STATUS_PILL) {
        setBg("bg-success");
      } else if (type === DANGER_STATUS_PILL) {
        setBg("bg-danger");
      } else if (type === WARNING_STATUS_PILL) {
        setBg("bg-warning");
      } else if (type === INFO_STATUS_PILL) {
        setBg("bg-info");
      }
    } else {
      setBg("bg-transparent");
    }

    return () => setBg("bg-transparent");
  }, [type]);

  if (!bom) {
    return <LoadingSpinner />;
  }

  return (
    <div className="position-relative">
      <div style={{ cursor: "pointer" }} onMouseEnter={() => setShowToolTip(true)} onMouseLeave={() => setShowToolTip(false)} className="d-flex align-items-center gap-1">
        <div className={`status-pill rounded-pill fw-bold py-1 px-3 d-flex justify-content-center align-items-center ${bg}`}>{name}</div>
        {badgeText && <span className="badge bg-info text-dynamic-black">{badgeText}</span>}
      </div>
      <div style={{ width: "18rem", transform: "translateY(5px)", display: showToolTip ? "block" : "none" }} className="card position-absolute">
        <div className="card-body">
          <p>
            <b>{bom.Components.length}</b> Components
          </p>
          <p>
            <b>{bom.FG_Qs.length}</b> Finished Good Questions
          </p>
          <p className="m-0">
            <b>{bom.Comp_Qs.length}</b> Component Questions
          </p>
        </div>
      </div>
    </div>
  );
}

export default StatusPill;
