import PropTypes from "prop-types";
import React from "react";
import { PiCaretDown, PiCaretUp, PiCheck } from "react-icons/pi";
import OutsideClickHandler from "react-outside-click-handler";

import { SORT_BY_LATEST, SORT_BY_OLDEST } from "../../constants";
import useSortByDropdown from "./useSortByDropdown";

function DropdownItem({ text, isSelected = false, onClick }) {
  return (
    <button onClick={onClick} type="button" className="btn d-flex justify-content-between align-items-center gap-3 usmca-certificate__sort-by-dropdown-menu-item">
      {text}
      {isSelected && <PiCheck />}
    </button>
  );
}

DropdownItem.propTypes = {
  text: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

function SortByDropdown() {
  const { show, setShow, setSortBy, currentMethod } = useSortByDropdown();

  return (
    <div className="position-relative">
      <OutsideClickHandler onOutsideClick={() => setShow(false)}>
        <button
          onClick={() => setShow(!show)}
          type="button"
          className="btn no-border-no-outline p-0 text-primary fw-bold text-uppercase usmca-certificate__sort-by-dropdown-toggle d-flex align-items-center gap-1"
        >
          Sort by
          {show ? <PiCaretUp /> : <PiCaretDown />}
        </button>
      </OutsideClickHandler>
      <div className={`usmca-certificate__sort-by-dropdown-menu ${show ? "usmca-certificate__sort-by-dropdown-menu--shown" : ""} rounded position-absolute`}>
        <DropdownItem onClick={() => setSortBy(SORT_BY_LATEST)} text="Newest" isSelected={currentMethod === SORT_BY_LATEST} />
        <DropdownItem onClick={() => setSortBy(SORT_BY_OLDEST)} text="Oldest" isSelected={currentMethod === SORT_BY_OLDEST} />
      </div>
    </div>
  );
}
export default SortByDropdown;
