import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { PiMagnifyingGlass } from "react-icons/pi";

import { useGenerateCertificateContext } from "../GenerateCertificateContext";

function SearchBox({ handleSearch }) {
  const { currentSearchQuery: currentQuery, setCurrentSearchQuery: setCurrentQuery } = useGenerateCertificateContext();
  const handleChange = useCallback(
    (e) => {
      setCurrentQuery(e.target.value);
      handleSearch(e.target.value);
    },
    [handleSearch, setCurrentQuery],
  );
  return (
    <div>
      <div className="position-relative">
        <input value={currentQuery} onChange={handleChange} type="text" className="form-control" placeholder="Quickly search for your BOMs." />
        <div style={{ top: 0, right: 0, zIndex: 10 }} className="btn no-border-no-outline text-primary position-absolute">
          <PiMagnifyingGlass />
        </div>
      </div>
    </div>
  );
}

SearchBox.propTypes = {
  handleSearch: PropTypes.func.isRequired,
};

export default SearchBox;
