import React from "react";
import { PiCheck, PiWallet } from "react-icons/pi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { CERTIFICATES_PAGE, DASHBOARD_PAGE, PAYMENTS_PAGE, QUALIFICATION_HISTORY_PAGE } from "../../../../constants";
import { currentPageSelector } from "../../../../features/ui/navigationSlice";
import BootstrapSVG from "../../../BootstrapSVG";

function NavItems() {
  const currentPage = useSelector(currentPageSelector);

  return (
    <ul className="list-unstyled d-flex flex-column gap-2">
      <li>
        <Link
          to="/dashboard/create-bill-of-material/"
          className={`btn icon-btn justify-content-start main-sidebar__nav-item ${currentPage === DASHBOARD_PAGE ? "main-sidebar__nav-item--active" : ""}`}
        >
          <BootstrapSVG iconName="house" />
          Home
        </Link>
      </li>
      <li>
        <Link
          to="/dashboard/"
          className={`btn icon-btn justify-content-start main-sidebar__nav-item ${currentPage === QUALIFICATION_HISTORY_PAGE ? "main-sidebar__nav-item--active" : ""}`}
        >
          <PiCheck />
          Qualification History
        </Link>
      </li>
      <li>
        <Link
          to="/dashboard/certificate-of-origin/"
          className={`btn icon-btn justify-content-start main-sidebar__nav-item ${currentPage === CERTIFICATES_PAGE ? "main-sidebar__nav-item--active" : ""}`}
        >
          <BootstrapSVG iconName="file-text" />
          Certificates
        </Link>
      </li>
      <li>
        <Link
          to="/dashboard/payments"
          className={`btn icon-btn justify-content-start main-sidebar__nav-item ${currentPage === PAYMENTS_PAGE ? "main-sidebar__nav-item--active" : ""}`}
        >
          <PiWallet />
          Payments
        </Link>
      </li>
    </ul>
  );
}

export default NavItems;
