import { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";

import { useGetPresignedPostQuery } from "../../../features/api/storage";
import generateAvatarFileKey from "../../../utils/generateAvatarFileKey";
import apiErrorToString from "../../../utils/response";

export default function usePresignedPost(file) {
  const [avatarFileKey, setAvatarFileKey] = useState(null);
  const { data: presignedPostData, isError, error } = useGetPresignedPostQuery({ key: avatarFileKey }, { skip: !avatarFileKey });

  useEffect(() => {
    if (file) {
      setAvatarFileKey(generateAvatarFileKey(file.name));
    }
    return () => setAvatarFileKey(null);
  }, [file]);

  useEffect(() => {
    if (isError) {
      if (error.status !== 401) {
        Swal.fire({
          title: "Error!",
          text: apiErrorToString(error),
          icon: "error",
        });
      }
    }
  }, [isError, error]);

  return useMemo(
    () => ({
      presignedPostData,
      avatarFileKey,
    }),
    [presignedPostData, avatarFileKey],
  );
}
