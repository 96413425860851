import * as Sentry from "@sentry/react";

export default class DebugLogger {
  constructor(moduleName) {
    this.moduleName = moduleName;
  }

  log(msg) {
    if (process.env.REACT_APP_LOG_LEVEL === "debug") {
      Sentry.captureMessage(`${this.moduleName} ${msg}`);
    }
  }
}
