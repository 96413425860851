import PropTypes from "prop-types";
import React from "react";

import { htsDisplay } from "../../utils/bom";
import getExtCost from "../../utils/getExtCost";
import { componentPropType } from "../BOMSummaryPDF/propTypes";

function ComponentPill({ type, text, justify = "start" }) {
  return (
    <div style={{ width: "max-content", justifySelf: justify }} className="py-1 px-2 rounded-pill bg-light-0">
      {type} | {text}
    </div>
  );
}

ComponentPill.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  justify: PropTypes.string,
};

function ComponentCard({ component }) {
  return (
    <div style={{ height: "210px" }} className="border rounded bg-light-2 p-3 d-flex flex-column justify-content-between">
      <div>
        <h4 className="mb-3">{component.Part}</h4>
        <p>{component.Desc}</p>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gridTemplateRows: "1fr 1fr", gap: "1rem", justifyItems: "between" }}>
        <ComponentPill text={component.Category} type="Category" />
        <ComponentPill text={component.Qty} type="QTY" />
        <ComponentPill text={component.Cost} type="Unit Cost" />
        <ComponentPill text={component.Origin} type="COO" justify="end" />
        <ComponentPill text={htsDisplay(component.Hts)} type="HTS" />
        <ComponentPill text={component.Unit} type="Units" />
        <ComponentPill text={getExtCost(component.Cost, component.Qty)} type="Extended Cost" />

        <ComponentPill text={component.Originating === "Y" ? "Yes" : "No"} type="Originating" justify="end" />
      </div>
    </div>
  );
}

ComponentCard.propTypes = {
  component: componentPropType.isRequired,
};

export default ComponentCard;
