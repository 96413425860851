import { Field, Form, Formik, useFormikContext } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import CountryCodesSelector from "../../../../components/CountryCodesSelector/CountryCodesSelector";
import HTSField from "../../../../components/FinishedGoodForm/HTSField";
import FormikComponentCategoryField from "../../../../components/FormikComponentCategoryField/FormikComponentCategoryField";
import FormikFieldGroup from "../../../../components/FormikFieldGroup/FormikFieldGroup";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import { currentUnparsedBOMSelector, setCurrentUnparsedBOM } from "../../../../features/fileImport/fileImportSlice";

function OriginField() {
  const [currentOrigin, setCurrentOrigin] = useState(null);
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (values && values.origin) {
      setCurrentOrigin(values.origin);
    } else {
      setCurrentOrigin(null);
    }

    return () => {
      setCurrentOrigin(null);
    };
  }, [values]);

  useEffect(() => {
    if (currentOrigin && currentOrigin.match(/US|MX|CA/)) {
      setFieldValue("originating", "Y");
    } else {
      setFieldValue("originating", "N");
    }
  }, [currentOrigin, setFieldValue]);

  return <CountryCodesSelector name="origin" label="Country of Origin" />;
}

export default function EditComponentModal({ show, onHide, componentIdx }) {
  const [component, setComponent] = useState(null);
  const currentUnparsedBOM = useSelector(currentUnparsedBOMSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUnparsedBOM) {
      const components = currentUnparsedBOM.filter((data) => data.Item === "Component");
      setComponent(components[componentIdx]);
    } else {
      setComponent(null);
    }

    return () => {
      setComponent(null);
    };
  }, [currentUnparsedBOM, componentIdx]);

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      setSubmitting(true);
      const fg = currentUnparsedBOM.find((data) => data.Item === "FG");
      let components = currentUnparsedBOM.filter((data) => data.Item === "Component");
      components = components.map((comp, idx) => {
        if (idx === componentIdx) {
          return {
            ...comp,
            PartNumber: values.partNumber,
            HTS: values.category === "Material" ? values.hts.replaceAll(".", "") : "0",
            Desc: values.description,
            Qty: values.qty,
            Units: values.units,
            UnitCost: values.price,
            Origin: values.origin,
            Originating: values.originating,
            Category: values.category,
          };
        }
        return comp;
      });
      dispatch(setCurrentUnparsedBOM([fg, ...components]));
      onHide();
      setSubmitting(false);
    },
    [componentIdx, currentUnparsedBOM, dispatch, onHide],
  );

  const initialValues = useMemo(() => {
    const values = {
      partNumber: "",
      hts: "",
      description: "",
      qty: 0,
      units: "pieces",
      price: 0,
      origin: "US",
      originating: "N",
      category: "Material",
    };
    if (component) {
      values.partNumber = component.PartNumber || "";
      values.hts = component.HTS ? String(component.HTS) : "";
      values.description = component.Desc || "";
      values.qty = String(component.Qty) || "";
      values.units = component.Units || "";
      values.price = component.UnitCost || "";
      values.origin = component.COO || "";
      values.originating = component.Originating || "";
      values.category = component.Category || "";
    }
    return values;
  }, [component]);

  let body;

  if (component) {
    body = (
      <Formik onSubmit={handleSubmit} initialValues={initialValues}>
        {({ isSubmitting }) => (
          <Form>
            <div className="mb-4">
              <div className="mb-3">
                <FormikFieldGroup name="partNumber" type="text" placeholder="Ex. C1" label="Part Number*" required />
              </div>
              <div className="mb-3">
                <HTSField />
              </div>
              <div className="mb-3">
                <FormikComponentCategoryField name="category" />
              </div>
              <div className="mb-3">
                <FormikFieldGroup name="description" type="text" placeholder="Ex. Lobster" label="Description" />
              </div>

              <div className="mb-3">
                <FormikFieldGroup name="qty" type="text" placeholder="Ex. 10" label="Quantity" />
              </div>

              <div className="mb-3">
                <FormikFieldGroup name="units" type="text" placeholder="Ex. pieces" label="Units" />
              </div>

              <div className="mb-3">
                <FormikFieldGroup name="price" type="text" placeholder="Ex. 100" label="Unit Cost" />
              </div>

              <div className="mb-3">
                <OriginField />
              </div>

              <div>
                <p>Is the component originating?</p>
                <div className="form-check">
                  <Field type="radio" name="originating" value="Y" className="form-check-input" />
                  <label htmlFor="radioUsmcaY" className="form-check-label">
                    Yes
                  </label>
                </div>

                <div className="form-check">
                  <Field type="radio" name="originating" value="N" className="form-check-input" />
                  <label htmlFor="radioUsmcaN" className="form-check-label">
                    No
                  </label>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center gap-2">
              <button disabled={isSubmitting} type="button" onClick={onHide} className="btn btn-outline-primary fw-bold text-uppercase">
                Cancel
              </button>
              <button type="submit" className="btn btn-primary fw-bold text-uppercase">
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  } else {
    body = (
      <div className="d-flex justify-content-center align-items-center">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <Modal scrollable show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Edit Component</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
    </Modal>
  );
}
