import React from "react";
import { PiCheck } from "react-icons/pi";
import { Link } from "react-router-dom";

import noContentImg from "../../../images/no-content.svg";

function NoCertificates() {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <img src={noContentImg} alt="no certificates" className="mb-4" />
      <p style={{ width: "16rem" }} className="mx-auto fw-bold text-center mb-4">
        You currently don&apos;t have any USMCA certificates.
      </p>
      <div className="d-flex align-items-center justify-content-center gap-2">
        <Link to="/dashboard/certificate-of-origin/generate/" className="btn btn-primary text-uppercase fw-bold">
          Create one
        </Link>
        <Link to="/dashboard/" className="btn text-uppercase fw-bold d-flex gap-1 align-items-center">
          <PiCheck />
          Qualification History
        </Link>
      </div>
    </div>
  );
}

export default NoCertificates;
