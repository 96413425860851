import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";

import { setCurrency, setShowChooseCurrencyModal } from "../../../features/fileImport/fileImportSlice";

export default function useChooseCurrencyModal() {
  const dispatch = useDispatch();
  const handleSubmit = useCallback(
    (values) => {
      dispatch(setCurrency(values.currency));
      dispatch(setShowChooseCurrencyModal(false));
    },
    [dispatch],
  );

  return useMemo(() => ({ handleSubmit }), [handleSubmit]);
}
