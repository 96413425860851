import React from "react";
import { Outlet } from "react-router-dom";

import useCustomerAvatar from "../../hooks/useCustomerAvatar";
import useGoogleAnalytics from "../../hooks/useGoogleAnalytics";
import useHubSpot from "../../hooks/useHubspot";

function AppInitializer() {
  useCustomerAvatar();
  useGoogleAnalytics();
  useHubSpot();
  return <Outlet />;
}

export default AppInitializer;
