/**
 *
 * @param unitPrice {number}
 * @param bulkAmount {number}
 * @param bulkPrice {number}
 * @returns {number}
 */
export function calculateSavingsPercentage(maxPrice, currentPrice) {
  const savings = maxPrice - currentPrice;
  return Math.floor((savings / maxPrice) * 100);
}

export function findPrice(prices, purchaseAmount) {
  return prices.find((price) => {
    const minQty = price.quantity.minimum;
    const maxQty = price.quantity.maximum;
    if (purchaseAmount >= minQty && purchaseAmount <= maxQty) {
      return price;
    }
    return null;
  });
}

export function priceDisplay(price) {
  if (price) {
    const priceStr = String(price);
    if (priceStr.length === 4) {
      return `${priceStr.slice(0, 1)},${priceStr.slice(1)}`;
    }
    if (priceStr.length === 5) {
      return `${priceStr.slice(0, 2)},${priceStr.slice(2)}`;
    }
    if (priceStr.length === 6) {
      return `${priceStr.slice(0, 3)},${priceStr.slice(3)}`;
    }
    if (priceStr.length === 7) {
      return `${priceStr.slice(0, 1)},${priceStr.slice(1, 4)},${priceStr.slice(4)}`;
    }
    if (priceStr.length === 8) {
      return `${priceStr.slice(0, 2)},${priceStr.slice(2, 4)},${priceStr.slice(4)}`;
    }

    return priceStr;
  }
  return "0";
}
