import { encodeURI } from "js-base64";
import { useNavigate } from "react-router-dom";

export default function useQualifyBOM(bomId) {
  const navigate = useNavigate();

  function qualify() {
    navigate(`/dashboard/bom/qualify/${encodeURI(bomId)}`);
  }

  return { qualify };
}
