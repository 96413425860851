import React, { useCallback } from "react";
import { PiArrowDown } from "react-icons/pi";

function ScrollToBottomButton() {
  const handleClick = useCallback(() => {
    window.scrollTo({
      top: document.body.scrollHeight,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <button onClick={handleClick} type="button" className="btn btn-outline-secondary">
      <PiArrowDown />
    </button>
  );
}

export default ScrollToBottomButton;
