import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";

function TypeCard({ text, type }) {
  const [selected, setSelected] = useState(false);
  const { setFieldValue, values } = useFormikContext();

  const handleClick = useCallback(() => {
    if (values.producerDataType !== type) {
      setFieldValue("producerDataType", type);
      if (type === "single" && values.producers && Array.isArray(values.producers) && values.producers.length > 1) {
        setFieldValue("producers", [values.producers[0]]);
      } else if (type === "list" && values.producers && Array.isArray(values.producers) && values.producers.length === 0) {
        setFieldValue("producers", [{ name: "", email: "", address: "", phoneNumber: "" }]);
      } else if (type !== "list" && type !== "single") {
        setFieldValue("producers", []);
      }
    }
  }, [setFieldValue, type, values]);

  useEffect(() => {
    if (values && values.producerDataType === type) {
      setSelected(true);
    } else {
      setSelected(false);
    }

    return () => setSelected(false);
  }, [values, type]);

  return (
    <button
      onClick={handleClick}
      type="button"
      className={`p-3 btn usmca-certificate__producer-type-card ${selected ? "usmca-certificate__producer-type-card--selected" : ""}`}
    >
      <p className={`m-0 text-center text-uppercase fw-bold ${selected ? "text-light" : "text-dark"}`}>{text}</p>
    </button>
  );
}

TypeCard.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["single", "list", "various", "request"]).isRequired,
};

export default TypeCard;
