import React from "react";
import { Link } from "react-router-dom";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import ViewCertPDFButton from "./ViewCertPDFButton";
import Certifier from "./components/Certifier/Certifier";
import Exporter from "./components/Exporter/Exporter";
import Importer from "./components/Importer/Importer";
import Producers from "./components/Producers/Producers";
import QualifiedGoods from "./components/QualifiedGoods/QualifiedGoods";
import useCertificateDetail from "./useCertificateDetail";

export default function CertificateOfOriginDetail() {
  const { certificate, loading, printing, handlePrint } = useCertificateDetail();

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center mt-5">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-lg-2" />
        <div className="col-lg-8">
          <div className="mb-5">
            <h1 className="mb-3">USMCA Certificate {certificate.certificate_id}</h1>
            <p>
              Here are the details available in this certificate. Click on the &quot;Save PDF&quot; or &quot;Print&quot; buttons below to get a copy of the certificate.
            </p>
          </div>
          <div className="mb-5">
            <QualifiedGoods certificate={certificate} />
          </div>
          <div className="mb-5">
            <Certifier certificate={certificate} />
          </div>

          <div className="mb-5">
            <Exporter certificate={certificate} />
          </div>

          <div className="mb-5">
            <Importer certificate={certificate} />
          </div>

          <div className="mb-5">
            <Producers certificate={certificate} />
          </div>

          <div className="d-flex justify-content-center align-items-center gap-3">
            <ViewCertPDFButton pdfUrl={certificate.pdf_url} />
            <button onClick={handlePrint} disabled={printing} type="button" className="btn btn-primary fw-bold text-uppercase">
              Print PDF
            </button>
            <Link className="btn btn-outline-primary fw-bold text-uppercase" to="/dashboard/certificate-of-origin/">
              Close
            </Link>
          </div>
        </div>
        <div className="col-lg-2" />
      </div>
    </div>
  );
}
