import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import QuestionsListGroup from "../QuestionsListGroup/QuestionsListGroup";

export default function FinishedGoodQuestions({ bom }) {
  if (!bom) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <div>
      <h3 className="mb-3">Questions</h3>
      <QuestionsListGroup questions={bom.FG_Qs} />
    </div>
  );
}
