import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";

import Progress from "../../../../components/Progress/Progress";
import { EXPORTER_DETAILS_STEP, FILL_IN_DETAILS_STEP, IMPORTER_DETAILS_STEP, PRODUCER_DETAILS_STEP, SELECT_BOMS_STEP, SUBMISSION_STEP } from "../../constants";

function ProgressBar({ step }) {
  const [detailsPercentComplete, setDetailsPercentComplete] = useState(0);
  const items = useMemo(
    () => [
      {
        name: "SELECT BOMS",
        active: step >= SELECT_BOMS_STEP,
        percentComplete: step > SELECT_BOMS_STEP ? 100 : 0,
      },
      {
        name: "FILL IN DETAILS",
        active: step >= FILL_IN_DETAILS_STEP,
        percentComplete: detailsPercentComplete,
      },
      {
        name: "SUBMISSION",
        active: step >= SUBMISSION_STEP,
      },
    ],
    [step, detailsPercentComplete],
  );

  useEffect(() => {
    if (step && step === EXPORTER_DETAILS_STEP) {
      setDetailsPercentComplete(25);
    } else if (step && step === PRODUCER_DETAILS_STEP) {
      setDetailsPercentComplete(50);
    } else if (step && step === IMPORTER_DETAILS_STEP) {
      setDetailsPercentComplete(75);
    } else if (step === SUBMISSION_STEP) {
      setDetailsPercentComplete(100);
    } else {
      setDetailsPercentComplete(0);
    }
  }, [step]);

  return <Progress items={items} />;
}

ProgressBar.propTypes = {
  step: PropTypes.number.isRequired,
};
export default ProgressBar;
