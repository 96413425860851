import React from "react";
import { Link } from "react-router-dom";

import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import ScrollToBottomButton from "../../../components/ScrollToBottomButton/ScrollToBottomButton";
import SortByButton from "../../../components/SortByButton/SortByButton";
import ToggleViewButton from "../../../components/ToggleViewButton/ToggleViewButton";
import ProgressBar from "../components/ProgressBar/ProgressBar";
import { SELECT_BOMS_STEP } from "../constants";
import ActionButtons from "./ActionButtons";
import { GenerateCertificateContextProvider, useGenerateCertificateContext } from "./GenerateCertificateContext";
import QualifiedBOMCards from "./QualifiedBOMCards";
import QualifiedCardsTable from "./QualifiedCardsTable";
import SearchBox from "./SearchBox/SearchBox";
import useQualifiedBoms from "./useQualifiedBoms";

function CurrentSearchQuery() {
  const { currentSearchQuery } = useGenerateCertificateContext();

  if (currentSearchQuery) {
    return (
      <p className="mb-3">
        Showing BOMs matching <b>{currentSearchQuery}</b>
      </p>
    );
  }

  return null;
}

// eslint-disable-next-line react/prop-types
function Content({ qualifiedBoms }) {
  const { viewAs } = useGenerateCertificateContext();

  if (viewAs === "card") {
    return <QualifiedBOMCards qualifiedBoms={qualifiedBoms} />;
  }

  return <QualifiedCardsTable qualifiedBoms={qualifiedBoms} />;
}

function FloatingScrollToBottomButton() {
  const { selectedBomIds } = useGenerateCertificateContext();

  if (selectedBomIds && Array.isArray(selectedBomIds) && selectedBomIds.length > 0) {
    return (
      <div style={{ right: "1rem", bottom: "1rem" }} className="position-fixed">
        <ScrollToBottomButton />
      </div>
    );
  }

  return null;
}

export default function GenerateCertificateOfOrigin() {
  const { qualifiedBoms, isError, error, isFetching, searching, handleSearch } = useQualifiedBoms();
  let body;

  if (isFetching) {
    body = (
      <div className="d-flex justify-content-center">
        <LoadingSpinner />
      </div>
    );
  } else if (isError) {
    body = <p className="alert alert-danger">Failed to retrieve BOMS. {JSON.stringify(error.data || "Unknown error")}.</p>;
  } else if (qualifiedBoms && Array.isArray(qualifiedBoms) && qualifiedBoms.length === 0) {
    body = searching ? (
      <p className="alert alert-info text-center">No qualified BOMs matching the query found. Please try another search term.</p>
    ) : (
      <p className="alert alert-info text-center">
        No qualified BOMs found. Please <Link to="/dashboard/new-bom/">create one</Link>.
      </p>
    );
  } else if (qualifiedBoms && Array.isArray(qualifiedBoms) && qualifiedBoms.length > 0) {
    body = (
      <div>
        <div className="mb-5">
          <Content qualifiedBoms={qualifiedBoms} />
        </div>
        <ActionButtons />
      </div>
    );
  }

  return (
    <GenerateCertificateContextProvider>
      <div className="container">
        <div className="row mb-4">
          <div className="col-lg-2" />
          <div className="col-lg-8">
            <div className="my-5 d-flex">
              <ProgressBar step={SELECT_BOMS_STEP} />
            </div>
            <h1 className="mb-3">Generate USMCA Certificate</h1>
            <Link className="btn btn-outline-secondary mb-4" to="/dashboard">
              Qualification History
            </Link>
            <p className="mb-4">Choose from the following qualified BOMs to generate a USMCA certificate.</p>
            <div className="mb-4 d-flex gap-2">
              <div className="flex-grow-1">
                <SearchBox handleSearch={handleSearch} />
              </div>
              <SortByButton
                options={[
                  ["newest", "Newest"],
                  ["oldest", "Oldest"],
                ]}
              />
              <ToggleViewButton />
            </div>
            <CurrentSearchQuery />
          </div>
          <div className="col-lg-2" />
        </div>
        <div className="row">{body}</div>
      </div>
      <FloatingScrollToBottomButton />
    </GenerateCertificateContextProvider>
  );
}
