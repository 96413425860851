/* eslint no-param-reassign: 0 */
import { createSlice } from "@reduxjs/toolkit";

import { FILE_IMPORT_INITIAL_STEP } from "../../constants";

const INITIAL_STATE = {
  successfulImports: [],
  unparsedBOMs: [],
  currentIndex: 0,
  currentStep: FILE_IMPORT_INITIAL_STEP,
  currentUnparsedBOM: null,
  headers: null,
  currentFile: null,
  uploadProgression: null,
  currency: null,
  showChooseCurrencyModal: false,
  parsingDone: false,
  parsedFinishedGood: null,
  parsedComponents: null,
  showHeaderConfirmationModal: false,
  headerValidation: { done: false, isValid: null },
  submittingToApi: false,
  matchedFinishedGoodHeaders: null,
  matchedComponentHeaders: null,
  newBomId: null,
  showFgQuestionsModal: false,
  showComponentQuestionsModal: false,
};

const fileImportSlice = createSlice({
  name: "fileImport",
  initialState: INITIAL_STATE,
  reducers: {
    setSuccessfulImports(state, action) {
      state.successfulImports = action.payload;
    },
    addSuccessfulImport(state, action) {
      state.successfulImports.push(action.payload);
    },
    setUnParsedBOMs(state, action) {
      state.unparsedBOMs = action.payload;
    },
    incrementBOMImportIndex(state) {
      const nextIdx = state.currentIndex + 1;
      if (state.unparsedBOMs.length - 1 < nextIdx) {
        return;
      }
      state.currentIndex = nextIdx;
    },
    setCurrentBOMImportIndex(state, action) {
      state.currentIndex = action.payload;
    },
    setCurrentUnparsedBOM(state, action) {
      state.currentUnparsedBOM = action.payload;
    },
    setHeaders(state, action) {
      state.headers = action.payload;
    },
    setCurrentFile(state, action) {
      state.currentFile = action.payload;
    },
    setUploadProgression(state, action) {
      state.uploadProgression = action.payload;
    },
    setCurrency(state, action) {
      state.currency = action.payload;
    },
    setShowChooseCurrencyModal(state, action) {
      state.showChooseCurrencyModal = action.payload;
    },
    setParsingDone(state, action) {
      state.parsingDone = action.payload;
    },
    setParsedFinishedGood(state, action) {
      state.parsedFinishedGood = action.payload;
    },
    setParsedComponents(state, action) {
      state.parsedComponents = action.payload;
    },
    setShowHeaderConfirmationModal(state, action) {
      state.showHeaderConfirmationModal = action.payload;
    },
    setHeaderValidation(state, action) {
      state.headerValidation = action.payload;
    },
    setCurrentStep(state, action) {
      state.currentStep = action.payload;
    },
    setSubmittingToApi(state, action) {
      state.submittingToApi = action.payload;
    },
    setMatchedFinishedGoodHeaders(state, action) {
      state.matchedFinishedGoodHeaders = action.payload;
    },
    setMatchedComponentHeaders(state, action) {
      state.matchedComponentHeaders = action.payload;
    },
    setNewBomId(state, action) {
      state.newBomId = action.payload;
    },
    setShowFgQuestionsModal(state, action) {
      state.showFgQuestionsModal = action.payload;
    },
    setShowComponentQuestionsModal(state, action) {
      state.showComponentQuestionsModal = action.payload;
    },
    clearState(state, action) {
      const clearAll = action.payload;
      Object.keys(INITIAL_STATE).forEach((key) => {
        if (clearAll) {
          state[key] = INITIAL_STATE[key];
        } else if (key !== "unparsedBOMs" && key !== "currentIndex" && key !== "successfulImports" && key !== "currentFile") {
          state[key] = INITIAL_STATE[key];
        }
      });
    },
  },
});

export default fileImportSlice.reducer;

export const {
  setSuccessfulImports,
  addSuccessfulImport,
  prepNextImport,
  incrementBOMImportIndex,
  setUnParsedBOMs,
  setCurrentBOMImportIndex,
  setCurrentUnparsedBOM,
  setHeaders,
  setHeadersCorrect,
  setCurrentFile,
  setUploadProgression,
  setCurrency,
  setShowChooseCurrencyModal,
  setParsingDone,
  setParsedFinishedGood,
  setParsedComponents,
  setShowHeaderConfirmationModal,
  setHeaderValidation,
  setCurrentStep,
  clearState,
  setMatchedFinishedGoodHeaders,
  setMatchedComponentHeaders,
  setSubmittingToApi,
  setNewBomId,
  setShowFgQuestionsModal,
  setShowComponentQuestionsModal,
} = fileImportSlice.actions;

export function successfulImportsSelector(state) {
  return state.fileImport.successfulImports;
}

export function unparsedBOMsSelector(state) {
  return state.fileImport.unparsedBOMs;
}

export function currentBOMImportIndexSelector(state) {
  return state.fileImport.currentIndex;
}

export function currentUnparsedBOMSelector(state) {
  return state.fileImport.currentUnparsedBOM;
}

export function csvHeadersSelector(state) {
  return state.fileImport.headers;
}

export function currentFileSelector(state) {
  return state.fileImport.currentFile;
}

export function uploadProgressionSelector(state) {
  return state.fileImport.uploadProgression;
}

export function currencySelector(state) {
  return state.fileImport.currency;
}

export function showChooseCurrencyModal(state) {
  return state.fileImport.showChooseCurrencyModal;
}

export function parsingDone(state) {
  return state.fileImport.parsingDone;
}

export function parsedFinishedGood(state) {
  return state.fileImport.parsedFinishedGood;
}

export function parsedComponents(state) {
  return state.fileImport.parsedComponents;
}

export function showHeaderConfirmationModal(state) {
  return state.fileImport.showHeaderConfirmationModal;
}

export function headerValidation(state) {
  return state.fileImport.headerValidation;
}

export function currentStepSelector(state) {
  return state.fileImport.currentStep;
}

export function submittingToApi(state) {
  return state.fileImport.submittingToApi;
}

export function mappedFinishedGoodHeaders(state) {
  return state.fileImport.matchedFinishedGoodHeaders;
}

export function mappedComponentHeaders(state) {
  return state.fileImport.matchedComponentHeaders;
}

export function newBomIdSelector(state) {
  return state.fileImport.newBomId;
}

export function showFgQuestionsModal(state) {
  return state.fileImport.showFgQuestionsModal;
}

export function showComponentQuestionsModal(state) {
  return state.fileImport.showComponentQuestionsModal;
}
