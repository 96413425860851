import { Field, useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { PiEye, PiEyeSlash } from "react-icons/pi";

import FormError from "../FormError";

function FormikPasswordField({ className = "", id, name, placeholder = "", showHelp = "" }) {
  const { errors } = useFormikContext();
  const [show, setShow] = useState(false);

  return (
    <div className="position-relative">
      <Field placeholder={placeholder} type={show ? "text" : "password"} className={`form-control ${className}`} id={id} name={name} />
      {showHelp && (
        <div id="passwordHelpBlock" className="form-text">
          Your password must be at least 8 characters long, contain at least 1 number, 1 uppercase letter, and 1 special character.
        </div>
      )}
      {errors && errors[name] && <FormError error={errors[name]} />}
      <button
        tabIndex="-1"
        onClick={() => setShow(!show)}
        type="button"
        className="btn p-0 no-border-no-outline position-absolute text-muted-mulberry"
        style={{ top: "7px", right: "10px" }}
      >
        {show ? <PiEyeSlash /> : <PiEye />}
      </button>
    </div>
  );
}

FormikPasswordField.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  showHelp: PropTypes.bool,
};

export default FormikPasswordField;
