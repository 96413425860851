import { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";

import { useCreateBOMMutation } from "../features/api/boms";

export default function useCloneBom() {
  const [isCloning, setIsCloning] = useState(false);
  const [createBOM, { isError, error }] = useCreateBOMMutation();

  const handleClone = useCallback(
    (bom) => {
      setIsCloning(true);
      createBOM({
        currency: bom.FG.Currency,
        fg: {
          part_number: bom.FG.Part,
          description: bom.FG.Desc,
          hts: bom.FG.Hts,
          origin: bom.FG.Origin,
          transaction_value: bom.FG.Value,
          net_cost: bom.FG.NetCost,
          total_cost: bom.FG.TotalCost,
          questions: [],
          auto_type: bom.FG.AutoType,
          auto_timeframe: bom.FG.AutoTimeframe,
        },
        components: bom.Components.map((component) => ({
          category: component.Category,
          hts: component.Hts,
          part_number: component.Part,
          description: component.Desc,
          origin: component.Origin,
          qty: component.Qty,
          units: component.Unit,
          cost: component.Cost,
          originating: component.Originating === "Y",
          questions: [],
        })),
      }).finally(() => {
        setIsCloning(false);
      });
    },
    [createBOM],
  );

  useEffect(() => {
    if (isError) {
      Swal.fire({
        title: "Error!",
        text: `Failed to duplicate. ${JSON.stringify(error || "Unknown error.")}`,
      });
    }
  }, [isError, error]);

  return { handleClone, isCloning };
}
