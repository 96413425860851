import * as Sentry from "@sentry/react";
import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import legalTextMarkdown from "../../data/terms-of-service.md";
import useAlreadyLoggedIn from "../../hooks/useAlreadyLoggedIn";
import useTermsOfServiceModal from "./useTermsOfServiceModal";

const RegistrationContext = createContext(null);

function RegistrationContextProvider({ children = null }) {
  useAlreadyLoggedIn();
  const [errorMsg, setErrorMsg] = useState(null);
  const navigate = useNavigate();
  const { show: showTermsModal, setShow: setShowTermsModal } = useTermsOfServiceModal();
  const [agreedTos, setAgreedTos] = useState(false);
  const [legalText, setLegalText] = useState(null);

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      setErrorMsg(null);
      setSubmitting(true);
      if (values.password1 !== values.password2) {
        setErrorMsg("Your password and the confirmation you entered don't match.");
        setSubmitting(false);
      } else if (!agreedTos) {
        setErrorMsg("You can't create an account without agreeing to our Terms of Service.");
        setSubmitting(false);
      } else {
        Auth.signUp({
          username: values.username,
          password: values.password1,
          attributes: {
            email: values.email,
          },
        })
          .then(() => {
            navigate("/auth/register/confirm", { state: { email: values.email, username: values.username } });
          })
          .catch((err) => {
            Sentry.captureException(err);
            setErrorMsg(JSON.stringify(err.message || "Unable to register. Please try again."));
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    },
    [navigate, agreedTos],
  );

  useEffect(() => {
    fetch(legalTextMarkdown)
      .then((response) => response.text())
      .then((data) => setLegalText(data));

    return () => {
      setLegalText(null);
    };
  }, []);

  const value = useMemo(
    () => ({ handleSubmit, errorMsg, showTermsModal, setShowTermsModal, agreedTos, setAgreedTos, legalText }),
    [handleSubmit, errorMsg, showTermsModal, setShowTermsModal, agreedTos, setAgreedTos, legalText],
  );
  return <RegistrationContext.Provider value={value}>{children}</RegistrationContext.Provider>;
}

RegistrationContextProvider.propTypes = {
  children: PropTypes.node,
};

export { RegistrationContextProvider };

export function useRegistrationContext() {
  return useContext(RegistrationContext);
}
