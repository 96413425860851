import { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { useAnswerComponentQuestionsMutation } from "../../../features/api/boms";
import { useComponentQuestionsContext } from "./ComponentQuestionsContext";

function useComponentQuestionsForm() {
  const { bom } = useComponentQuestionsContext();
  const [answerQuestions, { isError, isSuccess }] = useAnswerComponentQuestionsMutation();
  const navigate = useNavigate();
  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      setSubmitting(true);
      const questionsAndAnswers = bom.Comp_Qs.map((question) => {
        const answer = values[question.RuleID.replaceAll("#", "_")];
        return { ...question, CompID: question.RuleID, Answer: answer };
      });
      const requestBody = {
        BOMID: bom.BOMID,
        As: questionsAndAnswers,
      };
      answerQuestions(requestBody).finally(() => {
        setSubmitting(false);
      });
    },
    [bom, answerQuestions],
  );

  const initialValues = useMemo(() => {
    let values;
    if (bom && Array.isArray(bom.Comp_Qs) && bom.Comp_Qs.length > 0) {
      values = {};
      bom.Comp_Qs.forEach((question) => {
        const name = question.RuleID.replaceAll("#", "_");
        values[name] = "N";
      });
    }
    return values;
  }, [bom]);

  useEffect(() => {
    if (isError) {
      Swal.fire({
        title: "Error!",
        text: "Failed to submit answers. Please try again.",
        icon: "error",
      });
    } else if (isSuccess) {
      navigate("/dashboard/create-bill-of-material/submission/", { replace: true });
    }
  }, [isError, isSuccess, navigate]);

  return useMemo(() => ({ initialValues, handleSubmit }), [initialValues, handleSubmit]);
}

export default useComponentQuestionsForm;
