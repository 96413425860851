import { Field, Form, Formik } from "formik";
import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

import { showChooseCurrencyModal } from "../../features/fileImport/fileImportSlice";
import useCancelImport from "./hooks/useCancelImport";
import useChooseCurrencyModal from "./hooks/useChooseCurrencyModal";

export default function ChooseCurrencyModal() {
  const { cancel } = useCancelImport();
  const { handleSubmit } = useChooseCurrencyModal();
  const show = useSelector(showChooseCurrencyModal);

  return (
    <Modal centered show={show} onHide={() => cancel()}>
      <Modal.Body>
        <Formik onSubmit={handleSubmit} initialValues={{ currency: "USD" }}>
          {() => (
            <Form>
              <p>Please choose the currency you&apos;d like to use for the monetary values in this BOM.</p>
              <div className="form-check">
                <Field id="radioUSD" className="form-check-input" type="radio" name="currency" value="USD" />
                <label htmlFor="radioUSD" className="form-check-label">
                  USD
                </label>
              </div>

              <div className="form-check">
                <Field id="radioMXP" className="form-check-input" type="radio" name="currency" value="MXP" />
                <label htmlFor="radioMXP" className="form-check-label">
                  MXP
                </label>
              </div>

              <div className="form-check">
                <Field id="radioCAD" className="form-check-input" type="radio" name="currency" value="CAD" />
                <label htmlFor="radioCAD" className="form-check-label">
                  CAD
                </label>
              </div>
              <div className="d-flex justify-content-center gap-2">
                <button type="button" onClick={() => cancel()} className="btn btn-outline-primary fw-bold text-uppercase">
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary fw-bold text-uppercase">
                  Save & Continue
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
