import { Field, FieldArray, useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { PiPlusSquare, PiTrash } from "react-icons/pi";
import Swal from "sweetalert2";

import FormError from "../../../../../components/FormError";

function ProducerFields({ index, single = true, onDelete = () => {}, onAdd = () => {} }) {
  const { errors: formikErrors, touched: formikTouched } = useFormikContext();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (formikErrors.producers && formikTouched.producers && formikErrors.producers[index] && formikTouched.producers[index] && formikErrors.producers[index]) {
      setErrors({ ...formikErrors.producers[index] });
    } else {
      setErrors({});
    }

    return () => setErrors({});
  }, [formikErrors, formikTouched, index]);

  return (
    <div className={`${!single ? "border border-light-3 rounded p-3 mb-3" : ""}`}>
      {!single && (
        <div className="mb-4 d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center gap-1">
            <h4 className="m-0">Producer {index + 1} Form</h4>
            <button onClick={onDelete} type="button" className="btn m-0 p-0" aria-label="Remove producer">
              <PiTrash />
            </button>
          </div>
          <button onClick={onAdd} type="button" className="btn text-primary fw-bold text-uppercase d-flex gap-1 align-items-center">
            <PiPlusSquare />
            Add a Producer
          </button>
        </div>
      )}
      <div className="mb-3">
        <label htmlFor={`inputName${index}`} className="form-label">
          Producer&apos;s Name*
        </label>
        <Field type="text" name={`producers.${index}.name`} placeholder="Ex. John Doe" className={`form-control ${errors.name ? "is-invalid" : ""}`} />
        {errors.name && <FormError error={errors.name} />}
      </div>

      <div className="mb-3">
        <label htmlFor={`inputEmail${index}`} className="form-label">
          Producer&apos;s Email Address
        </label>
        <Field type="text" name={`producers.${index}.email`} placeholder="Ex. john@acme.com" className={`form-control ${errors.email ? "is-invalid" : ""}`} />
        {errors.email && <FormError error={errors.email} />}
      </div>

      <div className="mb-3">
        <label htmlFor={`inputPhone${index}`} className="form-label">
          Producer&apos;s Phone Number
        </label>
        <Field type="text" name={`producers.${index}.phoneNumber`} placeholder="Ex. +17173064124" className={`form-control ${errors.phoneNumber ? "is-invalid" : ""}`} />
        {errors.phoneNumber && <FormError error={errors.phoneNumber} />}
      </div>

      <div className="mb-3">
        <label htmlFor={`inputAddress${index}`} className="form-label">
          Producer&apos;s Address
        </label>
        <Field
          type="text"
          name={`producers.${index}.address`}
          placeholder="Ex. 4731 Hilltop Drive, Ralls, TX 79357"
          className={`form-control ${errors.address ? "is-invalid" : ""}`}
        />
        {errors.address && <FormError error={errors.address} />}
      </div>
    </div>
  );
}

ProducerFields.propTypes = {
  index: PropTypes.number.isRequired,
  single: PropTypes.bool,
  onDelete: PropTypes.func,
  onAdd: PropTypes.func,
};

function ProducerFieldArray() {
  const { values } = useFormikContext();

  const handleDelete = useCallback(
    (arrayHelpers, idx) => {
      if (values.producers && Array.isArray(values.producers) && values.producers.length === 1) {
        Swal.fire({
          title: "Cannot delete!",
          text: "You must have at least 1 producer in the list.",
          icon: "error",
        });
      } else {
        arrayHelpers.remove(idx);
      }
    },
    [values],
  );

  return (
    <FieldArray name="producers">
      {(arrayHelpers) => (
        <div className="mb-5">
          {values.producerDataType === "single" ? (
            <ProducerFields index={0} />
          ) : (
            values.producers &&
            values.producers.length > 0 &&
            values.producers.map((producer, idx) => (
              <ProducerFields
                /* eslint-disable-next-line react/no-array-index-key */
                key={`producer-${idx}`}
                index={idx}
                single={false}
                onDelete={() => handleDelete(arrayHelpers, idx)}
                onAdd={() => arrayHelpers.push({ name: "", email: "", address: "", phoneNumber: "" })}
              />
            ))
          )}
        </div>
      )}
    </FieldArray>
  );
}

export default ProducerFieldArray;
