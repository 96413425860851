import { useEffect, useMemo, useState } from "react";

import { CONTINUE_ACTION_TYPE, SETTINGS_ACTION_TYPE } from "./constants";

export default function useHeaderAction(bom) {
  const [actionType, setActionType] = useState(null);

  useEffect(() => {
    if (bom) {
      if (bom.BOM_State === "CLOSED") {
        setActionType(SETTINGS_ACTION_TYPE);
      } else {
        setActionType(CONTINUE_ACTION_TYPE);
      }
    } else {
      setActionType(null);
    }

    return () => setActionType(null);
  }, [bom]);

  return useMemo(() => ({ actionType }), [actionType]);
}
