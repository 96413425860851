export const SHOW_ALL = 0;
export const SHOW_QUALIFIED = 1;
export const SHOW_UNQUALIFIED = 2;
export const SHOW_SAVED = 3;
export const SHOW_FINISHED_GOOD_QUESTIONS_REQUIRED = 4;
export const SHOW_COMPONENTS_REQUIRED = 5;
export const SHOW_COMPONENT_QUESTIONS_REQUIRED = 6;
export const SHOW_READY_TO_SUBMIT = 7;

export const SHOW_QUALIFIED_PARAM = "qualified";
export const SHOW_UNQUALIFIED_PARAM = "unqualified";
export const SHOW_SAVED_PARAM = "saved";
export const SHOW_FINISHED_GOOD_QUESTIONS_REQUIRED_PARAM = "fg-questions";
export const SHOW_COMPONENTS_REQUIRED_PARAM = "comps-required";
export const SHOW_COMPONENT_QUESTIONS_REQUIRED_PARAM = "comp-questions";
export const SHOW_READY_TO_SUBMIT_PARAM = "qual-ready";

export const SORT_BY_LATEST = "latest";
export const SORT_BY_OLDEST = "oldest";
