import PropTypes from "prop-types";
import React from "react";

import ProgressZone from "./ProgressZone";
import UploadZone from "./UploadZone";
import progressionPropType from "./progressionPropType";

function FileUploadDropzone({ supportedFormats, maximumFileSize = "100mb", handleFiles, progression = null }) {
  let body;

  if (progression) {
    body = <ProgressZone progression={progression} />;
  } else {
    body = <UploadZone handleFiles={handleFiles} supportedFormats={supportedFormats} maximumFileSize={maximumFileSize} />;
  }

  return (
    <div>
      <div className="mb-3 w-100">{body}</div>
      <div className="d-flex justify-content-between">
        <p className="m-0 text-muted-mulberry">Supported Formats: {supportedFormats.map((fmt, idx) => (idx === supportedFormats.length - 1 ? fmt : `${fmt},`))}</p>
        <p className="m-0 text-muted-mulberry">Maximum Size: {maximumFileSize}</p>
      </div>
    </div>
  );
}

FileUploadDropzone.propTypes = {
  supportedFormats: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleFiles: PropTypes.func.isRequired,
  maximumFileSize: PropTypes.string,
  progression: progressionPropType,
};

export default FileUploadDropzone;
