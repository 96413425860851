import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { useCloseBomMutation } from "../../../features/api/boms";
import useBom from "../../../hooks/useBom";
import useBomIdFromParams from "../../../hooks/useDecodedBomId";

export default function CloseBOM() {
  const { encodedBomId } = useParams();
  const { bomId } = useBomIdFromParams();
  const { bom } = useBom(bomId);
  const navigate = useNavigate();
  const [closeBOM, { isError, error }] = useCloseBomMutation();

  useEffect(() => {
    if (bom) {
      if (bom.BOM_State === "FG_COMPLETE" || bom.BOM_State === "COMPS_COMPLETE") {
        closeBOM(bomId);
      } else {
        navigate(`/dashboard/bom/qualify/${encodedBomId}`);
      }
    }
  }, [bom, encodedBomId, bomId, navigate, closeBOM]);

  useEffect(() => {
    if (isError) {
      Swal.fire({
        title: "Error!",
        text: `Failed to qualify BOM. ${error.error || "Unknown error."}`,
      }).then(() => {
        navigate(`/dashboard/bom/${encodedBomId}`, { replace: true });
      });
    }
  }, [isError, error, navigate, encodedBomId]);

  if (!bomId) {
    return (
      <div className="container pt-5">
        <div className="row">
          <div className="d-flex justify-content-center">
            <LoadingSpinner />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container pt-5">
      <div className="row mb-5">
        <h1 className="text-center">Qualify BOM {bomId}</h1>
      </div>
      <div className="row">
        <div className="col-lg-4" />
        <div className="col-lg-4">
          <p className="alert alert-info text-center">Please wait while we qualify this BOM.</p>
        </div>
        <div className="col-lg-4" />
      </div>
    </div>
  );
}
