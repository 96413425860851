/* eslint-disable no-nested-ternary */
import { nanoid } from "nanoid";

import { htsDisplay } from "../../utils/bom";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

export default function QuestionsListGroup({ questions, forComponent }) {
  if (!questions) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <ol className="list-group list-unstyled">
      {questions.map((question, idx) => (
        <li key={nanoid()} className="list-group-item d-flex justify-content-between align-items-center">
          <div className="ms-2 me-auto">
            <div className="fw-bold mb-2">
              Question #{idx + 1} {forComponent && `for component ${htsDisplay(question.RuleID.split("#")[0])}`}
            </div>
            <p style={{ maxWidth: "90%" }} className="m-0">
              {question.Question}
            </p>
          </div>
          <span className="badge bg-primary rounded-pill">{question.Ans && question.Ans.length > 0 ? (question.Ans === "Y" ? "Yes" : "No") : "?"}</span>
        </li>
      ))}
    </ol>
  );
}
