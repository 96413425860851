import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { clearState, incrementBOMImportIndex, successfulImportsSelector } from "../../features/fileImport/fileImportSlice";

export default function FinishImport() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const successfulImports = useSelector(successfulImportsSelector);
  useEffect(() => {
    if (location && location.state) {
      const { action } = location.state;
      if (action === "continue") {
        dispatch(incrementBOMImportIndex());
        navigate("/dashboard/import-bom-csv/");
      } else if (action === "summary") {
        Swal.fire({
          title: "Success",
          text: `Imported ${successfulImports.length} BOMs.`,
        }).then(() => {
          dispatch(clearState(true));
          navigate("/dashboard", { replace: true });
        });
      } else {
        dispatch(clearState(true));
        navigate(`/dashboard/bom/${location.state.bomId}/`);
      }
    }
  }, [dispatch, location, navigate, successfulImports]);
  return (
    <div className="container pt-5">
      <div className="row">
        <div className="d-flex justify-content-center">
          <LoadingSpinner />
        </div>
      </div>
    </div>
  );
}
