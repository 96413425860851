import PropTypes from "prop-types";
import React from "react";

import bomSummaryPropType from "../../../propTypes/bomSummaryPropType";
import QualifiedBOMCard from "../components/QualifiedBOMCard/QualifiedBOMCard";
import { useGenerateCertificateContext } from "./GenerateCertificateContext";

function QualifiedBOMCards({ qualifiedBoms }) {
  const { bomIsSelected, addOrRemoveBomId } = useGenerateCertificateContext();
  return (
    <div className="row">
      <div className="col-lg-2" />
      <div className="col-lg-8">
        {qualifiedBoms.map((bom) => (
          <div key={bom.BOMID} className="mb-3">
            <QualifiedBOMCard bomId={bom.BOMID} selected={bomIsSelected(bom.BOMID)} onClick={() => addOrRemoveBomId(bom.BOMID)} />
          </div>
        ))}
      </div>
      <div className="col-lg-2" />
    </div>
  );
}

QualifiedBOMCards.propTypes = {
  qualifiedBoms: PropTypes.arrayOf(bomSummaryPropType).isRequired,
};

export default QualifiedBOMCards;
