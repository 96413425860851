/* eslint jsx-a11y/label-has-associated-control: 0 */
import * as Sentry from "@sentry/react";
import { Field, Form, Formik } from "formik";
import { nanoid } from "nanoid";
import React, { useCallback, useMemo, useState } from "react";
import Swal from "sweetalert2";

import countries from "../../data/countries.json";
import { useCreateCustomerMutation, useGetCustomerQuery, useUpdateCustomerMutation } from "../../features/api/customers";
import FormError from "../FormError";
import FormikFieldGroup from "../FormikFieldGroup/FormikFieldGroup";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import customerProfileFormSchema from "./customerProfileFormSchema";

function CustomerProfileForm() {
  const { data: customer, isLoading, isError, error } = useGetCustomerQuery();
  const createCustomer = useCreateCustomerMutation()[0];
  const updateCustomer = useUpdateCustomerMutation()[0];
  const [errorMsg, setErrorMsg] = useState(null);

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      setSubmitting(true);
      const requestBody = {
        name: values.name,
        company_name: values.companyName,
        phone_number: values.phoneNumber,
        street_address: values.streetAddress,
        city: values.city,
        state: values.state,
        country: values.country,
        zip_code: values.zipCode,
        tax_id: values.taxId,
        subscription_type: values.subscriptionType,
      };
      if (customer) {
        updateCustomer(requestBody)
          .then(() => {
            Swal.fire({
              title: "Success!",
              text: "Your profile has been updated!",
              icon: "success",
              confirmButtonText: "Dismiss",
            });
          })
          .catch((err) => {
            Sentry.captureException(err);
            setErrorMsg(JSON.stringify(err.response?.data || "Unknown error."));
          })
          .finally(() => {
            setSubmitting(false);
          });
      } else {
        createCustomer(requestBody)
          .then(() => {})
          .catch((err) => {
            Sentry.captureException(err);
            setErrorMsg(JSON.stringify(err.response?.data || "Unknown error."));
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    },
    [createCustomer, customer, updateCustomer],
  );

  const formInitialValues = useMemo(
    () => ({
      name: customer?.name || "",
      companyName: customer?.company_name || "",
      phoneNumber: customer?.phone_number || "",
      streetAddress: customer?.street_address || "",
      city: customer?.city || "",
      state: customer?.state || "",
      country: customer?.country || "US",
      zipCode: customer?.zip_code || "00000",
      taxId: customer?.tax_id || "",
      subscriptionType: customer?.subscription_type || "payg",
    }),
    [customer],
  );

  let body;

  if (isLoading) {
    body = (
      <div className="d-flex justify-content-center">
        <LoadingSpinner />
      </div>
    );
  } else if (isError && error.status !== 404) {
    body = (
      <div>
        <p className="alert alert-danger text-center">Failed to retrieve your profile. {JSON.stringify(error.data || "Unknown error")}.</p>
      </div>
    );
  } else {
    body = (
      <div>
        {errorMsg && <p className="alert alert-danger text-center mb-5">{errorMsg}</p>}
        <Formik validationSchema={customerProfileFormSchema} onSubmit={handleSubmit} initialValues={formInitialValues}>
          {({ isSubmitting, errors }) => (
            <Form>
              <div className="row mb-3">
                <div className="col">
                  <FormikFieldGroup name="name" placeholder="Ex. John Doe" label="Name*" type="text" required />
                </div>
                <div className="col">
                  <FormikFieldGroup name="companyName" placeholder="Ex. ACME Inc." label="Company Name*" type="text" required />
                </div>
                <div className="col">
                  <FormikFieldGroup name="taxId" placeholder="Your Tax/VAT Number" label="Tax/VAT Number" type="text" />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label htmlFor="inputEmail" className="form-label">
                    Email Address
                  </label>
                  <input type="email" disabled value={customer?.email} className="form-control" />
                </div>
                <div className="col">
                  <FormikFieldGroup name="phoneNumber" placeholder="Ex. +16317487946" type="text" label="Phone Number*" required />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col">
                  <label className="form-label w-100">
                    Street Address*
                    <Field
                      type="text"
                      name="streetAddress"
                      placeholder="Your street address"
                      className={`mt-1 form-control ${errors.streetAddress ? "is-invalid" : ""}`}
                      required
                    />
                    {errors.streetAddress && <FormError error={errors.streetAddress} />}
                  </label>
                </div>

                <div className="col">
                  <label className="form-label w-100">
                    City*
                    <Field type="text" name="city" placeholder="Your city" className={`mt-1 form-control ${errors.city ? "is-invalid" : ""}`} required />
                    {errors.city && <FormError error={errors.city} />}
                  </label>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col">
                  <label className="form-label w-100">
                    State*
                    <Field type="text" name="state" placeholder="Your state" className={`mt-1 form-control ${errors.state ? "is-invalid" : ""}`} required />
                    {errors.state && <FormError error={errors.state} />}
                  </label>
                </div>
                <div className="col">
                  <label className="form-label w-100">
                    Country*
                    <Field as="select" name="country" className={`form-select mt-1 ${errors.country ? "is-invalid" : ""}`}>
                      {countries.map((country) => (
                        <option key={nanoid()} value={country.code}>
                          {country.name}
                        </option>
                      ))}
                    </Field>
                  </label>
                </div>

                <div className="col">
                  <label htmlFor="inputZipCode" className="form-label">
                    Zip Code*
                  </label>
                  <Field name="zipCode" className={`form-control ${errors && errors.zipCode ? "is-invalid" : ""}`} placeholder="Ex. 12345" />
                  {errors && errors.zipCode && <FormError error={errors.zipCode} />}
                </div>
              </div>
              <button type="submit" className="btn btn-primary w-100" disabled={isSubmitting}>
                Save
              </button>
            </Form>
          )}
        </Formik>
      </div>
    );
  }

  return body;
}

export default CustomerProfileForm;
