import PropTypes from "prop-types";
import React, { useCallback } from "react";

import useCloneBom from "../../../../hooks/useCloneBom";
import useQualifyBOM from "../../../../hooks/useQualifyBOM";
import bomDetail from "../../../../propTypes/bomDetail";
import { CONTINUE_ACTION_TYPE, REAPPLY_ACTION_TYPE } from "./constants";

function ActionButton({ name, actionType, bom }) {
  const { qualify } = useQualifyBOM(bom?.BOMID);
  const { handleClone } = useCloneBom();

  const handleClick = useCallback(() => {
    if (actionType === CONTINUE_ACTION_TYPE) {
      qualify();
    } else if (actionType === REAPPLY_ACTION_TYPE) {
      handleClone(bom);
    }
  }, [actionType, qualify, bom, handleClone]);

  return (
    <button onClick={handleClick} type="button" className="btn btn-outline-primary fw-bold">
      {name}
    </button>
  );
}

ActionButton.propTypes = {
  name: PropTypes.string.isRequired,
  actionType: PropTypes.oneOf([CONTINUE_ACTION_TYPE, REAPPLY_ACTION_TYPE]).isRequired,
  bom: bomDetail.isRequired,
};

export default ActionButton;
