import { Field, Form, Formik } from "formik";
import React from "react";
import { object, string } from "yup";

import FormError from "../../components/FormError";
import FormikPasswordField from "../../components/FormikPasswordField/FormikPasswordField";
import password from "../../schemas/password";
import { useRegistrationContext } from "./RegistrationContext";

const schema = object().shape({
  username: string().required("Please enter a username."),
  email: string().email("Please enter a valid email address.").required("Please enter an email address."),
  password1: password,
  password2: string().required("Please enter a password confirmation."),
});

export default function RegistrationForm() {
  const { handleSubmit, errorMsg, setShowTermsModal } = useRegistrationContext();

  return (
    <Formik onSubmit={handleSubmit} validationSchema={schema} initialValues={{ username: "", email: "", password1: "", password2: "", agreeTos: false }}>
      {({ errors, isSubmitting }) => (
        <Form>
          <p className="fw-bold mb-4">Personal information</p>
          <div className="mb-3">
            <label htmlFor="inputUsername" className="form-label">
              Username
            </label>
            <Field className={`form-control ${errors && errors.username ? "is-invalid" : ""}`} placeholder="Your username" name="username" />
            {errors && errors.username && <FormError error={errors.username} />}
          </div>

          <div className="mb-3">
            <label htmlFor="inputEmail" className="form-label">
              Email Address
            </label>
            <Field type="email" className={`form-control ${errors && errors.email ? "is-invalid" : ""}`} placeholder="Ex. john@gmail.com" name="email" />
            {errors && errors.email && <FormError error={errors.email} />}
          </div>

          <div className="mb-3">
            <label htmlFor="inputPassword1" className="form-label">
              Password
            </label>
            <FormikPasswordField showHelp name="password1" id="inputPassword1" placeholder="********" className={errors && errors.password1 ? "is-invalid" : ""} />
          </div>

          <div className="mb-4">
            <label htmlFor="inputPassword2" className="form-label">
              Confirm Password
            </label>
            <FormikPasswordField
              name="password2"
              id="inputPassword2"
              placeholder="The same password as above"
              className={errors && errors.password2 ? "is-invalid" : ""}
            />
          </div>
          <div className="mb-4">
            <p>
              By filling in your information above and checking the box, you understand and agree to the
              <button onClick={() => setShowTermsModal(true)} type="button" className="btn no-border-no-outline text-uppercase fw-bold text-secondary p-0 ms-1">
                Terms of Service
              </button>
            </p>
          </div>
          {errorMsg && <p className="alert alert-danger">{errorMsg}</p>}
          <button disabled={isSubmitting} type="submit" className="btn w-100 btn-secondary fw-bold text-uppercase">
            Next
          </button>
        </Form>
      )}
    </Formik>
  );
}
