import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { selectedBomIdsSelector } from "../../../features/certificateOfOrigin/certificateOfOriginSlice";

export default function useSelectedBomsRequired() {
  const selectedBomIds = useSelector(selectedBomIdsSelector);
  const navigate = useNavigate();

  useEffect(() => {
    if (!Array.isArray(selectedBomIds) || selectedBomIds.length === 0) {
      Swal.fire({
        title: "Error!",
        text: "Please select some finished goods first.",
        icon: "error",
      }).then(() => {
        navigate("/dashboard/certificate-of-origin/generate/");
      });
    }
  }, [selectedBomIds, navigate]);
}
