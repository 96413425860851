import useBom from "../../hooks/useBom";
import { htsDisplay } from "../../utils/bom";
import getExtCost from "../../utils/getExtCost";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

export default function ComponentsTable({ bomId }) {
  const { bom } = useBom(bomId);

  if (!bom) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">Part Number</th>
          <th scope="col">HTS</th>
          <th scope="col">Description</th>
          <th scope="col">Category</th>
          <th scope="col">Originating</th>
          <th scope="col">Quantity</th>
          <th scope="col">Units</th>
          <th scope="col">Unit Cost ({bom.FG.Currency})</th>
          <th scope="col">Ext Cost ({bom.FG.Currency})</th>
          <th scope="col">Origin</th>
        </tr>
      </thead>
      <tbody>
        {bom.Components.map((component, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <tr key={idx}>
            <td>{component.Part}</td>
            <td>{htsDisplay(component.Hts)}</td>
            <td>{component.Desc}</td>
            <td>{component.Category}</td>
            <td>{component.Originating === "Y" || component.USMCA === "Y" ? "Yes" : "No"}</td>
            <td>{component.Qty}</td>
            <td>{component.Unit}</td>
            <td>{component.Cost}</td>
            <td>{getExtCost(component.Cost, component.Qty)}</td>
            <td>{component.Origin}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
