/* eslint jsx-a11y/label-has-associated-control: 0 */
import { Field, Form, Formik } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";
import { object, string } from "yup";

import FormError from "../../components/FormError";
import useDeleteAccount from "./useDeleteAccount";

const schema = object().shape({
  confirmation: string()
    .required("Please type Delete in the box.")
    .matches(/Delete/, "Please type Delete"),
});
function DeleteAccountModal({ onHide }) {
  const { handleSubmit, errorMsg } = useDeleteAccount();
  return (
    <Modal centered show onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h3>Delete account</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMsg && <p className="alert alert-danger text-center mb-5">{errorMsg}</p>}
        <p className="alert alert-danger text-center mb-5">
          This process will delete everything associated with your account.
          <br />
          Are you sure you want to continue?
        </p>
        <Formik validationSchema={schema} onSubmit={handleSubmit} initialValues={{ confirmation: "" }}>
          {({ isSubmitting, errors }) => (
            <Form>
              <div className="form-group">
                {/* eslint-disable-next-line  */}
                <label className="form-label w-100">
                  Type the word Delete to confirm*
                  <Field type="text" name="confirmation" className={`form-control mt-1 ${errors.confirmation ? "is-invalid" : ""}`} />
                  {errors.confirmation && <FormError error={errors.confirmation} />}
                </label>
              </div>
              <div className="d-flex justify-content-between">
                <button onClick={onHide} type="button" className="btn btn-outline-secondary">
                  Cancel
                </button>
                <button type="submit" className="btn btn-danger" disabled={isSubmitting}>
                  Delete
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

DeleteAccountModal.propTypes = {
  onHide: PropTypes.func.isRequired,
};

export default DeleteAccountModal;
