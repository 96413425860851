import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useListCertificatesQuery } from "../../features/api/certificates";
import { SORT_BY_LATEST, SORT_BY_OLDEST } from "./constants";

/**
 *
 * @returns {{certificates: {Array}, isError: {Boolean}, error: {Object}, isLoading: {Boolean}}}
 */
export default function useCertificates() {
  const { data: certificates, isError, error, isLoading } = useListCertificatesQuery();
  const [searchedCertificates, setSearchedCertificates] = useState([]);
  const [searchQuery, setSearchQuery] = useState(null);
  const [sortedCertificates, setSortedCertificates] = useState([]);
  const [sortBy, setSortBy] = useState(SORT_BY_LATEST);
  const searchParams = useSearchParams()[0];

  const getSorted = useCallback((dayA, dayB, method) => {
    if (dayA.isAfter(dayB)) {
      return method === SORT_BY_OLDEST ? 1 : -1;
    }
    return method === SORT_BY_OLDEST ? -1 : 1;
  }, []);

  useEffect(() => {
    if (searchParams && sortBy) {
      setSortBy(sortBy);
    } else {
      setSortBy(SORT_BY_LATEST);
    }

    if (searchParams) {
      const s = searchParams.get("sort-by");
      const q = searchParams.get("query");

      if (s) {
        setSortBy(s);
      } else {
        setSortBy(null);
      }

      if (q) {
        setSearchQuery(decodeURI(q));
      } else {
        setSearchQuery(null);
      }
    }

    return () => {
      setSortBy(SORT_BY_LATEST);
      setSearchQuery(null);
    };
  }, [searchParams, sortBy]);

  useEffect(() => {
    if (certificates && Array.isArray(certificates)) {
      if (searchQuery) {
        setSearchedCertificates(certificates.filter((cert) => cert.certificate_id.match(new RegExp(searchQuery)) !== null));
      } else {
        setSearchedCertificates([...certificates]);
      }
    } else {
      setSearchedCertificates([]);
    }
  }, [searchQuery, certificates]);

  useEffect(() => {
    if (searchedCertificates && Array.isArray(searchedCertificates)) {
      if (sortBy === SORT_BY_OLDEST) {
        setSortedCertificates(
          [...searchedCertificates].sort((certA, certB) => {
            const dayA = dayjs(certA.created_at);
            const dayB = dayjs(certB.created_at);
            return getSorted(dayA, dayB, SORT_BY_OLDEST);
          }),
        );
      } else {
        setSortedCertificates(
          [...searchedCertificates].sort((certA, certB) => {
            const dayA = dayjs(certA.created_at);
            const dayB = dayjs(certB.created_at);
            return getSorted(dayA, dayB, SORT_BY_LATEST);
          }),
        );
      }
    } else {
      setSortedCertificates([]);
    }

    return () => {
      setSortedCertificates([]);
    };
  }, [sortBy, searchedCertificates, getSorted]);
  return useMemo(() => ({ certificates: sortedCertificates, isError, error, isLoading, searchQuery }), [sortedCertificates, isError, error, isLoading, searchQuery]);
}
