import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

import CustomerAvatarChanger from "../../../components/CustomerAvatarChanger/CustomerAvatarChanger";
import { avatarDataUriSelector, avatarLoadingSelector, setAvatarIsLoading } from "../../../features/customerProfile/customerProfileSlice";
import useAvatarUpdate from "./useAvatarUpdate";
import usePresignedPost from "./usePresignedPost";
import useUpload from "./useUpload";

function Avatar() {
  const [avatarFile, setAvatarFile] = useState(false);
  const { presignedPostData, avatarFileKey } = usePresignedPost(avatarFile);
  const avatarImage = useSelector(avatarDataUriSelector);
  const avatarIsLoading = useSelector(avatarLoadingSelector);
  const { uploadDone, uploadFailed, setUploadDone } = useUpload(presignedPostData, avatarFile);
  const { updateDone, updateFailed } = useAvatarUpdate(uploadDone, avatarFileKey);
  const dispatch = useDispatch();

  const handleSetAvatar = useCallback(
    (fileObj) => {
      setAvatarFile(fileObj);
      dispatch(setAvatarIsLoading(true));
    },
    [dispatch],
  );

  useEffect(() => {
    if (uploadFailed || updateFailed) {
      dispatch(setAvatarIsLoading(false));
    }

    if (updateDone) {
      Swal.fire({
        title: "Success!",
        text: "Your avatar was updated.",
        icon: "success",
        confirmButtonText: "OK",
      });
      dispatch(setAvatarIsLoading(false));
      setUploadDone(false);
    }

    return () => {
      dispatch(setAvatarIsLoading(false));
    };
  }, [uploadFailed, updateDone, updateFailed, setUploadDone, dispatch]);

  return (
    <div className="border-bottom pb-5">
      <CustomerAvatarChanger setImage={handleSetAvatar} currentImage={avatarImage} isLoading={avatarIsLoading} />
    </div>
  );
}

export default Avatar;
