import { useEffect, useMemo, useState } from "react";

import { useUpdateAvatarMutation } from "../../../features/api/customers";

export default function useAvatarUpdate(uploadDone, fileKey) {
  const [updateDone, setUpdateDone] = useState(false);
  const [updateFailed, setUpdateFailed] = useState(false);
  const [updateAvatar, { isError, isSuccess }] = useUpdateAvatarMutation();

  useEffect(() => {
    if (uploadDone) {
      updateAvatar({ fileKey });
    }
  }, [uploadDone, fileKey, updateAvatar]);

  useEffect(() => {
    if (isError) {
      setUpdateFailed(true);
    } else if (isSuccess) {
      setUpdateFailed(false);
      setUpdateDone(true);
    }
  }, [isError, isSuccess]);

  return useMemo(
    () => ({
      updateDone,
      updateFailed,
      setUpdateDone,
      setUpdateFailed,
    }),
    [updateDone, updateFailed, setUpdateDone, setUpdateFailed],
  );
}
