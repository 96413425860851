import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { bomCountSelector } from "../../features/usageLimits/usageLimitsSlice";

export default function useBOMUsage() {
  const [maxReached, setMaxReached] = useState(false);
  const bomCount = useSelector(bomCountSelector);

  useEffect(() => {
    if (bomCount === 0) {
      setMaxReached(true);
    } else {
      setMaxReached(false);
    }
    return () => {
      setMaxReached(false);
    };
  }, [bomCount]);

  return useMemo(
    () => ({
      maxReached,
    }),
    [maxReached],
  );
}
