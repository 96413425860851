import * as Sentry from "@sentry/react";
import { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";

export default function useUpload(presignedPostData, file) {
  const [startUpload, setStartUpload] = useState(false);
  const [uploadDone, setUploadDone] = useState(false);
  const [uploadFailed, setUploadFailed] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (presignedPostData && file) {
      setStartUpload(true);
    }
  }, [presignedPostData, file]);

  useEffect(
    () => () => {
      setUploadDone(false);
      setStartUpload(false);
    },
    [],
  );

  useEffect(() => {
    if (error) {
      setStartUpload(false);
      setUploadDone(false);
      Swal.fire({
        title: "Error!",
        text: error,
        icon: "error",
      });
    }
  }, [error, startUpload]);

  useEffect(() => {
    if (startUpload && presignedPostData && file) {
      const formData = new FormData();
      Object.entries(presignedPostData.fields).forEach(([k, v]) => {
        formData.append(k, v);
      });
      formData.append("file", file);

      fetch(presignedPostData.url, {
        method: "POST",
        body: formData,
      })
        .then((res) => ({ error: res.ok, code: res.status }))
        .then((result) => {
          if ((result.error && result.code === 500) || result.code === 400 || result.code === 422) {
            setUploadFailed(true);
            setError(`Upload failed, please try again. Reason: ${JSON.stringify(result.data)}`);
          } else {
            setUploadDone(true);
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
          setUploadFailed(true);
          setError("Upload failed, please try again. Reason: server error.");
        });
    }
  }, [startUpload, presignedPostData, file]);

  return useMemo(
    () => ({
      uploadDone,
      uploadFailed,
      setUploadDone,
      setUploadFailed,
    }),
    [uploadDone, uploadFailed, setUploadDone, setUploadFailed],
  );
}
